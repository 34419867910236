import { createSlice } from '@reduxjs/toolkit';
import type { RootState } from 'store';

import {
  AIanalyticsRequest,
  StreamSuggestionResponse,
  SuggestedMessageRequest,
  SuggestionResponse
} from './aiAssistant.types';
import { apiSlice } from '../api/apiSlice';

export const aiAssistantApi = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getSuggestedResponse: build.mutation<SuggestionResponse, SuggestedMessageRequest>({
      query: ({ body, signal, force }) => ({
        url: `/ai/suggestions`,
        method: 'POST',
        ...(force && {
          headers: {
            'Cache-Control': 'no-cache'
          }
        }),
        body,
        signal
      })
    }),
    getStreamSuggestedResponse: build.mutation<StreamSuggestionResponse, SuggestedMessageRequest>({
      query: ({ body, signal, force }) => ({
        url: `/ai/stream`,
        method: 'POST',
        ...(force && {
          headers: {
            'Cache-Control': 'no-cache'
          }
        }),
        body,
        signal
      })
    }),
    sendAnalytics: build.mutation<null, AIanalyticsRequest>({
      query: (body) => {
        return {
          url: '/ai/analytics',
          method: 'POST',
          headers: {
            'API-KEY': import.meta.env.VITE_API_KEY
          },
          body
        };
      }
    })
  })
});

export const {
  useGetSuggestedResponseMutation,
  useGetStreamSuggestedResponseMutation,
  useSendAnalyticsMutation
} = aiAssistantApi;

const initialState = {
  hashKey: '',
  messageSuggestion: ''
};

export const aiAssistantSlice = createSlice({
  name: 'aiAssistant',
  initialState: initialState,
  reducers: {
    setMessageSuggestion: (state, action) => {
      state.messageSuggestion = action.payload;
    },
    setHashKey: (state, action) => {
      state.hashKey = action.payload;
    },
    resetAIasistantState: () => initialState
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      aiAssistantApi.endpoints.getStreamSuggestedResponse.matchFulfilled,
      (state, action) => {
        if (action.payload) {
          state.hashKey = action.payload.info.key;
        }
      }
    );
    builder.addMatcher(
      aiAssistantApi.endpoints.getSuggestedResponse.matchFulfilled,
      (state, action) => {
        if (action.payload) {
          state.hashKey = action.payload.info.key;
        }
      }
    );
  }
});

export default aiAssistantSlice.reducer;

export const { setMessageSuggestion, setHashKey, resetAIasistantState } = aiAssistantSlice.actions;

export const selectAiAssistant = (state: RootState) => state.aiAssistant;
