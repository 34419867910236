import { Common } from '@thecvlb/design-system/lib/src';
import classNames from 'classnames';
import { Status } from 'enums/status';
import { formatCategoryLabel } from 'utils/formatCategoryLabel';

import { getTaskCategoryText } from './taskCategoryLabel.settings';
import { TaskCategoryLabelProps } from './taskCategoryLabel.types';

const TaskCategoryLabel: React.FC<TaskCategoryLabelProps> = ({
  dataTestId,
  type,
  appointmentStatus,
  isSentRequests,
  tags,
  status
}) => {
  const className = classNames(
    'flex items-center justify-center font-semibold gap-1 text-sm text-gray-700 rounded-md py-1 px-2 w-fit whitespace-nowrap',
    { 'bg-secondary-100': type === 'messages' },
    { 'bg-orange-100': type === 'onboarding' },
    { 'bg-orange-100': type === 'pre-consult' },
    {
      'bg-yellow-100':
        type === 'appointment' || appointmentStatus?.toLowerCase().includes('appointment')
    },
    { 'bg-primary-50': type === 'post-consult' },
    { 'bg-violet-100': type === 'requests' && !isSentRequests },
    { 'bg-violet-100': type === 'request • pa' || type === 'request • cancel account' },
    { 'bg-blue-100': type === 'review' },
    { 'bg-red-100': type === 'front desk' },
    { 'bg-gray-100': type === 'history' },
    { 'bg-orange-100': type === 'queue' },
    { 'bg-green-100': type === 'renewal' },
    { 'bg-warm-gray-100': type === 'async' },
    { 'bg-white border border-violet-200': isSentRequests }
  );

  const circleClassName = classNames(
    'w-2 h-2 border border-white rounded-full',
    { 'bg-secondary': type === 'messages' },
    { 'bg-orange': type === 'onboarding' },
    { 'bg-orange': type === 'pre-consult' },
    {
      'bg-yellow':
        type === 'appointment' || appointmentStatus?.toLowerCase().includes('appointment')
    },
    { 'bg-primary-700': type === 'post-consult' },
    { 'bg-violet': type === 'requests' && !isSentRequests },
    { 'bg-violet': type === 'request • pa' || type === 'request • cancel account' },
    { 'bg-blue': type === 'review' },
    { 'bg-red': type === 'front desk' },
    { 'bg-gray-700': type === 'history' },
    { 'bg-green-500': type === 'renewal' },
    { 'bg-warm-gray-500': type === 'async' },
    { 'bg-violet-500': isSentRequests }
  );

  const queueLightningClasses = 'w-4 h-4 text-orange-500';

  const text = getTaskCategoryText(type, appointmentStatus, isSentRequests, tags);
  const isAppointmentCompleted =
    !appointmentStatus?.toLowerCase().includes('cancelled') &&
    !appointmentStatus?.toLowerCase().includes('missed');
  const isTaskCompleted = status?.toLowerCase() === Status.Completed.toLowerCase();
  const isCompleted = appointmentStatus
    ? isAppointmentCompleted && isTaskCompleted
    : isTaskCompleted;
  const categoryLabel =
    text === 'request • pa'
      ? 'Request • PA'
      : formatCategoryLabel(text, true) + (isCompleted ? ' • Completed' : '');
  const categoryLabelTitle = categoryLabel.replace('MI', 'Medically-insured');

  return (
    <div data-testid={dataTestId} className={className}>
      {type === 'queue' ? (
        <Common.Icon name="lightning" className={queueLightningClasses} />
      ) : (
        <i className={circleClassName} />
      )}
      <span data-testid="task_type" className="first-letter:uppercase" title={categoryLabelTitle}>
        {categoryLabel}
      </span>
    </div>
  );
};

export default TaskCategoryLabel;
