import { createColumnHelper } from '@tanstack/react-table';
import dayjs from 'dayjs';
import { DateFormat } from 'enums/dateFormats';

import EditCell from './EditCell';
import { StaffsTableProps } from './staffsTable.types';
import BulkEditCheckbox from '../BasicTable/BulkEditCheckox';
import Header from '../Header';

const columnHelper = createColumnHelper<StaffsTableProps>();

export const columns = [
  columnHelper.accessor('bulkEditIds', {
    header: ({ table }) => (
      <BulkEditCheckbox
        onChange={table.getToggleAllRowsSelectedHandler()}
        checked={table.getIsAllRowsSelected()}
        reset={table.resetRowSelection}
        isHeading
        ids={table.getGroupedRowModel().rows.map((id) => id.original._id)}
      />
    ),
    cell: ({ row }) => (
      <BulkEditCheckbox
        onChange={row.getToggleSelectedHandler()}
        checked={row.getIsSelected()}
        id={row.original._id}
      />
    )
  }),
  columnHelper.accessor('name', {
    header: () => <Header text="User" sortField="name" />
  }),
  columnHelper.accessor('lastLogin', {
    header: () => <Header text="Last active" sortField="lastLogin" />,
    cell: (info) => info.getValue() && dayjs(info.getValue()).format(DateFormat.MMM_D__YYYY)
  }),
  columnHelper.accessor('userType.name', {
    header: () => <Header text="User type" sortField="userType" />
  }),
  columnHelper.accessor('activeStates', {
    header: () => <Header text="Licensed in" sortField="activeStates" />,
    cell: (info) => <span>{Array.from(new Set(info.getValue() || [])).join(', ')}</span>
  }),
  columnHelper.accessor('email', {
    header: () => <Header text="Email" sortField="email" />,
    cell: (info) => <p className="max-w-[150px] truncate text-sm font-medium">{info.getValue()}</p>
  }),
  columnHelper.accessor('_id', {
    header: () => <Header text="Unique ID" sortField="uniqueId" />
  }),
  columnHelper.accessor('status', {
    header: () => <Header text="Status" sortField="status" />,
    cell: (info) => (
      <span className={`capitalize ${info.getValue() === 'active' ? 'text-blue' : 'text-red'}`}>
        {info.getValue()}
      </span>
    )
  }),
  columnHelper.accessor('actions', {
    header: () => <span className="text-sm font-bold">Actions</span>,
    cell: (info) => <EditCell id={info.row.original._id} />,
    enableSorting: false
  })
];
