import { DayPicker } from 'react-day-picker';
import styled from 'styled-components';
import tw from 'twin.macro';

export const StyledDatePickerInput = styled(DayPicker)`
  .rdp-cell {
    ${tw`text-sm`}
  }

  .rdp-caption_label {
    --rdp-accent-color: #fff !important;
    --rdp-outline: 2px solid #fff;
    --rdp-background-color: #fff;
    ${tw`text-gray-700 font-bold text-lg`}
  }

  .rdp-chevron {
    ${tw`!size-4`}

  .rdp-day .rdp-selected {
    ${tw`text-white font-bold`}
  }

  .rdp-selected:not(.rdp-disabled):not(.rdp-outside) {
    ${tw`bg-primary hover:bg-primary hover:opacity-90`};
  }

  .rdp-day:not(.rdp-disabled):not(.rdp-selected) {
    ${tw`text-gray-700`};
  }

  .rdp-day:not(.rdp-disabled):not(.rdp-selected):hover {
    ${tw`text-primary font-bold bg-secondary-100`};
  }
`;
