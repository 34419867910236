import { ResponseProps } from 'store/api/api.types';

export type SuggestionFlowType = 'PH' | 'other' | 'patientSummary' | 'taskEscalation';

export type SuggestedMessageRequest = {
  body: {
    flow: SuggestionFlowType;
    userId: string;
    text: string;
  };
  signal?: AbortSignal;
  force?: boolean; // force request withiut cache
};

export type PatientSummaryRequest = {
  body: { 'in-2': string };
};

export type PatientSummaryResponse = {
  outputs: {
    'out-30'?: string;
    'out-34'?: string;
    'out-35'?: string;
  };
  run_id: string;
};

export type SuggestionRequest = {
  body: { 'in-0': string; 'in-1'?: string; 'in-2'?: string; 'in-3'?: string };
  signal?: AbortSignal;
  isPH?: boolean;
  force?: boolean;
};

export type SuggestionDataResponse = {
  outputs?: Outputs;
  run_id?: string;
};

export type SuggestionResponse = ResponseProps<
  SuggestionDataResponse,
  { key: string; status: RequestStatusType }
>;

export type Outputs = {
  'out-6': string;
  'out-11'?: string;
  'out-12'?: string;
  'out-14'?: string;
  'out-18'?: string;
  'out-30'?: string;
  'out-32'?: string;
  'out-33'?: string;
  'out-34'?: string;
  'out-36'?: string;
};

export type SuggestionStreamChunk = {
  requestId: string;
  run_id?: string;
  order: number;
  metadata?: {};
  outputs?: Outputs;
  response?: {
    outputs: Outputs;
    run_id: string;
  };
  finished?: boolean;
  closed?: boolean;
};

export type StreamSuggestionResponse = ResponseProps<
  SuggestionStreamChunk,
  { key: string; status: RequestStatusType }
>;

export type AIanalyticsEvents =
  | 'isRejectSuggestion'
  | 'isClickedPastResponse'
  | 'isEditResponse'
  | 'isEditRequest';

export type AIanalyticsRequest = { event: AIanalyticsEvents; key: string };

export const RequestStatus = {
  IN_PROGRESS: 'in-progress',
  COMPLETED: 'completed'
} as const;

export type RequestStatusType = (typeof RequestStatus)[keyof typeof RequestStatus];
