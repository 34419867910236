import { MembershipPlanProps, PricePointProps } from 'components/crossSell/PlanType/planType.types';
import { PatientProps } from 'store/patients/patients.types';

import { ChangePlanSteps } from './changePlan.types';
import ChoosePlan from './ChoosePlan';
import ConfirmPayment from './ConfirmPayment';

export const renderContent = (
  step: ChangePlanSteps,
  setNewPlan: (type?: MembershipPlanProps) => void,
  setNewPricePoint: (type?: PricePointProps) => void,
  patientInfo: PatientProps,
  newPlan?: MembershipPlanProps,
  currentPlan?: MembershipPlanProps,
  activePP?: PricePointProps,
  newPricePoint?: PricePointProps
) => {
  if (!!setNewPlan && newPlan) {
  }
  switch (step) {
    case ChangePlanSteps.ConfirmPayment:
      return (
        <ConfirmPayment
          newPlan={newPlan}
          patientInfo={patientInfo}
          currentPlan={currentPlan}
          activePP={activePP}
          newPricePoint={newPricePoint}
        />
      );
    default:
      return (
        <ChoosePlan
          newPlan={newPlan}
          setNewPlan={setNewPlan}
          patientInfo={patientInfo}
          currentPlan={currentPlan}
          activePP={activePP}
          setNewPricePoint={setNewPricePoint}
          newPricePoint={newPricePoint}
        />
      );
  }
};
