import React from 'react';

import capitalize from 'lodash/capitalize';
import { CreditType, PaymentMethodType } from 'store/patients/patients.types';

const PaymentCell: React.FC<{
  paymentInfo?: PaymentMethodType;
  credit?: CreditType | null;
}> = ({ paymentInfo, credit }) => {
  const cardBrandTitle = `${capitalize(paymentInfo?.cardBrand)} ****${paymentInfo?.maskedCardNumber.slice(-4)}`;

  if (!credit && !paymentInfo) return null;

  if (credit && !paymentInfo) {
    return <span className="font-semibold">LifeMd Credit</span>;
  }

  return (
    <span className="font-semibold">
      {paymentInfo?.type === 'paypal_account' ? 'PayPal' : cardBrandTitle}
    </span>
  );
};

export default PaymentCell;
