import { AGING_ALERT_UNIT_OPTIONS, ALERT_TYPE_OPTIONS } from 'constants/alerts';
import { STATUS_OPTIONS } from 'constants/status';

import { useEffect, useState } from 'react';

import { OptionProps } from '@thecvlb/design-system/lib/src/common/Select';
import { notifySuccess } from 'components/common/Toast/Toast';
import ControlledMultiSelect from 'components/forms/controlled/ControlledMultiSelect';
import ControlledSelect from 'components/forms/controlled/ControlledSelect';
import InputField from 'components/forms/controlled/InputField';
import PopupFooter from 'components/modals/components/PopupFooter';
import PopupHeader from 'components/modals/components/PopupHeader';
import { AlertBaseOn, AlertUnit, OccurrenceOrder } from 'enums/agingAlert';
import { Role } from 'enums/role';
import startCase from 'lodash/startCase';
import { Option } from 'models/form.types';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import {
  useCreateAgingAlertMutation,
  useDeleteAgingAlertMutation,
  useEditAgingAlertMutation,
  useGetAgingAlertByIdQuery
} from 'store/agingAlerts/agingAlertsSlice';
import { useGetTaskCategoriesQuery, useLazyGetUserTypesQuery } from 'store/lookup/lookupSlice';
import { closeModal } from 'store/modal/modalSlice';
import { validation } from 'utils/helpers';
import { ANY_CHARACTER_REGEXP, NUMBER_REGEXP } from 'utils/regExp';

import AgingAlertFormDataProps, { AgingAlertProps } from './AgingAlert.types';

const takeOwner = 'Task Owner';

const AgingAlert: React.FC<AgingAlertProps> = ({ id }) => {
  const dispatch = useDispatch();
  const { data: taskCategories } = useGetTaskCategoriesQuery();
  const [getUserTypes, { data: userTypes }] = useLazyGetUserTypesQuery();
  const [createAgingAlert, { isLoading: loadCreate }] = useCreateAgingAlertMutation();
  const { data: agingAlertData, isLoading: loadData } = useGetAgingAlertByIdQuery({ id: id ?? '' });
  const [editAgingAlert, { isLoading }] = useEditAgingAlertMutation();
  const [deleteAgingAlert, { isLoading: loadDelete }] = useDeleteAgingAlertMutation();

  const [taskCategoriesOptions, setTaskCategoriesOptions] = useState<Option[]>([]);
  const [userTypeOptions, setUserTypeOptions] = useState<Option[]>([]);

  const { handleSubmit, control, formState, resetField, reset, getValues, setError } =
    useForm<AgingAlertFormDataProps>({
      reValidateMode: 'onChange'
    });

  const onSuccess = (message: string) => {
    notifySuccess(message);
    dispatch(closeModal());
  };

  const onSubmitForm = (formData: AgingAlertFormDataProps) => {
    const body = {
      name: formData.name,
      alertAmount: formData.alertAmount,
      alertTaskOwner: !!formData.audience?.find((item) => item.label === takeOwner),
      alertType: formData.alertType.map((type) => type.value),
      audience: formData.audience?.map((type) => type.label).filter((type) => type !== takeOwner),
      status: formData.status?.value,
      taskType: formData.taskType.value,
      alertUnit: formData.alertUnit.label.includes(AlertUnit.Mins) ? AlertUnit.Min : AlertUnit.Hrs,
      criterionField: formData.alertUnit.value.includes(OccurrenceOrder.Before)
        ? AlertBaseOn.DueDate
        : AlertBaseOn.CreateDate,
      occurenceOrder: formData.alertUnit.value.includes(OccurrenceOrder.Before)
        ? OccurrenceOrder.Before
        : OccurrenceOrder.After
    };

    if (id) {
      editAgingAlert({ id, body })
        .unwrap()
        .then((data) => onSuccess(data.message));
    } else {
      createAgingAlert({ body })
        .unwrap()
        .then((data) => onSuccess(data.message));
    }
  };

  const onDelete = () => {
    id &&
      deleteAgingAlert({ id })
        .unwrap()
        .then((data) => onSuccess(data.message));
  };

  const onChangeTaskCategory = (category: OptionProps) => {
    if (getValues('audience')) {
      resetField('audience', { defaultValue: null });
      formState.isSubmitted &&
        setError('audience', { type: 'required', message: 'Audience is required' });
    }

    category && category.value && getUserTypes(category.value as string);
  };

  useEffect(() => {
    if (taskCategories) {
      setTaskCategoriesOptions(
        taskCategories.map((category) => ({ label: category, value: category }))
      );
    }
  }, [taskCategories]);

  useEffect(() => {
    if (userTypes) {
      const newUserTypes = [
        { label: Role.AD, value: 'AD' },
        ...userTypes.map((type) => ({ label: type.name, value: type.shortCode })),
        { value: takeOwner, label: takeOwner }
      ];
      setUserTypeOptions(newUserTypes);
    }
  }, [userTypes]);

  useEffect(() => {
    getUserTypes();
  }, [getUserTypes]);

  useEffect(() => {
    if (agingAlertData) {
      const audience = agingAlertData?.audience?.map((type) => ({
        value: type.shortCode,
        label: type.name
      }));
      agingAlertData.alertTaskOwner && audience.push({ value: takeOwner, label: takeOwner });
      const alertUnit = AGING_ALERT_UNIT_OPTIONS.find(
        (option) => option.value === `${agingAlertData.alertUnit}.${agingAlertData.occurenceOrder}`
      );

      reset({
        name: agingAlertData.name,
        alertAmount: agingAlertData.alertAmount,
        alertUnit: alertUnit,
        alertType: agingAlertData?.alertType?.map((type) => ({ value: type, label: type })),
        audience: audience,
        status: agingAlertData.status
          ? {
              value: agingAlertData.status,
              label: startCase(agingAlertData.status)
            }
          : null,
        taskType: agingAlertData.taskType && {
          value: agingAlertData.taskType,
          label: agingAlertData.taskType
        }
      });
    }
  }, [agingAlertData, reset]);

  return (
    <div data-testid="aging_alert_popup" className="p-8">
      <PopupHeader title="Aging alert" id={id} />

      <form onSubmit={handleSubmit(onSubmitForm)} className="flex flex-col gap-4">
        <InputField
          dataTestId="name_field"
          name="name"
          label="Name"
          placeholder="Enter name..."
          control={control}
          labelDirection="row"
          type="text"
          errors={formState.errors.name}
          helper={formState.errors.name?.message}
          rules={validation('Name', ANY_CHARACTER_REGEXP)}
        />
        <ControlledSelect
          dataTestId="task_type_field"
          control={control}
          labelDirection="row"
          options={taskCategoriesOptions}
          placeholder="Select task type..."
          onChange={onChangeTaskCategory}
          label="Task type"
          rules={validation('Task type')}
          name="taskType"
        />
        <ControlledMultiSelect
          control={control}
          label="Alert type"
          name="alertType"
          placeholder="Select alert types..."
          options={ALERT_TYPE_OPTIONS}
          labelDirection="row"
          errors={formState.errors.alertType}
          rules={validation('Alert type')}
        />
        <ControlledSelect
          dataTestId="alert_unit_field"
          control={control}
          labelDirection="row"
          options={AGING_ALERT_UNIT_OPTIONS}
          placeholder="Select alert unit..."
          label="Alert unit"
          rules={validation('Alert unit')}
          name="alertUnit"
        />
        <InputField
          dataTestId="alert_amount"
          name="alertAmount"
          label="Alert amount"
          placeholder="Enter alert amount..."
          control={control}
          labelDirection="row"
          type="number"
          maxLength={10}
          errors={formState.errors.alertAmount}
          helper={formState.errors.alertAmount?.message}
          rules={validation('Alert amount', NUMBER_REGEXP)}
        />
        <ControlledMultiSelect
          dataTestId="audience"
          control={control}
          label="Audience"
          name="audience"
          placeholder="Select audiences..."
          options={userTypeOptions}
          labelDirection="row"
          errors={formState.errors.audience}
          rules={validation('Audience')}
        />
        <ControlledSelect
          dataTestId="status_field"
          control={control}
          labelDirection="row"
          options={STATUS_OPTIONS}
          placeholder="Select status..."
          label="Status"
          rules={validation('Status')}
          name="status"
        />
        <PopupFooter
          hiddenDeleteButton={!id}
          onRemove={onDelete}
          disabled={loadData || isLoading || loadCreate || loadDelete}
        />
      </form>
    </div>
  );
};

export default AgingAlert;
