import { useEffect, useState } from 'react';

import { createSelector } from '@reduxjs/toolkit';
import { Common } from '@thecvlb/design-system/lib/src';
import Alert from 'components/common/Alert';
import Benefits from 'components/patient/Insurance/Benefits';
import { getDateOfBenefits } from 'components/patient/Insurance/Benefits/benefits.settings';
import InsuranceForm from 'components/patient/Insurance/InsuranceForm';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { closeModal } from 'store/modal/modalSlice';
import { selectPatient, useLazyGetBenefitsQuery } from 'store/patients/patientsSlice';
import { selectTask } from 'store/tasks/tasksSlice';

import Loader from '../../common/Loader';

const selectBenefitsState = createSelector([selectTask, selectPatient], (task, patient) => ({
  taskDetails: task.taskDetails,
  benefits: patient.benefits
}));

const BenefitsModal = ({ patientId }: { patientId: string }) => {
  const dispatch = useAppDispatch();
  const { taskDetails, benefits } = useAppSelector(selectBenefitsState);
  const [loading, setLoading] = useState(true);

  const [getBenefits, { isFetching }] = useLazyGetBenefitsQuery();
  const [isUpdateInsurance, setIsUpdateInsurance] = useState(false);

  const showInsuranceForm = !benefits?.benefits || isUpdateInsurance;

  const showBenefits = benefits?.benefits && !isUpdateInsurance;
  const isInsurance = !!benefits?.insurance;
  const requestSentDate = getDateOfBenefits(benefits);

  useEffect(() => {
    const isWM = taskDetails?.personalInfo?.planInfo?.planName === 'Weight Management';
    if (isWM && patientId !== benefits?.userId) {
      getBenefits({ patientId: patientId }).then(() => setLoading(false));
    } else {
      setLoading(false);
    }
  }, []);

  return (
    <>
      <Loader isVisible={loading || isFetching} />
      <div className="p-6">
        <h2 className="mb-2 text-xl font-bold text-gray-700">Benefits</h2>
        {!loading && showInsuranceForm && (
          <div>
            <p className="mb-6 text-base font-medium">
              Enter insurance information to run a benefits check
            </p>
            {!isInsurance && (
              <Alert
                type="info"
                containerClasses="mb-5"
                children={
                  <p className="text-base font-medium">
                    Patient has indicated they do not want to use insurance to pay for their
                    prescriptions
                  </p>
                }
              />
            )}
            {showInsuranceForm && (
              <InsuranceForm
                insuranceInfo={benefits?.insurance}
                patientId={patientId}
                closeForm={() => setIsUpdateInsurance(false)}
                isModal
              />
            )}
          </div>
        )}

        {showBenefits && (
          <div className="pt-2">
            <Benefits isModal data={benefits} mostRecentDate={requestSentDate} />
            <div className="flex justify-end gap-x-2 pt-6">
              <Common.Button color="white-alt" onClick={() => setIsUpdateInsurance(true)} size="sm">
                Update insurance
              </Common.Button>
              <Common.Button color="blue" onClick={() => dispatch(closeModal())} size="sm">
                Close window
              </Common.Button>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default BenefitsModal;
