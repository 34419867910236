import { createColumnHelper } from '@tanstack/react-table';

import EditCell from './EditCell';
import { PatientAutomationsTableTypes } from './patientAutomationsTable.types';
import Header from '../Header';

const columnHelper = createColumnHelper<PatientAutomationsTableTypes>();

export const columns = [
  columnHelper.accessor('name', {
    header: () => <Header text="Automation name" sortField="name" />
  }),
  columnHelper.accessor('triggerType', {
    header: () => <Header text="Automation type" sortField="triggerType" />
  }),
  columnHelper.accessor('triggerTime', {
    header: () => <Header text="Automation time" sortField="triggerTime.timeInHours" />,
    cell: (info) => (
      <span>
        {info.getValue()
          ? info.getValue().timeInHours >= 1
            ? info.getValue().timeInHours + ' hours ' + info.getValue().occurenceOrder
            : (info.getValue().timeInHours * 60).toFixed() +
              ' mins ' +
              info.getValue().occurenceOrder
          : '-'}
      </span>
    )
  }),
  columnHelper.accessor('_id', {
    header: () => <Header text="Unique ID" sortField="uniqueId" />
  }),
  columnHelper.accessor('status', {
    header: () => <Header text="Status" sortField="status" />,
    cell: (info) => (
      <span className={`capitalize ${info.getValue() === 'active' ? 'text-blue' : 'text-red'}`}>
        {info.getValue()}
      </span>
    )
  }),
  columnHelper.accessor('actions', {
    header: () => <Header text="Actions" sortField="actions" />,
    cell: (info) => <EditCell id={info.row.original._id} />,
    enableSorting: false
  })
];
