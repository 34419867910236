import { DayPicker } from 'react-day-picker';
import styled from 'styled-components';
import tw from 'twin.macro';

export const StyledDayPicker = styled(DayPicker)`
  .rdp-caption_label {
    ${tw`text-lg`};
  }
  .rdp-chevron {
    ${tw`w-3 h-5 bg-transparent`};
    --rdp-accent-color: #00698b;
  }
  .rdp-button_previous:hover,
  .rdp-button_next:hover {
    ${tw`font-bold bg-secondary-100`}
  }
`;
