import { Common } from '@thecvlb/design-system/lib/src';
import { ProspectUserProps } from 'models/prospect.types';

const ExtraInfo = ({ patientDetails }: { patientDetails: ProspectUserProps }) => {
  const latestSignupSession =
    patientDetails?.signupSessions?.[patientDetails?.signupSessions.length - 1];

  const session = latestSignupSession?.session;
  const mif = session?.prospect?.mif;
  const marketing = session?.prospect?.marketing;
  const payment = session?.prospect?.payment;

  const containerClassName = 'mb-2 flex';
  const labelClassName = 'mr-4 w-[240px] text-base';
  const valueClassName = 'w-full break-words text-base';
  return (
    <div className="flex justify-center">
      <div className="mb-8 w-[500px] rounded-lg border border-gray-200 px-6 py-4">
        <div className="mb-3 mr-3 font-bold text-gray-700">Details / Last Session Info</div>
        <div className={containerClassName}>
          <span className={labelClassName}>BMI:</span>
          <span className={valueClassName}>
            {mif?.bmi && (
              <Common.ColorTag color="green" text={String(mif?.bmi?.value) || ''} size="md" />
            )}
          </span>
        </div>
        <div className={containerClassName}>
          <span className={labelClassName}>Height:</span>
          <span className={valueClassName}>
            {mif?.height && (
              <Common.ColorTag color="green" text={String(mif?.height?.value) || ''} size="md" />
            )}
          </span>
        </div>
        <div className={containerClassName}>
          <span className={labelClassName}>Weight:</span>
          <span className={valueClassName}>
            {mif?.weight && (
              <Common.ColorTag color="green" text={String(mif?.weight?.value) || ''} size="md" />
            )}
          </span>
        </div>
        <div className={containerClassName}>
          <span className={labelClassName}>Flow Url:</span>
          <span className={`${valueClassName} break-all`}>{patientDetails?.flowUrl}</span>
        </div>
        <div className={containerClassName}>
          <span className={labelClassName}>Referrer Url:</span>
          <span className={`${valueClassName} break-all`}>{patientDetails?.referrerUrl}</span>
        </div>
        <div className={containerClassName}>
          <span className={labelClassName}>Prospect Type:</span>
          <span className={valueClassName}>{marketing?.prospect_type}</span>
        </div>
        <div className={containerClassName}>
          <span className={labelClassName}>Initial Appointment Type:</span>
          <span className={valueClassName}>{marketing?.prospect_initial_appt_type}</span>
        </div>
        <div className={containerClassName}>
          <span className={labelClassName}>Product Handle:</span>
          <span className={valueClassName}>{payment?.product_handle}</span>
        </div>
        <div className={containerClassName}>
          <span className={labelClassName}>Product Price Point Handle:</span>
          <span className={valueClassName}>{payment?.product_price_point_handle}</span>
        </div>
      </div>
    </div>
  );
};

export default ExtraInfo;
