import React from 'react';

import * as Popover from '@radix-ui/react-popover';
import useOpenTask from 'hooks/tasks/useOpenTask/useOpenTask';

import { GlobalAppointmentCellProps, ListItem } from './globalEeventTooltip.types';

const GlobalEventTooltip: React.FC<GlobalAppointmentCellProps> = ({
  sortedAppointments,
  setShow,
  collisionBoundary
}) => {
  const { handleOpenTask } = useOpenTask();

  const handleOpen = (item: ListItem) => {
    setShow(false);
    handleOpenTask({
      taskId: item.taskId,
      assignToId: item.doctorId,
      assignToName: item.staffName
    });
  };

  return (
    <Popover.Portal>
      <Popover.Content
        data-testid="global_appo_tooltip"
        className="z-30 max-h-[400px] w-60 overflow-y-scroll rounded-xl bg-white px-4 py-3 text-black shadow-2xl scrollbar-hide"
        sideOffset={5}
        collisionBoundary={collisionBoundary}
        side="right"
      >
        {sortedAppointments.map((appointment, index) => {
          return (
            <div data-testid="appo_section" key={`${appointment.doctor}_${index}`}>
              <div className="flex justify-between">
                <span
                  data-testid="doc_name"
                  className="mb-2 w-3/4 overflow-hidden text-ellipsis text-base font-bold text-primary"
                >
                  {appointment.doctor}
                </span>
                <span data-testid="qty_of_appo" className="whitespace-nowrap text-base text-gray">
                  {appointment.list.length} appt (s)
                </span>
              </div>
              {appointment.list.map((item, idx) => (
                <div
                  data-testid="single_appo_container"
                  onClick={() => handleOpen(item)}
                  key={`${item.time}_${idx}`}
                  className="my-3 flex cursor-pointer items-center"
                >
                  <span data-testid="appo_time" className="mr-3 w-16 min-w-fit text-sm text-gray">
                    {item.time}
                  </span>
                  <span data-testid="patient_name" className="w-full truncate text-sm underline">
                    {item.patient}
                  </span>
                </div>
              ))}
              {(sortedAppointments.length === 1 || sortedAppointments.length - 1 !== index) && (
                <div className="my-2 h-px bg-gray-200" />
              )}
            </div>
          );
        })}
        <Popover.Arrow className="fill-white" />
      </Popover.Content>
    </Popover.Portal>
  );
};

export default GlobalEventTooltip;
