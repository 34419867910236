import { useMemo } from 'react';

import { createSelector } from '@reduxjs/toolkit';
import { skipToken } from '@reduxjs/toolkit/query';
import { getRepeats } from 'components/appointments/ManageAvailability/MonthlyAvailability/Event/EventDetails/eventDetails.settings';
import { notifySuccess } from 'components/common/Toast/Toast';
import dayjs from 'dayjs';
import { RoleShortName } from 'enums/role';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import isEmpty from 'lodash/isEmpty';
import { EditEventOptions, EventTypes, RepeatOptions } from 'models/event.types';
import { useParams } from 'react-router-dom';
import { EventDetailsProps } from 'store/calendar/calendar.types';
import {
  selectCalendarTimezone,
  selectEventInstanceId,
  setEventId,
  setEventInstanceId,
  useCreateEventMutation,
  useEditEventMutation,
  useGetEventQuery,
  useValidateEventMutation
} from 'store/calendar/calendarSlice';
import { closeModal } from 'store/modal/modalSlice';
import { selectUser } from 'store/user/userSlice';

import {
  getEditEventOptions,
  getLengthOfPreviousRecurringEvents,
  getUpdatedEventTime,
  updateEvent
} from './editEvent.settings';
import type { EditEventProps, EditFormValues } from './editEvent.types';
import EditEventForm from './EditEventForm';
import { handleEventsConflict } from '../CreateEventForm/createEvent.settings';

const eventDetailsTextClasses = 'text-base font-medium';

const selectEditEventState = createSelector(
  [selectUser, selectCalendarTimezone, selectEventInstanceId],
  (user, calendarTimezone, eventInstanceId) => ({
    loggedInUserId: user._id,
    shortCode: user.userType.shortCode,
    eventInstanceId,
    calendarTimezone
  })
);

const EditEvent = ({
  eventId,
  type,
  eventData,
  isRecurringEventChanged,
  onClose
}: EditEventProps) => {
  const { id: physicianId = '' } = useParams<{ id: string }>();

  const dispatch = useAppDispatch();
  const { shortCode, calendarTimezone, eventInstanceId, loggedInUserId } =
    useAppSelector(selectEditEventState);

  const { data: eventDetails, isLoading: isLoadingEventDetails } = useGetEventQuery(
    eventId ? { eventId } : skipToken
  );
  const { data: eventInstanceDetails, isLoading: isLoadingEventInstanceDetails } = useGetEventQuery(
    eventInstanceId
      ? {
          eventId: eventInstanceId
        }
      : skipToken
  );

  const [editEvent, { isLoading: isLoadingEditEvent }] = useEditEventMutation();
  const [createEvent, { isLoading: isLoadingCreateEvent }] = useCreateEventMutation();
  const [validateEvent, { isLoading: isLoadingValidateEvent }] = useValidateEventMutation();

  /**
   * @var userId
   * @description
   * If event is created for staff, I get the staff id from the URL
   * else I get the id from the current user
   */
  const userId = physicianId ? physicianId : loggedInUserId;

  const eventDetailsRepeat =
    eventDetails?.recurring?.type === 'weekly' && eventDetails?.recurring.interval === 2
      ? RepeatOptions.BI_WEEKLY
      : eventDetails?.recurring?.type;

  const isAdmin = shortCode === RoleShortName.Admin;
  const isLoading = isLoadingEventDetails || isLoadingEventInstanceDetails;
  const isEditing = isLoadingEditEvent || isLoadingCreateEvent || isLoadingValidateEvent;
  const isEventDateChanged = !dayjs(eventInstanceDetails?.start.dateTime).isSame(
    dayjs(eventData?.shiftStart?.day),
    'day'
  );
  const isEventRepeatsChanged = eventData?.repeats !== eventDetailsRepeat;

  const eventTimeShifts =
    eventInstanceDetails?.start.dateTime &&
    eventData?.shiftTime.length &&
    getUpdatedEventTime(
      eventInstanceDetails?.start.dateTime,
      calendarTimezone,
      eventData.shiftTime
    );

  const recurringEventInfo = getRepeats(eventDetails) ?? '';

  const options = useMemo(
    () =>
      getEditEventOptions({
        isAdmin,
        isEventDateChanged,
        isEventRepeatsChanged,
        isRecurringEventChanged,
        type
      }),
    [isAdmin, isEventDateChanged, isEventRepeatsChanged, isRecurringEventChanged, type]
  );

  const handleEditSuccess = (message: string) => {
    notifySuccess(message);
    dispatch(setEventId(''));
    dispatch(setEventInstanceId(''));
    closeModal();
    dispatch(closeModal());
  };

  const handleCreateEvent = ({ body }: { body: Partial<EventDetailsProps> }) => {
    createEvent(body)
      .unwrap()
      .then((response) => handleEditSuccess(response.message))
      .catch((error) => handleEventsConflict(error, dispatch, body, calendarTimezone));
  };

  const handleEditEvent = ({ id, body }: { id: string; body: Partial<EventDetailsProps> }) => {
    editEvent({ eventId: id, body })
      .unwrap()
      .then((response) => handleEditSuccess(response.message))
      .catch((error) => handleEventsConflict(error, dispatch, body, calendarTimezone));
  };

  const handleEditCurrentAndFollowingEvents = ({ body }: { body: Partial<EventDetailsProps> }) => {
    const isFirstRecurringEvent = dayjs(eventInstanceDetails?.originalStartTime.dateTime).isSame(
      dayjs(eventDetails?.start.dateTime),
      'hour'
    );

    const updateEventRecurring: Partial<EventDetailsProps> = {
      ...(eventDetails?.recurring && {
        recurring: {
          ...eventDetails.recurring,
          until: dayjs(eventInstanceDetails?.end.dateTime).subtract(1, 'day').toISOString()
        }
      })
    };

    /**
     * @description
     * Set event recurring until date to the end of the current event instance
     * and create a new event with the updated recurring properties
     */
    if (isFirstRecurringEvent) {
      editEvent({
        eventId,
        body
      })
        .unwrap()
        .then((response) => handleEditSuccess(response.message))
        .catch((error) => handleEventsConflict(error, dispatch, body, calendarTimezone));
    } else {
      validateEvent({ eventId, body })
        .unwrap()
        .then(() => {
          editEvent({
            eventId,
            body: {
              ...updateEventRecurring
            }
          })
            .unwrap()
            .then(() => {
              createEvent(body)
                .unwrap()
                .then((response) => handleEditSuccess(response.message))
                .catch((error) => handleEventsConflict(error, dispatch, body, calendarTimezone));
            })
            .catch((error) => handleEventsConflict(error, dispatch, body, calendarTimezone));
        })
        .catch((error) => handleEventsConflict(error, dispatch, body, calendarTimezone));
    }
  };

  const onSubmit = (data: EditFormValues) => {
    if (!eventData || isEmpty(eventData) || !eventDetails) return;
    // If the event is recurring and the user selects to edit current and following events
    // we calculate the length of the previous events and subtract it from the count
    if (
      data.editEventOption === EditEventOptions.CURRENT_AND_FOLLOWING &&
      !!eventData.ends?.count
    ) {
      eventData.ends.count -= getLengthOfPreviousRecurringEvents(
        eventData,
        eventDetails,
        calendarTimezone
      );
    }

    eventData.shiftTime.forEach((event, eventIndex) => {
      const reqBody = updateEvent({
        updateData: eventData,
        originalEvent: eventDetails,
        selectedEditOption: data.editEventOption,
        calendarTimezone,
        userId,
        eventTime: event,
        type
      });
      /**
       * @description
       * Check if it's the first time shift in the array .
       * When user change event and it's not the first time shift we should create a new event
       */
      const isFirstTimeShift = eventIndex === 0;
      if (!isFirstTimeShift) {
        handleCreateEvent({ body: reqBody });
        return;
      }
      if (data.editEventOption === EditEventOptions.CURRENT) {
        handleEditEvent({ id: eventInstanceId, body: reqBody });
      } else if (data.editEventOption === EditEventOptions.CURRENT_AND_FOLLOWING) {
        handleEditCurrentAndFollowingEvents({ body: reqBody });
      } else {
        handleEditEvent({ id: eventId, body: reqBody });
      }
    });
  };

  return (
    <>
      <div className="mb-6">
        <h3 className="mb-2 text-xl font-bold">
          {type === EventTypes.SHIFT ? 'Update shift' : 'Update time off'}
        </h3>

        {eventTimeShifts &&
          eventTimeShifts.length &&
          eventTimeShifts.map((time) => (
            <p className={eventDetailsTextClasses} key={time}>
              {time}
            </p>
          ))}
        <p className={eventDetailsTextClasses}>{recurringEventInfo}</p>
      </div>
      <EditEventForm
        options={options}
        onSubmit={onSubmit}
        onClose={onClose}
        isLoading={isLoading}
        isEditing={isEditing}
      />
    </>
  );
};

export default EditEvent;
