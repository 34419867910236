import { PlanCodesProps } from 'enums/appointmentStatus';
import { useFormContext } from 'react-hook-form';
import { getPaymentAmount } from 'utils/helpers';

import PriceInfo from './PriceInfo';

const PriceCard = () => {
  const { getValues, watch } = useFormContext();
  const planType = getValues('planType');
  const pricePoint = planType?.pricePoint;
  const promoCode = getValues('creditCardAttributes.couponCode');

  watch(['planType', 'creditCardAttributes.couponCode']);

  const { discountType, discountValue, discountAmount, amountDue } = getPaymentAmount(
    promoCode?.value,
    planType?.plan,
    planType?.pricePoint
  );

  const isWMPlan = planType?.plan?.planCode === PlanCodesProps.WeightManagementMembership;

  return (
    <div className="min-w-[272px] max-w-[440px] rounded-lg bg-secondary-50 p-6">
      {!planType?.plan ? (
        <></>
      ) : (
        <>
          {planType?.plan?.planCode !== 'unlimited-membership' && !isWMPlan && (
            <div className="mb-4 flex items-center justify-between font-bold text-primary-700">
              <span className="">Doctor’s visit</span>
              <span>${pricePoint?.initialAppointmentCost}</span>
            </div>
          )}
          <div className="mb-4">
            <div className="mb-2 flex items-center justify-between font-bold text-secondary">
              <span>{planType?.plan?.planName}</span>
              <span>${pricePoint?.totalCost}</span>
            </div>
            {!isWMPlan && <p className="text-sm text-secondary">(Begins after 30 days)</p>}
          </div>
          {discountType === 'percent' && discountValue !== '0' && (
            <div
              data-testid="discount_block"
              className="mb-3 flex justify-between text-sm font-bold text-orange-400 md:font-bold"
            >
              <h4>Discount: {discountValue}% off</h4>
              <span className="text-base">- ${discountAmount}</span>
            </div>
          )}
          {discountType === 'amount' && discountValue !== '0' && (
            <div
              data-testid="discount_block"
              className="mb-3 flex justify-between text-sm font-bold text-orange-400 md:font-bold"
            >
              <h4>Discount: ${discountValue} off</h4>
              <span className="text-base">- ${discountAmount}</span>
            </div>
          )}
          <div
            data-testid="total_price_block"
            className="mb-4 flex items-center justify-between font-bold text-primary-700"
          >
            <span>Total due today</span>
            <span>${amountDue}</span>
          </div>
          <hr className="mb-4 border-secondary" />
          <PriceInfo pricePointId={pricePoint?.planPricePointId} />
        </>
      )}
    </div>
  );
};

export default PriceCard;
