import { MessageType } from 'enums/messages';

import { useFrontDesk } from '../../contexts/FrontDeskContext/FrontDeskContext';
import { useMessages } from '../../contexts/MessagesContext/MessagesContext';
import { useStaffNote } from '../../contexts/StaffNotesContext/StaffNotesContext';

// Define the return type based on the input type
type UseClientMessagesReturnType<T extends MessageType> = () => T extends MessageType.Medical
  ? ReturnType<typeof useMessages>
  : T extends MessageType.StaffNote
    ? ReturnType<typeof useStaffNote>
    : T extends MessageType.Support
      ? ReturnType<typeof useFrontDesk>
      : { deleteMessageFromServer: () => void; sendUpdatedMessageToServer: () => void };

// NOTE: we are not using the actual hooks here, just the types
const useSMSMockup = () => {
  return { deleteMessageFromServer: () => {}, sendUpdatedMessageToServer: () => {} };
};

export const useClientMessages = <T extends MessageType>({
  type
}: {
  type: T;
}): UseClientMessagesReturnType<T> => {
  if (type === MessageType.Medical) {
    return useMessages as UseClientMessagesReturnType<T>;
  } else if (type === MessageType.StaffNote) {
    return useStaffNote as UseClientMessagesReturnType<T>;
  } else if (type === MessageType.Support) {
    return useFrontDesk as UseClientMessagesReturnType<T>;
  } else {
    return useSMSMockup as UseClientMessagesReturnType<T>;
  }
};
