import React, { useEffect } from 'react';

import { ColumnDef, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import ReportTable from 'components/reports/ReportTable/ReportTable';
import { useGetDefaultBasicTableValues } from 'hooks/common/useGetDefaultBasicTableValues';
import { useInView } from 'react-intersection-observer';
import { getDynamicColumns } from 'utils/getDynamicColumns';

import { PhysicianReportsTablesProps } from './physicianReportsTables.types';

const PhysicianReportsTables: React.FC<PhysicianReportsTablesProps> = ({
  myTasksData,
  myTasksDataCount,
  myTasksDataIsFetching,

  requestsToStaff,
  requestsToStaffCount,
  requestsToStaffIsFetching,

  requestsByAssignee,
  requestsByAssigneeCount,
  requestsByAssigneeIsFetching,

  setLoad
}) => {
  const { ref: staffTableRef, entry: staffTableEntry } = useInView({ threshold: 0.5 });
  const { ref: assigneeTableRef, entry: assigneeTableEntry } = useInView({ threshold: 0.4 });

  // My Tasks
  const [myTasksTableData, myTasksTableColumns] = useGetDefaultBasicTableValues({
    isFetching: myTasksDataIsFetching,
    data: myTasksData || [],
    columns: getDynamicColumns('rpt-tasks', false, myTasksData)
  });

  const myTasksTable = useReactTable({
    data: myTasksTableData,
    columns: myTasksTableColumns as ColumnDef<Record<string, string | number>[]>[],
    getCoreRowModel: getCoreRowModel()
  });

  // Requests sent to staff
  const [requestsToStaffTableData, requestsToStaffTableColumns] = useGetDefaultBasicTableValues({
    isFetching: requestsToStaffIsFetching,
    data: requestsToStaff || [],
    columns: getDynamicColumns('rqst-stf', false, requestsToStaff)
  });

  const requestsToStaffTable = useReactTable({
    data: requestsToStaffTableData,
    columns: requestsToStaffTableColumns as ColumnDef<Record<string, string | number>[]>[],
    getCoreRowModel: getCoreRowModel()
  });

  // Requests by assignee
  const [requestsByAssigneeTableData, requestsByAssigneeTableColumns] =
    useGetDefaultBasicTableValues({
      isFetching: requestsByAssigneeIsFetching,
      data: requestsByAssignee || [],
      columns: getDynamicColumns('rqst-asgn', false, requestsByAssignee)
    });

  const requestsByAssigneeTable = useReactTable({
    data: requestsByAssigneeTableData,
    columns: requestsByAssigneeTableColumns as ColumnDef<Record<string, string | number>[]>[],
    getCoreRowModel: getCoreRowModel()
  });

  useEffect(() => {
    !myTasksDataIsFetching &&
      setLoad({
        staff: staffTableEntry?.isIntersecting,
        assignee: assigneeTableEntry?.isIntersecting
      });
  }, [staffTableEntry?.isIntersecting, assigneeTableEntry?.isIntersecting]);

  return (
    <React.Fragment>
      <div className="mb-10">
        <ReportTable
          title="My Tasks"
          table={myTasksTable}
          totalCount={myTasksDataCount || 0}
          isFetching={myTasksDataIsFetching}
          tableName="rpt-tasks"
        />
      </div>
      <div className="mb-10" ref={staffTableRef}>
        <ReportTable
          title="Requests sent to staff"
          table={requestsToStaffTable}
          totalCount={requestsToStaffCount || 0}
          isFetching={requestsToStaffIsFetching}
          tableName="rqst-stf"
        />
      </div>
      <div className="mb-10" ref={assigneeTableRef}>
        <ReportTable
          title="Requests by assignee"
          table={requestsByAssigneeTable}
          totalCount={requestsByAssigneeCount || 0}
          isFetching={requestsByAssigneeIsFetching}
          tableName="rqst-asgn"
        />
      </div>
    </React.Fragment>
  );
};

export default PhysicianReportsTables;
