import {
  DashboardProps,
  RequestsByAssigneeProps,
  TableDataType
} from 'store/physicianReports/physicianReports.types';

import { apiSlice } from '../api/apiSlice';

export const physicianReportsApiSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getPhysicianReportsDashboard: build.query<
      DashboardProps,
      {
        params: string;
      }
    >({
      query: ({ params }) => `/physician-reports/reporting-dashboard?${params}`,
      transformResponse: (response: { data: DashboardProps }) => response.data
    }),
    getPhysicianReportsTasks: build.query<
      TableDataType[],
      {
        params: string;
      }
    >({
      query: ({ params }) => `/physician-reports/my-tasks?${params}`,
      transformResponse: (response: { data: TableDataType[] }) => response.data
    }),
    getPhysicianReportsStaff: build.query<
      TableDataType[],
      {
        params: string;
      }
    >({
      query: ({ params }) => `/physician-reports/requests-to-staff?${params}`,
      transformResponse: (response: { data: TableDataType[] }) => response.data
    }),
    getPhysicianReportsAssignee: build.query<
      { data: TableDataType[]; totalCount: number },
      {
        params: string;
      }
    >({
      query: ({ params }) => `/physician-reports/requests-by-assignee?${params}`,
      transformResponse: (response: RequestsByAssigneeProps) => ({
        data: response.data,
        totalCount: response.info.totalCount
      })
    }),
    getDownloadReport: build.query<Blob, { params: string }>({
      query: ({ params }) => ({
        url: `/download-reports/physician?${params}`,
        method: 'GET',
        responseHandler: async (response: Response) => response.blob()
      }),
      transformResponse: (response: Blob) => response
    })
  })
});

export const {
  useLazyGetPhysicianReportsDashboardQuery,
  useLazyGetPhysicianReportsTasksQuery,
  useLazyGetPhysicianReportsStaffQuery,
  useLazyGetPhysicianReportsAssigneeQuery,
  useLazyGetDownloadReportQuery
} = physicianReportsApiSlice;
