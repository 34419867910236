import { Common } from '@thecvlb/design-system';
import Loader from 'components/common/Loader';
import { AppointmentListType } from 'enums/appointments';
import { TaskCategories } from 'enums/taskCategories';
import { useAppDispatch } from 'hooks/redux';
import useOpenTask from 'hooks/tasks/useOpenTask/useOpenTask';
import { useController, useForm } from 'react-hook-form';
import { useGetPatientAppointmentsQuery } from 'store/appointments/appointmentsSlice';
import { closeModal } from 'store/modal/modalSlice';
import { useGetPatientQuery } from 'store/patients/patientsSlice';
import { useGetSubscriptionCancelReasonsQuery } from 'store/subscription/subscriptionSlice';

import { DeactivatePatientProps, FormData } from './deactivatePatient.types';

const DeactivatePatient: React.FC<DeactivatePatientProps> = ({
  patientId,
  onSubmit,
  isLoading
}) => {
  const dispatch = useAppDispatch();
  const { handleOpenTask } = useOpenTask();
  const { data: upcomingAppointmentData, isFetching: isFetchingAppointments } =
    useGetPatientAppointmentsQuery(
      { patientId, params: { appointmentListType: AppointmentListType.UpcomingAppointment } },
      { refetchOnMountOrArgChange: true }
    );
  const { handleSubmit, control, register } = useForm<FormData>({
    reValidateMode: 'onChange'
  });
  const { data: deactivateReasons, isFetching } = useGetSubscriptionCancelReasonsQuery(null);
  const showLoader = isFetching || isFetchingAppointments || isLoading;
  const isDisabled = isLoading || showLoader;
  const upcomingAppointments = upcomingAppointmentData?.appointments || [];
  const hasActiveAppointments = !!upcomingAppointments.length;

  const detailsRegister = register('details');
  const {
    field,
    formState: { errors }
  } = useController({
    control,
    name: 'reason',
    defaultValue: '',
    rules: {
      required: {
        value: true,
        message: 'Reason is required'
      }
    }
  });

  const { patient } = useGetPatientQuery(
    { id: patientId },
    {
      selectFromResult: ({ data }) => ({
        patient: data
      })
    }
  );

  const handleClose = () => {
    dispatch(closeModal());
  };

  return (
    <>
      <Loader isVisible={showLoader} />
      <form
        data-testid="deactivate_patient_popup"
        className="p-6"
        onSubmit={handleSubmit(onSubmit)}
      >
        <h2 className="mb-2 text-xl font-bold">
          Deactivate {patient?.firstName + ' ' + patient?.lastName}?
        </h2>
        <p className="mb-6 text-base text-gray-700">
          When you deactivate a patient, they will no longer be able to use the platform
          {hasActiveAppointments ? ', and the following appointments will be cancelled:' : '.'}
        </p>
        <div className="flex flex-col gap-4">
          {hasActiveAppointments &&
            upcomingAppointments?.map((appointment) => (
              <Common.Appointment
                onClick={() =>
                  handleOpenTask({
                    taskId: appointment.taskId,
                    assignToId: appointment.doctorId,
                    assignToName: appointment.staffName,
                    shouldClosePrevModal: true,
                    shouldExcludeForAdmin: true
                  })
                }
                startTime={appointment?.appointmentTime?.startTime}
                isQueueAppointment={
                  appointment.category?.toLowerCase() === TaskCategories.Queue.toLowerCase()
                }
                text={`${appointment?.appointmentType?.displayName} • ${appointment?.staffName || ''}`}
                key={appointment?._id}
                hideButtons
                type="mustBeCanceled"
              />
            ))}

          <Common.SelectAlt
            dataTestId="select_reason_dropdown"
            placeholder="Select..."
            label="Please select reason"
            size="sm"
            value={field.value}
            options={deactivateReasons ?? []}
            onChange={(value) => field.onChange(value)}
            ref={field.ref}
            name={field.name}
            error={errors[field.name]}
            hideSuccessState
            disabled={isDisabled}
          />
          <Common.TextArea
            dataTestId="details_field"
            label="Details"
            size="sm"
            placeholder="Enter any details you want to add..."
            {...detailsRegister}
          />
        </div>
        <div className="mt-6 flex gap-2">
          <button
            className="w-full rounded-lg bg-gray-100 py-[7.5px] text-sm font-bold text-gray-700 disabled:bg-gray-200 disabled:text-gray"
            onClick={handleClose}
          >
            Close window
          </button>
          <button
            className="w-full rounded-lg bg-red-500 py-[7.5px] text-sm font-bold text-white disabled:bg-gray-200 disabled:text-gray"
            disabled={isDisabled}
          >
            Deactivate account
          </button>
        </div>
      </form>
    </>
  );
};

export default DeactivatePatient;
