import { useEffect } from 'react';

import { useLazyGetOrderQuery } from 'store/orders/ordersSlice';

import EditOrderForm from './EditOrderForm';

const EditOrder = ({ orderId }: { orderId?: string }) => {
  const [getOrder, { data: orderDetails, isFetching }] = useLazyGetOrderQuery();

  useEffect(() => {
    if (orderId) {
      getOrder({ id: orderId });
    }
  }, [getOrder, orderDetails, orderId]);

  return (
    <div className="mt-6 flex gap-x-16 px-16">
      <div className="w-full">
        <h2 className="font-bold">{orderId ? `Edit Order` : 'Create Order'}</h2>
        {orderDetails && typeof orderDetails.patient === 'object' && (
          <p className="py-2 capitalize">
            {`${orderDetails?.patient?.firstName} ${orderDetails?.patient?.lastName}`}{' '}
            {orderId ? `- ${orderId}` : ''}
          </p>
        )}
        <EditOrderForm orderId={orderId} orderDetails={orderDetails} isFetching={isFetching} />
      </div>
    </div>
  );
};

export default EditOrder;
