import { AppointmentStatus } from './tooltip.types';
import { CalendarEvent } from '../calendar.types';

export const getTooltipClasses = (appointment?: CalendarEvent) => {
  const defaultClasses = 'w-full h-full rounded p-1';
  const isMissedAppointment =
    appointment?.missedAppointment ||
    appointment?.missedAppointmentByStaff ||
    appointment?.status === AppointmentStatus.Cancelled;
  const isCompletedAppointment = appointment?.status === AppointmentStatus.Completed;
  const isCurrentAppointment =
    appointment?.status === AppointmentStatus.Pending &&
    !appointment?.pastAppointment &&
    !isMissedAppointment;

  if (isMissedAppointment) {
    return `${defaultClasses} bg-red-100 text-red-700 ${appointment?.pastAppointment && 'opacity-30'}`;
  } else if (isCompletedAppointment) {
    return `${defaultClasses} bg-secondary-100 text-secondary-700  ${appointment?.pastAppointment && 'opacity-30'}`;
  } else if (isCurrentAppointment) {
    return `${defaultClasses} bg-secondary-600 text-white`;
  }
  return defaultClasses;
};
