import { PricePointProps } from 'components/crossSell/PlanType/planType.types';
import { PlanCodesProps } from 'enums/appointmentStatus';
import {
  AddAppointmentDetails,
  FreeAppointments,
  MembershipData
} from 'store/appointments/appointments.types';
import { PlanInfoProps } from 'store/patients/patients.types';

import {
  AppointmentProps,
  AppointmentSteps,
  PlanType,
  StepName,
  StepsTitleProps
} from './CreateAppointmentModal.types';

export const defaultSteps: StepName[] = [
  AppointmentSteps.CATEGORY,
  AppointmentSteps.FILES,
  AppointmentSteps.DATE_TIME,
  AppointmentSteps.MEMBERSHIP,
  AppointmentSteps.CHECKOUT,
  AppointmentSteps.CONFIRMATION
];

export const rescheduleSteps: StepName[] = [
  AppointmentSteps.DATE_TIME,
  AppointmentSteps.CONFIRMATION
];

export const listOfPlanThatIncludeAppointment = [
  PlanCodesProps.UnlimitedMembership,
  PlanCodesProps.WeightManagementMembership,
  PlanCodesProps.ConciergeMembership
];

export const defaultAppointmentData: AppointmentProps & { confirmation?: boolean } = {
  _id: '',
  appointmentDescription: '',
  appointmentTypeId: '',
  callType: 'video',
  category: '',
  displayName: '',
  doctorId: '',
  endTime: '',
  files: [],
  isWeightManagementAppointment: false,
  startTime: '',
  uploadRequired: false,
  confirmation: false
};

export const defaultMembershipData: MembershipData = {
  freeAppointmentInfo: {
    bookedFreeAppointmentDate: undefined,
    freeAppointmentsAmount: 0,
    isFree: false,
    periodEnd: '',
    periodStart: ''
  },
  planID: '',
  pp: null,
  type: ''
};

export const getDefaultStepContent = (
  hasPastAppointments?: boolean,
  rescheduleAppointment?: boolean
): StepName[] => {
  return rescheduleAppointment
    ? rescheduleSteps
    : [...(hasPastAppointments ? [AppointmentSteps.MAKE_UP] : []), ...defaultSteps];
};

export const getStepContentTitle = (
  step: number,
  stepsList: StepName[],
  patientPlanInfo?: PlanInfoProps,
  isFree?: boolean
) => {
  const isTotalCare = patientPlanInfo?.planCode === PlanCodesProps.TotalCareMembership;
  const planIncludeAppointment = listOfPlanThatIncludeAppointment.includes(
    patientPlanInfo?.planCode as PlanCodesProps
  );

  const membershipTotalCareTitle = isFree
    ? 'TotalCare member -  Credit available!'
    : 'TotalCare member  - Monthly credit used';
  const membershipFreeAppointmentTitle = planIncludeAppointment
    ? `${patientPlanInfo?.planName} member - Unlimited free appointments`
    : 'Would they like to upgrade to unlimited appointments?';

  const steps: StepsTitleProps = {
    MAKE_UP: 'Is this a make-up appointment?',
    CATEGORY: 'What is this appointment for?',
    FILES: 'Add additional information for the appointment',
    DATE_TIME: 'Select a provider',
    MEMBERSHIP: isTotalCare ? membershipTotalCareTitle : membershipFreeAppointmentTitle,
    CHECKOUT: 'Confirm and pay',
    CONFIRMATION: 'Appointment confirmed'
  };

  return steps[stepsList[step]];
};

export const buildCreateAppointmentBody = (
  data: AppointmentProps,
  selectedPastAppointment: boolean,
  isAdHocAppointment: boolean
): AddAppointmentDetails => ({
  appointmentDescription: data.appointmentDescription.trim(),
  appointmentMethod: data.callType,
  appointmentTime: {
    endTime: data.endTime,
    startTime: data.startTime
  },
  appointmentTypeId: data.appointmentTypeId,
  doctorId: data.doctorId,
  isWeightManagementAppointment: data.isWeightManagementAppointment,
  isAdHocAppointment: isAdHocAppointment,
  ...(selectedPastAppointment && {
    makeUp: {
      appointmentId: data.pastAppointmentId,
      description: data.pastAppointmentDescription
    }
  })
});

export const buildMembershipDataBody = (
  planID: string,
  pricePoints?: PricePointProps,
  data?: FreeAppointments
) => ({
  freeAppointmentInfo: {
    bookedFreeAppointmentDate: data?.freePerMonthAlreadyBookedAppointmentStartTime || '',
    freeAppointmentsAmount: data?.freePerMonthAppointmentsLeft || 0,
    isFree: data?.isFree || false,
    periodEnd: data?.subscriptionMonthEnd || '',
    periodStart: data?.subscriptionMonthStart || '',
    subscriptionHasFree: data?.subscriptionHasFreePerMonthFeature
  },
  planID: planID,
  pp: pricePoints || null,
  type: PlanType.OneTime,
  ...(data?.insuranceEligibility && { insuranceEligibility: data.insuranceEligibility })
});
