import { TaskDetailsProps } from 'models/tasks.types';
import { hasPermission, TASK_ACCORDING_TO_CATEGORY } from 'utils/popupsContentPermission';

export const getAssignProviderData = (taskDetails: TaskDetailsProps) => {
  const showAssignProviderBtn = hasPermission(
    TASK_ACCORDING_TO_CATEGORY.showAssignProviderBtn,
    taskDetails.category
  );
  const isReAssignProviderFlow = !!taskDetails?.doctorInfo?._id;

  return { showAssignProviderBtn, isReAssignProviderFlow };
};
