import {
  BaseQueryApi,
  BaseQueryFn,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError
} from '@reduxjs/toolkit/query';
import { fetchAuthSession } from 'aws-amplify/auth';
import type { RootState } from 'store';
import { setToken } from 'store/user/userSlice';

// TODO customize baseUrl in services.ts
export const baseQuery = fetchBaseQuery({
  baseUrl: import.meta.env.VITE_PLANEX_API_URL,
  prepareHeaders: async (headers, api) => {
    try {
      // Re-Auth happens automatically when the token expires
      const session = await fetchAuthSession({ forceRefresh: true });
      if (session?.tokens) {
        const accessToken = session.tokens.accessToken?.toString() ?? '';
        const idToken = session.tokens.idToken?.toString() ?? '';

        // Set the token in the store to trigger pre-existing functionality
        if ('dispatch' in api) {
          const api2 = api as BaseQueryApi;
          if ((api2.getState() as RootState).user.accessToken !== accessToken) {
            api2.dispatch(setToken({ accessToken, idToken }));
          }
        }

        headers.set('Authorization', 'Bearer ' + accessToken);
        headers.set('idToken', idToken);
      }
    } catch (e) {
      if (e !== 'The user is not authenticated')
        console.error('Unable to fetch current session', e);
    } finally {
      headers.set('API-KEY', import.meta.env.VITE_API_KEY ?? '');
    }

    return headers;
  }
});

// TODO: ask backend to add API for re-authentication like on patient side
export const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  return baseQuery(args, api, extraOptions);
};
