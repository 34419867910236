import classNames from 'classnames';

const CalendarHint = () => {
  const wrapperClassnames = classNames(
    'z-20 w-screen-xs px-8 py-6 shadow-2xl border-t border-gray-100 fixed right-[-140px] bg-white bg-opacity-90 text-xl leading-tight transition transition-all duration-300 transform scale-0 group-hover:scale-100 group-hover:-translate-x-1/2 -translate-y-2/3 rounded-3xl'
  );
  const hintClasses = 'absolute w-6 h-px top-1.5 left-2 bg-gray-600';

  return (
    <>
      <div data-testid="calendar_info_popup" className={wrapperClassnames}>
        <span className="text-xl font-bold text-gray-700">Past appts</span>
        <div className="flex items-center">
          <div className="h-4 w-8 rounded-lg bg-secondary-100" />
          <div className="ml-2 mr-4 flex h-4 w-8 rounded-lg bg-secondary-100">
            <div className="relative m-auto size-2">
              <div className="m-auto size-2 rounded-full border-2 border-white bg-red" />
              <div className={hintClasses} />
            </div>
          </div>
          <span className="text-gray-700">Action required</span>
        </div>
        <span className="mt-4 block text-xl font-bold text-gray-700">Future appts</span>
        <div className="flex items-center">
          <div className="h-4 w-8 rounded-lg bg-secondary" />
          <div className="ml-2 mr-4 flex h-4 w-8 rounded-lg bg-secondary">
            <div className="relative m-auto size-2">
              <div className="relative m-auto size-2 rounded-full border-2 border-white bg-red" />
              <div className={hintClasses} />
            </div>
          </div>
          <span className="text-gray-700">Action required</span>
        </div>
      </div>
    </>
  );
};

export default CalendarHint;
