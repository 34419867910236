import { SentRequestFilterData, TaskFilterData } from 'constants/filters';

import { Row } from '@tanstack/react-table';
import { TasksTableColumnsProps } from 'components/tables/TaskTable/tasksTable.types';
import { Role } from 'enums/role';
import { TaskCategories } from 'enums/taskCategories';
import { TaskProps } from 'models/tasks.types';
import queryString from 'query-string';
import { Location, URLSearchParamsInit } from 'react-router-dom';
import { QueueTasksProps } from 'store/queue/queueSlice.types';

export const onClickTaskRow = (
  row: Row<TasksTableColumnsProps | QueueTasksProps>,
  location: Location,
  setSearch: (
    nextInit: URLSearchParamsInit,
    navigateOptions?: { replace?: boolean | undefined; state?: unknown } | undefined
  ) => void,
  action: ((task: TaskProps | QueueTasksProps) => void) | null
) => {
  if (row.original._id) {
    let searchQuery;
    if (action) {
      searchQuery = {
        ...queryString.parse(location.search)
      };
      action(row.original);
    } else {
      searchQuery = {
        ...queryString.parse(location.search),
        taskModalOpenID: row.original._id
      };
    }
    setSearch(searchQuery as URLSearchParamsInit);
  }
};

export const showAssignedToTaskPopup = (
  row: Row<TasksTableColumnsProps | QueueTasksProps>,
  userId: string
) => {
  if ('assignedToInfo' in row.original) {
    const isTaskAssignToMe = row?.original.assignedToInfo?._id === userId;
    return row?.original.assignedToInfo && !isTaskAssignToMe;
  }
};

export const showQueueListPermission = (userRole: string) => {
  return userRole === Role.AD || userRole === Role.MA;
};

export const filtersByRoles = (
  userRole: string,
  isSentRequests?: boolean,
  isQueueDisabled?: boolean,
  isPatientPage?: boolean
) =>
  (isSentRequests ? SentRequestFilterData : TaskFilterData).filter((item) => {
    if (isQueueDisabled && item.label === TaskCategories.Queue) return false;

    if (isPatientPage) return true;

    if (item.roles) {
      return item.roles.includes(userRole as Role);
    }
  });

export const getQueueCategoryText = (category: string) => {
  let text;
  switch (category) {
    case TaskCategories.Appointment:
      text = 'Queue';
      break;
    case TaskCategories.Onboarding:
      text = 'Queue onboard';
      break;
    case TaskCategories.PreConsult:
      text = 'Queue pre-consult';
      break;
    default:
      text = 'Queue';
  }
  return text;
};
