import { ObjectValues } from 'utils/common/types';

export const ShiftType = {
  FLOATER: 'Floater',
  DAY_OFF: 'Day off',
  BREAK: 'Break',
  PRIMARY_CARE: 'Primary care',
  SPECIALIZED: 'Specialized',
  PRIMARY_CARE_AND_SPECIALIZED: 'Primary care & Specialized',
  ALL_TYPES: 'All types',
  ALL_TYPES_AVAILABLE_TO_BOOK: 'All types - Available to book',
  ALL_TYPES_UNAVAILABLE_TO_BOOK: 'All types - Floater - Unavailable to book'
} as const;

export type ShiftTypes = ObjectValues<typeof ShiftType>;
