import React from 'react';

import { nanoid } from '@reduxjs/toolkit';
import classNames from 'classnames';

import { HoursCellProps } from './hoursCell.types';
import { hours } from '../../providerAvailability.setting';

const HoursCell: React.FC<HoursCellProps> = ({
  shouldUseLabel = false,
  shouldShowTopBorder = false,
  shouldShowLeftBorder = true,
  shouldShowLastRightBorder = false
}) => {
  return (
    <>
      {hours.map((time: string, idx: number) => (
        <div
          data-testid="hour-cell"
          key={nanoid()}
          className={classNames(
            'flex w-[4.16666666667%] items-center text-xs font-medium text-gray-400',
            {
              'border-l': shouldShowLeftBorder,
              'border-r': shouldShowLastRightBorder && idx === hours.length - 1,
              'border-t': shouldShowTopBorder
            }
          )}
        >
          {shouldUseLabel && time.substring(0, time.length - 1)}
        </div>
      ))}
    </>
  );
};

export default HoursCell;
