import { Common } from '@thecvlb/design-system/lib/src';
import Loader from 'components/common/Loader';
import ControlledSelect from 'components/forms/controlled/ControlledSelect';
import { useFormContext } from 'react-hook-form';
import { useGetSubscriptionCancelReasonsQuery } from 'store/subscription/subscriptionSlice';

import { CancelPlanReasonProps } from './cancelPlanReason.types';
import { CancellationType } from '../cancelPlan.types';

const CancelPlanReason: React.FC<CancelPlanReasonProps> = ({ cancellationType }) => {
  const { control, register } = useFormContext();
  const { data: subscriptionCancelReasons, isFetching } = useGetSubscriptionCancelReasonsQuery({
    cancellationType
  });
  const label =
    cancellationType === CancellationType.CancelAccountAndRefundPatient
      ? 'Reason for cancellation'
      : 'Reason for downgrade';

  return (
    <>
      {isFetching && <Loader isVisible />}
      <ControlledSelect
        control={control}
        options={subscriptionCancelReasons}
        name="cancelReason"
        className="mb-6 w-full"
        placeholder="Select..."
        disabled={isFetching}
        size="md"
        label={label}
        rules={{
          required: {
            value: true,
            message: 'Reason is required'
          }
        }}
      />

      <Common.TextArea
        label="Details"
        size="sm"
        placeholder="Enter any details you want to add..."
        defaultValue=""
        rows={4}
        {...register('details')}
      />
    </>
  );
};

export default CancelPlanReason;
