import dayjs from 'dayjs';
import { AppointmentItem } from 'store/appointments/appointments.types';

import { CalendarEvent } from '../Calendar/calendar.types';

export const handleEvents = (appointments: AppointmentItem[]) => {
  const list: CalendarEvent[] = appointments.map((appointment) => {
    const eventStartTime = dayjs(appointment.appointmentTime?.startTime).toDate();
    const eventTitle = appointment.patientData?.[0]?.name;

    return {
      allDay: false,
      isMine: false,
      isActionRequired: appointment.isActionRequired,
      id: appointment._id,
      taskId: appointment.taskId,
      title: eventTitle,
      passedConsult: dayjs(appointment.appointmentTime?.startTime).isBefore(dayjs()),
      start: eventStartTime,
      end: dayjs(appointment.appointmentTime?.endTime).toDate(),
      status: appointment.appointmentStatus,
      color: 'transparent',
      missedAppointment: appointment.missedAppointment,
      missedAppointmentByStaff: appointment.missedAppointmentByStaff,
      pastAppointment: appointment.pastAppointment
    };
  });

  return list;
};
