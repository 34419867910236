import { CancelAppointmentReason } from 'enums/appointments';
import { MarkCompleteReasons } from 'enums/markCompleteOption';
import type {
  AppointmentItem,
  AppointmentItemProps,
  AppointmentQueryParams,
  AvailableDatesDataProps,
  AvailableTimesDataProps,
  CallLogTracksReqProps,
  CallLogTracksResProps,
  CheckFreeAppointmentsReqProps,
  CreateAppointmentReqProps,
  CreateAppointmentResProps,
  ExtendedSlots,
  FreeAppointments,
  GetAppointmentsPayload,
  GetAvailableDatesReqProps,
  GetAvailableTimesReqProps,
  GetCombinedAvailabilityReqProps,
  GetGlobalAvailableDatesReqProps,
  GetZoomTokenReqProps,
  GetZoomTokenResProps
} from 'store/appointments/appointments.types';
import { parseUpcomingAppointment } from 'utils/appointment';

import { apiSlice } from '../api/apiSlice';

export const appointmentsApiSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getToken: build.query<{ token: string }, { id: string }>({
      keepUnusedDataFor: 0,
      query: ({ id }) => `/appointments/${id}/call-token?type=video`,
      transformResponse: (response: { data: { token: string } }) => response.data
    }),
    getZoomToken: build.query<GetZoomTokenResProps, GetZoomTokenReqProps>({
      keepUnusedDataFor: 0,
      query: (id) => `/appointments/${id}/call-token-zoom`
    }),
    getAppointments: build.query<AppointmentItem[], GetAppointmentsPayload>({
      query: (params) => ({
        url: `appointments/calendar`,
        params
      }),
      transformResponse: (response: { data: AppointmentItem[] }) => response.data,
      providesTags: (result) =>
        result
          ? ['Appointment', ...result.map(({ _id }) => ({ type: 'Appointment' as const, id: _id }))]
          : ['Appointment']
    }),
    getGlobalAppointments: build.mutation<
      { time: string; appointments: AppointmentItem[] }[],
      { startDate: string; endDate: string; timezone: string; physicianIds?: string[] }
    >({
      query: ({ startDate, endDate, timezone, physicianIds }) => ({
        url: `appointments/global-calendar`,
        params: { startDate, endDate, timezone },
        method: 'POST',
        body: { physicianIds }
      }),
      transformResponse: (response: {
        data: { time: string; appointments: AppointmentItem[] }[];
      }) => response.data,
      invalidatesTags: ['Appointment']
    }),
    cancelAppointment: build.mutation<
      { message: string },
      { id: string; cancelReason: CancelAppointmentReason }
    >({
      query: ({ id, cancelReason }) => ({
        url: `appointments/${id}/cancel`,
        method: 'PATCH',
        body: { cancelReason }
      }),
      invalidatesTags: (_result, _error, arg) => [
        { type: 'Appointment', id: arg.id },
        'PatientAppointments'
      ]
    }),
    markCompleteAppointment: build.mutation({
      query: ({ id, appointmentCompleteReason }) => ({
        url: `appointments/${id}/mark-complete`,
        body: { appointmentCompleteReason },
        method: 'PATCH'
      }),
      transformResponse: (response: { data: { newTaskId: string } }) => response.data,
      invalidatesTags: (_result, _error, arg) => [{ type: 'Appointment', id: arg }]
    }),
    visitTypeAppointment: build.mutation({
      query: ({ id, body }: { id: string; body: { visitType: string } }) => ({
        url: `appointments/${id}/visit-type`,
        method: 'PATCH',
        body
      })
    }),
    trackConnectError: build.mutation({
      query: ({
        appointmentId,
        body
      }: {
        appointmentId: string;
        body: {
          event: 'connect' | 'disconnected' | 'reconnecting';
          callType: 'video' | 'phone';
          statusCode?: number;
          message?: string;
        };
      }) => ({
        url: `/appointments/${appointmentId}/call-event-errors`,
        method: 'POST',
        headers: {
          Platform: 'web-physician'
        },
        body
      })
    }),
    callLogTracks: build.mutation<CallLogTracksResProps, CallLogTracksReqProps>({
      query: ({ appointmentId, ...body }) => ({
        body: {
          ...body
        },
        headers: { Platform: 'web-physician' },
        method: 'POST',
        url: `appointments/${appointmentId}/call-log-tracks`
      })
    }),
    getPatientAppointments: build.query<
      { appointments: AppointmentItemProps[]; totalCount: number },
      { patientId: string; params?: AppointmentQueryParams }
    >({
      query: ({ patientId, params }) => ({
        url: `patients/${patientId}/appointments`,
        params
      }),
      transformResponse: (response: {
        data: AppointmentItemProps[];
        info: { totalCount: number };
      }) => ({
        appointments: response.data ? [...response.data.map(parseUpcomingAppointment)] : [],
        totalCount: response.info.totalCount
      }),
      providesTags: ['PatientAppointments']
    }),
    getCombinedAvailability: build.query<ExtendedSlots, GetCombinedAvailabilityReqProps>({
      query: (params) => ({
        params,
        url: 'doctors/combined-availability'
      }),
      transformResponse: (response: { data: ExtendedSlots }) => response.data
    }),
    getAvailableTimes: build.query<AvailableTimesDataProps, GetAvailableTimesReqProps>({
      query: ({ doctorId, ...params }) => ({
        params,
        url: `doctors/${doctorId}/availability`
      }),
      transformResponse: (response: { data: AvailableTimesDataProps }) => response.data
    }),
    getAvailableDates: build.query<AvailableDatesDataProps[], GetAvailableDatesReqProps>({
      query: ({ doctorId, ...params }) => ({
        params,
        url: `doctors/${doctorId}/available-dates`
      }),
      transformResponse: (response: { data: AvailableDatesDataProps[] }) => response.data
    }),
    getGlobalAvailableDates: build.query<
      AvailableDatesDataProps[],
      GetGlobalAvailableDatesReqProps
    >({
      query: ({ ...params }) => ({
        params,
        url: 'available-dates'
      }),
      transformResponse: (response: { data: AvailableDatesDataProps[] }) => response.data
    }),
    checkFreeAppointments: build.mutation<FreeAppointments, CheckFreeAppointmentsReqProps>({
      query: (body) => ({
        body,
        method: 'POST',
        url: 'appointments/payment-predict'
      }),
      transformResponse: (response: { data: FreeAppointments }) => response.data
    }),
    uploadFiles: build.mutation<unknown, { appointmentId: string; body: FormData }>({
      query: ({ appointmentId, body }) => ({
        body,
        method: 'POST',
        url: `appointments/${appointmentId}/upload`
      }),
      transformResponse: (response: { data: AvailableTimesDataProps }) => response.data
    }),
    createAppointment: build.mutation<CreateAppointmentResProps, CreateAppointmentReqProps>({
      query: ({ patientId, body }) => ({
        body,
        method: 'POST',
        url: `/patients/${patientId}/appointments`
      }),
      transformResponse: (response: { data: CreateAppointmentResProps }) => response.data,
      invalidatesTags: ['PatientAppointments']
    }),
    cancelPendingAppointments: build.mutation<{ message: string }, { patientId: string }>({
      query: ({ patientId }) => ({
        url: `patients/${patientId}/cancel-pending-appointments`,
        method: 'POST'
      }),
      invalidatesTags: ['PatientAppointments']
    }),
    updateCompleteOrCancelReason: build.mutation<
      { message: string },
      { appointmentId: string; body: { reason: MarkCompleteReasons | CancelAppointmentReason } }
    >({
      query: ({ appointmentId, body }) => ({
        url: `appointments/${appointmentId}/update-reason`,
        method: 'PATCH',
        body
      })
    })
  })
});

export const {
  useLazyGetTokenQuery,
  useLazyGetZoomTokenQuery,
  useLazyGetAppointmentsQuery,
  useGetGlobalAppointmentsMutation,
  useCancelAppointmentMutation,
  useMarkCompleteAppointmentMutation,
  useTrackConnectErrorMutation,
  useLazyGetPatientAppointmentsQuery,
  useLazyGetCombinedAvailabilityQuery,
  useLazyGetAvailableTimesQuery,
  useLazyGetAvailableDatesQuery,
  useLazyGetGlobalAvailableDatesQuery,
  useCheckFreeAppointmentsMutation,
  useUploadFilesMutation,
  useGetPatientAppointmentsQuery,
  useCreateAppointmentMutation,
  useCancelPendingAppointmentsMutation,
  useCallLogTracksMutation,
  useUpdateCompleteOrCancelReasonMutation
} = appointmentsApiSlice;
