import { ColorTagProps } from '@thecvlb/design-system/lib/src/common';
import capitalize from 'lodash/capitalize';

export const formatHeaderText = (value: string | undefined) => {
  if (!value) return;
  if (value === 'Missing RX') return value;
  return capitalize(value);
};

const colorMap = [
  {
    color: 'green',
    status: [
      'completed',
      'created',
      'shipped',
      'processed',
      'transferred',
      'replacement',
      'local pharmacy',
      'delivered',
      'new',
      'complete'
    ]
  },
  {
    color: 'red',
    status: [
      'removed',
      'cancelled',
      'error',
      'failure',
      'exception',
      'missing rx',
      'expired',
      'blocked',
      'pa required',
      'patient no response',
      'provider no response'
    ]
  },
  { color: 'yellow', status: ['pending', 'placed', 'in progress', 'shipping', 'on hold'] },
  { color: 'gray', status: ['closed'] }
];

export const getColorMapValue = (value: string | undefined): ColorTagProps['color'] => {
  if (!value) return 'gray';
  const picked = colorMap.find((item) => item.status.includes(value.toLocaleLowerCase()));
  return (picked?.color as ColorTagProps['color']) || 'gray';
};
