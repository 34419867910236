import { Common } from '@thecvlb/design-system';
import { getOptions } from 'components/appointments/ManageAvailability/WeeklyAvailability/WeeklyAvailabilityForm/TimeSlotTypeMultiselect/timeSlotTypeMultiSelect.settings';
import isEmpty from 'lodash/isEmpty';
import { EventMultiSelectValue, EventTypes } from 'models/event.types';
import { Controller, useFormContext } from 'react-hook-form';
import { ShiftTypesResponseProps } from 'store/calendar/calendar.types';
import { useGetShiftTypesQuery } from 'store/calendar/calendarSlice';

import { ShiftTypeSelectProps } from './shiftTypeSelect.types';
import { FormValues } from '../../manageAvailability.types';
import { transformShiftTypesToCategoryFilters } from '../EventCategorySelect/eventCategorySelect.settings';

const ShiftTypeSelect = ({
  type,
  defaultShiftTime,
  setShowCategoryFilters
}: ShiftTypeSelectProps) => {
  const { control, watch, getValues, setValue, clearErrors } = useFormContext<FormValues>();

  const { data: shiftTypes, isLoading: isLoadingShiftTypes } = useGetShiftTypesQuery();

  const handleFloaterSelected = () => {
    setValue('categoryFilters', []);
    setValue('categoryExclusions', []);
    setShowCategoryFilters(false);
  };

  /**
   * Updates the category filters based on the selected shift types.
   * It transforms the shift types into category filter options and sets the category filters value to the intersection of the current category filters and the new category filter options.
   * It also toggles the visibility of the category filters based on whether there are any selected shift types.
   *
   * @param {EventMultiSelectValue[]} newValues - The new selected shift types.
   * @param {ShiftTypesResponseProps[]} shiftTypesResponse - The response of the shift types query.
   */
  const updateCategoryFilters = (
    newValues: EventMultiSelectValue[],
    shiftTypesResponse: ShiftTypesResponseProps[]
  ) => {
    const categoryFilterOptions = transformShiftTypesToCategoryFilters(
      shiftTypesResponse,
      newValues
    );

    const allAvailableOptions = categoryFilterOptions.flatMap((filter) => filter.options);
    const selectedOptions = watch('categoryFilters');
    const exclusionsOptions = watch('categoryExclusions');

    const filteredSelectedOptions = selectedOptions?.filter((selectedOption) =>
      allAvailableOptions.some((option) => option.value === selectedOption.value)
    );

    const filteredExclusionsOptions = exclusionsOptions?.filter((selectedOption) =>
      allAvailableOptions.some((option) => option.value === selectedOption.value)
    );

    setValue('categoryFilters', filteredSelectedOptions);
    setValue('categoryExclusions', filteredExclusionsOptions);
    setShowCategoryFilters(!isEmpty(newValues));
  };

  /**
   * Handles the selection of a new shift type when the 'floater' shift type is already selected.
   * It updates the category filters based on the new shift type and returns an array containing only the new shift type.
   *
   * @param {EventMultiSelectValue[]} newValues - The new selected shift types.
   * @param {EventMultiSelectValue[]} previousValues - The previously selected shift types.
   * @param {ShiftTypesResponseProps[]} shiftTypesResponse - The response of the shift types query.
   * @returns {EventMultiSelectValue[]} An array containing only the new shift type.
   */
  const handleAnotherOptionSelected = (
    newValues: EventMultiSelectValue[],
    previousValues: EventMultiSelectValue[],
    shiftTypesResponse: ShiftTypesResponseProps[]
  ) => {
    const newOption = newValues.find(
      (newValue) => !previousValues.some((prevValue) => prevValue.value === newValue.value)
    );

    if (newOption) {
      updateCategoryFilters([newOption], shiftTypesResponse);
    }
    return [newOption];
  };

  /**
   * Handles changes to the 'time-off' event type.
   *
   * It toggles between the 'time-off' and 'break' event types. So you can't select both at the same time.
   * If the 'time-off' event type is selected, it resets the shift time to the default shift time and clears any errors related to shift time.
   *
   * @param {EventMultiSelectValue[]} newValue - The new value of the event types after a change.
   * @returns {EventMultiSelectValue[]} The updated event types.
   */
  const handleTimeOffChange = (newValue: EventMultiSelectValue[]) => {
    const newValues = newValue as EventMultiSelectValue[];
    if (getValues('eventTypes')?.length > 0) {
      newValues.splice(0, 1);
    }

    if (newValues?.find((eventType) => eventType.value === 'time-off')) {
      setValue('shiftTime', defaultShiftTime);
      clearErrors('shiftTime');
    }

    return newValues;
  };

  /**
   * Handles changes to the shift type.
   * It updates the category filters based on the selected shift types.
   * So if you unselect a shift type, it will also unselect the category filters associated with that shift type.
   * If the 'floater' shift type is selected, it handles the selection differently based on whether the 'floater' shift type was previously selected or not.
   *
   * @param {EventMultiSelectValue[]} newValues - The new value of the shift type after a change.
   * @param {ShiftTypesResponseProps[]} shiftTypesResponse - The response of the shift types query.
   * @returns {EventMultiSelectValue[]} The updated shift types.
   */
  const handleShiftTypeChange = (
    newValues: EventMultiSelectValue[],
    shiftTypesResponse: ShiftTypesResponseProps[]
  ) => {
    const floaterType = newValues.find(
      (eventType) => eventType.label.toLocaleLowerCase() === 'floater'
    );
    const previousValues = getValues('eventTypes');
    const previousFloaterType = previousValues.find(
      (eventType) => eventType.label.toLocaleLowerCase() === 'floater'
    );

    if (floaterType && !previousFloaterType) {
      // 'floater' was just selected, so return only 'floater'
      handleFloaterSelected();
      return [floaterType];
    } else if (floaterType && previousFloaterType) {
      return handleAnotherOptionSelected(newValues, previousValues, shiftTypesResponse);
    }

    updateCategoryFilters(newValues, shiftTypesResponse);
    return newValues;
  };

  const handleEventTypeChange = (newValue: EventMultiSelectValue[]) => {
    // Allow user to select only one type of event when adding or updating time off
    if (type === EventTypes.TIME_OFF) {
      return handleTimeOffChange(newValue);
    } else if (shiftTypes) {
      return handleShiftTypeChange(newValue, shiftTypes);
    }
    return newValue;
  };

  return (
    <Controller
      name="eventTypes"
      control={control}
      rules={{
        required:
          type === EventTypes.TIME_OFF
            ? 'Select at least one time off type'
            : 'Select at least one shift type'
      }}
      render={({ field }) =>
        isLoadingShiftTypes ? (
          <div
            data-testid="shift-type-loading-skeleton"
            className="h-8 w-full animate-pulse rounded bg-slate-200"
          />
        ) : (
          <Common.MultiSelect
            dataTestId="time_off_type_field"
            size="sm"
            value={field.value}
            options={getOptions(type, shiftTypes)}
            placeholder="Enter input..."
            name={field.name}
            onBlur={field.onBlur}
            onChange={(newValue) =>
              field.onChange(handleEventTypeChange(newValue as EventMultiSelectValue[]))
            }
          />
        )
      }
    />
  );
};

export default ShiftTypeSelect;
