import { useCallback, useEffect, useState } from 'react';

import Calendar from 'components/appointments/Calendar';
import Loader from 'components/common/Loader';
import dayjs from 'dayjs';
import { CalendarTab } from 'enums/calendarTabs';
import { DateFormat } from 'enums/dateFormats';
import { useLazyGetAppointmentsQuery } from 'store/appointments/appointmentsSlice';
import { getISOStringIncludingTimezone } from 'utils/helpers';

import { handleEvents } from './myCalendar.settings';
import { CalendarEvent, CalendarPeriod } from '../Calendar/calendar.types';

const MyCalendar: React.FC<{ activeTab: CalendarTab; timezone: string }> = ({
  activeTab,
  timezone
}) => {
  const [getAppointments, { data: appointments, isFetching, isLoading }] =
    useLazyGetAppointmentsQuery();

  const [eventsList, setEventsList] = useState<CalendarEvent[]>([]);
  const [dates, setDates] = useState<CalendarPeriod>({
    startDate: dayjs().startOf('week').format(DateFormat.YYYY_MM_DD),
    endDate: dayjs().endOf('week').add(1, 'days').format(DateFormat.YYYY_MM_DD),
    timezone
  });

  const handleDateChange = useCallback(
    (startDate: string, endDate: string) => {
      setDates({
        startDate: dayjs(startDate).format(DateFormat.YYYY_MM_DD),
        endDate: dayjs(endDate).format(DateFormat.YYYY_MM_DD),
        timezone
      });
    },
    [timezone]
  );

  useEffect(() => {
    if (activeTab === CalendarTab.Tasks) {
      getAppointments(
        {
          startTime: getISOStringIncludingTimezone(
            dates.startDate,
            timezone,
            DateFormat.YYYY_MM_DD
          ),
          endTime: getISOStringIncludingTimezone(dates.endDate, timezone, DateFormat.YYYY_MM_DD)
        },
        true
      );
    }
  }, [getAppointments, dates.startDate, dates.endDate, dates.timezone, activeTab, timezone]);

  useEffect(() => {
    if (Array.isArray(appointments) && appointments.length) {
      setEventsList(handleEvents(appointments));
    }
  }, [appointments]);

  return (
    <>
      <Loader isVisible={isFetching || isLoading} />
      <Calendar events={eventsList} onUpdate={handleDateChange} timezone={timezone} />
    </>
  );
};

export default MyCalendar;
