import { useMemo } from 'react';

import { createSelector } from '@reduxjs/toolkit';
import { Common } from '@thecvlb/design-system/lib/src';
import ControlledSelect from 'components/forms/controlled/ControlledSelect';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { useForm } from 'react-hook-form';
import { useToggle } from 'react-use';
import { closeModal } from 'store/modal/modalSlice';
import { useGetAvailableProvidersQuery } from 'store/staffs/staffsSlice';
import { selectTask } from 'store/tasks/tasksSlice';
import { selectUser } from 'store/user/userSlice';
import { validation } from 'utils/helpers';

import { normalizeAvailableProvidersData } from './form.settings';
import { AssignProvidersFormProps } from './form.types';
import { ProviderSearchProps } from '../assignProvider.types';

const selectAssignProviderFormState = createSelector([selectTask, selectUser], (task, user) => ({
  taskDetails: task.taskDetails,
  userTimezone: user.timezone
}));

const Form = ({ onSubmit, isLoading, actionBtnLabel }: AssignProvidersFormProps) => {
  const dispatch = useAppDispatch();

  const { taskDetails, userTimezone } = useAppSelector(selectAssignProviderFormState);
  const [confirmed, toggleConfirmed] = useToggle(false);
  const { handleSubmit, control, watch, formState } = useForm<ProviderSearchProps>({
    mode: 'onChange'
  });
  const cancelAssignProvider = () => dispatch(closeModal());

  const {
    data: availableProvidersData,
    isLoading: isLoadingAvailProviders,
    isFetching: isFetchingAvailProviders,
    isSuccess
  } = useGetAvailableProvidersQuery({
    appointmentTypeId: taskDetails.appointmentInfo.appointmentType._id ?? '',
    state: taskDetails.personalInfo.state ?? '',
    patientUserId: taskDetails.patientId,
    timezone: userTimezone,
    isUrgentTask: taskDetails.status === 'URGENT'
  });

  const searchData = useMemo(() => {
    return availableProvidersData?.data.length
      ? normalizeAvailableProvidersData(availableProvidersData.data)
      : [];
  }, [availableProvidersData]);

  const staff = watch('doctorName');

  const staffError = staff?.warning;

  const noAvailableStaffMembers =
    isSuccess && !isLoading && !searchData.length
      ? { warning: 'No staff members found' }
      : undefined;

  const disabledSubmit =
    isLoading || staffError?.canAssignStaff === false || (staffError?.shouldConfirm && !confirmed);

  const successHelperText =
    !!staff?.value && !staffError?.message
      ? "Provider is online and licensed in patient's state"
      : '';

  const helperText =
    formState.errors.doctorName?.message ||
    staffError?.message ||
    noAvailableStaffMembers?.warning ||
    successHelperText;

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-4">
      <ControlledSelect
        dataTestId="assign_provider_select"
        control={control}
        options={searchData}
        name="doctorName"
        className="w-full"
        label="Provider"
        placeholder="Search for provider"
        rules={validation('Provider')}
        errors={formState.errors.doctorName || staffError || noAvailableStaffMembers}
        helper={helperText}
        disabled={isLoadingAvailProviders || isFetchingAvailProviders}
      />
      {staffError?.shouldConfirm && (
        <div>
          <Common.Checkbox checked={confirmed} onChange={toggleConfirmed} color="blue">
            I confirm that the task is not urgent so it can wait more than 48 hours
          </Common.Checkbox>
        </div>
      )}
      <div className="mt-2 grid grid-cols-2 gap-x-2">
        <Common.Button
          type="button"
          color="white-alt"
          className="!w-full justify-center"
          onClick={cancelAssignProvider}
          size="sm"
        >
          Cancel
        </Common.Button>
        <Common.Button
          className="!w-full justify-center"
          type="submit"
          color="blue"
          size="sm"
          disabled={disabledSubmit}
        >
          {actionBtnLabel}
        </Common.Button>
      </div>
    </form>
  );
};

export default Form;
