import { ErrorMessage, FieldValuesFromFieldErrors } from '@hookform/error-message';
import { Common } from '@thecvlb/design-system/lib/src';
import classNames from 'classnames';
import { Controller, FieldErrors, FieldName, FieldValues, Path } from 'react-hook-form';
import { NumberFormatValues, PatternFormat } from 'react-number-format';
import { isValidPhoneNumber } from 'react-phone-number-input';

import { formatPhone } from './phoneInput.settings';
import { PhoneInputProps } from './phoneInput.types';

const PhoneInput = <TFieldValues extends FieldValues>({
  control,
  globalFormState,
  name = 'phone' as Path<TFieldValues>,
  labelTitle,
  placeholder,
  labelClasses = 'w-52 text-2xl mr-4',
  inputClasses = 'w-full'
}: PhoneInputProps<TFieldValues>) => {
  const isValid = !globalFormState.errors[name] && globalFormState.isSubmitted;

  const inputOwnClasses = classNames(
    'p-4 pl-3 h-8 border rounded-lg placeholder-gray outline-none bg-white ring-1 border-none',
    inputClasses ? inputClasses : '',
    !globalFormState.errors[name]
      ? 'border-gray-200 ring-gray-400 shadow-none'
      : 'border-red ring-2 ring-red shadow-sm',
    { 'border-secondary': isValid }
  );

  const postIconClassName = classNames(
    'absolute right-3 w-4 h-4 top-[8.5px]',
    globalFormState.errors[name] ? 'text-red' : isValid ? 'text-green' : 'text-gray'
  );

  const errorMessageTextClasses = 'text-red text-xs mt-1.5';

  const isValidPhone = (val: string) => {
    return (val && isValidPhoneNumber(val)) || 'Invalid phone number';
  };

  return (
    <div data-testid="phone_field_area" className="flex w-full justify-between">
      <label className={labelClasses}>{labelTitle}</label>
      <Controller
        name={name}
        control={control}
        rules={{
          required: {
            value: true,
            message: 'Phone is required'
          },

          validate: {
            isValidPhone
          }
        }}
        render={({ field: { value, onChange, ref, onBlur }, formState }) => {
          return (
            <div className="w-full">
              <div className="relative">
                <PatternFormat
                  onBlur={onBlur}
                  format="+# ### ### ####"
                  getInputRef={(inputRef: HTMLInputElement) => ref(inputRef)}
                  placeholder={placeholder}
                  onValueChange={(phone: NumberFormatValues) => onChange(phone.formattedValue)}
                  value={formatPhone(value)}
                  className={inputOwnClasses}
                  type="tel"
                />
                {(globalFormState.errors[name] || isValid) && (
                  <Common.Icon
                    name={globalFormState.errors[name] ? 'error' : 'check-circle'}
                    className={postIconClassName}
                  />
                )}
              </div>
              {formState.errors && (
                <ErrorMessage
                  errors={formState.errors}
                  name={
                    name as unknown as FieldName<
                      FieldValuesFromFieldErrors<FieldErrors<TFieldValues>>
                    >
                  }
                  render={({ message }) => <p className={errorMessageTextClasses}>{message}</p>}
                />
              )}
            </div>
          );
        }}
      />
    </div>
  );
};

export default PhoneInput;
