import { ALERT_UNIT_OPTIONS } from 'constants/alerts';
import {
  INTERNAL_TRIGGER_OPTIONS,
  NOTIFICATION,
  PATIENT_MESSAGE,
  PATIENT_TRIGGER_OPTIONS,
  TASK,
  TRIGGER_BASED_ON,
  WEBHOOK
} from 'constants/automation';

import { useEffect, useState } from 'react';

import { OptionProps } from '@thecvlb/design-system/lib/src/common';
import { notifySuccess } from 'components/common/Toast/Toast';
import { OccurrenceOrder } from 'enums/agingAlert';
import { Role, RoleShortName } from 'enums/role';
import startCase from 'lodash/startCase';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { closeModal } from 'store/modal/modalSlice';
import {
  useCreateTriggerMutation,
  useDeleteTriggerMutation,
  useLazyGetTriggerByIdQuery,
  useUpdateTriggerMutation
} from 'store/triggers/triggersSlice';

import { AutomationProps } from './automation.types';
import AutomationForm from './AutomationForm';
import { AutomationFormDataProps } from './AutomationForm/automationForm.types';

const Automation: React.FC<AutomationProps> = ({ id, isPatientTriggers }) => {
  const dispatch = useDispatch();
  const typeOptions = isPatientTriggers ? PATIENT_TRIGGER_OPTIONS : INTERNAL_TRIGGER_OPTIONS;

  const [getAutomationById, { data: automationData, isLoading }] = useLazyGetTriggerByIdQuery();
  const [createTrigger, { isLoading: loadingCreate }] = useCreateTriggerMutation();
  const [updateTrigger, { isLoading: loadingEdit }] = useUpdateTriggerMutation();
  const [deleteTrigger, { isLoading: loadingDelete }] = useDeleteTriggerMutation();

  const [currentType, setCurrentType] = useState<OptionProps>(typeOptions[0]);
  const [isShowTiming, setIsShowTiming] = useState(true);
  const [webhookEventType, setWebhookEventType] = useState<string>('');
  const [timingUnitOptions, setTimingUnitOptions] = useState<OptionProps[]>();
  const [triggerBasedOnValue, setTriggerBasedOnValue] = useState<string>('');

  const showTriggerBasedOn = !!INTERNAL_TRIGGER_OPTIONS.filter((item) => item == currentType)
    .length;
  const isDisabled = isLoading || loadingCreate || loadingEdit || loadingDelete;

  const methods = useForm({ reValidateMode: 'onChange' });

  const onSuccess = (message: string) => {
    notifySuccess(message);
    dispatch(closeModal());
  };

  const onSubmitForm = (formData: AutomationFormDataProps) => {
    let timeInHours;
    let occurenceOrder;
    if (formData) {
      if (isShowTiming) {
        timeInHours = String(formData.timingUnits.value).includes('min')
          ? (Number(formData.timingAmount) / 60).toFixed(2)
          : formData.timingAmount;
        occurenceOrder = String(formData.timingUnits.value)?.includes(OccurrenceOrder.Before)
          ? OccurrenceOrder.Before
          : OccurrenceOrder.After;
      }

      const body = {
        name: formData.name,
        status: formData.status.value,
        triggerType: currentType.value,

        ...(isShowTiming && {
          triggerTime: { occurenceOrder, timeInHours },
          criterionField: formData.triggerBasedOn?.value
        }),
        ...(currentType === TASK && {
          note: formData.note,
          categoryName: formData.category?.value,
          audience: [formData.triggerTo?.value]
        }),

        ...(currentType === NOTIFICATION && {
          text: formData.text,
          buttons: [{ variable: formData.button?.value, label: formData.button?.label }]
        }),

        ...(currentType === PATIENT_MESSAGE && {
          message: formData.message,
          buttons: [{ variable: formData.button?.value, label: formData.button?.label }]
        }),

        ...(currentType === WEBHOOK && {
          webhookType: formData.webhookType?.value,
          webhookTypeId: formData.webhookUrl,
          eventType: formData.eventType?.value
        })
      };
      const request = id ? updateTrigger({ id, body }) : createTrigger({ body });
      request.unwrap().then((res) => onSuccess(res.message));
    }
  };

  const onChangeType = (value: string) => {
    const type = typeOptions.find((t) => t.value === value);
    if (type) {
      setCurrentType(type);
    }
  };

  const onDeleteTrigger = () => {
    if (id) {
      deleteTrigger({ id })
        .unwrap()
        .then((res) => onSuccess(res.message));
    }
  };

  useEffect(() => {
    if (automationData) {
      const { triggerType } = automationData;
      const newType = typeOptions.find((type) => type.value === triggerType);
      newType && setCurrentType(newType);
    }
  }, [automationData, typeOptions]);

  useEffect(() => {
    if (webhookEventType === 'Appointment Reminder') {
      const modifiedTimingUnitsListBefore = ALERT_UNIT_OPTIONS.filter((item) =>
        item?.value?.includes('before')
      );
      setTimingUnitOptions(modifiedTimingUnitsListBefore);
    } else if (
      webhookEventType === 'Message: Responded' ||
      webhookEventType === 'Front Desk: Responded' ||
      webhookEventType === 'Appointment Missed' ||
      triggerBasedOnValue === 'createdAt'
    ) {
      const modifiedTimingUnitsListAfter = ALERT_UNIT_OPTIONS.filter((item) =>
        item?.value?.includes('after')
      );
      setTimingUnitOptions(modifiedTimingUnitsListAfter);
    } else setTimingUnitOptions(ALERT_UNIT_OPTIONS);
  }, [webhookEventType, triggerBasedOnValue]);

  useEffect(() => {
    if (id) {
      getAutomationById({ id });
    }
  }, [getAutomationById, id]);

  useEffect(() => {
    if (automationData) {
      const {
        name,
        text,
        message,
        eventType,
        webhookType,
        webhookTypeId,
        triggerTime,
        triggerType,
        buttons,
        status,
        categoryName,
        note,
        audience,
        criterionField
      } = automationData;

      const timingUnits = ALERT_UNIT_OPTIONS.find((item) => {
        if (triggerTime?.timeInHours >= 1) {
          return item.value.includes('hours.' + triggerTime?.occurenceOrder);
        } else {
          return item.value.includes('minutes.' + triggerTime?.occurenceOrder);
        }
      });

      const triggerTo = audience?.[0];

      setIsShowTiming(!!timingUnits);
      if (criterionField) setTriggerBasedOnValue(criterionField);
      methods.reset({
        type: { label: triggerType, value: triggerType },
        status: { value: status, label: startCase(status) },
        name,
        ...(timingUnits && {
          timingUnits,
          timingAmount:
            triggerTime.timeInHours >= 1
              ? triggerTime.timeInHours
              : (triggerTime.timeInHours * 60).toFixed()
        }),
        ...(categoryName && {
          category: { value: categoryName, label: categoryName }
        }),
        ...(note && { note }),
        ...(audience &&
          triggerTo && {
            triggerTo: { value: triggerTo, label: Role[triggerTo as RoleShortName] }
          }),
        ...(text && { text }),
        ...(buttons &&
          buttons[0] && {
            button: { value: buttons[0].variable, label: buttons[0].label }
          }),
        ...(message && { message }),
        ...(webhookTypeId && { webhookUrl: webhookTypeId }),
        ...(webhookType && { webhookType: { value: webhookType, label: webhookType } }),
        ...(eventType && { eventType: { value: eventType, label: eventType } }),
        ...(criterionField && {
          triggerBasedOn: {
            value: criterionField,
            label: TRIGGER_BASED_ON.find((item) => item.value === criterionField)?.label
          }
        })
      });
    } else {
      methods.reset({
        type: methods.getValues('type'),
        status: methods.getValues('status'),
        name: methods.getValues('name'),
        timingUnits: methods.getValues('timingUnits'),
        timingAmount: methods.getValues('timingAmount')
      });
    }
  }, [currentType, automationData, methods]);

  return (
    <FormProvider {...methods}>
      <AutomationForm
        id={id}
        onSubmitForm={onSubmitForm}
        currentType={currentType.value as string}
        typeOptions={typeOptions}
        onChangeType={onChangeType}
        onDeleteTrigger={onDeleteTrigger}
        setIsShowTiming={setIsShowTiming}
        isShowTiming={isShowTiming}
        disabledButtons={isDisabled}
        setEventType={setWebhookEventType}
        timingUnitOptions={timingUnitOptions}
        showTriggerBasedOn={showTriggerBasedOn}
        setTriggerBasedOn={setTriggerBasedOnValue}
      />
    </FormProvider>
  );
};

export default Automation;
