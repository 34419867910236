import { useMemo } from 'react';

import { ColumnDef, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { Common } from '@thecvlb/design-system';
import BasicTable from 'components/tables/BasicTable';
import { useGetDefaultBasicTableValues } from 'hooks/common/useGetDefaultBasicTableValues';
import { useAppDispatch } from 'hooks/redux';
import { closeModal } from 'store/modal/modalSlice';

import { ProspectsParametersProps } from './prospectParameters.types';
import { getParametersData } from './prospectsParameters.settings';
import { columns } from './ProspectsParametersTable/column';

const ProspectsParameters: React.FC<ProspectsParametersProps> = ({
  flowQueryParams,
  flowUrl,
  referrerUrl,
  displayActions
}) => {
  const dispatch = useAppDispatch();
  const parametersData = useMemo(
    () => getParametersData({ flowQueryParams, flowUrl, referrerUrl }),
    [flowQueryParams, flowUrl, referrerUrl]
  );

  const [tableData, tableColumns] = useGetDefaultBasicTableValues({
    isFetching: false,
    columns,
    data: parametersData
  });

  const table = useReactTable({
    data: tableData,
    columns: tableColumns as ColumnDef<{ key: string; value: string }, unknown>[],
    getCoreRowModel: getCoreRowModel()
  });

  return (
    <div
      className={`max-h-[576px] overflow-y-scroll pt-4 scrollbar-hide ${!displayActions && 'px-6 pb-6'}`}
    >
      {/* totalCount={0} to hide pagination for prospects parameters */}
      <BasicTable table={table} totalCount={0} theadClassName="w-full" />
      {displayActions && (
        <div className="mt-6 flex w-full items-center justify-end gap-2">
          <Common.Button
            dataTestId="cancel_btn"
            type="button"
            color="white-alt"
            onClick={() => dispatch(closeModal())}
            size="sm"
          >
            Cancel
          </Common.Button>
          <Common.Button dataTestId="save_btn" disabled type="button" color="blue" size="sm">
            Save
          </Common.Button>
        </div>
      )}
    </div>
  );
};

export default ProspectsParameters;
