import { TRIGGER_BASED_ON } from 'constants/automation';

import ControlledSelect from 'components/forms/controlled/ControlledSelect';
import InputField from 'components/forms/controlled/InputField';
import { validation } from 'utils/helpers';
import { NUMBER_REGEXP } from 'utils/regExp';

import { TimingSectionProps } from './timingSection.types';

const TimingSection: React.FC<TimingSectionProps> = ({
  control,
  formState,
  timingUnitOptions,
  showTriggerBasedOn,
  setTriggerBasedOn
}) => {
  return (
    <div className="flex flex-col gap-4">
      {showTriggerBasedOn && (
        <ControlledSelect
          control={control}
          labelDirection="row"
          onChange={(val) => {
            setTriggerBasedOn(val.value as string);
          }}
          options={TRIGGER_BASED_ON}
          placeholder="Select trigger based on..."
          rules={validation('Trigger based on')}
          label="Trigger based on"
          name="triggerBasedOn"
        />
      )}
      <ControlledSelect
        control={control}
        labelDirection="row"
        options={timingUnitOptions}
        placeholder="Select timing (units)..."
        rules={validation('Timing (units)')}
        label="Timing (units)"
        name="timingUnits"
      />

      <InputField
        name="timingAmount"
        placeholder="Enter timing amount..."
        label="Timing (amount)"
        control={control}
        labelDirection="row"
        type="number"
        errors={formState.errors.payAmount}
        rules={validation('Timing amount', NUMBER_REGEXP)}
      />
    </div>
  );
};

export default TimingSection;
