import dayjs from 'dayjs';

export const tableNames = [
  'phy-utlzn',
  'nrs-utlzn',
  'ma-ovrw',
  'ma-ovrw-agnt',
  'frnt-ovrw',
  'frnt-by-usr'
];

export const getDefaultReportsParams = () => ({
  limit: 10,
  dateType: 'last-30-days',
  startDate: new Date(dayjs().subtract(30, 'days').startOf('day').toString()).getTime(),
  endDate: new Date(dayjs().endOf('day').toString()).getTime()
});
