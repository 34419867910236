import { createColumnHelper } from '@tanstack/react-table';

import { BillingConfigTableProps } from './billingConfigTable.types';
import EditCell from './EditCell';
import Header from '../Header';

const columnHelper = createColumnHelper<BillingConfigTableProps>();

export const columns = [
  columnHelper.accessor('category', {
    header: () => <Header text="Category" sortField="category" />
  }),
  columnHelper.accessor('planA', {
    header: () => <Header text="Plan A price" sortField="planA" />
  }),
  columnHelper.accessor('planB', {
    header: () => <Header text="Plan B price" sortField="planB" />
  }),
  columnHelper.accessor('status', {
    header: () => <Header text="Status" sortField="status" />,
    cell: (info) => (
      <span className={`capitalize ${info.getValue() === 'active' ? 'text-blue' : 'text-red'}`}>
        {info.getValue()}
      </span>
    )
  }),
  columnHelper.accessor('actions', {
    header: () => <span className="text-sm font-bold">Actions</span>,
    cell: (info) => <EditCell id={info.row.original._id ?? ''} status={info.row.original.status} />,
    enableSorting: false
  })
];
