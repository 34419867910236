import { AdminPlatformUtilizationReportsProps } from 'store/adminReports/adminReports.types';

import { apiSlice } from '../api/apiSlice';

export const adminReportsApi = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getListPlatformUtilizationDetails: build.query<
      { data: AdminPlatformUtilizationReportsProps },
      { params: string }
    >({
      query: ({ params }) => `/admin-reports/platform-utilization?${params}`,
      transformResponse: (response: { data: AdminPlatformUtilizationReportsProps }) => response
    }),
    getListPhysicianUtilizationDetails: build.query<
      { data: Record<string, string | number>[]; totalCount: number },
      { params: string }
    >({
      query: ({ params }) => `/admin-reports/physician-utilization?${params}`,
      transformResponse: (response: {
        data: Record<string, string | number>[];
        info: { totalCount: number };
      }) => ({
        data: response.data,
        totalCount: response.info.totalCount
      })
    }),
    getListNurseUtilizationDetails: build.query<
      { data: Record<string, string | number>[]; totalCount: number },
      { params: string }
    >({
      query: ({ params }) => `/admin-reports/nurse-practioner-utilization?${params}`,
      transformResponse: (response: {
        data: Record<string, string | number>[];
        info: { totalCount: number };
      }) => ({
        data: response.data,
        totalCount: response.info.totalCount
      })
    }),
    getMedicalAssistantOverview: build.query<
      { data: Record<string, string | number>[]; totalCount: number },
      { params: string }
    >({
      query: ({ params }) => `/admin-reports/medical-assistant-overview?${params}`,
      transformResponse: (response: {
        data: Record<string, string | number>[];
        info: { totalCount: number };
      }) => ({
        data: response.data,
        totalCount: response.info.totalCount
      })
    }),
    getMedicalAssistantOverviewByAgent: build.query<
      { data: Record<string, string | number>[]; totalCount: number },
      { params: string }
    >({
      query: ({ params }) => `/admin-reports/medical-assistant-overview-by-agent?${params}`,
      transformResponse: (response: {
        data: Record<string, string | number>[];
        info: { totalCount: number };
      }) => ({
        data: response.data,
        totalCount: response.info.totalCount
      })
    }),
    getFrontDeskOverview: build.query<
      { data: Record<string, string | number>[]; totalCount: number },
      { params: string }
    >({
      query: ({ params }) => `/admin-reports/front-desk-overview?${params}`,
      transformResponse: (response: {
        data: Record<string, string | number>[];
        info: { totalCount: number };
      }) => ({
        data: response.data,
        totalCount: response.info.totalCount
      })
    }),
    getFrontDeskOverviewByUser: build.query<
      { data: Record<string, string | number>[]; totalCount: number },
      { params: string }
    >({
      query: ({ params }) => `/admin-reports/front-desk-by-user?${params}`,
      transformResponse: (response: {
        data: Record<string, string | number>[];
        info: { totalCount: number };
      }) => ({
        data: response.data,
        totalCount: response.info.totalCount
      })
    }),
    downloadAdminReports: build.query<Blob, { params: string }>({
      query: ({ params }) => ({
        url: `/download-reports/admin?${params}`,
        method: 'GET',
        responseHandler: (response: Response) => {
          return response.blob().then((res) => res);
        }
      }),
      transformResponse: (response: Blob) => response
    })
  })
});

export const {
  useLazyGetListPlatformUtilizationDetailsQuery,
  useLazyGetListPhysicianUtilizationDetailsQuery,
  useLazyGetListNurseUtilizationDetailsQuery,
  useLazyGetMedicalAssistantOverviewByAgentQuery,
  useLazyGetMedicalAssistantOverviewQuery,
  useLazyGetFrontDeskOverviewQuery,
  useLazyGetFrontDeskOverviewByUserQuery,
  useLazyDownloadAdminReportsQuery
} = adminReportsApi;
