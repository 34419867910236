import { PrescriptionLabels } from 'enums/orderLabels';
import { Prescription } from 'store/orders/orders.types';

import { PrescriptionInfoItem } from './prescriptionInfo.types';
export const getItems = (prescription: Prescription): PrescriptionInfoItem[] => {
  return [
    {
      label: PrescriptionLabels.Name,
      value: prescription.name && <span>{prescription.name}</span>
    },
    {
      label: PrescriptionLabels.Brand,
      value: prescription.brandName && <span>{prescription.brandName}</span>
    },
    {
      label: PrescriptionLabels.NDC,
      value: prescription.ndc && <span>{prescription.ndc}</span>
    },
    {
      label: PrescriptionLabels.Quantity,
      value: prescription.quantity && (
        <span>{`${prescription.quantity} ${prescription.quantityUnits}`}</span>
      )
    }
  ];
};
