import dayjs from 'dayjs';
import { DateFormat } from 'enums/dateFormats';
import { RenewalStatus, RenewalStatusMessages } from 'enums/taskDetailsStatus';

export const getRenewalStatusMessage = (
  status: keyof typeof RenewalStatusMessages,
  date?: string
) => {
  if (
    status === RenewalStatus.APPOINTMENT_REQUIRED_BY_PROVIDER_SCHEDULED ||
    status === RenewalStatus.APPOINTMENT_REQUIRED_BASED_ON_RESPONSE_SCHEDULED ||
    status === RenewalStatus.PATIENT_COMPLETED_APPOINTMENT_WAITING_FOR_PRESCRIPRION
  ) {
    return RenewalStatusMessages[status](date ? dayjs(date).format(DateFormat.MMMM_D) : '');
  }

  return RenewalStatusMessages[status];
};
