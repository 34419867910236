import { GENERAL_HEALTH } from 'constants/chat';

import { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';

import { createSelector } from '@reduxjs/toolkit';
import Channel from 'components/patient/Channel';
import { CareChannelListProps } from 'contexts/MessagesContext/messagesContext.types';
import { MessageType } from 'enums/messages';
import { useClientMessages } from 'hooks/common/useClientMessages';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import {
  channelsSelectors,
  resetChannels,
  selectChannels,
  setChannel,
  useLazyGetFrontDeskChannelsQuery,
  useLazyGetPatientChannelsQuery
} from 'store/channels/channelsSlice';
import { selectUser } from 'store/user/userSlice';

import { MessagesProps } from './messages.types';
import SMS from '../SMS';
import Sidebar from './SideBar/Sidebar';

const selectMessagesState = createSelector(
  [selectUser, selectChannels, channelsSelectors.selectAll],
  (user, channels, allChannels) => ({
    userId: user._id,
    channels: allChannels,
    currentChannel: channels.currentChannel
  })
);

const Messages: React.FC<MessagesProps> = ({ patient }) => {
  const dispatch = useAppDispatch();
  const { userId, channels, currentChannel } = useAppSelector(selectMessagesState);

  const [messageType, setMessageType] = useState<MessageType>(MessageType.Medical);

  const [getPatientChannels] = useLazyGetPatientChannelsQuery({ refetchOnReconnect: true });
  const [getFrontDeskChannels] = useLazyGetFrontDeskChannelsQuery({ refetchOnReconnect: true });

  const nodeRef = useRef<HTMLDivElement>(null);
  const validMessageType =
    messageType !== MessageType.SMS && messageType !== MessageType.StaffNote
      ? messageType
      : MessageType.Medical;

  const useMessages = useClientMessages({ type: validMessageType });
  const { markSeen, joinRoom, closeChannel, isConnected } = useMessages();

  /**
   * Determines the initial channel based on the selected message type (Medical or Support).
   * If the message type is Medical, it selects the channel with the title 'General Health'.
   * If the message type is Support, it selects the first available channel.
   * If no suitable channel is found, it returns null.
   */
  const initialChannel = useMemo(() => {
    if (messageType === MessageType.Medical && channels?.length) {
      return (
        channels.find((item) => item.channelTitle.toLowerCase() === GENERAL_HEALTH.toLowerCase()) ||
        null
      );
    } else if (messageType === MessageType.Support && channels?.length) {
      return channels[0];
    }
    return null;
  }, [channels, messageType]);

  const handleChange = useCallback(
    (channel: CareChannelListProps) => {
      closeChannel();
      dispatch(setChannel(channel));
    },
    [closeChannel, dispatch]
  );

  const handleChangeTab = useCallback(
    (tab: MessageType) => {
      closeChannel();
      dispatch(setChannel(null));
      setMessageType(tab);
    },
    [closeChannel, dispatch, setMessageType]
  );

  /**
   * Scrolls the view to the bottom of the messages list whenever the component is mounted.
   * This is useful for showing the most recent messages in a chat or conversation.
   */
  useLayoutEffect(() => {
    if (nodeRef.current) nodeRef.current.scrollIntoView({ behavior: 'smooth' });
  }, []);

  /**
   * Fetches the appropriate channels based on the selected message type (Medical or Support) when the component mounts.
   * If the message type is Medical, it fetches the patient's channels.
   * If the message type is Support, it fetches the front desk channels.
   * On unmount, it resets the channels.
   *
   */
  useEffect(() => {
    if (messageType === MessageType.Medical && patient._id) {
      getPatientChannels({ patientId: patient._id });
    } else if (messageType === MessageType.Support && patient._id) {
      getFrontDeskChannels({ patientId: patient._id });
    }

    return () => {
      dispatch(resetChannels());
    };
  }, [dispatch, getFrontDeskChannels, getPatientChannels, messageType, patient._id]);

  useEffect(() => {
    if (!currentChannel) dispatch(setChannel(initialChannel));
  }, [initialChannel, currentChannel, dispatch]);

  /**
   * Joins the channel if the user is connected and the user ID matches the user ID field inside channel object.
   * If there are any unread messages in the current channel, it marks them as seen.
   *
   */
  useEffect(() => {
    if (currentChannel) {
      if (patient._id === currentChannel?.patientId && isConnected) {
        // NOTE: unreadMessageCount is set to 0 because we are not loading
        // all unread messages at once for the patient for now
        const unreadMessageCount = 0;
        joinRoom(currentChannel.channelId, unreadMessageCount);
        if (currentChannel.unreadMessageCount) {
          markSeen({ channelId: currentChannel.channelId, id: userId });
        }
      }
    }
  }, [
    patient._id,
    currentChannel?.patientId,
    joinRoom,
    markSeen,
    currentChannel,
    userId,
    isConnected
  ]);

  const shouldShowChannel =
    messageType !== MessageType.SMS && messageType !== MessageType.StaffNote;

  return (
    <div className="flex h-[calc(100vh-24rem)] min-h-[75vh] w-full flex-row">
      <div data-testid="channels_block" className="flex flex-row">
        <div className="w-screen-xs overflow-y-auto border-r border-gray-200">
          <Sidebar
            changeActiveTab={handleChangeTab}
            patientId={patient._id}
            messageType={messageType}
            handleChange={handleChange}
          />
        </div>
      </div>
      <div className="flex w-full overflow-y-auto">
        {shouldShowChannel && (
          <Channel channel={currentChannel} key={currentChannel?.channelId} type={messageType} />
        )}

        {messageType === MessageType.SMS && <SMS />}
      </div>
      <div ref={nodeRef} />
    </div>
  );
};

export default Messages;
