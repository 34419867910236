import { useEffect } from 'react';

import { notifySuccess } from 'components/common/Toast/Toast';
import dayjs from 'dayjs';
import { useAppDispatch } from 'hooks/redux';
import { EditEventOptions } from 'models/event.types';
import {
  useDeleteEventMutation,
  useEditEventMutation,
  useGetEventQuery,
  useLazyGetEventQuery
} from 'store/calendar/calendarSlice';
import { closeModal } from 'store/modal/modalSlice';

import { DeleteEventProps, FormValues } from './deleteEvent.types';
import DeleteShiftForm from './DeleteEventForm';

const eventDetailsTextClasses = 'text-base font-medium';

const DeleteEvent: React.FC<DeleteEventProps> = ({ recurringEventTitle, title, eventId, type }) => {
  const dispatch = useAppDispatch();
  const { data: eventDetails, isLoading: isLoadingEventInstanceDetails } = useGetEventQuery({
    eventId
  });
  const [
    getOriginalEventDetails,
    { data: originalEventDetails, isLoading: isLoadingOriginalEventDetails }
  ] = useLazyGetEventQuery();
  const [deleteEvent, { isLoading: isLoadingDeleteShift }] = useDeleteEventMutation();
  const [editEvent, { isLoading: isLoadingEditShift }] = useEditEventMutation();

  const isLoadingEventDetails = isLoadingEventInstanceDetails || isLoadingOriginalEventDetails;
  const isLoading = isLoadingDeleteShift || isLoadingEditShift;
  const isRecurring = !!eventDetails?.recurringEventId;

  const handleSuccess = (message: string) => {
    notifySuccess(message);
    dispatch(closeModal());
  };

  const onSubmit = (data: FormValues) => {
    if (data.deleteShiftOption === EditEventOptions.CURRENT) {
      deleteEvent({ eventId }).unwrap().then(handleSuccess);
    } else if (
      data.deleteShiftOption === EditEventOptions.CURRENT_AND_FOLLOWING &&
      eventDetails &&
      originalEventDetails
    ) {
      const isOriginalEvent = dayjs(eventDetails?.originalStartTime.dateTime).isSame(
        dayjs(originalEventDetails?.start.dateTime)
      );

      const until = dayjs(eventDetails.originalStartTime.dateTime)
        .subtract(1, 'minute')
        .toISOString();

      const { _id: id, ...updatedEvent } = originalEventDetails;

      /**
       * @description
       * If it's original event I just delete it, if event instance I update it's recurring until date
       */
      (isOriginalEvent
        ? deleteEvent({ eventId: originalEventDetails._id })
        : editEvent({
            eventId: id,
            body: {
              ...(updatedEvent.recurring && {
                recurring: {
                  ...updatedEvent.recurring,
                  until: until
                }
              })
            }
          })
      )
        .unwrap()
        .then((response) =>
          typeof response === 'string' ? handleSuccess(response) : handleSuccess(response.message)
        );
    } else if (data.deleteShiftOption === EditEventOptions.ALL && eventDetails?.recurringEventId) {
      deleteEvent({ eventId: eventDetails.recurringEventId }).unwrap().then(handleSuccess);
    }
  };

  useEffect(() => {
    if (eventDetails?.recurringEventId) {
      getOriginalEventDetails({ eventId: eventDetails.recurringEventId });
    }
  }, [eventDetails?.recurringEventId, getOriginalEventDetails]);

  return (
    <div data-testid="delete_event_popup" className="p-6">
      <div className="mb-6">
        <h3 className="mb-2 text-xl font-bold">
          {type === 'shift' ? 'Delete shift' : 'Delete time off'}
        </h3>
        <p className={eventDetailsTextClasses}>{title}</p>
        {recurringEventTitle && <p className={eventDetailsTextClasses}>{recurringEventTitle}</p>}
      </div>
      <DeleteShiftForm
        type={type}
        isRecurring={isRecurring}
        onSave={onSubmit}
        isLoading={isLoading}
        isLoadingEventDetails={isLoadingEventDetails}
      />
    </div>
  );
};

export default DeleteEvent;
