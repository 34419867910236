import { Dispatch, SetStateAction } from 'react';

export const handlePermissions = (
  name: string,
  permissions: string[],
  setPermissions: Dispatch<SetStateAction<string[]>>
) => {
  permissions.includes(name)
    ? setPermissions((prevPagePermissions) => prevPagePermissions.filter((p) => p !== name))
    : setPermissions((prevPagePermissions) => [...prevPagePermissions, name]);
};
