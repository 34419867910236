import { combineReducers, configureStore, Reducer, UnknownAction } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { crossSellApi } from 'store/crossSell/crossSellSlice';

import aiAssistantReducer from './aiAssistant/aiAssistantSlice';
import { apiSlice } from './api/apiSlice';
import availabilityReducer from './availability/availabilitySlice';
import { basicTableSlice } from './basicTable/basicTableSlice';
import bulkEditReducer from './bulkEdit/bulkEditSlice';
import calendarReducer from './calendar/calendarSlice';
import channelsReducer from './channels/channelsSlice';
import chatReducer from './chat/chatSlice';
import customerFeedbackReducer from './customerFeedback/customerFeedbackSlice';
import { rtkQueryErrorLogger } from './errorCatchingMiddleware/errorCatchingMiddleware';
import modalReducer from './modal/modalSlice';
import notificationsReducer from './notifications/notificationsSlice';
import orderPaneReducer from './orderPane/orderPaneSlice';
import ordersReducer, { ordersApi } from './orders/ordersSlice';
import patientReducer from './patients/patientsSlice';
import queueReducer from './queue/queueSlice';
import reviewsReducer from './reviews/reviewsSlice';
import sharedFormContextsReducer from './sharedFormContexts/sharedFormContextsSlice';
import slidingInPaneReducer from './slidingInPane/slidingInPaneSlice';
import smsReducer from './sms/smsSlice';
import staffFeedbackReducer from './staffFeedback/staffFeedbackSlice';
import staffNotesReducer from './staffNotes/staffNotesSlice';
import systemReducer from './system/systemSlice';
import { taskSlice, tasksSlice } from './tasks/tasksSlice';
import triggersReducer from './triggers/triggersSlice';
import userReducer from './user/userSlice';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['user']
};

const middlewares = [
  apiSlice.middleware,
  crossSellApi.middleware,
  rtkQueryErrorLogger,
  ordersApi.middleware
];

const appReducer = combineReducers({
  user: userReducer,
  triggers: triggersReducer,
  notifications: notificationsReducer,
  queue: queueReducer,
  staffNotes: staffNotesReducer,
  modal: modalReducer,
  task: taskSlice.reducer,
  tasks: tasksSlice.reducer,
  basicTables: basicTableSlice.reducer,
  slidingInPane: slidingInPaneReducer,
  orderPane: orderPaneReducer,
  availability: availabilityReducer,
  sms: smsReducer,
  bulkEdit: bulkEditReducer,
  reviews: reviewsReducer,
  staffFeedback: staffFeedbackReducer,
  customerFeedback: customerFeedbackReducer,
  patient: patientReducer,
  chat: chatReducer,
  channels: channelsReducer,
  orders: ordersReducer,
  system: systemReducer,
  calendar: calendarReducer,
  [crossSellApi.reducerPath]: crossSellApi.reducer,
  [apiSlice.reducerPath]: apiSlice.reducer,
  [ordersApi.reducerPath]: ordersApi.reducer,
  aiAssistant: aiAssistantReducer,
  sharedFormContexts: sharedFormContextsReducer
});

const rootReducer: Reducer = (state: RootState, action: UnknownAction) => appReducer(state, action);

const persistedReducer = persistReducer(persistConfig, rootReducer);

export function setupStore(preloadedState?: Partial<RootState>) {
  return configureStore({
    reducer: persistedReducer,
    preloadedState,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({ serializableCheck: false }).concat(middlewares)
  });
}

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(middlewares)
});

setupListeners(store.dispatch);

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof appReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];
