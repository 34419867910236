import React, { ChangeEvent, FC, forwardRef, useRef, useState } from 'react';

import { nanoid } from '@reduxjs/toolkit';
import { Common } from '@thecvlb/design-system';
import classNames from 'classnames';
import { useClickAway, useToggle } from 'react-use';

import { OptionProps, SelectProps } from './select.types';

export const Select: FC<SelectProps> = forwardRef<HTMLInputElement, SelectProps>(
  (
    {
      name,
      label,
      options,
      disabled,
      id,
      type = 'text',
      helper,
      errors,
      onChange,
      onInputChange,
      value,
      className
    },
    ref
  ) => {
    const optionsRef = useRef(null);
    const [focus, toggleFocus] = useToggle(false);
    const [filter, setFilter] = useState<string>('');

    const handleChangeInput = (event: ChangeEvent<HTMLInputElement>) => {
      onInputChange && onInputChange(event.target.value);
      toggleFocus(true);
      setFilter(event.target.value);
    };

    const handleClickOption = (item: OptionProps) => {
      onChange(item);
      toggleFocus(false);
      if (onInputChange) {
        setFilter(item.label);
      } else {
        setFilter('');
      }
    };

    const handleClickInput = () => {
      toggleFocus();
      !onInputChange && setFilter('');
    };

    useClickAway(optionsRef, () => {
      toggleFocus(false);
      !onInputChange && setFilter('');
    });

    const wrapperClassName = classNames(
      'relative flex flex-col gap-1.5 sm: gap-1',
      { 'z-10': focus },
      className
    );
    const labelClassName = classNames(
      'absolute h-fit transition-all ease-in-out duration-300 pointer-events-none',
      focus || (value && !onInputChange) || (onInputChange && filter.length)
        ? 'text-xs top-[6px] my-0 sm:top-[2px]'
        : 'sm:text-base top-[15px] sm:top-[12px]',
      !!errors && !disabled
        ? 'text-red'
        : focus
          ? 'text-primary'
          : (value && !disabled && !onInputChange) || (onInputChange && filter.length)
            ? 'text-secondary'
            : 'text-gray',
      type === 'search' ? 'left-11 sm:left-9' : 'left-4 sm:left-3'
    );
    const inputWrapperClassName = classNames(
      'relative w-full after:absolute after:inset-x-0 after:-bottom-0',
      focus
        ? 'after:bg-white after:inset-x-[1px] after:h-[4px]'
        : 'after:inset-x-0 after:rounded-b-sm after:h-[2px]',
      !!errors && !disabled
        ? 'after:bg-red'
        : (value && !disabled && !onInputChange) || (onInputChange && filter.length)
          ? 'after:bg-secondary'
          : 'after:bg-gray'
    );
    const inputClassName = classNames(
      'bg-white outline-none border w-full rounded-t-md rounded-b-sm border-gray-200 outline-0 shadow-sm pb-3.5 pt-4 pr-11 text-gray-700 focus:border-gray-200 disabled:bg-gray-100 disabled:shadow-none sm:text-base sm:pb-2.5 sm:pt-3 sm:pr-9',
      type === 'search' ? 'pl-11 sm:pl-9' : 'pl-4 sm:pl-3'
    );
    const helperClassName = classNames(
      'text-xs',
      !!errors && !disabled
        ? 'text-red'
        : focus
          ? 'text-primary'
          : value
            ? 'text-secondary'
            : 'text-gray'
    );
    const searchIconClassName = 'absolute inset-y-0 my-auto left-4 text-gray sm:left-3';
    const arrowIconClassName = classNames(
      'absolute inset-y-0 my-auto right-4 pointer-events-none sm:right-3',
      disabled ? 'text-gray' : ' text-gray-700'
    );
    const optionsClassName =
      'absolute top-full -mt-[2px] w-full min-h-[2px] max-h-[300px] bg-white shadow-sm overflow-auto rounded-b-sm after:sticky after:z-10 after:inset-x-0 after:block after:-bottom-0 after:h-[2px] after:rounded-b-sm after:bg-primary';
    const getOptionClassName = (item: OptionProps) =>
      classNames(
        'px-3 py-1 cursor-pointer border-l border-r border-gray-200',
        item.label === value?.label ? 'bg-blue text-white' : 'text-gray hover:bg-blue-100'
      );
    const valueClassName = classNames(
      'absolute pointer-events-none top-[17px] sm:text-base sm:top-[13px]',
      type === 'search' ? 'left-[45px] sm:left-[37px] ' : 'left-[17px] sm:left-[13px]',
      disabled ? 'text-gray' : ' text-gray-700'
    );

    return (
      <div className={wrapperClassName} ref={optionsRef}>
        <div className={inputWrapperClassName}>
          <label className={labelClassName} htmlFor={id}>
            {label}
          </label>
          {!onInputChange && value && !filter && (
            <span className={valueClassName}>{value.label}</span>
          )}
          <input
            autoComplete="off"
            className={inputClassName}
            data-testid="select"
            disabled={disabled}
            id={id}
            name={name}
            ref={ref}
            type="text"
            value={filter}
            onChange={handleChangeInput}
            onClick={handleClickInput}
          />
          {type === 'search' && <Common.Icon className={searchIconClassName} name={'search'} />}
          <Common.Icon className={arrowIconClassName} name={focus ? 'arrow-up' : 'arrow-down'} />
          {focus && (
            <div className={optionsClassName}>
              {options
                .filter((item) => item.label.toLowerCase().includes(filter.toLowerCase()))
                .map((item) => (
                  <div
                    className={getOptionClassName(item)}
                    key={nanoid()}
                    onClick={() => handleClickOption(item)}
                  >
                    {item.label}
                  </div>
                ))}
            </div>
          )}
        </div>
        {helper && <span className={helperClassName}>{helper}</span>}
      </div>
    );
  }
);

export default React.memo(Select);
