import React from 'react';

const GroupTitle: React.FC<{
  title: string;
}> = ({ title }) => {
  return (
    <div className="mx-3 my-2">
      <span className="text-sm font-bold text-gray-500">{title.toUpperCase()}</span>
    </div>
  );
};

export default GroupTitle;
