import { Common } from '@thecvlb/design-system/lib/src';

const ReAssignAppointmentPopup: React.FC<{
  handleSubmit: () => void;
  handleCancel: () => void;
  shouldReassign: boolean;
}> = ({ handleSubmit, handleCancel, shouldReassign }) => {
  return (
    <div className="p-6">
      <h2 className="mb-2 text-xl font-bold text-gray-700">
        {shouldReassign ? 'Re-assign appointments & try again' : 'Confirm deactivation'}
      </h2>

      {shouldReassign && (
        <div className="m-8 mx-auto flex size-24 items-center justify-center rounded-full bg-red-500 font-normal">
          <Common.Icon name="not-available" className="size-20 -scale-x-100 text-white" />
        </div>
      )}

      {shouldReassign ? (
        <div className="flex flex-col text-center">
          {shouldReassign && (
            <h2 className="text-lg font-bold"> Provider has appointments scheduled</h2>
          )}

          <span className="text-base font-semibold">
            To deactivate this provider, please re-assign their outstanding appointments to other
            providers
          </span>

          <span className="mt-6 text-base font-semibold">
            Deactivating providers unassigns them from all their active tasks. Appointments need to
            be re-assigned before a provider can be de-activated
          </span>
        </div>
      ) : (
        <div className="flex flex-col">
          <span className="text-base font-semibold">
            De-activating staff will un-assign them from all their active tasks
          </span>
        </div>
      )}

      <div className="mt-6 flex gap-2">
        <Common.Button
          dataTestId="cancel_btn"
          onClick={handleCancel}
          size="sm"
          color="white-alt"
          className="w-full justify-center"
        >
          Cancel
        </Common.Button>
        <Common.Button
          dataTestId="continue_btn"
          size="sm"
          color="red"
          className="w-full justify-center"
          onClick={handleSubmit}
        >
          Re-assign appointments
        </Common.Button>
      </div>
    </div>
  );
};

export default ReAssignAppointmentPopup;
