import { useEffect, useState } from 'react';

import { Common } from '@thecvlb/design-system/lib/src';
import Loader from 'components/common/Loader';
import { notifySuccess } from 'components/common/Toast/Toast';
import dayjs from 'dayjs';
import { AppointmentListType } from 'enums/appointments';
import { useAppDispatch } from 'hooks/redux';
import { FormProvider, useForm } from 'react-hook-form';
import {
  useCancelPendingAppointmentsMutation,
  useGetPatientAppointmentsQuery
} from 'store/appointments/appointmentsSlice';
import { closeModal } from 'store/modal/modalSlice';
import { getDisplayName } from 'store/patients/patients.settings';
import {
  useCancelSubscriptionAutoRenewMutation,
  useDeactivateWithRefundMutation,
  useDowngradeSubscriptionMutation,
  useGetSubscriptionDetailsQuery
} from 'store/patients/patientsSlice';

import { getCancelationTitle, getConfirmButtonLabel, renderContent } from './cancelPlan.settings';
import {
  CancellationSteps,
  CancellationType,
  CancelPlanFormData,
  CancelPlanProps
} from './cancelPlan.types';

const CancelPlan: React.FC<CancelPlanProps> = ({ patientInfo, isAutoRenew }) => {
  const dispatch = useAppDispatch();
  const [cancelSubscriptionAutoRenew, { isLoading }] = useCancelSubscriptionAutoRenewMutation();
  const [deactivateWithRefund, { isLoading: isLoadingDeactivateWithRefund }] =
    useDeactivateWithRefundMutation();
  const [downgradeSubscription, { isLoading: isLoadingDowngradeSubscription }] =
    useDowngradeSubscriptionMutation();
  const [cancelPendingAppointments, { isLoading: isLoadingCancelPendingAppointments }] =
    useCancelPendingAppointmentsMutation();
  const { data: subscriptionDetails, isFetching } = useGetSubscriptionDetailsQuery({
    patientId: patientInfo?._id || ''
  });
  const { data: upcomingAppointmentData, isFetching: isFetchingAppointments } =
    useGetPatientAppointmentsQuery(
      {
        patientId: patientInfo?._id || '',
        params: { appointmentListType: AppointmentListType.UpcomingAppointment }
      },
      { refetchOnMountOrArgChange: true }
    );
  const upcomingAppointments = upcomingAppointmentData?.appointments || [];

  const methods = useForm<CancelPlanFormData>({
    reValidateMode: 'onChange',
    criteriaMode: 'all',
    mode: 'onChange'
  });
  const [step, setStep] = useState<CancellationSteps>(CancellationSteps.CancelPlan);
  const [cancelationType, setCancelationType] = useState<CancellationType>();
  const [isFetchingPatient, setIsFetchingPatient] = useState(false);

  const showLoading =
    isLoading ||
    isLoadingDeactivateWithRefund ||
    isLoadingDowngradeSubscription ||
    isLoadingCancelPendingAppointments ||
    isFetchingPatient;
  const patientName = getDisplayName(patientInfo);
  const title = getCancelationTitle(step, cancelationType);
  const formData = methods.watch();
  const isPatientInactive = patientInfo?.status.toLowerCase() === 'inactive';
  const disableCancelAccount =
    (step === CancellationSteps.Confirmation &&
      cancelationType === CancellationType.CancelAccountAndRefundPatient &&
      !formData.receivedManagerApproval) ||
    !cancelationType ||
    showLoading;

  const handleBack = () => {
    if (step === CancellationSteps.CancelPlan) {
      dispatch(closeModal());
    } else {
      setStep((prevStep) => {
        switch (prevStep) {
          case CancellationSteps.Confirmation:
            return CancellationSteps.ChooseReason;
          case CancellationSteps.CancelAppointments:
            return CancellationSteps.ChooseReason;
          default:
            return CancellationSteps.CancelPlan;
        }
      });
    }
  };

  const handleCancelAccount = async () => {
    if (step === CancellationSteps.CancelAppointments) {
      patientInfo?._id &&
        cancelPendingAppointments({ patientId: patientInfo?._id })
          .unwrap()
          .then(() => setStep(CancellationSteps.Confirmation));
    } else if (step !== CancellationSteps.Confirmation) {
      setStep((prevStep) => {
        switch (prevStep) {
          case CancellationSteps.CancelPlan:
            return CancellationSteps.ChooseReason;
          case CancellationSteps.ChooseReason:
            const showCancelAppointmentsStep =
              (cancelationType === CancellationType.CancelAccountAndRefundPatient ||
                cancelationType === CancellationType.DowngradeRefundPatient) &&
              !!upcomingAppointments.length;

            return showCancelAppointmentsStep
              ? CancellationSteps.CancelAppointments
              : CancellationSteps.Confirmation;
          case CancellationSteps.CancelAppointments:
            return CancellationSteps.Confirmation;
          default:
            return CancellationSteps.CancelPlan;
        }
      });
    } else if (typeof formData?.cancelReason?.value === 'string' && patientInfo?._id) {
      if (cancelationType !== CancellationType.CancelAutoRenew) {
        await cancelPendingAppointments({ patientId: patientInfo?._id });
      }
      (cancelationType === CancellationType.CancelAutoRenew
        ? cancelSubscriptionAutoRenew
        : cancelationType === CancellationType.DowngradeRefundPatient
          ? downgradeSubscription
          : deactivateWithRefund)({
        patientId: patientInfo._id,
        cancelReasonCode: formData.cancelReason.value,
        note: formData.details,
        ...(formData.refundAmount && { refundAmount: Number(formData.refundAmount) })
      })
        .unwrap()
        .then((data) => {
          if (cancelationType === CancellationType.DowngradeRefundPatient) {
            setIsFetchingPatient(true);
            setTimeout(() => {
              dispatch(closeModal());
              setIsFetchingPatient(false);
              notifySuccess(data?.message);
            }, 9000);
          } else {
            notifySuccess(data?.message);
            dispatch(closeModal());
          }
        });
    }
  };

  useEffect(() => {
    if (isAutoRenew) {
      setStep(CancellationSteps.ChooseReason);
      setCancelationType(CancellationType.CancelAutoRenew);
    }
  }, [isAutoRenew]);

  return (
    <FormProvider {...methods}>
      {(isFetching || isFetchingAppointments) && <Loader isVisible />}
      <form className="p-6" onSubmit={methods.handleSubmit(handleCancelAccount)}>
        <h2 className="mb-2 text-xl font-bold text-gray-700">{title}</h2>
        <p className="mb-6 text-base font-medium text-gray-700">
          {patientName}, {dayjs().diff(patientInfo?.dob, 'year')}
        </p>
        {patientInfo &&
          renderContent(
            step,
            setCancelationType,
            upcomingAppointments,
            cancelationType,
            isPatientInactive,
            subscriptionDetails,
            patientInfo.firstName
          )}
        <div className="mt-6 flex justify-end gap-2">
          <Common.Button color="white-alt" onClick={handleBack} size="sm" type="button">
            {step === CancellationSteps.CancelPlan ? 'Cancel' : 'Back'}
          </Common.Button>
          <Common.Button
            color={
              step === CancellationSteps.Confirmation &&
              cancelationType !== CancellationType.CancelAutoRenew
                ? 'red'
                : 'blue'
            }
            size="sm"
            disabled={disableCancelAccount}
            isLoading={showLoading}
          >
            {getConfirmButtonLabel(step, cancelationType)}
          </Common.Button>
        </div>
      </form>
    </FormProvider>
  );
};

export default CancelPlan;
