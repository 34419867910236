import { IconProps } from '@thecvlb/design-system/lib/src/common';
import { TaskDetailsProps } from 'models/tasks.types';
import { PatientProps } from 'store/patients/patients.types';
import { QueueTasksProps } from 'store/queue/queueSlice.types';

import { TasksTableColumnsProps } from '../tasksTable.types';

export enum OnboardingStatus {
  Ok = 'ok',
  Warning = 'warning',
  Error = 'error',
  NotApplicable = 'not applicable'
}

export interface OnboardingStatusProps {
  details: TasksTableColumnsProps | QueueTasksProps | TaskDetailsProps | PatientProps;
  iconSize?: 'sm' | 'md';
}

export interface StatusDetailsProps {
  status: OnboardingStatus;
  iconName: IconProps['name'];
  count?: number;
  label: string;
  value?: string;
}
