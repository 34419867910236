import parse, { Element } from 'html-react-parser';
import { sanitizeHTML } from 'utils/common/parseHTML';

export const MessageCell: React.FC<{ title: string }> = ({ title }) => {
  const sanitizedString = sanitizeHTML(title);

  return (
    <span className="text-sm">
      {parse(sanitizedString, {
        replace: (domNode) => {
          if (domNode instanceof Element) {
            if (domNode.tagName === 'p' || domNode.tagName === 'a') {
              domNode.attribs.className = 'text-sm';
              return domNode;
            } else if (domNode.tagName === 'ol') {
              domNode.attribs.className = 'text-sm my-2 ml-6 list-decimal';
              return domNode;
            } else if (domNode.tagName === 'ul') {
              domNode.attribs.className = 'text-sm my-2 ml-6 list-disc';
              return domNode;
            }
          }
        }
      })}
    </span>
  );
};
