import { Common } from '@thecvlb/design-system';

const WelcomeToUnlimited: React.FC<{
  isOpen: boolean;
  onClose: () => void;
}> = ({ isOpen, onClose }) => {
  return (
    <Common.Modal close={onClose} isOpen={isOpen} zIndex={120} size="sm">
      <div className="mx-auto my-5 flex size-16 items-center justify-center rounded-full bg-teal-100">
        <Common.Icon name="check" className="text-teal-600" />
      </div>
      <h3
        className="mb-4 text-center text-m2xl font-bold text-primary md:text-2xl"
        data-test="modal_heading"
      >
        Welcome to Unlimited!
      </h3>
      <p className="mb-4 text-center text-base leading-6 text-gray-900">
        You’ve upgraded your plan, and now have access to unlimited appointments, after hours care,
        and more.
      </p>
      <Common.Button
        className="mx-auto"
        color="blue"
        postIcon="arrow-right"
        fullWidthOnMobile
        onClick={onClose}
      >
        Done
      </Common.Button>
    </Common.Modal>
  );
};

export default WelcomeToUnlimited;
