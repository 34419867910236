import React from 'react';

import ControlledSelect from 'components/forms/controlled/ControlledSelect';
import { Options } from 'components/patient/History/history.types';
import { useFormContext } from 'react-hook-form';

const TeamSelector: React.FC<{
  teamsOptions: Options[];
}> = ({ teamsOptions }) => {
  const { control } = useFormContext();

  return (
    <ControlledSelect
      control={control}
      name="team"
      labelDirection="row"
      options={teamsOptions}
      placeholder="Search team"
      rules={{
        required: {
          value: true,
          message: 'Team is required'
        }
      }}
    />
  );
};

export default TeamSelector;
