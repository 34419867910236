export const BILLING_PLAN_A = [
  { value: '5', label: '5' },
  { value: '10', label: '10' },
  { value: '15', label: '15' }
];

export const BILLING_PLAN_B = [
  { value: '0', label: '0' },
  { value: '5', label: '5' },
  { value: '10', label: '10' }
];
