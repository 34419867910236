import { OrderFormStateProps } from './editOrderForm.types';

export const getSubmitOrderData = (formData: OrderFormStateProps) => {
  return {
    insurance: {
      memberId: formData.memberId,
      rxGroup: formData?.rxGroup,
      rxBin: formData.rxBin,
      pcn: formData?.pcn,
      relationship: formData.relationship.value
    }
  };
};
