import React, { useEffect, useMemo, useRef, useState } from 'react';

import { Common } from '@thecvlb/design-system/lib/src';
import Diamonds from 'assets/icons/chat/Diamonds.svg?react';
import classNames from 'classnames';
import User from 'components/common/User';
import AssignToUser from 'components/modals/AssignToUser';
import Tooltip from 'components/tables/TaskTable/OnboardingDetails/Tooltip';
import dayjs from 'dayjs';
import { DateFormat } from 'enums/dateFormats';
import { RoleShortName } from 'enums/role';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { useClickAway, useToggle } from 'react-use';
import { useLazyGetUserTypesQuery } from 'store/lookup/lookupSlice';
import { openModal } from 'store/modal/modalSlice';
import { useSetAiAttributesMutation } from 'store/myProfile/myProfileSlice';
import { updateAIMessageTaskAutoRoutingRead } from 'store/user/userSlice';

import { TransferPopupItemProps, TransferPopupProps } from './transferPopup.types';
import Audience from '../Audience';
import Divider from './components/Divider';
import GroupTitle from './components/GroupTitle';
import EscalateTask from '../../modals/EscalateTask';

const TransferPopup: React.FC<TransferPopupProps> = ({
  name,
  role,
  src,
  items,
  onClickTaskToQueue,
  onButtonClick,
  isTasksList,
  children,
  taskId,
  assignedToId,
  specificAudience,
  taskCategory,
  tags,
  teams,
  patientId,
  escalationDetails,
  assignedAt,
  assignedTeamAt
}) => {
  const ref = useRef(null);
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.user);
  const [isOpenMenu, setIsOpenMenu] = useToggle(false);
  const [isIconHovered, setIsIconHovered] = useState(false);
  const [selectedItem, setSelectedItem] = useState<TransferPopupItemProps>();
  const [getUserTypes, { data: userTypes }] = useLazyGetUserTypesQuery();
  const [setAiAttributes, { isLoading }] = useSetAiAttributesMutation();

  useEffect(() => {
    if (taskCategory && !items && isOpenMenu) {
      getUserTypes(taskCategory);
    }
  }, [taskCategory, getUserTypes, items, isOpenMenu]);

  const menuItems = (items || userTypes)?.filter(
    (item) => item.shortCode && item.shortCode !== role
  );

  useClickAway(ref, () => {
    isOpenMenu && setIsOpenMenu(false);
  });

  const handleItem = (
    item: TransferPopupItemProps,
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.stopPropagation();
    setSelectedItem(item);
    if (item) {
      setIsOpenMenu(false);
      onButtonClick(item);
    }
  };

  const handleTaskToQueue = () => {
    setIsOpenMenu(false);
    onClickTaskToQueue?.();
  };

  const handleSearch = (type: 'team' | 'staff') => {
    dispatch(
      openModal({
        modalContent:
          type === 'team' ? (
            <EscalateTask
              taskId={taskId || ''}
              isTeams
              isAssign
              taskCategory={taskCategory}
              patientId={patientId}
            />
          ) : (
            <AssignToUser
              taskId={taskId}
              assignedToId={assignedToId}
              taskCategory={taskCategory}
              patientId={patientId}
            />
          ),
        size: 'sm',
        hideClose: true
      })
    );
  };

  const handleOnClick: React.MouseEventHandler = (event) => {
    event.stopPropagation();
    setIsOpenMenu();
  };

  const wrapperClassName = 'w-fit relative';
  const buttonClassName = 'flex items-center gap-1 text-gray-700 text-base p-1';
  const dropdownClassName = classNames(
    'absolute shadow-lg bg-white rounded-lg p-2 z-10 bg-white origin-top transition ease-in-out duration-300 transform',
    isOpenMenu ? 'scale-y-100' : 'scale-y-0'
  );

  const shouldShowAudience =
    name === 'Unassigned' && specificAudience && specificAudience?.length > 0;
  const shouldShowTeams = name === 'Unassigned' && teams && teams?.length > 0;

  const shouldShowEscalation = useMemo(() => {
    if (escalationDetails) {
      if (!assignedAt && !assignedTeamAt) return true;

      const datesToCompare = [];
      if (assignedAt) datesToCompare.push(assignedAt);
      if (assignedTeamAt) datesToCompare.push(assignedTeamAt);

      return datesToCompare.every(
        (date) => !dayjs(escalationDetails?.escalatedAt).isBefore(dayjs(date))
      );
    }

    return false;
  }, [escalationDetails, assignedAt, assignedTeamAt]);

  const showAITooltip = !user.ai?.messageTaskAutoRoutingRead;

  const iconFocusProps = {
    onMouseOver: () => {
      setIsIconHovered(true);
    },
    onMouseLeave: () => {
      setIsIconHovered(false);
    }
  };

  const handleCloseAITooltip: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    event.stopPropagation();
    setAiAttributes({ messageTaskAutoRoutingRead: true })
      .unwrap()
      .then(() => {
        dispatch(
          updateAIMessageTaskAutoRoutingRead({
            messageTaskAutoRoutingRead: dayjs().format(DateFormat.YYYY_MM_DDTHH_mm_ss_ssZ)
          })
        );
      });
    setIsIconHovered(false);
  };

  const renderAssignToComponent = () => {
    if (shouldShowEscalation) {
      return (
        <div className="flex size-5 min-w-[20px] items-center justify-center rounded-full bg-yellow-500">
          <Common.Icon name="error" className="size-4 text-white" />
        </div>
      );
    }
    if (shouldShowTeams) {
      return (
        <div className="relative flex items-center text-gray-700">
          <Common.Icon name="team" className="size-5 text-white" />
          <span className="absolute -right-0.5 bottom-0 rounded-full bg-gray-500 px-[2.5px] pb-[2px] pt-px text-3xs font-bold text-white ">
            T
          </span>
        </div>
      );
    }
    if (shouldShowAudience) {
      return <Audience specificAudience={specificAudience} />;
    }
    return <User name={name} role={role} src={src} />;
  };

  const isAIrouted = tags?.includes('is_ai');

  return (
    <div
      ref={ref}
      className={wrapperClassName}
      onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => e.stopPropagation()}
      onMouseLeave={iconFocusProps.onMouseLeave}
    >
      <button onClick={handleOnClick} className={buttonClassName}>
        {isTasksList ? children : renderAssignToComponent()}
        {isAIrouted && (
          <div onMouseOver={iconFocusProps.onMouseOver} className="relative">
            <Diamonds width={16} height={16} fill="#424647" />

            {showAITooltip && isIconHovered && (
              <Tooltip>
                <div className="mb-1 flex justify-between">
                  <span className="text-sm font-bold text-white">Task auto-routing</span>
                  <Common.Button
                    style="pill"
                    color="white-alt"
                    size="sm"
                    isLoading={!!isLoading}
                    className="!p-1 !text-gray-300"
                    onClick={handleCloseAITooltip}
                  >
                    <Common.Icon name="close" className="size-3 text-gray" />
                  </Common.Button>
                </div>
                <p className="text-left text-sm font-medium text-white">
                  Message tasks are now automatically routed based on the message content. Tap here
                  to quickly reassign to another role or user.
                </p>
              </Tooltip>
            )}
          </div>
        )}
        {!isTasksList && <Common.Icon name="transfer" className="size-4" />}
      </button>
      <div data-testid="transfer_popup" className={dropdownClassName}>
        {!isTasksList && (
          <>
            <Common.MenuVertical
              icon="arrow-alt-left"
              label="Release task to list"
              isRed
              onClick={handleTaskToQueue}
            />
            <Divider />
          </>
        )}
        {!!menuItems?.length && <GroupTitle title="roles" />}
        {!!menuItems?.length &&
          menuItems.map((item) => (
            <div key={item.name} className="flex items-center gap-1">
              <Common.MenuVertical
                label={item.displayName || item.name}
                active={selectedItem?.name === item.name}
                size="sm"
                alt
                onClick={(e) => handleItem(item, e)}
                buttonClasses="w-auto"
              />
              {isAIrouted &&
                specificAudience?.includes(RoleShortName.Physician) &&
                item.shortCode === RoleShortName.Physician && (
                  <Diamonds width={16} height={16} fill="#424647" />
                )}
            </div>
          ))}
        {!!menuItems?.length && <Divider />}
        <GroupTitle title="teams" />
        <Common.MenuVertical
          label="Search team..."
          size="sm"
          alt
          icon="search"
          onClick={() => handleSearch('team')}
        />

        <GroupTitle title="users" />
        <Common.MenuVertical
          label="Search staff..."
          size="sm"
          alt
          icon="search"
          onClick={() => handleSearch('staff')}
        />
      </div>
    </div>
  );
};

export default TransferPopup;
