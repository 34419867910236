import { useEffect, useState } from 'react';

import MainSection from 'components/settings/MainSection';
import NavBar from 'components/settings/Navbar';
import { Category, Mappings, SettingsCategory, SubCategory } from 'store/settings/settings.types';
import { useGetGlobalSettingsQuery } from 'store/settings/settingsSlice';

import SettingsSkeleton from './Skeleton';

const Settings = () => {
  const [mappings, setMappings] = useState<Mappings>();
  const [areaName, setAreaName] = useState<string>('');
  const [sectionName, setSectionName] = useState<string>('');
  const [initialTabSelected, setInitialTabSelected] = useState(false);

  const { data, isLoading, isSuccess } = useGetGlobalSettingsQuery(undefined, {
    pollingInterval: 300000 // 5 minutes
  });

  useEffect(() => {
    if (isSuccess && data.length) {
      const formatted: Mappings = {};

      // Loop through each record in data
      for (const record of data) {
        let level: Category = formatted;

        // Create the category tree
        for (const category of record.categories) {
          if (!(level as SubCategory)[category]) {
            (level as SubCategory)[category] = {};
          }
          level = (level as SubCategory)[category];
        }

        if (!(level as SettingsCategory).settings) level.settings = [];

        (level as SettingsCategory).settings.push(record);
      }

      setMappings(formatted);

      if (!initialTabSelected) {
        const initialAreaName = Object.keys(formatted)[0];
        const initialSectionName = Object.keys(formatted[initialAreaName])[0];
        setAreaName(initialAreaName);
        setSectionName(initialSectionName);
        setInitialTabSelected(true);
      }
    }
  }, [data, initialTabSelected, isSuccess]);

  return (
    <div data-testid="settings">
      {isLoading ? (
        <SettingsSkeleton />
      ) : (
        <div className="flex bg-white p-6">
          <div className="mr-8 flex w-[240px] flex-none flex-col">
            <NavBar
              mappings={mappings}
              areaName={areaName}
              sectionName={sectionName}
              setAreaName={setAreaName}
              setSectionName={setSectionName}
            />
          </div>
          <div className="flex w-full min-w-0 flex-col">
            {sectionName && <div className="mb-6 pt-4 text-xl font-bold">{sectionName}</div>}
            {mappings && (
              <MainSection mappings={mappings} areaName={areaName} sectionName={sectionName} />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Settings;
