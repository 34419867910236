import classNames from 'classnames';

import GroupedMIFQuestion from './GroupedMIFQuestion';
import { MIFQuestionProps } from './mifQuestion.types';
import SimpleMIFQuestion from './SimpleMIFQuestion';

const MIFQuestion: React.FC<MIFQuestionProps> = ({ question, showAllOptions, displayNumber }) => {
  const isGroupedQuestion = !!question.group;

  const wrapperClasses = classNames('flex gap-4 p-3 border-b border-black/5', {
    'bg-red-100': question.warning
  });

  const displayNumberClasses = classNames(
    'flex-none h-6 w-6 rounded-3xl border py-0.5 text-center text-sm font-medium',
    question.warning ? 'border-red-500 text-red-500' : 'border-gray-500 text-gray-500'
  );

  return (
    <div className={wrapperClasses}>
      {/* If BE does not send the displayNumber than we should use normal numeration */}
      <span className={displayNumberClasses}>{question.displayNumber || displayNumber}</span>
      {isGroupedQuestion ? (
        <GroupedMIFQuestion question={question} showAllOptions={showAllOptions} />
      ) : (
        <SimpleMIFQuestion question={question} showAllOptions={showAllOptions} />
      )}
    </div>
  );
};

export default MIFQuestion;
