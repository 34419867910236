import { BACKEND_DATE_FORMAT, FRONTEND_DATE_FORMAT } from 'constants/dateFormat';
import { DEFAULT_SEARCH_PARAMS } from 'constants/tables';
import { SEX_AT_BIRTH } from 'constants/user';

import { useEffect, useRef, useState } from 'react';

import Accordion from 'components/common/Accordion';
import { formatPhoneNumber } from 'components/common/form/PhoneInput/phoneInput.settings';
import Loader from 'components/common/Loader';
import QuickLinks from 'components/common/QuickLinks';
import { notifyError } from 'components/common/Toast/Toast';
import AppointmentType from 'components/crossSell/AppointmentType/AppointmentType';
import ConfirmAndPay from 'components/crossSell/confirmAndPay';
import CreateProfile from 'components/crossSell/CreateProfile';
import Header from 'components/crossSell/Header';
import PlanType from 'components/crossSell/PlanType';
import SelectDoctor from 'components/crossSell/SelectDoctor';
import CrossSellAlert from 'components/modals/CrossSell';
import Modal from 'components/modals/modal';
import dayjs from 'dayjs';
import { PlanCodesProps } from 'enums/appointmentStatus';
import { CrossSellLinks } from 'enums/crossSell';
import { PathName } from 'enums/pathName';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import queryString from 'query-string';
import { FormProvider, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { useCreateAccountMutation, useGetStatesQuery } from 'store/crossSell/crossSellSlice';
import { closeModal, openModal, selectModal } from 'store/modal/modalSlice';
import { SubmitEvent } from 'store/patients/patients.types';
import {
  useLazyGetProspectPatientQuery,
  useLazyGetProspectsQuery
} from 'store/patients/patientsSlice';
import { selectUser } from 'store/user/userSlice';
import { getStateTimezone } from 'utils/datesAndTime';

import { defaultCoupon, handleActiveLink, tabLinks } from './crossSell.settings';
import { FormDataProps } from './crossSell.types';
import ExtraInfo from './ExtraInfo';

const CrossSell = () => {
  const methods = useForm<FormDataProps>({
    mode: 'onChange',
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      dob: '',
      sexAtBirth: '',
      phone: '',
      address: '',
      city: '',
      zipCode: '',
      state: '',
      planType: null,
      creditCardAttributes: {
        couponCode: {},
        fullName: ''
      },
      appointmentType: '',
      appointmentDesc: ''
    }
  });
  const { handleSubmit, reset, getValues, setValue, watch } = methods;
  const isConfirmed = true;
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const [isModal, setIsModal] = useState(0);
  const [isCreatingAccount, setIsCreatingAccount] = useState(false);
  const { isOpen: isOpenModal, size, modalContent, hideClose } = useAppSelector(selectModal);
  const [getProspects] = useLazyGetProspectsQuery();
  const locationQuery = queryString.parse(location.search);
  const patientId = locationQuery?.userId as string;
  const patientName = `${watch('firstName') || ''} ${watch('lastName') || ''}`.trim();
  const selectedPlanType = getValues('planType');
  const isWMPlan = selectedPlanType?.plan?.planCode === PlanCodesProps.WeightManagementMembership;
  const watchedAppointmentType = watch('appointmentType');

  const [getPatientDetails, { data: patientDetails, isLoading: isLoadingProspects }] =
    useLazyGetProspectPatientQuery();
  const {
    _id: staffUserId,
    firstName: staffFirstName,
    lastName: staffUserLastName
  } = useAppSelector(selectUser);
  const [createAccount] = useCreateAccountMutation();
  const { data: states } = useGetStatesQuery();

  const companyPartnerNames = patientDetails?.companyPartners?.connectedPartners?.map(
    (p) => p.partnerName
  );
  const isOptaviaPatient =
    companyPartnerNames?.some((p) => p.toLowerCase() === 'optavia') ||
    String(patientDetails?.companyPartners?.onboardingPartner?.partnerName)
      .toLowerCase()
      .includes('optavia');

  const createProfileRef = useRef<HTMLHeadingElement>(null);
  const planTypeRef = useRef<HTMLHeadingElement>(null);
  const appointmentTypeRef = useRef<HTMLHeadingElement>(null);
  const selectDoctorRef = useRef<HTMLHeadingElement>(null);
  const confirmAndPayRef = useRef<HTMLHeadingElement>(null);

  const refs = {
    [CrossSellLinks.CreateProfile]: createProfileRef,
    [CrossSellLinks.PlanType]: planTypeRef,
    [CrossSellLinks.AppointmentType]: appointmentTypeRef,
    [CrossSellLinks.SelectDoctor]: selectDoctorRef,
    [CrossSellLinks.ConfirmAndPay]: confirmAndPayRef
  };

  const splitFullName = (fullName: string) => {
    const lastIndex = fullName.lastIndexOf(' ');
    const firstName = fullName.slice(0, lastIndex);
    const lastName = fullName.slice(lastIndex + 1);

    return [firstName, lastName];
  };

  useEffect(() => {
    if (!isOpenModal && isModal) {
      if (location.search.length) {
        navigate({ pathname: PathName.Prospect, search: DEFAULT_SEARCH_PARAMS });
        getProspects({ params: queryString.parse(location.search) });
      } else {
        navigate(0);
      }
      setIsModal(0);
    } else {
      setIsModal(1);
    }
  }, [isOpenModal]);

  const onSubmit = (formData: FormDataProps) => {
    const signupSession =
      patientDetails?.signupSessions?.[patientDetails?.signupSessions.length - 1];

    const expirationYear = formData.creditCardAttributes.expDate.split('/')[1];
    const expirationMonth = formData.creditCardAttributes.expDate.split('/')[0];
    const [firstName, lastName] = splitFullName(formData.creditCardAttributes.fullName);
    const couponValue = formData.creditCardAttributes?.couponCode.value;
    const hasCouponCode = couponValue !== '';
    const appointmentTypeValue =
      typeof formData?.appointmentType === 'string'
        ? formData?.appointmentType
        : formData?.appointmentType?.value;
    const patientsState =
      typeof formData.state === 'string' ? formData.state : formData.state?.label;

    if (!formData.appointmentTime) {
      notifyError('Appointment time is required');
      return;
    }
    if (
      typeof formData.appointmentTime === 'object' &&
      (!formData.appointmentTime.startTime || !formData.appointmentTime.endTime)
    ) {
      notifyError('Appointment time is required');
      return;
    }

    const newReqData = {
      ...(appointmentTypeValue && {
        appointment: {
          appointmentMethod: 'video',
          appointmentTypeId: appointmentTypeValue,
          appointmentDescription: formData.appointmentDesc,
          planId: formData?.planType?.plan?._id,
          ...(typeof formData.appointmentTime === 'object' && {
            appointmentTime: {
              startTime: formData.appointmentTime?.startTime,
              endTime: formData.appointmentTime?.endTime
            }
            // sessionId: signupSession?.session?.id || '',
          }),
          timezone: dayjs.tz?.guess(),
          doctorId: formData?.doctorId
        }
      }),
      marketing: {
        prospect_type: signupSession?.session?.prospect?.marketing?.prospect_type || '',
        prospect_initial_appt_type:
          signupSession?.session?.prospect?.marketing?.prospect_initial_appt_type || '',
        prospect_flow: 'phone-signup',
        'flow-source': 'vpc-physician',
        onboarding_url: signupSession?.session?.prospect?.marketing?.onboarding_url || '',
        staff_signup_name: `${staffFirstName} ${staffUserLastName}`,
        staff_signup_id: staffUserId
      },
      user: {
        address: formData.address,
        firstName: formData.firstName,
        lastName: formData.lastName,
        email: formData.email,
        dob: dayjs.utc(formData.dob).format(BACKEND_DATE_FORMAT),
        sexAtBirth:
          typeof formData.sexAtBirth === 'string'
            ? Number(formData.sexAtBirth)
            : Number(formData.sexAtBirth?.value),
        phone: formData.phone,
        timezone: getStateTimezone(patientsState),
        referrerUrl: signupSession?.session?.prospect?.user?.referrerUrl || '',
        flowUrl: signupSession?.session?.prospect?.user?.flowUrl || '',
        isAsyncSignup: false,
        state: patientsState,
        city: formData.city,
        zipCode: formData.zipCode,
        noPassword: true
      },
      payment: {
        product_handle: formData?.planType?.plan?.planCode || '',
        product_price_point_handle: formData?.planType?.pricePoint?.planPricePointId || '',
        coupon_codes: hasCouponCode ? [formData.creditCardAttributes?.couponCode.value] : [],
        credit_card_attributes: {
          first_name: firstName,
          last_name: lastName,
          full_number: formData.creditCardAttributes.fullNumber,
          expiration_month: expirationMonth,
          expiration_year: expirationYear,
          cvv: formData.creditCardAttributes.cvv,
          zip: formData.creditCardAttributes?.billingZip
        }
      },
      mif: signupSession?.session?.prospect?.mif,
      mif_qa: signupSession?.session?.prospect?.mif_qa,
      submit: 'USER' as SubmitEvent
    };

    if (newReqData) {
      setIsCreatingAccount(true);
      createAccount(newReqData)
        .unwrap()
        .then((data) => {
          if (data) {
            dispatch(
              openModal({
                size: 'sm',
                modalContent: <CrossSellAlert handleClick={() => dispatch(closeModal())} />
              })
            );
            setIsCreatingAccount(false);
          }
        })
        .catch(() => {
          setIsCreatingAccount(false);
        });
    }
  };

  useEffect(() => {
    if (patientId) {
      getPatientDetails({ id: patientId });
    }
  }, [getPatientDetails, patientId]);

  useEffect(() => {
    if (patientDetails) {
      const latestSignupSession =
        patientDetails?.signupSessions?.[patientDetails?.signupSessions.length - 1];
      const session = latestSignupSession?.session;
      const planType = getValues('planType');

      reset({
        firstName: patientDetails.firstName ?? '',
        lastName: patientDetails.lastName ?? '',
        email: patientDetails.email ?? '',
        dob: patientDetails.dob ? dayjs.utc(patientDetails.dob).format(FRONTEND_DATE_FORMAT) : '',
        sexAtBirth: patientDetails?.sexAtBirth
          ? SEX_AT_BIRTH.find((item) => item.value === patientDetails?.sexAtBirth)
          : '',
        phone: patientDetails.phone?.phoneNumber
          ? formatPhoneNumber(patientDetails.phone?.phoneNumber)
          : '',
        address: patientDetails.address ?? '',
        city: patientDetails.city ?? '',
        zipCode: patientDetails.zipCode ?? '',
        state: patientDetails.state
          ? states?.find((state) => state.label === patientDetails.state)
          : '',
        doctorState: patientDetails.state
          ? states?.find((state) => state.label === patientDetails.state)
          : '',
        planType,
        creditCardAttributes: {
          fullName: `${patientDetails.firstName ?? ''} ${patientDetails.lastName ?? ''}`,
          couponCode: defaultCoupon
        },
        appointmentDesc: session?.prospect?.appointment?.appointmentDescription,
        appointmentType: watchedAppointmentType
      });
    }
  }, [getValues, patientDetails, reset, states]);

  const watchedState = watch('state');
  useEffect(() => {
    if (typeof watchedState === 'object' && watchedState !== null) {
      setValue('doctorState', watchedState);
      setValue('doctorId', '');
      setValue('appointmentTime', '');
    }
  }, [watchedState, setValue]);

  const watchedDate = watch('date');
  useEffect(() => {
    // Reset appointment time on date change from the date picker
    setValue('doctorId', '');
    setValue('appointmentTime', '');
  }, [watchedDate, setValue]);

  return (
    <div className="sticky h-screen w-screen overflow-scroll">
      {isLoadingProspects && <Loader isVisible />}
      <Header userName={patientName} />
      <div className="mt-6 flex gap-x-16 px-16">
        <div data-testid="cross_sell_sidebar" className="w-72 min-w-[210px]">
          <QuickLinks
            tabLinks={tabLinks}
            onChange={(link) => handleActiveLink(link, refs)}
            type="custom"
          />
        </div>

        <div className="w-full">
          {patientDetails && <ExtraInfo patientDetails={patientDetails} />}
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Accordion
                title="Create profile"
                ref={createProfileRef}
                children={<CreateProfile states={states ?? []} />}
              />
              <Accordion
                title="Plan type"
                ref={planTypeRef}
                children={<PlanType patientId={patientId} />}
              />
              <Accordion
                title="Appointment type"
                ref={appointmentTypeRef}
                children={
                  <AppointmentType
                    isConfirmed={isConfirmed}
                    isWMPlan={isWMPlan}
                    isOptaviaPatient={isOptaviaPatient}
                  />
                }
              />
              <Accordion
                title="Select a doctor"
                ref={selectDoctorRef}
                children={
                  <SelectDoctor isOptaviaPatient={isOptaviaPatient} states={states ?? []} />
                }
              />
              <Accordion
                title="Confirm and pay"
                ref={confirmAndPayRef}
                children={<ConfirmAndPay isCreatingAccount={isCreatingAccount} />}
              />
            </form>
          </FormProvider>
        </div>
      </div>
      {isOpenModal && (
        <Modal isOpen={isOpenModal} size={size} padding={false} hideClose={hideClose}>
          {modalContent}
        </Modal>
      )}
    </div>
  );
};

export default CrossSell;
