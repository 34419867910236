import React, { useMemo } from 'react';

import { Common } from '@thecvlb/design-system';
import SearchCombobox from 'components/common/form/SearchCombobox';
import { radioOptions } from 'components/modals/AssignTasks/assignTasks.settings';
import { RadioType } from 'components/modals/AssignTasks/assignTasks.types';
import RoleSelector from 'components/modals/AssignTasks/components/RoleSelector';
import TeamSelector from 'components/modals/AssignTasks/components/TeamSelector';
import { useFormContext } from 'react-hook-form';
import { AssignValueType, AssignValueTypes } from 'store/tasks/task.types';
import { useGetTeamsQuery } from 'store/teams/teamsSlice';

const AssignmentTypeSelector: React.FC = () => {
  const { control, watch, setValue, resetField } = useFormContext();

  const { data: teamsList } = useGetTeamsQuery({});
  const teamsOptions = useMemo(
    () => teamsList?.teams?.map((team) => ({ label: team.name, value: team._id })),
    [teamsList]
  );

  const assignType = watch('type');

  const handleCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue('type', e.target.value as AssignValueTypes, { shouldDirty: true });
    resetField('role');
    resetField('team');
    resetField('staff');
  };

  const getChildComponent = (value: AssignValueTypes) => {
    if (value === AssignValueType.role && assignType === AssignValueType.role) {
      return <RoleSelector />;
    } else if (value === AssignValueType.team && assignType === AssignValueType.team) {
      return <TeamSelector teamsOptions={teamsOptions || []} />;
    } else if (value === AssignValueType.staff && assignType === AssignValueType.staff) {
      return (
        <SearchCombobox
          control={control}
          setValue={setValue}
          name="staff"
          userType="Staff"
          label=""
          placeholder="Search staff"
          isRequired
          size="sm"
          validationMessage="Staff is required"
          shouldCheckAvailability
          shouldSendTimezone
        />
      );
    }
  };

  return (
    <div className="my-6 flex flex-col gap-y-4">
      {radioOptions.map((option, key) => {
        const { type, label, value, isHighlighted } = option;

        return (
          <div key={key}>
            {type === RadioType.standard ? (
              <Common.RadioButton
                color="blue"
                size="md"
                value={value}
                onChange={handleCheck}
                checked={assignType === value}
                {...(isHighlighted && { className: 'text-red-500' })}
              >
                {label}
              </Common.RadioButton>
            ) : (
              <Common.RichRadioButton
                color="blue"
                size="md"
                label={label}
                value={value}
                onChange={handleCheck}
                checked={assignType === value}
              >
                {getChildComponent(value as AssignValueTypes)}
              </Common.RichRadioButton>
            )}
            {key === 1 && <div className="mt-3 border-t border-gray-200" />}
          </div>
        );
      })}
    </div>
  );
};

export default AssignmentTypeSelector;
