import { STATUS_OPTIONS } from 'constants/status';

import { StaffsFiltersFormProps } from './staffsFiltersForm.types';

export const defaultValues: StaffsFiltersFormProps = {
  name: '',
  email: '',
  lastLogin: '',
  uniqueId: '',
  'userType.name': '',
  searchKey: '',
  activeStates: [],
  status: { label: '', value: '' },
  userType: { label: '', value: '' }
};

export const getStaffsFiltersValue = (
  key: string,
  val: string,
  licensedInData?: { label: string; value: string }[]
) => {
  const currentKey = key === 'userType.name' ? 'userType' : key;
  let currentValue;
  switch (currentKey) {
    case 'status':
      currentValue = STATUS_OPTIONS.find((item) => item.value === val);
      break;
    case 'activeStates':
      if (Array.isArray(val)) {
        currentValue = [];
        val.forEach((eventKey) => {
          currentValue.push(licensedInData?.find((item) => item.label === eventKey));
        });
      } else {
        currentValue = [licensedInData?.find((item) => item.label === val)];
      }
      break;
    case 'userType':
      currentValue = { label: val, value: val };
      break;
    default:
      currentValue = val;
  }
  return currentValue;
};
