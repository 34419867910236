import { ChartType, TooltipItem } from 'chart.js';

export const WEIGHT_TABS = [
  {
    label: 'All'
  },
  { label: 'Week' },
  { label: 'Month' },
  { label: 'Year' }
];

export const tooltip = {
  bodyFont: { size: 13, weight: 'bold' as number | 'bold' | 'normal' | 'lighter' | 'bolder' },
  borderWidth: 1,
  callbacks: {
    label: (tooltipItem: TooltipItem<ChartType>) => (tooltipItem?.formattedValue ?? '') + ' lbs'
  },
  caretSize: 0,
  displayColors: false,
  intersect: false,
  padding: 8,
  titleFont: { size: 9, weight: 'normal' as number | 'bold' | 'normal' | 'lighter' | 'bolder' }
};

export const pointConfig = {
  borderWidth: 3,
  pointBorderWidth: 0,
  pointHitRadius: 6,
  pointHoverRadius: 6,
  pointRadius: 1,
  tension: 0.3
};
