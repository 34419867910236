import { CATEGORY_OPTIONS_HISTORY } from 'constants/tasks';

import { useMemo, useState } from 'react';

import DatePickerInput from 'components/common/DatePickerInput';
import ControlledMultiSelect from 'components/forms/controlled/ControlledMultiSelect';
import { HistoryProps, Options } from 'components/patient/History/history.types';
import dayjs from 'dayjs';
import { useTableFiltersData } from 'hooks/filters/useTableFiltersData';
import { useAppDispatch } from 'hooks/redux';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import queryString from 'query-string';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { useGetEventTypesQuery } from 'store/lookup/lookupSlice';
import { closeModal } from 'store/modal/modalSlice';
import { getAppliedFilterValues, handleReset } from 'utils/filters/filters';

import { defaultValues, filterEventTypesByCategories } from './historyForm.settings';
import Footer from '../Footer';

const HistoryForm = () => {
  const location = useLocation();
  const { data: eventTypes } = useGetEventTypesQuery();

  const { handleSubmit, control, reset, watch, setValue, getValues } = useForm<HistoryProps>({
    reValidateMode: 'onChange'
  });
  const dispatch = useAppDispatch();
  const [startDateSelected, setStartDateSelected] = useState<Date>();
  const [endDate, setEndDate] = useState<Date>();
  const navigate = useNavigate();
  const [, setFormData] = useTableFiltersData('history', defaultValues, reset);

  const selectedCategories = getValues('eventKey');

  const getValue = (data: Options | Options[]) => {
    if (Array.isArray(data)) {
      return data.length && data?.map((item) => item.value);
    }
    return data.value;
  };

  const onSubmit = (data: HistoryProps) => {
    setFormData({ ...defaultValues, ...data });

    const parsedUrl = queryString.parse(location.search);

    const filters = {
      ...parsedUrl,
      pageNo: '0',
      ...(data?.patientId && { patientId: data.patientId }),
      startDate: data.startDate,
      endDate: data.endDate,
      ...(data?.eventKey?.length && { eventKey: getValue(data.eventKey) }),
      ...(data?.eventType && { eventType: getValue(data.eventType) })
    };

    const appliedFilterValues = getAppliedFilterValues(filters);

    if (!isEqual(appliedFilterValues, parsedUrl)) {
      navigate({ search: queryString.stringify(appliedFilterValues) });
    }
    dispatch(closeModal());
  };

  const eventTypesList = useMemo(() => {
    let result: Options[] = [];

    if (selectedCategories && !isEmpty(selectedCategories) && eventTypes && !isEmpty(eventTypes)) {
      result = filterEventTypesByCategories(selectedCategories, eventTypes);
    }

    return result;
  }, [selectedCategories, eventTypes]);

  watch();

  return (
    <form className="w-[350px]" onSubmit={handleSubmit(onSubmit)}>
      <div className="flex flex-col gap-4">
        <ControlledMultiSelect
          control={control}
          label="Category"
          name="eventKey"
          placeholder="Select category..."
          options={CATEGORY_OPTIONS_HISTORY}
          labelDirection="col"
        />
        <ControlledMultiSelect
          control={control}
          label="Event type"
          name="eventType"
          placeholder="Select event type..."
          options={eventTypesList}
          labelDirection="col"
        />
        <DatePickerInput
          dataTestId="date_from_field"
          placeholder="Select date"
          label="Date from"
          labelDirection="col"
          inputValue={getValues('startDate') ?? ''}
          setInputValue={(value) => setValue('startDate', value)}
          selectedDate={startDateSelected}
          setSelectedDate={setStartDateSelected}
          startAllowedDate={dayjs().subtract(100, 'year').toDate()}
          lastAllowedDate={new Date()}
          captionLayout={'dropdown'}
          wrapperClasses="w-full"
          size="sm"
        />
        <DatePickerInput
          dataTestId="date_to_field"
          placeholder="Select date"
          label="Date to"
          labelDirection="col"
          inputValue={getValues('endDate') ?? ''}
          setInputValue={(value) => setValue('endDate', value)}
          selectedDate={endDate}
          setSelectedDate={setEndDate}
          startAllowedDate={dayjs().subtract(100, 'year').toDate()}
          lastAllowedDate={new Date()}
          captionLayout={'dropdown'}
          wrapperClasses="w-full"
          size="sm"
        />
      </div>
      <Footer onClick={() => handleReset(dispatch, reset, defaultValues, navigate)} />
    </form>
  );
};

export default HistoryForm;
