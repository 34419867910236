import { useCallback, useEffect, useState } from 'react';

import { OptionProps } from '@thecvlb/design-system/lib/src/common';
import { notifySuccess } from 'components/common/Toast/Toast';
import MessageTemplatesForm from 'components/modals/components/MessageTemplatesForm';
import {
  FormDataTemplates,
  MessageTemplatesProps,
  TemplateTypes
} from 'components/modals/components/MessageTemplatesForm/messageTemplatesForm.types';
import startCase from 'lodash/startCase';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useGetVariablesAndButtonsQuery } from 'store/lookup/lookupSlice';
import { closeModal } from 'store/modal/modalSlice';
import {
  useCreatePatientMessageTemplatesMutation,
  useDeletePatientMessageTemplatesMutation,
  useLazyGetPatientMessageTemplateByIdQuery,
  useUpdatePatientMessageTemplatesMutation
} from 'store/patientMessageTemplates/patientMessageTemplatesSlice';

const defaultValues = {
  message: '',
  shortCode: '',
  status: null,
  variables: null
};

const MessageTemplates: React.FC<MessageTemplatesProps> = ({ id }) => {
  const dispatch = useDispatch();

  const [getTemplateData, { data: singleTemplateData }] =
    useLazyGetPatientMessageTemplateByIdQuery();
  const { data: variablesAndButtons } = useGetVariablesAndButtonsQuery({ type: 'Message' });
  const [updateTemplate, { isLoading: loadingEdit }] = useUpdatePatientMessageTemplatesMutation();
  const [deleteTemplate, { isLoading: loadingDelete }] = useDeletePatientMessageTemplatesMutation();
  const [postPatientMessageTemplate, { isLoading: loadingCreate }] =
    useCreatePatientMessageTemplatesMutation();
  const [variableOptions, setVariableOptions] = useState<OptionProps[]>([]);
  const { control, formState, handleSubmit, reset, setValue, getValues } =
    useForm<FormDataTemplates>({
      reValidateMode: 'onChange',
      shouldFocusError: false,
      defaultValues
    });

  const isDisabled = loadingCreate || loadingEdit || loadingDelete;

  const onSuccess = useCallback(
    (message: string) => {
      notifySuccess(message);
      dispatch(closeModal());
    },
    [dispatch]
  );

  const handleFormSubmit: SubmitHandler<FormDataTemplates> = useCallback(
    (data) => {
      if (data) {
        const body = {
          message: data.message,
          shortCode: data.shortCode,
          status: data.status?.value
        };

        const request = id ? updateTemplate({ id, body }) : postPatientMessageTemplate(body);

        request.unwrap().then((res) => onSuccess(res.message));
      }
    },
    [id, onSuccess, postPatientMessageTemplate, updateTemplate]
  );

  const handleDeleteTemplate = useCallback(() => {
    if (id) {
      deleteTemplate(id)
        .unwrap()
        .then((res) => onSuccess(res.message));
    }
  }, [deleteTemplate, id, onSuccess]);

  useEffect(() => {
    if (id) getTemplateData({ templateId: id });
  }, [getTemplateData, id]);

  useEffect(() => {
    if (variablesAndButtons) {
      const newVariables = variablesAndButtons.variables.map((variable) => ({
        label: variable.label,
        value: variable.variable
      }));

      setVariableOptions(newVariables);
    }
  }, [variablesAndButtons]);

  useEffect(() => {
    if (singleTemplateData) {
      reset({
        message: singleTemplateData.message,
        shortCode: singleTemplateData.shortCode,
        status: { label: startCase(singleTemplateData.status), value: singleTemplateData.status }
      });
    }
  }, [reset, singleTemplateData]);

  return (
    <MessageTemplatesForm
      type={TemplateTypes.patient}
      popupHeaderTitle="Patient message template"
      onDeleteTemplate={handleDeleteTemplate}
      onFormSubmit={handleFormSubmit}
      handleSubmit={handleSubmit}
      formState={formState}
      getValues={getValues}
      setValue={setValue}
      control={control}
      variableOptions={variableOptions}
      disabledButtons={isDisabled}
      id={id}
    />
  );
};

export default MessageTemplates;
