import { AppointmentItemProps } from 'store/appointments/appointments.types';
import { SubscriptionDetailsResponse } from 'store/patients/patients.types';

import CancelAppointments from './CancelAppointments';
import { CancellationSteps, CancellationType } from './cancelPlan.types';
import CancelPlanConfirmation from './CancelPlanConfirmation';
import CancelPlanReason from './CancelPlanReason';
import CancelPlanType from './CancelPlanType';

export const renderContent = (
  step: CancellationSteps,
  setCancelationType: (type: CancellationType) => void,
  upcomingAppointments: AppointmentItemProps[],
  cancellationType?: CancellationType,
  isPatientInactive?: boolean,
  subscriptionDetails?: SubscriptionDetailsResponse,
  patientName?: string
) => {
  switch (step) {
    case CancellationSteps.ChooseReason:
      return <CancelPlanReason cancellationType={cancellationType} />;
    case CancellationSteps.CancelAppointments:
      return (
        <CancelAppointments
          patientName={patientName || ''}
          upcomingAppointments={upcomingAppointments}
        />
      );
    case CancellationSteps.Confirmation:
      return (
        <CancelPlanConfirmation
          cancellationType={cancellationType}
          subscriptionDetails={subscriptionDetails}
        />
      );
    default:
      return (
        <CancelPlanType
          cancellationType={cancellationType}
          setCancelationType={setCancelationType}
          isPatientInactive={isPatientInactive}
          subscriptionDetails={subscriptionDetails}
        />
      );
  }
};

export const getCancelationTitle = (
  step: CancellationSteps,
  cancellationType?: CancellationType
) => {
  switch (step) {
    case CancellationSteps.CancelPlan:
      return 'Cancel plan';
    case CancellationSteps.ChooseReason:
      if (cancellationType === CancellationType.CancelAutoRenew) {
        return 'Cancel auto-renew';
      }
      if (cancellationType === CancellationType.DowngradeRefundPatient) {
        return 'Downgrade & refund patient';
      }
      return 'Cancel account & refund patient';
    case CancellationSteps.CancelAppointments:
      if (cancellationType === CancellationType.DowngradeRefundPatient) {
        return 'Downgrade & refund patient';
      }
      return 'Cancel account & refund patient';
    case CancellationSteps.Confirmation:
      if (cancellationType === CancellationType.CancelAutoRenew) {
        return 'Confirmation: Cancel auto-renew';
      }
      if (cancellationType === CancellationType.DowngradeRefundPatient) {
        return 'Confirmation: Downgrade & refund patient';
      }
      return 'Confirmation: Cancel account & refund patient';
    default:
      return 'Cancel plan';
  }
};

export const getConfirmButtonLabel = (
  step: CancellationSteps,
  cancellationType?: CancellationType
) => {
  if (step === CancellationSteps.Confirmation) {
    if (cancellationType === CancellationType.CancelAutoRenew) {
      return 'Cancel auto-renew';
    }
    if (cancellationType === CancellationType.DowngradeRefundPatient) {
      return 'Downgrade account & refund';
    }
    return 'Cancel account & refund';
  }
  if (step === CancellationSteps.CancelAppointments) {
    return 'Cancel & next';
  }
  return 'Next';
};
