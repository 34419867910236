import { Popover, PopoverButton, PopoverPanel } from '@headlessui/react';
import { Common } from '@thecvlb/design-system';
import { useAppliedFilters } from 'hooks/filters/useAppliedFilters';

const CommonPopover: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const { appliedFiltersCount } = useAppliedFilters();

  const popoverPanelClasses =
    'absolute right-0 top-0 z-20 mt-10 size-fit rounded-2xl bg-white px-4 shadow-2xl transition duration-75 ease-out data-[closed]:scale-95 data-[closed]:opacity-0';
  return (
    <Popover>
      <PopoverButton
        as={Common.Button}
        dataTestId="search_btn"
        color="white-alt"
        size="md"
        preIcon="search"
      >
        <span className="whitespace-nowrap text-sm">
          {!!appliedFiltersCount ? `${appliedFiltersCount} applied` : 'Search'}
        </span>
      </PopoverButton>
      <PopoverPanel transition className={popoverPanelClasses}>
        {children}
      </PopoverPanel>
    </Popover>
  );
};

export default CommonPopover;
