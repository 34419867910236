import React, { ChangeEvent, FC, forwardRef } from 'react';

import { Common } from '@thecvlb/design-system';
import classNames from 'classnames';
import { useToggle } from 'react-use';

import { InputProps } from './input.types';

export const Input: FC<InputProps> = forwardRef<HTMLInputElement, InputProps>(
  (
    { type, name, label, disabled, id, helper, errors, onChange, value, className, dataTestId },
    ref
  ) => {
    const [password, togglePassword] = useToggle(false);
    const [focus, toggleFocus] = useToggle(false);

    const onFocus = (event: ChangeEvent<HTMLInputElement>) => {
      toggleFocus();
      if (event.target.value.length) {
        setTimeout(() => event.target.select(), 50);
      }
    };

    const wrapperClassName = classNames('relative flex flex-col gap-1.5 sm: gap-1', className);
    const labelClassName = classNames(
      'absolute h-fit transition-all ease-in-out duration-300 pointer-events-none',
      focus || value
        ? 'text-xs top-[6px] my-0 sm:top-[2px]'
        : 'sm:text-base top-[15px] sm:top-[12px]',
      !!errors && !disabled
        ? 'text-red'
        : focus
          ? 'text-primary'
          : value && !disabled
            ? 'text-secondary'
            : 'text-gray',
      type === 'search' ? 'left-11 sm:left-9' : 'left-4 sm:left-3'
    );
    const inputWrapperClassName = classNames(
      'relative w-full after:absolute after:inset-x-0 after:-bottom-0 after:h-[2px] after:rounded-b-sm after:transition-all after:ease-in-out after:duration-300',
      !!errors && !disabled
        ? 'after:bg-red'
        : focus
          ? 'after:bg-primary'
          : value && !disabled
            ? 'after:bg-secondary'
            : 'after:bg-gray'
    );
    const inputClassName = classNames(
      'border w-full outline-none rounded-t-md rounded-b-sm border-gray-200 outline-0 shadow-sm pb-3.5 pt-4 px-4 text-gray-700 focus:border-gray-200 disabled:bg-gray-100 disabled:shadow-none sm:text-base sm:pb-2.5 sm:pt-3 sm:px-3',
      {
        'pl-11 sm:pl-9': type === 'search',
        'pr-11 sm:pr-9': type === 'password'
      }
    );
    const helperClassName = classNames(
      'text-xs',
      !!errors && !disabled
        ? 'text-red'
        : focus
          ? 'text-primary'
          : value
            ? 'text-secondary'
            : 'text-gray'
    );
    const passwordButtonClassName = 'absolute inset-y-0 my-auto right-4 text-gray sm:right-3';
    const searchIconClassName = 'absolute inset-y-0 my-auto left-4 text-gray sm:left-3';

    return (
      <div className={wrapperClassName}>
        <div className={inputWrapperClassName} data-testid={dataTestId}>
          <label className={labelClassName} htmlFor={id}>
            {label}
          </label>
          <input
            autoComplete="off"
            className={inputClassName}
            data-testid={dataTestId}
            disabled={disabled}
            id={id}
            name={name}
            ref={ref}
            type={password ? 'text' : type}
            value={value}
            onBlur={toggleFocus}
            onChange={onChange}
            onFocus={onFocus}
          />
          {type === 'search' && <Common.Icon className={searchIconClassName} name={'search'} />}
          {type === 'password' && (
            <button
              className={passwordButtonClassName}
              data-testid="eye_icon"
              tabIndex={type === 'password' ? 0 : -1}
              type="button"
              onClick={togglePassword}
            >
              <Common.Icon name={password ? 'eye' : 'eye-closed'} />
            </button>
          )}
        </div>
        {helper && <span className={helperClassName}>{helper}</span>}
      </div>
    );
  }
);

export default React.memo(Input);
