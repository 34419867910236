import { Common } from '@thecvlb/design-system/lib/src';
import { PlanCodesProps } from 'enums/appointmentStatus';
import { RoleShortName } from 'enums/role';

import DetailsIcon from './DetailsIcon';
import { getOnboardingStatuses } from './onboardingDetails.setings';
import { OnboardingStatusProps } from './onboardingDetails.types';

const OnboardingDetails: React.FC<OnboardingStatusProps> = ({ details, iconSize }) => {
  const { preConsultTaskCompletedBy, statuses } = getOnboardingStatuses(details);
  const isTotalCareMembership =
    details.tags?.find(
      (tag) => typeof tag === 'object' && tag._id === PlanCodesProps.TotalCareMembership
    ) ||
    ('patientInfo' in details &&
      details.patientInfo?.tags?.find((tag) => tag === PlanCodesProps.TotalCareMembership));

  return (
    <div className="flex items-center justify-start gap-2">
      {preConsultTaskCompletedBy?.role && preConsultTaskCompletedBy?.profileImage && (
        <Common.ProfileImage
          role={preConsultTaskCompletedBy.role as RoleShortName}
          src={preConsultTaskCompletedBy.profileImage}
          size="sm"
        />
      )}
      {statuses?.map((status) =>
        isTotalCareMembership && status.label === 'MIF' ? null : (
          <DetailsIcon statusDetails={status} key={status.iconName} size={iconSize} />
        )
      )}
    </div>
  );
};

export default OnboardingDetails;
