import { BulkEditResponseProps } from 'components/modals/bulkEditModals/UpdateFailed/updateFailed.types';
import {
  AvailableProvidersResponseProps,
  EditStaffData,
  NewStaff,
  Staff,
  StaffsQueryParams
} from 'store/staffs/staffs.types';

import { apiSlice } from '../api/apiSlice';

export const staffsApiSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getStaffs: build.query<{ staffs: Staff[]; totalCount: number }, { params: StaffsQueryParams }>({
      query: ({ params }) => ({ url: `/staffs`, params }),
      transformResponse: (response: { data: Staff[]; info: { totalCount: number } }) => ({
        staffs: response.data,
        totalCount: response.info.totalCount
      }),
      providesTags: ['Staff']
    }),
    getStaff: build.query<Staff, { staffId: string }>({
      query: ({ staffId }) => ({
        url: `/staffs/${staffId}`
      }),
      providesTags: ['Staff'],
      transformResponse: (response: { data: Staff[] }) => response.data[0]
    }),

    updateStaffMember: build.mutation({
      query: ({ staffId, staff }: { staffId: string; staff: EditStaffData }) => ({
        url: `/staffs/${staffId}`,
        method: 'PUT',
        headers: {
          'API-KEY': import.meta.env.VITE_API_KEY
        },
        body: staff
      }),
      transformResponse: (response: { message: string }) => response
    }),

    updateStaffMemberImage: build.mutation({
      query: ({ staffId, profileImage }: { staffId: string; profileImage: File }) => {
        const formData = new FormData();
        formData.append('profileImage', profileImage);

        return {
          url: `/staffs/${staffId}/profile-image`,
          method: 'POST',
          headers: {
            'API-KEY': import.meta.env.VITE_API_KEY
          },
          body: formData
        };
      },
      transformResponse: (response: { data: NewStaff; message: string }) => response
    }),

    addStaffMember: build.mutation({
      query: ({ staff }: { staff: EditStaffData }) => ({
        url: `/staffs`,
        method: 'POST',
        body: staff
      }),
      transformResponse: (response: { data: NewStaff; message: string }) => response,
      invalidatesTags: ['Staff']
    }),

    getAvailableProviders: build.query<
      AvailableProvidersResponseProps,
      {
        appointmentTypeId?: string;
        searchKey?: string;
        state: string;
        patientUserId: string;
        timezone?: string;
        isUrgentTask?: boolean;
      }
    >({
      query: ({ state, searchKey, appointmentTypeId, patientUserId, timezone, isUrgentTask }) => ({
        url: `/available-providers`,
        params: {
          state,
          searchKey,
          patientUserId,
          appointmentTypeId,
          timezone,
          isUrgentTask
        }
      })
    }),

    updateStaffMembers: build.mutation({
      query: (body) => ({
        url: `/staffs-bulk-update`,
        method: 'PATCH',
        body
      }),
      transformResponse: (response: BulkEditResponseProps) => response,
      invalidatesTags: ['Staff']
    }),

    unassignStaffTasks: build.mutation({
      query: ({ staffId }: { staffId: string }) => ({
        url: `/staffs/${staffId}/unassign-tasks`,
        method: 'PATCH'
      }),
      invalidatesTags: ['Staff']
    })
  })
});

export const {
  useGetStaffsQuery,
  useLazyGetStaffsQuery,
  useUpdateStaffMemberImageMutation,
  useLazyGetStaffQuery,
  useGetStaffQuery,
  useUpdateStaffMemberMutation,
  useAddStaffMemberMutation,
  useGetAvailableProvidersQuery,
  useLazyGetAvailableProvidersQuery,
  useUpdateStaffMembersMutation,
  useUnassignStaffTasksMutation
} = staffsApiSlice;
