import { Common } from '@thecvlb/design-system/lib/src';
import { TaskCategories } from 'enums/taskCategories';
import useOpenTask from 'hooks/tasks/useOpenTask/useOpenTask';

import { CancelAppointmentsProps } from './cancelAppointments.types';

const CancelAppointments: React.FC<CancelAppointmentsProps> = ({
  patientName,
  upcomingAppointments
}) => {
  const { handleOpenTask } = useOpenTask();

  return (
    <div className="flex flex-col gap-4">
      <p className="mb-2 text-base font-medium text-gray-700">
        The following appointments must first be cancelled. {patientName} will not be charged for
        these.
      </p>
      {upcomingAppointments?.map((appointment) => (
        <Common.Appointment
          onClick={() =>
            handleOpenTask({
              taskId: appointment.taskId,
              assignToId: appointment.doctorId,
              assignToName: appointment.staffName,
              shouldClosePrevModal: true
            })
          }
          startTime={appointment?.appointmentTime?.startTime}
          isQueueAppointment={
            appointment.category?.toLowerCase() === TaskCategories.Queue.toLowerCase()
          }
          text={`${appointment?.appointmentType?.displayName} • ${appointment?.staffName || ''}`}
          key={appointment?._id}
          type="mustBeCanceled"
          hideButtons
        />
      ))}
    </div>
  );
};

export default CancelAppointments;
