import { INSURANCE_RELATIONSHIP_OPTIONS } from 'constants/order';

import { useEffect } from 'react';

import { Common } from '@thecvlb/design-system';
import classNames from 'classnames';
import Loader from 'components/common/Loader';
import { notifyError, notifySuccess } from 'components/common/Toast/Toast';
import ControlledSelect from 'components/forms/controlled/ControlledSelect';
import InputField from 'components/forms/controlled/InputField';
import { InsuranceLabels } from 'enums/orderLabels';
import { useForm } from 'react-hook-form';
import { useToggle } from 'react-use';
import { useUpdateOrderMutation } from 'store/orders/ordersSlice';

import { getItems, getSubmitOrderData } from './insuranceInfo.settings';
import { InsuranceInfoProps, OrderFormStateProps } from './insuranceInfo.types';

const InsuranceInfo: React.FC<InsuranceInfoProps> = ({
  order,
  insurance,
  wrapperClassNames,
  labelClassNames
}) => {
  const [isEditing, setIsEditing] = useToggle(false);
  const [updateOrder, { isLoading: isLoadingEdit }] = useUpdateOrderMutation();

  const { handleSubmit, control, reset } = useForm<OrderFormStateProps>({
    reValidateMode: 'onChange',
    criteriaMode: 'all',
    mode: 'onChange'
  });

  const wrapperClasses = classNames(wrapperClassNames || 'grid grid-cols-2 gap-x-6 gap-y-2');
  const labelClasses = classNames(
    'text-base mr-5 min-w-fit flex-none text-gray',
    labelClassNames || 'w-24'
  );

  useEffect(() => {
    reset({
      ...order.insurance,
      relationship: INSURANCE_RELATIONSHIP_OPTIONS.find(
        (c) => c.value === order.insurance?.relationship
      )
    });
  }, [reset, order]);

  const handleSave = async (formData: OrderFormStateProps) => {
    const hasRequiredData = (o: {
      memberId: string;
      rxBin: string;
      relationship: { label: string; value: string };
    }) => {
      if (!o.memberId) return false;
      if (!o.rxBin) return false;
      if (!o.relationship) return false;
      return true;
    };

    if (hasRequiredData(formData)) {
      const submitOrder = getSubmitOrderData(formData);

      if (submitOrder) {
        updateOrder({ orderId: order.id, order: submitOrder })
          .unwrap()
          .then((data) => {
            notifySuccess(data.message || 'Successfully updated order');
            setIsEditing(false);
          })
          .catch((error) => {
            notifyError(error.data?.message);
          });
      }
    } else {
      notifyError('Missing required data');
    }
    return;
  };

  const EditButtons = () => (
    <div className="mb-4">
      {isEditing && (
        <div className="flex">
          <Common.Button
            color="white-alt"
            size="sm"
            className="mr-2"
            onClick={() => setIsEditing(false)}
            preIconClassName="shrink-0"
          >
            <span className="whitespace-normal text-left font-semibold">Cancel</span>
          </Common.Button>
          <Common.Button
            disabled={isLoadingEdit}
            color="blue"
            size="sm"
            className=""
            preIconClassName="shrink-0"
          >
            <span className="whitespace-normal text-left font-semibold">Save</span>
          </Common.Button>
        </div>
      )}
      {!isEditing && (
        <div>
          <Common.Button
            color="green"
            size="sm"
            preIcon="pencil"
            className=""
            onClick={() => setIsEditing(true)}
            preIconClassName="shrink-0"
          >
            <span className="whitespace-normal text-left font-semibold">Edit</span>
          </Common.Button>
        </div>
      )}
    </div>
  );

  return (
    <form onSubmit={handleSubmit(handleSave)}>
      <Loader isVisible={isLoadingEdit} />
      <div className="flex justify-between">
        <h4 className="mb-2 text-base font-bold text-gray-700">Insurance info</h4>
        <EditButtons />
      </div>
      <div className={wrapperClasses}>
        {getItems(insurance).map((el) => {
          if (!isEditing) {
            return (
              <div key={el.label} className="flex">
                <p className={labelClasses}>{el.label}</p>
                <p className={classNames('text-base text-gray-700', { underline: el.isUnderline })}>
                  {el.value}
                </p>
              </div>
            );
          }

          if (el.label === InsuranceLabels.MemberId) {
            return (
              <div key={el.label} className="flex">
                <p className={labelClasses}>{el.label}</p>
                <InputField control={control} name={el.property} className="w-64" />
              </div>
            );
          } else if (el.label === InsuranceLabels.RxGroup) {
            return (
              <div key={el.label} className="flex">
                <p className={labelClasses}>{el.label}</p>
                <InputField control={control} name={el.property} className="w-64" />
              </div>
            );
          } else if (el.label === InsuranceLabels.RxBIN) {
            return (
              <div key={el.label} className="flex">
                <p className={labelClasses}>{el.label}</p>
                <InputField control={control} name={el.property} className="w-64" />
              </div>
            );
          } else if (el.label === InsuranceLabels.PCN) {
            return (
              <div key={el.label} className="flex">
                <p className={labelClasses}>{el.label}</p>
                <InputField control={control} name={el.property} className="w-64" />
              </div>
            );
          } else if (el.label === InsuranceLabels.Relationship) {
            return (
              <div key={el.label} className="flex">
                <p className={labelClasses}>{el.label}</p>
                <ControlledSelect
                  control={control}
                  options={INSURANCE_RELATIONSHIP_OPTIONS}
                  name={el.property}
                  placeholder="Select..."
                  className="w-64"
                />
              </div>
            );
          } else {
            return (
              <div key={el.label} className="flex">
                <p className={labelClasses}>{el.label}</p>
                <p className={classNames('text-base text-gray-700', { underline: el.isUnderline })}>
                  {el.value}
                </p>
              </div>
            );
          }
        })}
      </div>
    </form>
  );
};

export default InsuranceInfo;
