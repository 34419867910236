export enum Role {
  CS = 'Customer Service Rep',
  AD = 'Admin',
  NP = 'Nurse Practitioner',
  PH = 'Physician',
  MA = 'Medical Assistant',
  MD = 'Master Physician'
}

export enum RoleShortName {
  CustomerService = 'CS',
  Admin = 'AD',
  NursePractitioner = 'NP',
  Physician = 'PH',
  MasterPhysician = 'MD',
  MedicalAssistant = 'MA'
}
