import { useEffect, useMemo } from 'react';

import { createSelector } from '@reduxjs/toolkit';
import { Common } from '@thecvlb/design-system';
import { useFlag } from '@unleash/proxy-client-react';
import classNames from 'classnames';
import EscalatedTasksFilter from 'components/common/LoggedUserHeader/EscalatedTasksFilter';
import AppliedFiltersTags from 'components/filters/AppliedFiltersTags/AppliedFiltersTags';
import SearchForm from 'components/filters/SearchForm';
import TaskCategoriesFilter from 'components/filters/Tasks/TaskCategoriesFilter';
import CreateTask from 'components/modals/CreateTaskForm/CreateTaskForm';
import ConnectedTasksTable from 'components/tables/TaskTable/ConnectedTasksTable';
import AssignTasksButton from 'components/tasks/AssignTasksButton';
import { TagTypes } from 'enums/tagTypes';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { TasksQueryParams } from 'models/tasks.types';
import { useSearchParams } from 'react-router-dom';
import { openModal } from 'store/modal/modalSlice';
import { useGetTagsListQuery } from 'store/patients/patientsSlice';
import { selectTasks, useLazyGetTaskFiltersDetailsQuery } from 'store/tasks/tasksSlice';
import { useGetTaskTagsQuery } from 'store/tasks/tasksSlice';
import { useGetTeamsQuery } from 'store/teams/teamsSlice';
import { selectUser } from 'store/user/userSlice';
import { parseQueryParams } from 'utils/router';

import { filtersByRoles } from './Tasks.settings';

const selectUserState = createSelector(selectUser, selectTasks, (user, tasks) => ({
  userRole: user.userType.name,
  tasksSummary: tasks.tasksSummary
}));

const TasksPage = () => {
  const [searchParams, setSearchParams] = useSearchParams({ limit: '50', pageNo: '0' });
  const dispatch = useAppDispatch();

  const isQueueDisabled = useFlag('disable-queue');

  const queryParams: TasksQueryParams = useMemo(() => {
    const parsedQueryParams = parseQueryParams(searchParams);
    // remove taskModalOpenId from request payload
    if (parsedQueryParams?.taskModalOpenID) delete parsedQueryParams?.taskModalOpenID;
    return parsedQueryParams;
  }, [searchParams]);

  const { userRole, tasksSummary } = useAppSelector(selectUserState);

  const showTabs = !!tasksSummary?.escalationsCount && tasksSummary?.escalationsCount > 0;

  const [getTaskFiltersDetails] = useLazyGetTaskFiltersDetailsQuery();

  const { data: tags } = useGetTagsListQuery({
    tagTypes: [TagTypes.Both, TagTypes.Manual, TagTypes.System]
  });
  const tagsOptions = useMemo(
    () => tags?.data?.map((tag) => ({ label: tag.name, value: tag._id })),
    [tags?.data]
  );

  const { data: teamsList } = useGetTeamsQuery({});
  const teamsOptions = useMemo(
    () => teamsList?.teams?.map((team) => ({ label: team.name, value: team._id })),
    [teamsList]
  );

  const { data: taskTagsList } = useGetTaskTagsQuery();
  const taskTagsOptions = useMemo(
    () => taskTagsList?.map((tag) => ({ label: tag.name, value: tag._id })),
    [taskTagsList]
  );

  const filterData = useMemo(
    () => filtersByRoles(userRole, queryParams.isSentRequests, isQueueDisabled),
    [queryParams.isSentRequests, userRole, isQueueDisabled]
  );

  useEffect(() => {
    if (
      !searchParams.has('futureTasksRange') &&
      (searchParams.has('isMyPatients') || searchParams.has('isSentRequests'))
    ) {
      getTaskFiltersDetails()
        .unwrap()
        .then((data) => {
          if (data.futureTasksRange) {
            searchParams.set('futureTasksRange', data.futureTasksRange);
            setSearchParams(searchParams);
          }
        })
        .catch((error) => console.error(error));
    }
    // This useEffect should run only on isMyPatients and isSentRequests search params change
  }, [searchParams.get('isMyPatients'), searchParams.get('isSentRequests')]);

  const handleCreateNewTask = () => {
    dispatch(
      openModal({
        size: 'sm',
        modalContent: <CreateTask />,
        hideClose: true
      })
    );
  };

  const searchAndCreateTaskButtons = (
    <div className="flex items-center gap-3">
      <Common.Button style="pill" color="green-alt" size="sm" onClick={handleCreateNewTask}>
        <Common.Icon name="plus" color="green-600" />
      </Common.Button>
      <SearchForm showSearchBar />
    </div>
  );

  return (
    <>
      <div className="relative mb-5">
        <div className="flex flex-col gap-4">
          {showTabs && (
            <div className="flex items-center justify-between">
              <EscalatedTasksFilter />
              {searchAndCreateTaskButtons}
            </div>
          )}
          <div className="flex items-start justify-between">
            <TaskCategoriesFilter categories={filterData} />
            <div
              className={classNames('ml-4 shrink-0', {
                'mr-4': !showTabs
              })}
            >
              <AssignTasksButton />
            </div>
            {!showTabs && searchAndCreateTaskButtons}
          </div>
        </div>
      </div>
      <AppliedFiltersTags
        tagsOptions={tagsOptions}
        teamsOptions={teamsOptions}
        taskTagsOptions={taskTagsOptions}
      />
      <ConnectedTasksTable />
    </>
  );
};

export default TasksPage;
