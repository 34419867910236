import { DayPicker } from 'react-day-picker';
import styled from 'styled-components';
import tw from 'twin.macro';

const StyledDayPicker = styled(DayPicker)`
  .rdp-caption_label {
    ${tw`text-lg text-gray-700 font-bold`}
  }

  .rdp-weekdays {
    ${tw`text-gray-500 `}
  }

  .rdp-head_cell {
    ${tw`text-gray-500 text-xs font-medium`}
  }

  .rdp-tbody {
    ${tw`text-gray-700 text-sm`}
  }

  .rdp-button_reset.rdp-button.rdp-day {
    ${tw`w-9 h-9 font-medium`}
  }

  .rdp-button.rdp-day.rdp-day_selected {
    ${tw`bg-primary text-white w-9 h-9 font-bold`}
  }

  .recurring {
    ${tw`bg-secondary-100 text-primary w-9 h-9 font-bold`}
  }
`;

export { StyledDayPicker };
