import { NOTIFICATION, PATIENT_MESSAGE, TASK, WEBHOOK } from 'constants/automation';
import { STATUS_OPTIONS } from 'constants/status';

import { Common } from '@thecvlb/design-system/lib/src';
import ControlledSelect from 'components/forms/controlled/ControlledSelect';
import InputField from 'components/forms/controlled/InputField';
import PopupFooter from 'components/modals/components/PopupFooter';
import PopupHeader from 'components/modals/components/PopupHeader';
import { useFormContext } from 'react-hook-form';
import { validation } from 'utils/helpers';
import { ANY_CHARACTER_REGEXP } from 'utils/regExp';

import { AutomationFormDataProps, AutomationFormProps } from './automationForm.types';
import NotificationAndPatientMessageContent from '../automationFormComponents/NotificationAndPatientMessageContent';
import TaskContent from '../automationFormComponents/TaskContent';
import TimingSection from '../automationFormComponents/TimingSection';
import WebhookContent from '../automationFormComponents/WebhookContent';

const AutomationForm: React.FC<AutomationFormProps> = ({
  id,
  onSubmitForm,
  currentType,
  typeOptions,
  onChangeType,
  onDeleteTrigger,
  setIsShowTiming,
  isShowTiming,
  disabledButtons,
  setEventType,
  timingUnitOptions,
  showTriggerBasedOn,
  setTriggerBasedOn
}) => {
  const { handleSubmit, control, formState } = useFormContext<AutomationFormDataProps>();
  const content = {
    [TASK.value]: <TaskContent />,
    [WEBHOOK.value]: (
      <WebhookContent setIsShowTiming={setIsShowTiming} setEventType={setEventType} />
    ),
    [NOTIFICATION.value]: <NotificationAndPatientMessageContent currentType={currentType} />,
    [PATIENT_MESSAGE.value]: <NotificationAndPatientMessageContent currentType={currentType} />
  };

  return (
    <div data-testid="automation_popup" className="p-8">
      <PopupHeader title="Automation" id={id} />

      <form onSubmit={handleSubmit(onSubmitForm)} className="flex flex-col gap-4">
        <InputField
          name="name"
          placeholder="Name"
          label="Name"
          control={control}
          labelDirection="row"
          type="text"
          errors={formState.errors.name}
          helper={formState.errors.name?.message?.toString()}
          rules={validation('Name', ANY_CHARACTER_REGEXP)}
        />
        <Common.SelectAlt
          size="sm"
          label="Type"
          labelPosition="left"
          value={currentType}
          hideSuccessState
          options={typeOptions}
          onChange={onChangeType}
        />

        {content[String(currentType)]}

        {isShowTiming && (
          <TimingSection
            control={control}
            formState={formState}
            timingUnitOptions={timingUnitOptions}
            showTriggerBasedOn={showTriggerBasedOn}
            setTriggerBasedOn={setTriggerBasedOn}
          />
        )}

        <ControlledSelect
          control={control}
          labelDirection="row"
          options={STATUS_OPTIONS}
          placeholder="Select status..."
          label="Status"
          name="status"
          rules={validation('Status')}
        />

        <PopupFooter
          hiddenDeleteButton={!id}
          onRemove={onDeleteTrigger}
          disabled={disabledButtons}
        />
      </form>
    </div>
  );
};

export default AutomationForm;
