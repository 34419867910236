import { STATUS_OPTIONS } from 'constants/status';

import { FrontDeskRequestTypesFiltersFormProps } from './frontDeskRequestTypesFiltersForm.types';

export const defaultValues: FrontDeskRequestTypesFiltersFormProps = {
  requestType: '',
  searchKey: '',
  routeTo: '',
  uniqueId: '',
  status: { value: '', label: '' }
};

export const getFrontDeskRequestTypesFiltersValue = (key: string, val: string) => {
  let currentValue;
  switch (key) {
    case 'status':
      currentValue = STATUS_OPTIONS.find((item) => item.value === val);
      break;
    default:
      currentValue = val;
  }
  return currentValue;
};
