import { FRONTEND_DATE_FORMAT } from 'constants/dateFormat';

import { Common } from '@thecvlb/design-system';
import dayjs from 'dayjs';
import { DateFormat } from 'enums/dateFormats';
import { useFormContext } from 'react-hook-form';

const NoAvailableDoctors = () => {
  const { setValue, watch } = useFormContext();
  const state = watch('');
  const date = watch('date');

  const handleClick = (days: number) => {
    setValue('date', dayjs(date).add(days, 'day').format(FRONTEND_DATE_FORMAT));
  };

  return (
    <div className="mx-auto mb-14 flex justify-center">
      <div className="flex w-full items-center rounded-xl bg-white px-8 py-6 shadow">
        <Common.Icon name="error" className="mr-4 size-9 text-red" />
        <div>
          <p className="text-xl text-gray-700 lg:text-base 2xl:text-lg">
            There are no available doctors licensed in {state?.label}
            &nbsp;on&nbsp;
            {dayjs(date).format(DateFormat.MM_DD)}.
          </p>
          <p className="text-xl font-bold text-red lg:text-base 2xl:text-lg">
            View available appointments on
            <span
              aria-hidden="true"
              onClick={() => handleClick(1)}
              className="mx-1 cursor-pointer underline"
            >
              {dayjs(date).add(1, 'day').format(DateFormat.MM_DD)}
            </span>
            or
            <span
              aria-hidden="true"
              onClick={() => handleClick(2)}
              className="ml-1 cursor-pointer underline"
            >
              {dayjs(date).add(2, 'days').format(DateFormat.MM_DD)}
            </span>
            .
          </p>
        </div>
      </div>
    </div>
  );
};

export default NoAvailableDoctors;
