import RadioButtonCard from 'components/crossSell/RadioButtonCard';
import { getRecommendPlanLabel } from 'components/modals/PlanRecommendation/planRecommendation.settings';

import { PlanRecommendationOptionCardProps } from './planRecommendationOptionCard.types';

const PlanRecommendationOptionCard: React.FC<PlanRecommendationOptionCardProps> = ({
  plan,
  chosenSubPlanId,
  onChange,
  showDisableRecommendation
}) => {
  return (
    <RadioButtonCard
      label={getRecommendPlanLabel(plan.title, plan.description, showDisableRecommendation)}
      value={plan._id}
      color="blue"
      isSelected={!!chosenSubPlanId && chosenSubPlanId === plan._id}
      className={showDisableRecommendation ? 'order-last' : ''}
      handleChange={() => onChange?.(plan._id)}
    />
  );
};

export default PlanRecommendationOptionCard;
