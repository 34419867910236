import { RoleOptionType, RoleType } from './roleSelector.types';

export const roleOptionsList: RoleOptionType[] = [
  {
    label: 'Primary provider',
    value: RoleType.primary
  },
  {
    label: 'Provider',
    value: RoleType.PH
  },
  {
    label: 'Medical assistant',
    value: RoleType.MA
  },
  {
    label: 'Customer service',
    value: RoleType.CS
  }
];
