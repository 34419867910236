import { STATUS_OPTIONS } from 'constants/status';

import { useEffect, useState } from 'react';

import { OptionProps } from '@thecvlb/design-system/lib/src/common';
import ControlledSelect from 'components/forms/controlled/ControlledSelect';
import InputField from 'components/forms/controlled/InputField';
import { useFormContext } from 'react-hook-form';
import { useLazyGetStaffCostsQuery } from 'store/staffCosts/staffCostsSlice';
import { validation } from 'utils/helpers';
import { NUMBER_REGEXP } from 'utils/regExp';

const PaySection: React.FC<{
  defaultUserType?: string;
  isCreateStaffFlow?: boolean;
  isOptional?: boolean;
}> = ({ defaultUserType, isCreateStaffFlow, isOptional = false }) => {
  const { resetField, control, formState, watch } = useFormContext();
  const [getStaffCosts, { data }] = useLazyGetStaffCostsQuery();
  const [costsOptions, setCostsOptions] = useState<OptionProps[]>([]);

  const onChangePayAmount = (option: OptionProps) => {
    const payAmount = data?.staffCosts?.find((item) => item._id === option.value)?.payAmount;
    resetField('payAmount', { defaultValue: payAmount });
  };

  useEffect(() => {
    if (data) {
      const costs = data.staffCosts.map((costItem) => ({
        label: costItem.payType,
        value: costItem._id
      }));
      setCostsOptions(costs);
    }
  }, [data]);

  useEffect(() => {
    getStaffCosts({
      params: {
        searchKey: watch('role')?.value?.userType?.name || defaultUserType,
        status: 'active'
      }
    });
    resetField('payAmount', { defaultValue: '' });
    resetField('payType', { defaultValue: undefined });
    // The watch method must have a 'role' argument
  }, [getStaffCosts, resetField, watch('role')]);

  return (
    <>
      {isCreateStaffFlow && (
        <>
          <ControlledSelect
            control={control}
            labelDirection="row"
            options={costsOptions || []}
            placeholder="Select pay type..."
            onChange={onChangePayAmount}
            label="Pay type"
            name="payType"
            rules={validation('Pay type', null, null, isOptional)}
          />

          <InputField
            name="payAmount"
            label="Pay amount ($)"
            control={control}
            labelDirection="row"
            type="text"
            errors={formState.errors.payAmount}
            rules={validation('Pay amount', NUMBER_REGEXP, null, isOptional)}
            disabled
          />
        </>
      )}

      <ControlledSelect
        control={control}
        labelDirection="row"
        options={STATUS_OPTIONS}
        placeholder="Select status..."
        label="Status"
        name="status"
        rules={validation('Status', null, null, isOptional)}
      />
    </>
  );
};

export default PaySection;
