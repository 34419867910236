import { Common } from '@thecvlb/design-system/lib/src';
import { PricePointProps } from 'components/crossSell/PlanType/planType.types';
import { priceInfo } from 'components/patient/Billing/PlanDetails/planDetails.settings';
import { PatientProps } from 'store/patients/patients.types';
import { GetElationMedicatonsResponse } from 'store/weightManagement/weightManagement.types';

import { matchWithNotAllowedStates } from './../../../modals/PrescribeCompound/PrescribeCompoundForm/prescribeCompoundForm.settings';
import { ChoosePlanTab } from './choosePlan.types';

export const FlexCare = 'FlexCare';

export const choosePlanTabs: Common.DataItemProps[] = [
  { label: ChoosePlanTab.EveryMonth },
  { label: ChoosePlanTab.EveryThreeMonths },
  { label: ChoosePlanTab.EverySixMonths },
  { label: ChoosePlanTab.Yearly }
];

export const getPlanLabel = (
  planName: string,
  isFlexCare: boolean,
  pricePoints?: PricePointProps | null,
  isCurrentPlan?: boolean,
  isCurrentPricePoint?: boolean,
  activePeriodsTab?: ChoosePlanTab
) => {
  const subTitle = pricePoints
    ? isFlexCare
      ? `Pay as you go • ${pricePoints.planPricePointId}`
      : `$${priceInfo(pricePoints, true)} • ${pricePoints.planPricePointId}`
    : '';
  return (
    <span className="ml-1 flex flex-col gap-0.5">
      {isCurrentPlan && isCurrentPricePoint && (
        <span className="text-sm font-medium text-blue">
          Current plan{isCurrentPricePoint && activePeriodsTab ? ` • ${activePeriodsTab}` : ''}
        </span>
      )}
      <span>{planName}</span>
      <span className="text-sm font-medium text-gray">{subTitle}</span>
    </span>
  );
};

export const getPricePoint = (
  pricePoints: PricePointProps[],
  activeTab?: ChoosePlanTab,
  isWM?: boolean
) => {
  let paymentIntervalQTY = 1;
  switch (activeTab) {
    case ChoosePlanTab.EveryThreeMonths:
      paymentIntervalQTY = 3;
      break;
    case ChoosePlanTab.EverySixMonths:
      paymentIntervalQTY = 6;
      break;
    case ChoosePlanTab.Yearly:
      paymentIntervalQTY = 12;
      break;
  }
  return pricePoints?.find((pricePoint) => {
    const isMatchQTY = pricePoint.paymentInterval.qty === paymentIntervalQTY;
    return isWM
      ? isMatchQTY &&
          // should show only default Weight management plans
          ['wm-one-month-129', 'wm-three-months-129', 'wm-six-months-129'].includes(
            pricePoint.planPricePointId
          )
      : isMatchQTY;
  });
};

export const getPricePoints = (
  pricePoints: PricePointProps[],
  activeTab?: ChoosePlanTab,
  isWM?: boolean
) => {
  let paymentIntervalQTY = 1;
  switch (activeTab) {
    case ChoosePlanTab.EveryThreeMonths:
      paymentIntervalQTY = 3;
      break;
    case ChoosePlanTab.EverySixMonths:
      paymentIntervalQTY = 6;
      break;
    case ChoosePlanTab.Yearly:
      paymentIntervalQTY = 12;
      break;
  }

  if (isWM) {
    return pricePoints?.filter((pricePoint) => {
      const isMatchQTY = pricePoint.paymentInterval.qty === paymentIntervalQTY;

      // should show only default Weight management plans
      return (
        isMatchQTY &&
        [
          'wm-one-month-129',
          'wm-three-months-129',
          'wm-six-months-129',
          'bundle-semaglutide-one-month',
          'bundle-semaglutide-three-month',
          'bundle-semaglutide-six-month',
          'bundle-tirzepatide-one-month',
          'bundle-tirzepatide-three-month',
          'bundle-tirzepatide-six-month'
        ].includes(pricePoint.planPricePointId)
      );
    });
  } else {
    const matchedPricePoint = pricePoints?.find((pricePoint) => {
      const isMatchQTY = pricePoint.paymentInterval.qty === paymentIntervalQTY;
      return isMatchQTY;
    });
    return matchedPricePoint && [matchedPricePoint];
  }
};

export const preventPricePointInPatientsState = (
  pricePoint: PricePointProps,
  medications: GetElationMedicatonsResponse | undefined,
  patientInfo: PatientProps
) => {
  let disablePricePoint = false;

  const isBundlePricePoint = [
    'bundle-semaglutide-one-month',
    'bundle-semaglutide-three-month',
    'bundle-semaglutide-six-month',
    'bundle-tirzepatide-one-month',
    'bundle-tirzepatide-three-month',
    'bundle-tirzepatide-six-month'
  ].includes(pricePoint?.planPricePointId);
  if (matchWithNotAllowedStates(patientInfo.state) && isBundlePricePoint) {
    disablePricePoint = true;
  }

  const isTirzepatidePricePoint = [
    'bundle-tirzepatide-one-month',
    'bundle-tirzepatide-three-month',
    'bundle-tirzepatide-six-month'
  ].includes(pricePoint?.planPricePointId);
  let isSemaglutideInPatientsState = false;

  const isSemaglutidePricePoint = [
    'bundle-semaglutide-one-month',
    'bundle-semaglutide-three-month',
    'bundle-semaglutide-six-month'
  ].includes(pricePoint?.planPricePointId);
  let isTirzepatideInPatientsState = false;

  medications?.data.forEach((medication) => {
    const isSemaglutideMedication = medication.name.toLowerCase().includes('semaglutide');
    const isTirzepatideMedication = medication.name.toLowerCase().includes('semaglutide');
    let hasPharmacyInPatientsState = false;

    medication.pharmacies.forEach((pharmacy) => {
      if (pharmacy.states.includes(patientInfo.state)) {
        hasPharmacyInPatientsState = true;
      }
    });

    if (isSemaglutideMedication && hasPharmacyInPatientsState) {
      isSemaglutideInPatientsState = true;
    }
    if (isTirzepatideMedication && hasPharmacyInPatientsState) {
      isTirzepatideInPatientsState = true;
    }
  });

  if (isSemaglutidePricePoint && !isSemaglutideInPatientsState) {
    disablePricePoint = true;
  }
  if (isTirzepatidePricePoint && !isTirzepatideInPatientsState) {
    disablePricePoint = true;
  }

  return disablePricePoint;
};
