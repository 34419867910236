import { createColumnHelper } from '@tanstack/react-table';

import { ActionsCell, getCoverageColor } from './benefitsTable.settings';
import { BenefitTableProps } from './benefitsTable.types';
import PAStatusCell from './PAStatusCell';
import Header from '../Header';
const columnHelper = createColumnHelper<BenefitTableProps>();

export const columns = [
  columnHelper.accessor('drug_name', {
    header: () => <Header text="Medication" sortField="drug_name" />,
    cell: (info) => <span className="font-bold">{info.getValue()}</span>,
    size: 200
  }),
  columnHelper.accessor('outcome', {
    header: () => <Header text="Coverage" sortField="outcome" />,
    cell: (info) => <span className="font-medium">{getCoverageColor(info.getValue())}</span>
  }),
  columnHelper.accessor('copay', {
    header: () => <Header text="Copay" sortField="copay" />,
    cell: (info) => <span className="font-medium">{info.getValue()}</span>
  }),
  columnHelper.accessor('paRequestStatus', {
    header: () => <Header text="PA Status" sortField="paRequestStatus" />,
    cell: (info) => (
      <PAStatusCell
        requestId={info.row.original.request_id?.toString()}
        drugName={info.row.original.drug_name}
        insuranceName={info.row.original.insurance_name}
        requestStatus={info.row.original?.paRequestStatus}
      />
    )
  }),
  columnHelper.accessor('actions', {
    header: () => <span className="text-sm font-bold">Actions</span>,
    cell: (info) => {
      return <ActionsCell status={info.row.original.outcome} />;
    }
  })
];
