import { MEMBERSHIP_PROSPECT } from 'constants/prospect';
import { AUDIENCE_OPTIONS, FUTURE_TASKS_FILTERS } from 'constants/taskFilter';

import { PROBLEM_TYPE_FILTER } from 'components/modals/CustomerFeedbackFilters/customerFeedbackFiltersForm.settings';
import { URGENCY_TASK_FILTER } from 'components/modals/NotificationsFiltersForm/NotificationsFiltersForm.settings';
import {
  ISSUE_DESCRIPTION_FILTER,
  ISSUE_TYPE_FILTER
} from 'components/modals/StaffFeedbackFiltersForm/staffFeedbackFiltersForm.settings';
import { QueueTaskStatuses, QueueTaskStatusLabels } from 'enums/taskCategories';
import { OnboardingDetailsLabel, OnboardingStatus } from 'enums/taskDetailsStatus';
import cloneDeep from 'lodash/cloneDeep';
import upperFirst from 'lodash/upperFirst';
import { Option } from 'models/form.types';
import { formatCategoryLabel } from 'utils/formatCategoryLabel';

import { AppliedFilterProps } from './appliedFiltersTags.type';

export const camelToFlat = (camelCaseString: string) => {
  const splittedString = camelCaseString.replace(/[A-Z]/g, ' $&').trimStart().toLowerCase();
  return splittedString.charAt(0).toUpperCase() + splittedString.slice(1);
};

const FILTER_LABEL_MAP = {
  patientNames: 'Patient',
  assignedToNames: 'Assign to',
  searchKey: 'Search',
  doctorName: 'Physician',
  criterionField: 'Based on',
  createdAt: 'Creation Date',
  displayName: 'Appointment type',
  'audience.shortCode': 'Audience',
  'author.name': 'Author',
  alertAmount: 'Amount',
  shortCode: 'Shortcode',
  lastLogin: 'Last active',
  activeStates: 'Licensed in',
  'userType.name': 'User type',
  _id: 'Unique ID',
  triggerType: 'Type',
  triggerUnit: 'Units',
  'triggerTime.timeInHours': 'Timing (amount)',
  futureTasksRange: 'Future tasks',
  patientName: 'Name',
  phone: 'Phone',
  planId: 'Plan',
  prospectStatus: 'Status',
  category: 'Category',
  alertUnit: 'Unit',
  eventKey: 'Category',
  startDate: 'Date from',
  endDate: 'Date to',
  urgencyTask: 'Urgency',
  providerName: 'Physician',
  patientStatuses: 'Details',
  dynamicStatus: 'Details',
  tags: 'Patient tag',
  onboardingStatuses: 'Onboarding',
  patientTags: 'Patient tags',
  taskTags: 'Task tags',
  audiences: 'Audience'
};

const getFilterLabel = (filter: AppliedFilterProps) => {
  switch (filter.label) {
    case 'category':
      return Array.isArray(filter.value) && filter.value.length > 1 ? 'Categories' : 'Category';
    case 'patientNames':
      return Array.isArray(filter.value) && filter.value.length > 1 ? 'Patients' : 'Patient';
    default:
      return (FILTER_LABEL_MAP as FilterLabels)?.[filter.label];
  }
};

interface FilterLabels {
  [key: string]: unknown;
}

export function normalizeFilterLabel(filter: AppliedFilterProps) {
  // NOTE: Filters tags names should correspond with inputs labels.
  // As url query parameters differ a little we should map them before
  if ((FILTER_LABEL_MAP as FilterLabels)[filter.label]) {
    return getFilterLabel(filter);
  }
  return camelToFlat(filter.label);
}

const normalizeFilterValue = (filterValue: unknown) => {
  if (Array.isArray(filterValue)) {
    const updatedLabels = filterValue.map((value) => formatCategoryLabel(value));
    return updatedLabels.join(', ');
  }
  return typeof filterValue === 'string'
    ? upperFirst(formatCategoryLabel(filterValue))
    : filterValue;
};

export const presentFilter = (filter: AppliedFilterProps) => {
  const label = normalizeFilterLabel(filter);
  const value = normalizeFilterValue(filter.value);
  return `${label}: ${value}`;
};

const normalizeDateRangeFilter = (
  appliedFilters: AppliedFilterProps[],
  startLabel: string,
  endLabel: string,
  normalizedLabel: string
): AppliedFilterProps | undefined => {
  const startFilterIndex = appliedFilters.findIndex((filter) => filter.label === startLabel);
  const endFilterIndex = appliedFilters.findIndex((filter) => filter.label === endLabel);

  if (startFilterIndex !== -1) {
    const value =
      endFilterIndex !== -1
        ? `${appliedFilters[startFilterIndex].value} ${appliedFilters[endFilterIndex].value}`
        : appliedFilters[startFilterIndex].value;

    return {
      label: normalizedLabel,
      value
    };
  }

  return undefined;
};

const normalizeUnitsFilter = (
  // NOTE: We have couple of inputs on Filters forms called Units.
  // It is one select input that produces 2 query parameters
  // We need parse those query parameters and to pass default value to that select input -
  // we should first normilize data so value we pass is exactly the same as one of ALERT_UNIT_OPTIONS options passed to input
  // So value we return here is label from one of ALERT_UNIT_OPTIONS

  appliedFilters: AppliedFilterProps[],
  unitLabel: string,
  orderLabel: string,
  normalizedLabel: string
): [AppliedFilterProps, number] | [null, number] => {
  const unitFilterIndex = appliedFilters.findIndex((filter) => filter.label === unitLabel);
  const orderFilterIndex = appliedFilters.findIndex((filter) => filter.label === orderLabel);

  if (unitFilterIndex === -1) return [null, unitFilterIndex];

  const hours =
    appliedFilters[unitFilterIndex].value === 'hrs' ||
    appliedFilters[unitFilterIndex].value === 'hours'
      ? 'Hours'
      : '';
  const minutes =
    appliedFilters[unitFilterIndex].value === 'min' ||
    appliedFilters[unitFilterIndex].value === 'minutes'
      ? 'Mins'
      : '';
  const value = `${hours || minutes} (${appliedFilters[orderFilterIndex].value})`;

  return [
    {
      label: normalizedLabel,
      value
    },
    unitFilterIndex
  ];
};

export const normalizeAppliedFilters = (
  appliedFilters: AppliedFilterProps[],
  tagsOptions?: Option[],
  teamsOptions?: Option[],
  taskTagsOptions?: Option[]
) => {
  const copiedFilters = cloneDeep(appliedFilters);

  const updateMultiOptionFilter = (filterLabel: string, option?: Option[]) => {
    const filterIndex = copiedFilters.findIndex((filter) => filter.label === filterLabel);
    if (filterIndex !== -1) {
      const filterValue = copiedFilters[filterIndex];

      if (filterValue?.value && Array.isArray(filterValue.value)) {
        const result = option?.filter((item) => filterValue.value.includes(item.value));
        copiedFilters[filterIndex] = {
          label: filterLabel,
          value: result?.map((item) => item.label) ?? ''
        };
      } else if (typeof filterValue?.value === 'string') {
        const result = option?.find((item) => filterValue.value === item.value);
        copiedFilters[filterIndex] = {
          label: filterLabel,
          value: result?.label ?? ''
        };
      }
    }
  };

  const updateSingleOptionFilter = (filterLabel: string, option: Option[]) => {
    const filterIndex = copiedFilters.findIndex((filter) => filter.label === filterLabel);

    if (filterIndex !== -1) {
      const filterValue = option.find(
        (item) => item.value.toString() === copiedFilters[filterIndex].value
      );
      copiedFilters[filterIndex] = {
        label: filterLabel,
        value: filterValue?.label ?? ''
      };
    }
  };

  const createFilterValue = normalizeDateRangeFilter(
    copiedFilters,
    'startCreateDate',
    'endCreateDate',
    'createdDate'
  );
  if (createFilterValue) {
    copiedFilters.push(createFilterValue);
  }

  const dueFilterValue = normalizeDateRangeFilter(
    copiedFilters,
    'startDueDate',
    'endDueDate',
    'dueDate'
  );
  if (dueFilterValue) {
    copiedFilters.push(dueFilterValue);
  }

  const [unitFilterValue, unitFilterIndex] = normalizeUnitsFilter(
    copiedFilters,
    'alertUnit',
    'occurenceOrder',
    'alertUnit'
  );
  if (unitFilterValue) {
    copiedFilters[unitFilterIndex] = unitFilterValue;
  }

  const [triggerUnitFilterValue, triggerUnitFilterIndex] = normalizeUnitsFilter(
    copiedFilters,
    'triggerUnit',
    'triggerTime.occurenceOrder',
    'triggerUnit'
  );
  if (triggerUnitFilterValue) {
    copiedFilters[triggerUnitFilterIndex] = triggerUnitFilterValue;
  }

  const futureTaskRangeFilter = copiedFilters.findIndex(
    (filter) => filter.label === 'futureTasksRange'
  );
  if (futureTaskRangeFilter !== -1) {
    const appliedFutureTaskOption = FUTURE_TASKS_FILTERS.find(
      (item) => item.value === copiedFilters[futureTaskRangeFilter].value
    );
    copiedFilters[futureTaskRangeFilter] = {
      label: 'futureTasksRange',
      value: appliedFutureTaskOption?.label ?? ''
    };
  }

  const patientStatusesFilter = copiedFilters.findIndex(
    (filter) => filter.label === 'patientStatuses'
  );
  if (patientStatusesFilter !== -1) {
    const patientStatusesOptions = copiedFilters[patientStatusesFilter].value
      .toString()
      .split(',')
      .map((filterValue) => QueueTaskStatusLabels[filterValue as QueueTaskStatuses])
      .join(', ');
    copiedFilters[patientStatusesFilter] = {
      label: 'patientStatuses',
      value: patientStatusesOptions ?? ''
    };
  }

  const onboardingStatuses = copiedFilters.findIndex(
    (filter) => filter.label === 'onboardingStatuses'
  );
  if (onboardingStatuses !== -1) {
    const onboardingFiltersLabels = {
      ...OnboardingDetailsLabel,
      [OnboardingStatus.INSURANCE_COVERED]: 'Insurance: Complete - Covered',
      [OnboardingStatus.INSURANCE_NOT_COVERED]: 'Insurance: Complete - Not covered',
      [OnboardingStatus.INSURANCE_PA_REQUIRED]: 'Insurance: PA required',
      [OnboardingStatus.INSURANCE_PA_REQUESTED]: 'Insurance: PA requested',
      [OnboardingStatus.INSURANCE_PLAN_INACTIVE]: 'Insurance: Plan inactive',
      [OnboardingStatus.INSURANCE_WAITING_BENEFITS]: 'Insurance: Waiting for benefits'
    };
    const onboardingStatusesOptions = copiedFilters[onboardingStatuses].value
      .toString()
      .split(',')
      .map((filterValue) => onboardingFiltersLabels[filterValue as OnboardingStatus])
      .join(', ');
    copiedFilters[onboardingStatuses] = {
      label: 'onboardingStatuses',
      value: onboardingStatusesOptions ?? ''
    };
  }

  const dynamicStatusFilter = copiedFilters.findIndex((filter) => filter.label === 'dynamicStatus');
  if (dynamicStatusFilter !== -1) {
    const dynamicStatusOptions =
      QueueTaskStatusLabels[
        copiedFilters[dynamicStatusFilter].value.toString() as QueueTaskStatuses
      ];
    copiedFilters[dynamicStatusFilter] = {
      label: 'dynamicStatus',
      value: dynamicStatusOptions ?? ''
    };
  }

  const issueDescriptionsFilter = copiedFilters.findIndex(
    (filter) => filter.label === 'issueDescriptions'
  );
  if (issueDescriptionsFilter !== -1) {
    const issueDescriptionsValue = copiedFilters[issueDescriptionsFilter].value;
    const issueDescriptionsOptions = (
      Array.isArray(issueDescriptionsValue) ? issueDescriptionsValue : [issueDescriptionsValue]
    ).map(
      (issueDescription) =>
        ISSUE_DESCRIPTION_FILTER.find((issue) => issue.value === issueDescription)?.label || ''
    );
    copiedFilters[issueDescriptionsFilter] = {
      label: 'issueDescriptions',
      value: issueDescriptionsOptions ?? ''
    };
  }

  updateSingleOptionFilter('problemType', PROBLEM_TYPE_FILTER);
  updateSingleOptionFilter('issueType', ISSUE_TYPE_FILTER);
  updateSingleOptionFilter('planId', MEMBERSHIP_PROSPECT);
  updateSingleOptionFilter('urgencyTask', URGENCY_TASK_FILTER);

  updateMultiOptionFilter('tags', tagsOptions);
  updateMultiOptionFilter('patientTags', tagsOptions);
  updateMultiOptionFilter('teams', teamsOptions);
  updateMultiOptionFilter('audiences', AUDIENCE_OPTIONS);
  updateMultiOptionFilter('taskTags', taskTagsOptions);

  const result = copiedFilters.filter(
    (item) =>
      ![
        'startCreateDate',
        'endCreateDate',
        'startDueDate',
        'endDueDate',
        'occurenceOrder',
        'triggerTime.occurenceOrder'
      ].includes(item.label)
  );

  return result;
};
