import { Common } from '@thecvlb/design-system';
import NewStaffMember from 'components/modals/StaffMember';
import { useDispatch } from 'react-redux';
import { openModal } from 'store/modal/modalSlice';

const EditCell: React.FC<{ id: string }> = ({ id }) => {
  const dispatch = useDispatch();

  return (
    <div className="flex gap-1">
      <button
        data-testid="pencil_icon"
        onClick={() =>
          dispatch(
            openModal({
              modalContent: <NewStaffMember staffId={id} />,
              hideClose: true,
              size: 'xl'
            })
          )
        }
      >
        <Common.Icon name="pencil" className="size-4 hover:text-primary-500" />
      </button>
    </div>
  );
};

export default EditCell;
