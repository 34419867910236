import { useEffect, useState } from 'react';

import { Common } from '@thecvlb/design-system';
import NotificationsList from 'components/common/LoggedUserHeader/Notifications/NotificationsList';
import AppliedFiltersTags from 'components/filters/AppliedFiltersTags/AppliedFiltersTags';
import SearchForm from 'components/filters/SearchForm';
import { NotificationTab } from 'enums/notifications';
import { useAppSelector } from 'hooks/redux';
import queryString from 'query-string';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  selectNotifications,
  useClearAllNotificationsMutation,
  useMarkSeenNotificationsMutation
} from 'store/notifications/notificationsSlice';

const ActivityFeed = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const activeTabParam = searchParams.get('active-tab') as NotificationTab;
  const [clearAllNotifications, { isLoading }] = useClearAllNotificationsMutation();
  const [activeTab, setActiveTab] = useState<NotificationTab>(activeTabParam);
  const { unreadNotificationsCount } = useAppSelector(selectNotifications);
  const [markSeenNotifications] = useMarkSeenNotificationsMutation();

  const tabs: Common.DataItemProps[] = [
    {
      label: NotificationTab.Inbox,
      count: unreadNotificationsCount,
      badge: !!unreadNotificationsCount
    },
    { label: NotificationTab.Archived }
  ];

  const handleClearNotifications = () => {
    clearAllNotifications();
  };

  const handleActiveTab = (tab: Common.DataItemProps) => {
    setActiveTab(tab.label as NotificationTab);
    searchParams.delete('active-tab');
    const queryParams = Object.fromEntries([...searchParams]) || {};
    navigate(
      { search: queryString.stringify({ 'active-tab': tab.label, ...queryParams }) },
      { replace: true }
    );
  };

  useEffect(() => {
    if (!!unreadNotificationsCount && activeTab === NotificationTab.Inbox) {
      markSeenNotifications();
    }
  }, [activeTab, markSeenNotifications, unreadNotificationsCount]);

  useEffect(() => {
    if (activeTabParam) setActiveTab(activeTabParam as NotificationTab);
  }, [activeTabParam]);

  const isInboxTab = activeTab === NotificationTab.Inbox;

  return (
    <div className="rounded-md bg-white pb-6 shadow">
      <div className="pl-4 pt-4">
        <AppliedFiltersTags />
      </div>
      <div className="sticky top-0 z-10 flex items-center justify-between rounded-md bg-white px-4">
        <Common.Tabs
          onChange={handleActiveTab}
          data={tabs}
          defaultSelected={[activeTab]}
          type="line"
        />
        <div className="flex h-min gap-2">
          {isInboxTab && (
            <Common.Button
              dataTestId="clear_all_btn"
              onClick={handleClearNotifications}
              color="white"
              size="sm"
              disabled={isLoading}
              isLoading={isLoading}
            >
              Archive all
            </Common.Button>
          )}
          <SearchForm showSearchBar />
        </div>
      </div>
      <NotificationsList currentTab={activeTab} />
    </div>
  );
};

export default ActivityFeed;
