import { notifySuccess } from 'components/common/Toast/Toast';
import { useRetryBulkEdit } from 'hooks/bulkEdits/useRetryBulkEdit';
import { useAppDispatch } from 'hooks/redux';
import { resetBulkEdits } from 'store/bulkEdit/bulkEditSlice';
import { closeModal } from 'store/modal/modalSlice';

import Container from './Container';
import { UpdateFailedProps } from './updateFailed.types';

const UpdateFailed = ({ requestBody }: UpdateFailedProps) => {
  const dispatch = useAppDispatch();
  const { retry, editResult, isLoading } = useRetryBulkEdit();
  const failedItems = editResult?.data?.failed;
  const failedCount = failedItems?.length ?? 0;
  const updatedItems = editResult?.data?.updated;
  const updatedCount = updatedItems?.length ?? 0;
  const allCount = failedCount + updatedCount;

  const handleOnRetry = () => {
    retry(requestBody)
      .unwrap()
      .then((res) => {
        if (!res.data?.failed.length) {
          notifySuccess(res.message);
          dispatch(resetBulkEdits());
          dispatch(closeModal());
        }
      });
  };

  const failedComponent = (
    <ul className="mb-4 list-inside list-disc">
      {failedItems?.map((item) => (
        <li key={item.userId} className="font-bold">
          {item.username || item.patientName}
        </li>
      ))}
    </ul>
  );

  const updatedComponent = (
    <ul className="mb-4 list-inside list-disc">
      {updatedItems?.map((item) => <li key={item.userId}>{item.username || item.patientName}</li>)}
    </ul>
  );

  return (
    <Container
      handleOnRetry={handleOnRetry}
      failedCount={failedCount}
      allCount={allCount}
      failedComponent={failedComponent}
      updatedComponent={updatedComponent}
      isLoading={isLoading}
    />
  );
};

export default UpdateFailed;
