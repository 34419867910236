import { useState } from 'react';

import { createSelector } from '@reduxjs/toolkit';
import { skipToken } from '@reduxjs/toolkit/query';
import { Common } from '@thecvlb/design-system';
import { notifySuccess } from 'components/common/Toast/Toast';
import dayjs from 'dayjs';
import { DateFormat } from 'enums/dateFormats';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import isEmpty from 'lodash/isEmpty';
import { EventTypes, RepeatOptions } from 'models/event.types';
import { EventDetailsProps } from 'store/calendar/calendar.types';
import {
  selectCalendarTimezone,
  selectEventId,
  selectEventInstanceId,
  setEventId,
  setEventInstanceId,
  useCreateEventMutation,
  useEditEventMutation,
  useGetEventQuery
} from 'store/calendar/calendarSlice';
import { closeModal } from 'store/modal/modalSlice';

import CreateEventForm from '../CreateEventForm';
import { handleEventsConflict } from '../CreateEventForm/createEvent.settings';
import EditEvent from '../EditEvent';
import { getDateTime, getDaysOfWeek } from '../manageAvailability.settings';
import { FormValues } from '../manageAvailability.types';

const selectTimeOffState = createSelector(
  [selectCalendarTimezone, selectEventId, selectEventInstanceId],
  (calendarTimezone, eventId, eventInstanceId) => ({
    calendarTimezone,
    eventId: eventId || eventInstanceId
  })
);

const AddTimeOff: React.FC<{ userId: string }> = ({ userId }) => {
  const { calendarTimezone, eventId } = useAppSelector(selectTimeOffState);
  const dispatch = useAppDispatch();

  const { data: eventDetails } = useGetEventQuery(eventId ? { eventId } : skipToken);

  const [createEvent, { isLoading }] = useCreateEventMutation();
  const [editEvent] = useEditEventMutation();

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isEventRecurringChanged, setIsEventRecurringChanged] = useState(false);
  const [eventData, setEventData] = useState<FormValues>();

  const handleClose = () => {
    setIsEditModalOpen(false);
  };

  const openEditModal = (data: FormValues) => {
    setEventData(data);
    setIsEditModalOpen(true);
  };

  const handleCreateEvent = ({ body }: { body: Partial<EventDetailsProps> }) => {
    createEvent(body)
      .unwrap()
      .then((response) => {
        notifySuccess(response.message || 'Time off has been added');
        dispatch(closeModal());
      })
      .catch((error) => handleEventsConflict(error, dispatch, body, calendarTimezone));
  };

  const createRequestBody = (
    data: FormValues,
    startDay: Date,
    event: { start: string; end: string }
  ) => {
    const isDayOff = data.eventTypes.find((type) => type.value === EventTypes.TIME_OFF);

    return {
      start: {
        dateTime: isDayOff
          ? dayjs(startDay).startOf(DateFormat.D).format(DateFormat.YYYY_MM_DD_HH_mm_ss)
          : getDateTime(startDay, event.start, DateFormat.YYYY_MM_DD_HH_mm_ss),
        timeZone: calendarTimezone
      },
      end: {
        dateTime: isDayOff
          ? dayjs(startDay)
              .add(1, 'day')
              .startOf(DateFormat.D)
              .format(DateFormat.YYYY_MM_DD_HH_mm_ss)
          : getDateTime(startDay, event.end, DateFormat.YYYY_MM_DD_HH_mm_ss),
        timeZone: calendarTimezone
      },
      type: data.eventTypes[0].value === EventTypes.BREAK ? EventTypes.BREAK : EventTypes.TIME_OFF,
      participants: [
        {
          userId
        }
      ]
    };
  };

  const handleRecurringChange = (
    data: FormValues,
    startDay: Date,
    event: { start: string; end: string }
  ) => {
    const isUntil = data.ends?.option === 'on' && data.ends.date;
    const until = dayjs
      .tz(
        `${dayjs(data.ends?.date).format(DateFormat.YYYY_MM_DD)} ${getDateTime(
          startDay,
          event.start,
          DateFormat.HH_mm_ss
        )}`,
        calendarTimezone
      )
      .toISOString();

    return {
      type: data.repeats === RepeatOptions.BI_WEEKLY ? RepeatOptions.WEEKLY : data.repeats,
      daysOfWeek: getDaysOfWeek(data.repeats, startDay),
      interval: data.repeats === RepeatOptions.BI_WEEKLY ? 2 : 1,
      ...(isUntil && {
        until
      }),
      ...(data.ends?.count && { count: data.ends?.count })
    };
  };

  const handleSubmit = (data: FormValues) => {
    const isRecurringEvent = !isEmpty(eventDetails?.recurring);
    const isRecurringOptionSelected = data.repeats !== RepeatOptions.DOES_NOT_REPEAT;
    const isEventDoesNotRepeatSelected = data.repeats === RepeatOptions.DOES_NOT_REPEAT;

    /**
     * @description
     * Open edit modal only when user is editing recurring event.
     * When user change event from non-recurring to recurring or vice versa we don't open edit modal
     */
    if (isRecurringEvent && !isEventDoesNotRepeatSelected && eventId) {
      openEditModal(data);
    } else {
      data.shiftTime.forEach((event, eventIndex) => {
        if (event.start && event.end && data.shiftStart?.day) {
          /**
           * @description
           * Check if it's the first time shift in the array .
           * When user change event and it's not the first time shift we should create a new event
           */
          const isFirstTimeShift = eventIndex === 0;
          const reqBody: Partial<EventDetailsProps> = createRequestBody(
            data,
            data.shiftStart.day,
            event
          );

          if (isRecurringOptionSelected) {
            reqBody.recurring = handleRecurringChange(data, data.shiftStart.day, event);
          } else if (isEventDoesNotRepeatSelected && eventId && isFirstTimeShift) {
            reqBody.recurring = null;
          }

          if (eventId) {
            if (!isFirstTimeShift) {
              handleCreateEvent({ body: reqBody });
              return;
            }
            editEvent({ eventId, body: reqBody })
              .unwrap()
              .then(() => {
                notifySuccess('Time off has been edited');
                dispatch(setEventId(''));
                dispatch(setEventInstanceId(''));
                dispatch(closeModal());
              })
              .catch((error) => handleEventsConflict(error, dispatch, reqBody, calendarTimezone));
          } else {
            handleCreateEvent({ body: reqBody });
          }
        }
      });
    }
  };

  return (
    <>
      <Common.Modal isOpen={isEditModalOpen} size="sm" zIndex={120} hideCloseButton>
        <EditEvent
          isRecurringEventChanged={isEventRecurringChanged}
          eventData={eventData}
          eventId={eventId}
          type={EventTypes.TIME_OFF}
          onClose={handleClose}
        />
      </Common.Modal>

      <CreateEventForm
        setIsEventRecurringChanged={setIsEventRecurringChanged}
        eventType={EventTypes.TIME_OFF}
        saveData={handleSubmit}
        isLoading={isLoading}
      />
    </>
  );
};

export default AddTimeOff;
