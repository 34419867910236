import { CareChannelListProps } from 'contexts/MessagesContext/messagesContext.types';
import dayjs from 'dayjs';
import { DateFormat } from 'enums/dateFormats';

export const getMessageTime = (channel: CareChannelListProps) => {
  return dayjs(channel.latestMessageDate).format(DateFormat.h_mm_a);
};

export const getLatestMessage = (channel: CareChannelListProps) => {
  const generalHealthDefaultMessage =
    'Ask your dedicated medical team health-related questions and channel here.';
  const otherChannelsDefaultMessage = `Use this space for all communications relating to ${channel?.channelTitle?.toLowerCase()}.`;

  const isGeneralHealth = channel?.channelTitle?.toLowerCase().includes('general health');
  const defaultMessage = isGeneralHealth
    ? generalHealthDefaultMessage
    : otherChannelsDefaultMessage;
  return channel.latestMessage ? channel.latestMessage : defaultMessage;
};

export const parseMessageToChannel = (message = '') => {
  return message.replace(/<[^>]*>?/gm, '');
};
