import { HealthMetricTypes } from 'enums/chart';

import { BodyMassIndexProps } from './bodyMassIndex.types';
import HealthMetric from '../../HealthMetric/HealthMetric';

const BodyMassIndex: React.FC<BodyMassIndexProps> = ({ bmi }) => {
  return (
    <HealthMetric type={HealthMetricTypes.BMI} value={bmi} metricSubtitle={bmi.toString()}>
      <p className="mb-4">
        Body weight is automatically calculated based on your height and weight.
      </p>
    </HealthMetric>
  );
};
export default BodyMassIndex;
