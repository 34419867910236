import { useState } from 'react';

import { Common } from '@thecvlb/design-system/lib/src';
import classNames from 'classnames';

import { DetailsIconProps } from './detailsIcon.types';
import { getIconClassName, getTooltipStatusColor } from '../onboardingDetails.setings';
import Tooltip from '../Tooltip';

const DetailsIcon: React.FC<DetailsIconProps> = ({ statusDetails, size = 'sm' }) => {
  const [isIconHovered, setIsIconHovered] = useState(false);

  const iconFocusProps = {
    onMouseOver: () => {
      setIsIconHovered(true);
    },
    onMouseLeave: () => {
      setIsIconHovered(false);
    }
  };

  const statusValueClassNames = classNames(
    'font-bold',
    getTooltipStatusColor(statusDetails.status)
  );

  return (
    <div data-testid="details-icon" className="relative" {...iconFocusProps}>
      <Common.Icon
        name={statusDetails.iconName}
        className={getIconClassName(statusDetails.status, size)}
      />
      {isIconHovered && (
        <Tooltip>
          {`${statusDetails.label}: `}
          <span className={statusValueClassNames}>{statusDetails.value}</span>
        </Tooltip>
      )}
    </div>
  );
};

export default DetailsIcon;
