export enum PathName {
  Login = '/',
  Dashboard = '/dashboard',
  Admin = '/dashboard/admin',
  AgingConfiguration = '/dashboard/admin/aging-configuration',
  SystemSettings = '/dashboard/admin/system-settings',
  Calendar = '/dashboard/appointments',
  Availability = '/dashboard/appointments/availability',
  UpdateArticle = '/dashboard/articles/update',
  CreateArticle = '/dashboard/articles/create',
  Articles = '/dashboard/articles',
  Patients = '/dashboard/patients',
  Prospect = '/dashboard/patients/prospect',
  Patient = '/dashboard/patient',
  AdminReports = '/dashboard/reports/admin',
  MedicalAssistantReports = '/dashboard/reports/medical-assistant',
  PhysicianReports = '/dashboard/reports/physician',
  Tasks = '/dashboard/tasks',
  ActivityFeed = '/dashboard/activity-feed',
  AppointmentTypes = '/dashboard/admin/appointment-types',
  PhysicianRequestTypes = '/dashboard/physician-request-types',
  FrontDeskRequestTypes = '/dashboard/admin/front-desk-request-types',
  PatientMessageTemplates = '/dashboard/admin/patient-message-templates',
  RequestMessageTemplates = '/dashboard/admin/request-message-templates',
  InternalNoteTemplates = '/dashboard/admin/internal-note-templates',
  Staffs = '/dashboard/admin/manage-staff',
  Teams = '/dashboard/admin/manage-teams',
  ManageRoles = '/dashboard/admin/manage-roles',
  ManageRole = '/dashboard/admin/manage-role',
  InternalAutomations = '/dashboard/admin/internal-automations',
  PatientAutomations = '/dashboard/admin/patient-automation',
  BillingConfiguration = '/dashboard/admin/billing-config',
  StaffCosts = '/dashboard/admin/staff-costs',
  VideoCall = '/videoCall',
  CrossSell = '/cross-sell',
  NotFound = '*',
  Orders = '/dashboard/orders',
  Reviews = '/dashboard/admin/reviews',
  StaffFeedback = '/dashboard/admin/staff-feedback',
  CustomerFeedback = '/dashboard/admin/customer-feedback'
}
