/**
 * Supported file formats
 * @description - Checks if the file is valid
 * allowedFileFormats - Array of allowed file formats
 * @link - https://www.notion.so/lifemd-inc/Supported-file-types-2b6f1326825047de8b7157a88ad266a6?pvs=4#49b5b6c334e840d9903ae0583a651fa4
 */
export const fileValidator = (file: File) => {
  const { allowedFileFormats, fileSizeMBLimit } = {
    allowedFileFormats: [
      'doc',
      'docx',
      'application/msword',
      'application/vnd.ms-excel',
      'application/vnd.ms-powerpoint',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/vnd.openxmlformats-officedocument.presentationml.presentation',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'text/plain',
      'application/pdf',
      'image/png',
      'image/jpg',
      'image/jpeg',
      'image/gif',
      'image/webp',
      'image/heic',
      'video/quicktime',
      'video/mp4',
      'video/mov',
      'video/webm',
      'video/x-m4v'
    ],
    fileSizeMBLimit: 20
  };
  const { size, type } = file;
  const result = {
    isValidFile: false,
    errVal: ''
  };
  if (length === 0) {
    return result;
  } else if (!allowedFileFormats.includes(type)) {
    result.errVal = 'File format is invalid';
  } else if (size / 1024 / 1024 > fileSizeMBLimit) {
    result.errVal = `File size exceeded the limit of ${fileSizeMBLimit}MB`;
  } else {
    result.isValidFile = true;
  }
  return result;
};
