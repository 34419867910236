import { ReportsFilterData } from 'constants/filters';

import React, { useEffect, useState } from 'react';

import { Common } from '@thecvlb/design-system/lib/src';
import FilterButtons from 'components/filters/FilterTabs';
import { DashboardCard } from 'components/reports/DashboardCard';
import dayjs from 'dayjs';
import { DateFormat } from 'enums/dateFormats';
import { PathName } from 'enums/pathName';
import fileDownload from 'js-file-download';
import queryString from 'query-string';
import Skeleton from 'react-loading-skeleton';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  AppointmentsCardProps,
  MessagesCardProps,
  PatientsCardProps,
  PlanBreakdownCardProps
} from 'store/adminReports/adminReports.types';
import {
  useLazyDownloadAdminReportsQuery,
  useLazyGetFrontDeskOverviewByUserQuery,
  useLazyGetFrontDeskOverviewQuery,
  useLazyGetListNurseUtilizationDetailsQuery,
  useLazyGetListPhysicianUtilizationDetailsQuery,
  useLazyGetListPlatformUtilizationDetailsQuery,
  useLazyGetMedicalAssistantOverviewByAgentQuery,
  useLazyGetMedicalAssistantOverviewQuery
} from 'store/adminReports/adminReportsSlice';

import { getDefaultReportsParams, tableNames } from './adminReports.settings';
import AdminReportsTables from './AdminReportsTables/AdminReportsTables';

const AdminReports: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [
    getListPlatformUtilizationDetails,
    { data: platformUtilizationDetails, isFetching: platformUtilizationDetailsFetching }
  ] = useLazyGetListPlatformUtilizationDetailsQuery();
  const [
    getPhysicianUtilizationDetails,
    { data: physicianUtilizationDetails, isFetching: physicianUtilizationDetailsFetching }
  ] = useLazyGetListPhysicianUtilizationDetailsQuery();
  const [
    getListNurseUtilizationDetails,
    { data: nurseUtilizationDetails, isFetching: nurseUtilizationDetailsFetching }
  ] = useLazyGetListNurseUtilizationDetailsQuery();
  const [
    getMedicalAssistantOverviewByAgent,
    { data: medicalAssistantOverviewByAgent, isFetching: medicalAssistantOverviewByAgentFetching }
  ] = useLazyGetMedicalAssistantOverviewByAgentQuery();
  const [
    getMedicalAssistantOverview,
    { data: medicalAssistantOverview, isFetching: medicalAssistantOverviewFetching }
  ] = useLazyGetMedicalAssistantOverviewQuery();
  const [getFrontDeskOverview, { data: frontDeskOverview, isFetching: frontDeskOverviewFetching }] =
    useLazyGetFrontDeskOverviewQuery();
  const [
    getFrontDeskOverviewByUser,
    { data: frontDeskOverviewByUser, isFetching: frontDeskOverviewByUserFetching }
  ] = useLazyGetFrontDeskOverviewByUserQuery();
  const [downloadAdminReports, { isFetching: isDownload }] = useLazyDownloadAdminReportsQuery();
  const [loadData, setLoadData] = useState<Record<string, boolean | undefined>>({
    physicianOverview: false,
    npOverview: false,
    maOverview: false,
    maOverviewAgent: false,
    frontdesk: false,
    frontdeskUser: false
  });

  const isFetchingReports =
    platformUtilizationDetailsFetching ||
    physicianUtilizationDetailsFetching ||
    nurseUtilizationDetailsFetching ||
    medicalAssistantOverviewByAgentFetching ||
    medicalAssistantOverviewFetching ||
    frontDeskOverviewFetching;

  const isDisabledDownload = isFetchingReports || isDownload;

  useEffect(() => {
    if (location.search) {
      const params = queryString.parse(location.search);
      getListPlatformUtilizationDetails(
        {
          params: queryString.stringify({
            pageNo: params['plf-utlzn'],
            limit: 10,
            startDate: params.startDate,
            endDate: params.endDate,
            sortField: params['plf-utlzn' + '--sf'],
            sortOrder: params['plf-utlzn' + '--so']
          })
        },
        true
      );
      loadData.physicianOverview &&
        getPhysicianUtilizationDetails(
          {
            params: queryString.stringify({
              pageNo: params['phy-utlzn'],
              limit: 10,
              startDate: params.startDate,
              endDate: params.endDate,
              sortField: params['phy-utlzn' + '--sf'],
              sortOrder: params['phy-utlzn' + '--so']
            })
          },
          true
        );
      loadData.npOverview &&
        getListNurseUtilizationDetails(
          {
            params: queryString.stringify({
              pageNo: params['nrs-utlzn'],
              limit: 10,
              startDate: params.startDate,
              endDate: params.endDate,
              sortField: params['nrs-utlzn' + '--sf'],
              sortOrder: params['nrs-utlzn' + '--so']
            })
          },
          true
        );
      loadData.maOverview &&
        getMedicalAssistantOverview(
          {
            params: queryString.stringify({
              pageNo: params['ma-ovrw'],
              limit: 10,
              startDate: params.startDate,
              endDate: params.endDate,
              sortField: params['ma-ovrw' + '--sf'],
              sortOrder: params['ma-ovrw' + '--so']
            })
          },
          true
        );
      loadData.maOverviewAgent &&
        getMedicalAssistantOverviewByAgent(
          {
            params: queryString.stringify({
              pageNo: params['ma-ovrw-agnt'],
              limit: 10,
              startDate: params.startDate,
              endDate: params.endDate,
              sortField: params['ma-ovrw-agnt' + '--sf'],
              sortOrder: params['ma-ovrw-agnt' + '--so']
            })
          },
          true
        );
      loadData.frontdesk &&
        getFrontDeskOverview(
          {
            params: queryString.stringify({
              pageNo: params['frnt-ovrw'],
              limit: 10,
              startDate: params.startDate,
              endDate: params.endDate,
              sortField: params['frnt-ovrw' + '--sf'],
              sortOrder: params['frnt-ovrw' + '--so']
            })
          },
          true
        );
      loadData.frontdeskUser &&
        getFrontDeskOverviewByUser(
          {
            params: queryString.stringify({
              pageNo: params['frnt-by-usr'],
              limit: 10,
              startDate: params.startDate,
              endDate: params.endDate,
              sortField: params['frnt-by-usr' + '--sf'],
              sortOrder: params['frnt-by-usr' + '--so']
            })
          },
          true
        );
    } else {
      let queryParams = getDefaultReportsParams();
      tableNames.map((item) => {
        queryParams = { ...queryParams, [item]: 0 };
      });
      navigate(
        {
          pathname: PathName.AdminReports,
          search: queryString.stringify(queryParams)
        },
        { replace: true }
      );
    }
  }, [
    loadData,
    navigate,
    location.search,
    getListPlatformUtilizationDetails,
    getPhysicianUtilizationDetails,
    getListNurseUtilizationDetails,
    getMedicalAssistantOverview,
    getMedicalAssistantOverviewByAgent,
    getFrontDeskOverview,
    getFrontDeskOverviewByUser
  ]);

  const downloadFile = () => {
    if (location.search) {
      const params = queryString.parse(location.search);
      downloadAdminReports({
        params: queryString.stringify({ startDate: params.startDate, endDate: params.endDate })
      })
        .unwrap()
        .then((res) => {
          fileDownload(
            res,
            `lifemd-care-report-${dayjs().format(DateFormat.YYYY_MMM_DD_hh_mm_A)}.csv`
          );
        });
    }
  };

  const changeToDashboardFormat = (
    data:
      | PatientsCardProps
      | AppointmentsCardProps
      | MessagesCardProps
      | PlanBreakdownCardProps
      | undefined
  ) => {
    if (data) {
      return Object.keys(data).map((item) => ({
        key: item,
        value: data[item as keyof typeof data]
      }));
    } else return undefined;
  };

  return (
    <React.Fragment>
      <div className="my-5 flex items-center justify-between">
        <FilterButtons filterData={ReportsFilterData} queryParam="dateType" />
        <Common.Button
          disabled={isDisabledDownload}
          preIcon="download"
          style="pill"
          size="sm"
          color="green-alt"
          onClick={downloadFile}
        >
          Download
        </Common.Button>
      </div>
      <div className="mb-5 flex justify-between gap-5">
        {platformUtilizationDetailsFetching ? (
          <Skeleton
            height={'138px'}
            className="rounded-xl"
            containerClassName="flex-col gap-4 w-full"
          />
        ) : (
          <DashboardCard
            title="Patients"
            text={changeToDashboardFormat(platformUtilizationDetails?.data.Patients)}
            color="primary"
            isInfoRequired={true}
          />
        )}
        {platformUtilizationDetailsFetching ? (
          <Skeleton
            height={'138px'}
            className="rounded-xl"
            containerClassName="flex-col gap-4 w-full"
          />
        ) : (
          <DashboardCard
            title="Plan Breakdown"
            text={changeToDashboardFormat(platformUtilizationDetails?.data['Plan breakdown'])}
            color="secondary-dark"
            isInfoRequired={true}
          />
        )}
      </div>
      <div className="mb-5 flex justify-between gap-5">
        {platformUtilizationDetailsFetching ? (
          <Skeleton
            height={'138px'}
            className="rounded-xl"
            containerClassName="flex-col gap-4 w-full"
          />
        ) : (
          <DashboardCard
            title="Appointments"
            text={changeToDashboardFormat(platformUtilizationDetails?.data.Appointments)}
            color="secondary-light"
            isInfoRequired={true}
          />
        )}
        {platformUtilizationDetailsFetching ? (
          <Skeleton
            height={'138px'}
            className="rounded-xl"
            containerClassName="flex-col gap-4 w-full"
          />
        ) : (
          <DashboardCard
            title="Messages"
            text={changeToDashboardFormat(platformUtilizationDetails?.data.Messages)}
            color="secondary-light"
            isInfoRequired={true}
          />
        )}
      </div>
      <AdminReportsTables
        platformUtilizationDetails={platformUtilizationDetails?.data}
        platformUtilizationDetailsFetching={platformUtilizationDetailsFetching}
        physicianUtilizationDetails={physicianUtilizationDetails?.data}
        physicianUtilizationDetailsCount={physicianUtilizationDetails?.totalCount}
        physicianUtilizationDetailsFetching={physicianUtilizationDetailsFetching}
        nurseUtilizationDetails={nurseUtilizationDetails?.data}
        nurseUtilizationDetailsCount={nurseUtilizationDetails?.totalCount}
        nurseUtilizationDetailsFetching={nurseUtilizationDetailsFetching}
        medicalAssistantOverview={medicalAssistantOverview?.data}
        medicalAssistantOverviewCount={medicalAssistantOverview?.totalCount}
        medicalAssistantOverviewFetching={medicalAssistantOverviewFetching}
        medicalAssistantOverviewByAgent={medicalAssistantOverviewByAgent?.data}
        medicalAssistantOverviewByAgentCount={medicalAssistantOverviewByAgent?.totalCount}
        medicalAssistantOverviewByAgentFetching={medicalAssistantOverviewByAgentFetching}
        frontDeskOverview={frontDeskOverview?.data}
        frontDeskOverviewCount={frontDeskOverview?.totalCount}
        frontDeskOverviewFetching={frontDeskOverviewFetching}
        frontDeskOverviewByUser={frontDeskOverviewByUser?.data}
        frontDeskOverviewByUserCount={frontDeskOverviewByUser?.totalCount}
        frontDeskOverviewByUserFetching={frontDeskOverviewByUserFetching}
        setLoad={setLoadData}
      />
    </React.Fragment>
  );
};

export default AdminReports;
