export const RoleType = {
  primary: 'primary',
  PH: 'PH',
  MA: 'MA',
  CS: 'CS'
} as const;

export type RoleTypes = keyof typeof RoleType;

export type RoleOptionType = {
  label: string;
  value: RoleTypes;
};
