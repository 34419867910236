import { useCallback, useMemo } from 'react';

import dayjs from 'dayjs';
import isString from 'lodash/isString';
import { formatPhoneNumber } from 'react-phone-number-input';
import { useDebounce } from 'react-use';
import { GetUsersResponseProps } from 'store/lookup/lookup.types';
import { useLazyGetUsersQuery } from 'store/lookup/lookupSlice';
import { AvailableProvider } from 'store/staffs/staffs.types';
import { useLazyGetAvailableProvidersQuery } from 'store/staffs/staffsSlice';
import { selectTask } from 'store/tasks/tasksSlice';
import { selectUser } from 'store/user/userSlice';

import { useAppSelector } from '../../redux';

export const useSearchComboboxData = (
  query: string,
  userType: string,
  shouldSendTimezone?: boolean,
  shouldCheckAvailability?: boolean
) => {
  const [getUsers, { data: usersData, isFetching, isLoading }] = useLazyGetUsersQuery();
  const { timezone } = useAppSelector(selectUser);
  const [
    getAvailableProviders,
    {
      data: availableProvidersData,
      isLoading: isLoadingAvailProviders,
      isFetching: isFetchingAvailProviders
    }
  ] = useLazyGetAvailableProvidersQuery();
  const { taskDetails } = useAppSelector(selectTask);

  useDebounce(
    () => {
      if (query) {
        if (userType === 'AvailableProviders') {
          getAvailableProviders({
            searchKey: query,
            appointmentTypeId: taskDetails.appointmentInfo.appointmentType._id ?? '',
            state: taskDetails.personalInfo.state ?? '',
            patientUserId: taskDetails.patientId,
            timezone: timezone
          });
        } else {
          getUsers({
            userType: userType,
            searchKey: query,
            limit: 20,
            pageNo: 0,
            shouldCheckAvailability,
            ...(shouldSendTimezone && {
              timezone: dayjs.tz.guess()
            })
          });
        }
      }
    },
    1000,
    [query, taskDetails, userType, getAvailableProviders, getUsers, timezone]
  );

  const normalizeData = useCallback((data: GetUsersResponseProps[]) => {
    return data.map((item) => {
      const userName = item.name ? item.name : item.displayName;
      const phone = isString(item.phone) ? formatPhoneNumber(item.phone) : '';
      const label = phone ? `${userName} • ${phone}` : userName;

      return {
        label,
        value: userName,
        id: item._id
      };
    });
  }, []);

  const normalizeAvailableProvidersData = useCallback(
    (data: AvailableProvider[]) =>
      data.map((item) => ({
        label: `${item.firstName} ${item.lastName}`,
        value: `${item.firstName} ${item.lastName}`,
        id: item.userId
      })),
    []
  );

  const searchData = useMemo(() => {
    return userType === 'AvailableProviders' && availableProvidersData?.data.length
      ? normalizeAvailableProvidersData(availableProvidersData.data)
      : usersData?.length
        ? normalizeData(usersData)
        : [];
  }, [userType, availableProvidersData, usersData, normalizeAvailableProvidersData, normalizeData]);

  return useMemo(
    () => ({
      searchData,
      isLoading: isLoadingAvailProviders || isLoading,
      isFetching: isFetching || isFetchingAvailProviders
    }),
    [isFetching, isFetchingAvailProviders, isLoading, isLoadingAvailProviders, searchData]
  );
};
