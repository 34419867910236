import { PersonalInfo } from 'models/tasks.types';
import { useGetMembershipPlansQuery } from 'store/crossSell/crossSellSlice';
import { PatientProps } from 'store/patients/patients.types';

export const useGetPatientActivePricePoint = (patient?: PatientProps | PersonalInfo) => {
  const { data: membershipPlans, isLoading, isFetching } = useGetMembershipPlansQuery();
  const hasActivePlanId = patient && 'activePlanId' in patient;
  const hasActivePricePoint = patient && 'activePricePoint' in patient;

  const currentPlan =
    hasActivePlanId && membershipPlans?.find((plan) => plan._id === patient.activePlanId);
  const currentPricePoint =
    currentPlan &&
    hasActivePricePoint &&
    currentPlan?.pricePoints?.find((pp) => pp.planPricePointId === patient.activePricePoint);

  return {
    currentPlan,
    currentPricePoint,
    isLoading,
    isFetching
  };
};
