export const WEBHOOK = { label: 'Webhook', value: 'Webhook' };
export const TASK = { label: 'Task', value: 'Task' };
export const PATIENT_MESSAGE = { label: 'Patient Message', value: 'Patient Message' };
export const NOTIFICATION = { label: 'Notification', value: 'Notification' };

export const INTERNAL_TRIGGER_OPTIONS = [TASK, NOTIFICATION, PATIENT_MESSAGE];
export const PATIENT_TRIGGER_OPTIONS = [WEBHOOK, PATIENT_MESSAGE];

export const TRIGGER_BASED_ON = [
  { label: 'Created date', value: 'createdAt' },
  { label: 'Due date', value: 'dueDate' }
];
