import { useEffect } from 'react';

import { Common } from '@thecvlb/design-system/lib/src';
import Loader from 'components/common/Loader';
import PrescribeCompound from 'components/modals/PrescribeCompound';
import PrescribeCompoundResult from 'components/modals/PrescribeCompoundResult';
import TriggerRenewal from 'components/modals/TriggerRenewal';
import dayjs from 'dayjs';
import { DateFormat } from 'enums/dateFormats';
import { PatientPrescriptionsType, PaymentStatus } from 'enums/prescriptions';
import { useAppDispatch } from 'hooks/redux';
import useOpenTask from 'hooks/tasks/useOpenTask/useOpenTask';
import { Tooltip } from 'react-tooltip';
import { useToggle } from 'react-use';
import { openModal } from 'store/modal/modalSlice';
import { getDisplayName } from 'store/patients/patients.settings';
import { useLazyViewEMRQuery } from 'store/patients/patientsSlice';
import { RenewalStatus } from 'store/prescriptions/prescriptions.types';
import {
  useRetryPaymentMutation,
  useUpdatePrescriptionMutation
} from 'store/prescriptions/prescriptionsSlice';
import { getRemainingTime } from 'utils/datesAndTime';

import { PrescriptionsActionCellProps } from './prescriptionsActionCell.types';
export const PrescriptionsActionCell: React.FC<PrescriptionsActionCellProps> = ({
  prescription,
  patientInfo
}) => {
  const dispatch = useAppDispatch();
  const { handleOpenTask } = useOpenTask();
  const [isStableDose, toggleStableDose] = useToggle(false);
  const [viewEMR, { isFetching, isLoading }] = useLazyViewEMRQuery();
  const [retryPayment, { isLoading: isLoadingRetryPayment }] = useRetryPaymentMutation();
  const [updatePrescription, { isLoading: isLoadingUpdatePrescriptions }] =
    useUpdatePrescriptionMutation();

  const patientName = getDisplayName(patientInfo);
  const showLoader =
    isLoading || isFetching || isLoadingRetryPayment || isLoadingUpdatePrescriptions;
  const renewalTaskId = prescription.renewalTaskId;
  const signedAt = prescription.signedAt;
  const isFailed = prescription.paymentStatus === PaymentStatus.Failed;
  const isGLP = prescription.type === PatientPrescriptionsType.Order;
  const wasMadeWithLifeMD = prescription.type === PatientPrescriptionsType.LMDPrescription;
  const isWeightManagement = patientInfo?.planInfo?.planName === 'Weight Management';

  const showTriggerRenewalButton =
    !!prescription.isRenewalRequestAllowed &&
    dayjs(prescription.renewalRequestAllowedAt).diff(dayjs(), 'hours') < 1;
  const showRemainigTimeToRenewal =
    prescription.renewalRequestAllowedAt &&
    dayjs(prescription.renewalRequestAllowedAt).diff(dayjs(), 'hours') >= 1 &&
    !!prescription.isRenewalRequestAllowed;
  const showRemainigTimeToRenewalTooltip =
    !!prescription.renewalFrequency?.supply || !!prescription.renewalRequestAllowedAt;
  const showRenewalPending =
    prescription.isRenewalCreated &&
    prescription?.renewalTask?.status !== RenewalStatus.Completed &&
    !prescription?.renewalSurvey?.canceledAt;

  const requestDetails = {
    patientId: patientInfo?._id || '',
    paymentAmount: prescription.qty,
    prescription: prescription.name
  };

  const handleStableDose: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    event.stopPropagation();
    const titrationsList = [
      {
        ...(prescription.titration?._id && { _id: prescription.titration?._id }),
        ...(prescription.titration?.qty && { qty: prescription.titration?.qty }),
        units: prescription.titration?.units || 'ml',
        adjustedAt: dayjs().local().utc().format(DateFormat.YYYY_MM_DDTHH_mm_ss_ss_Z),
        stableDose: !isStableDose
      }
    ];
    updatePrescription({
      prescriptionId: prescription.id,
      titrations: titrationsList
    });
    toggleStableDose();
  };

  const handleRetryPayment = async () => {
    retryPayment({ prescriptionId: prescription.id })
      .unwrap()
      .then((prescribeResult) => {
        prescribeResult?.data &&
          dispatch(
            openModal({
              size: 'sm',
              modalContent: (
                <PrescribeCompoundResult
                  prescribeResult={prescribeResult.data}
                  requestDetails={requestDetails}
                />
              )
            })
          );
      });
  };

  const handleViewEMR = () => {
    patientInfo?._id && viewEMR({ id: patientInfo._id });
  };

  const handleCopyToNewPrescriptions = () => {
    patientInfo?._id &&
      dispatch(
        openModal({
          hideClose: true,
          modalContent: (
            <PrescribeCompound
              patientId={patientInfo?._id}
              patientInfo={patientInfo}
              defaultPrescription={prescription}
            />
          ),
          size: 'sm'
        })
      );
  };

  const handleTriggerRenewal = () => {
    if (patientInfo?._id && prescription.orderId) {
      dispatch(
        openModal({
          hideClose: true,
          modalContent: (
            <TriggerRenewal
              patientName={patientName}
              orderId={prescription.orderId}
              patientId={patientInfo?._id}
              showNotWMWarning={!isWeightManagement}
            />
          ),
          size: 'sm'
        })
      );
    }
  };

  useEffect(() => {
    toggleStableDose(Boolean(prescription.titration?.stableDose));
  }, [prescription, toggleStableDose]);

  const remainingRenewalTooltipText = `Your current ${
    prescription.renewalFrequency?.supply
  } day supply is not ready to be renewed. You will be able to request a renewal on ${dayjs(
    prescription.renewalRequestAllowedAt
  ).format(DateFormat.MMM_DD)}.`;

  return (
    <div className="flex flex-wrap justify-end gap-3">
      {showLoader && <Loader isVisible />}
      {!signedAt ? (
        <Common.Button
          onClick={handleViewEMR}
          color="white"
          className="flex w-full justify-center"
          size="sm"
        >
          <Common.Icon name="view-emr" className="size-4 flex-none hover:text-primary-500" />
          <span className="whitespace-nowrap">Sign in Elation</span>
        </Common.Button>
      ) : isGLP && renewalTaskId ? (
        <Common.Button
          size="sm"
          className="flex w-full justify-center whitespace-nowrap text-center"
          color="green"
          onClick={() => handleOpenTask({ taskId: renewalTaskId })}
        >
          View renewal
        </Common.Button>
      ) : (
        wasMadeWithLifeMD && (
          <Common.Button
            color="white"
            className="flex w-full justify-center"
            size="sm"
            onClick={handleCopyToNewPrescriptions}
          >
            <Common.Icon name="lifemd" className="size-4 flex-none hover:text-primary-500" />
            Copy to new prescription
          </Common.Button>
        )
      )}
      {signedAt && isGLP && prescription.titration?.qty && (
        <label className="flex cursor-pointer items-center gap-2 whitespace-nowrap text-sm font-medium text-gray-700">
          <Common.Toggle color="blue" size="sm" checked={isStableDose} onClick={handleStableDose} />
          Stable dose
        </label>
      )}
      {isFailed && (
        <Common.Button
          onClick={handleRetryPayment}
          className="flex w-full justify-center"
          color="blue"
          style="pill"
          size="sm"
          disabled={isLoadingRetryPayment}
        >
          <Common.Icon name="checkout" className="size-4 flex-none hover:text-primary-500" />
          <span className="whitespace-nowrap">Retry payment</span>
        </Common.Button>
      )}
      {showTriggerRenewalButton && (
        <Common.Button
          color="green-alt"
          className="flex w-full justify-center"
          size="sm"
          onClick={handleTriggerRenewal}
        >
          Trigger renewal
        </Common.Button>
      )}
      {showRemainigTimeToRenewal && (
        <>
          {showRemainigTimeToRenewalTooltip && (
            <Tooltip
              className="z-30 max-w-[200px] !rounded-xl !bg-black !p-3 !text-sm !text-white"
              classNameArrow="w-4 h-4"
              id="renewal"
            />
          )}
          <Common.Button
            color="white"
            data-tooltip-html={remainingRenewalTooltipText}
            data-tooltip-id="renewal"
            preIcon="calendar"
            preIconClassName="mr-2 !size-7"
            size="sm"
          >
            Available to renew in {getRemainingTime(prescription.renewalRequestAllowedAt)}
          </Common.Button>
        </>
      )}
      {showRenewalPending && (
        <div className="flex items-center text-green">
          <Common.Icon className="mr-2 mt-0.5" name="check" />
          <h4 className="text-sm font-bold">Renewal pending</h4>
        </div>
      )}
    </div>
  );
};

export default PrescriptionsActionCell;
