/**
 * Configuration object for the socket.io client library.
 *
 * @typedef {Object} Config
 * @property {Object} webSocket - The WebSocket configuration.
 * @property {string} webSocket.baseUrlWebSocketMessages - The base URL for WebSocket connections related to messages.
 * @property {string} webSocket.baseUrlWebSocketFrontDesk - The base URL for WebSocket connections related to the front desk.
 * @property {string} webSocket.baseUrlWebSocketStaff - The base URL for WebSocket connections related to staff.
 *
 * @example
 * import config from './chat';
 * const socket = io(config.webSocket.baseUrlWebSocketMessages);
 */
const config = {
  webSocket: {
    baseUrlWebSocketMessages: `${import.meta.env.VITE_SOCKET_URL}/messages`,
    baseUrlWebSocketFrontDesk: `${import.meta.env.VITE_SOCKET_URL}/frontDesk`,
    baseUrlWebSocketStaff: `${import.meta.env.VITE_SOCKET_URL}/staff`
  }
};

export default config;
