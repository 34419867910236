import { MouseEvent, useEffect, useMemo, useState } from 'react';

import { Common } from '@thecvlb/design-system/lib/src';
import classNames from 'classnames';
import { useAppSelector } from 'hooks/redux';
import { useDispatch } from 'react-redux';
import { closeModal } from 'store/modal/modalSlice';
import { selectStaffFeedback } from 'store/staffFeedback/staffFeedbackSlice';

import { getPrevNextStaffFeedbackId } from './staffFeedback.settings';
import StaffFeedbackDetails from './StaffFeedbackDetails';

const StaffFeedback: React.FC<{ roomId: string }> = ({ roomId }) => {
  const dispatch = useDispatch();
  const { data: staffFeedback } = useAppSelector(selectStaffFeedback);
  const [feedbackRoomId, setFeedbackRoomId] = useState(roomId);
  const feedback = useMemo(
    () => staffFeedback.find((item) => item.roomName === feedbackRoomId),
    [feedbackRoomId, staffFeedback]
  );
  const { prevFeedbackId, nextFeedbackId } = useMemo(
    () => getPrevNextStaffFeedbackId(feedbackRoomId, staffFeedback),
    [feedbackRoomId, staffFeedback]
  );

  useEffect(() => {
    if (!feedbackRoomId) {
      dispatch(closeModal());
    }
  }, [feedbackRoomId, dispatch]);

  const handlePagination = (event: MouseEvent<HTMLButtonElement>) => {
    if (event.currentTarget.name === 'prev' && prevFeedbackId) {
      setFeedbackRoomId(prevFeedbackId);
    }
    if (event.currentTarget.name === 'next' && nextFeedbackId) {
      setFeedbackRoomId(nextFeedbackId);
    }
  };

  const isNextFeedbackIconDisabled = !nextFeedbackId;
  const isPrevFeedbackIconDisabled = !prevFeedbackId;
  const nextFeedbackClassNames = classNames({ 'text-gray-500': isNextFeedbackIconDisabled });
  const prevFeedbackClassNames = classNames({ 'text-gray-500': isPrevFeedbackIconDisabled });

  return (
    <>
      <div data-testid="staff_feedback_popup" className="p-6">
        <div className="flex content-end justify-between">
          <h1 data-testid="provider_name" className="mb-9 text-xl font-bold text-gray-700">
            Review: {feedback?.providerInfo?.name}
          </h1>
          <div className="flex items-start overflow-hidden">
            <button
              className="p-1"
              onClick={handlePagination}
              disabled={isPrevFeedbackIconDisabled}
              name="prev"
            >
              <Common.Icon name="arrow-left" className={prevFeedbackClassNames} />
            </button>
            <button
              className="p-1"
              onClick={handlePagination}
              disabled={isNextFeedbackIconDisabled}
              name="next"
            >
              <Common.Icon name="arrow-right" className={nextFeedbackClassNames} />
            </button>
          </div>
        </div>

        <StaffFeedbackDetails feedback={feedback} />

        <div className="mt-10 flex justify-end gap-x-2">
          <Common.Button color="blue" onClick={() => dispatch(closeModal())} size="sm">
            Close
          </Common.Button>
        </div>
      </div>
    </>
  );
};

export default StaffFeedback;
