import React, { ComponentType, lazy, Suspense } from 'react';

import Loader from 'components/common/Loader';

export const SuspenseWrapper: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  return <Suspense fallback={<Loader isVisible />}>{children}</Suspense>;
};

/**
 * @description
 *  https://medium.com/@alonmiz1234/retry-dynamic-imports-with-react-lazy-c7755a7d557a
 */

export const lazyWithRetries = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  componentImport: any
) =>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  lazy((): Promise<{ default: ComponentType<any> }> => {
    return new Promise((resolve, reject) => {
      // check if the window has already been refreshed
      const hasRefreshed = JSON.parse(sessionStorage.getItem('retry-lazy-refreshed') || 'false');
      // try to import the component
      componentImport()
        .then(
          (
            component: // eslint-disable-next-line @typescript-eslint/no-explicit-any
            | { default: ComponentType<any> }
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              | PromiseLike<{ default: ComponentType<any> }>
          ) => {
            sessionStorage.setItem('retry-lazy-refreshed', 'false'); // success so reset the refresh
            resolve(component);
          }
        )
        .catch((error: Error) => {
          if (!hasRefreshed) {
            // not been refreshed yet
            sessionStorage.setItem('retry-lazy-refreshed', 'true'); // we are now going to refresh
            return window.location.reload(); // refresh the page
          }
          reject(error); // Default error behaviour as already tried refresh
        });
    });
  });
