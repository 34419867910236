import { useEffect, useState } from 'react';

import { FieldValues, useController } from 'react-hook-form';
import { useDebounce } from 'react-use';

import { AutocompleteSelectProps } from './autocompleteSelect.types';
import ControlledCombobox from '../ControlledCombobox';

const AutocompleteSelect = <TFieldValues extends FieldValues>({
  getOptionsFunc,
  ...otherProps
}: AutocompleteSelectProps<TFieldValues>) => {
  const { field, fieldState } = useController({ ...otherProps });
  const [inputValue, setInputValue] = useState('');
  const [, cancel] = useDebounce(
    () => {
      if (inputValue) getOptionsFunc(inputValue);
    },
    500,
    [inputValue]
  );

  useEffect(() => {
    return () => cancel();
  }, [cancel]);

  return (
    <div className={otherProps.className}>
      <ControlledCombobox
        {...otherProps}
        name={field.name}
        size="sm"
        data-testid="search_field"
        placeholder="Search by name or date..."
        onInputChange={(event) => setInputValue(event.target.value)}
        onChange={(option) => field.onChange(option)}
        inputValue={inputValue}
        helperText={fieldState.error?.message}
        errors={fieldState.error}
      />
    </div>
  );
};

export default AutocompleteSelect;
