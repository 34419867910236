import LightCoolBg from 'assets/images/virtual-backgorunds/lifemd-bg-light-cool.webp';
import LightNeutralBg from 'assets/images/virtual-backgorunds/lifemd-bg-light-neutral.webp';
import LightWarmBg from 'assets/images/virtual-backgorunds/lifemd-bg-light-warm.webp';
import VirtualBg from 'assets/images/virtual-backgorunds/lifemd-bg.webp';
import { VirtualBackgroundsTypes } from 'pages/ZoomCall/Controls/SettingsPopup/VideoEffects/videEffects.types';

export const setVirtualBackground = (
  isVirtualBGSupported: boolean,
  currentVB?: VirtualBackgroundsTypes,
  backgroundToApply?: VirtualBackgroundsTypes
) => {
  const backgroundMapping = {
    [VirtualBackgroundsTypes.Blur]: VirtualBackgroundsTypes.Blur,
    [VirtualBackgroundsTypes.Blue]: VirtualBg,
    [VirtualBackgroundsTypes.LightNeutral]: LightNeutralBg,
    [VirtualBackgroundsTypes.LightWarm]: LightWarmBg,
    [VirtualBackgroundsTypes.LightCool]: LightCoolBg
  };

  const background = backgroundToApply ?? currentVB ?? VirtualBackgroundsTypes.None;

  if (!isVirtualBGSupported || background === VirtualBackgroundsTypes.None) {
    return null;
  }

  const imageUrl = backgroundMapping[background];

  if (imageUrl) {
    return { virtualBackground: { imageUrl } };
  }
};
