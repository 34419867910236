import { Role } from 'enums/role';

export const PAY_TYPE_TO_OPTIONS = [
  { value: 'Per year (w2)', label: 'Per year (w2)' },
  { value: 'Per consult', label: 'Per consult' },
  { value: 'Per hour', label: 'Per hour' }
];

export const ROLE_OPTIONS = [
  { value: Role.NP, label: Role.NP },
  { value: Role.PH, label: Role.PH },
  { value: Role.MA, label: Role.MA },
  { value: Role.AD, label: Role.AD },
  { value: Role.CS, label: Role.CS }
];

export const ROUTE_TO_OPTIONS = [
  { value: 'CS', label: 'CS' },
  { value: 'MA', label: 'MA' }
];

export const AUDIENCE = [
  { label: 'Task owner', value: 'Task owner' },
  { label: 'Admin', value: 'AD' },
  { label: 'PH', value: 'PH' },
  { label: 'NP', value: 'NP' },
  { label: 'MA', value: 'MA' },
  { label: 'CS', value: 'CS' }
];
