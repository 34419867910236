import { CATEGORY_OPTION } from 'constants/article';
import { STATUS_OPTIONS } from 'constants/status';

import { ArticlesFilterFormProps } from './articlesFilterForm.types';

export const defaultValues: ArticlesFilterFormProps = {
  title: '',
  category: { value: '', label: '' },
  searchKey: '',
  author: '',
  createdAt: '',
  uniqueId: '',
  'author.name': '',
  'audience.shortCode': '',
  audience: { value: '', label: '' },
  status: { value: '', label: '' }
};

export const getArticlesFiltersValue = (key: string, val: string) => {
  const currentKey =
    key === 'author.name' ? 'author' : key === 'audience.shortCode' ? 'audience' : key;
  let currentValue;
  switch (currentKey) {
    case 'category':
      currentValue = CATEGORY_OPTION.find((item) => item.value === val);
      break;
    case 'status':
      currentValue = STATUS_OPTIONS.find((item) => item.value === val);
      break;
    case 'audience':
      currentValue = { label: val, value: val };
      break;
    case 'author':
      currentValue = val;
      break;
    default:
      currentValue = val;
  }
  return currentValue;
};
