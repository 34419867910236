import { ReviewProps } from 'store/reviews/reviews.types';

export const getPrevNextReviewsId = (currentId: string, reviews: ReviewProps[]) => {
  const currentReviewIndex = reviews.findIndex((item) => item._id === currentId);
  const prevReviewId = currentReviewIndex >= 1 ? reviews[currentReviewIndex - 1]._id : null;
  const nextReviewId =
    currentReviewIndex >= 0 && reviews[currentReviewIndex + 1]
      ? reviews[currentReviewIndex + 1]._id
      : null;
  return { prevReviewId, nextReviewId };
};
