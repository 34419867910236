import { Common } from '@thecvlb/design-system/lib/src';

const CreditBalanceCard: React.FC<{ creditBalance: string; firstName?: string }> = ({
  creditBalance,
  firstName
}) => {
  return (
    <div className="flex w-full items-center gap-4 rounded-2xl border border-gray-200 p-4">
      <div className="flex min-w-[60px] items-center self-stretch rounded-lg bg-secondary-50">
        <Common.Logo className="mx-auto w-4" name="cross" />
      </div>
      <div>
        <h4 className="mb-1 text-sm font-bold">LifeMD credit: ${creditBalance}</h4>
        <h5 className="text-sm font-medium text-gray">
          {firstName}’s credit balance will be spent prior to charging their card.
        </h5>
      </div>
    </div>
  );
};

export default CreditBalanceCard;
