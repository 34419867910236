import { useEffect } from 'react';

import classNames from 'classnames';
import { useParams } from 'react-router-dom';
import { useLazyGetLatestHealthMetricsQuery } from 'store/patientChart/patientChartSlice';

import BloodPressure from './BloodPressure';
import BloodPressureFromDevice from './BloodPressureFromDevice';
import BodyMassIndex from './BodyMassIndex';
import Cholesterol from './Cholesterol';
import { HealthMetricsProps } from './healthMetrics.types';
import Height from './Height';
import Waist from './Waist';
import Weight from './Weight';
import WeightFromDevice from './WeightFromScale';
import Skeleton from '../Skeleton';

const HealthMetrics: React.FC<HealthMetricsProps> = ({ patient }) => {
  const { patientId = '' } = useParams<{ patientId: string }>();

  const [getLatestHealthMetrics, { data: latestMetrics, isLoading }] =
    useLazyGetLatestHealthMetricsQuery();

  const handleGetLatestData = () => {
    if (patientId) {
      getLatestHealthMetrics({ patientId });
    }
  };

  useEffect(handleGetLatestData, [patientId, getLatestHealthMetrics]);

  const sectionHeader = (title: string, subheader?: string) => {
    return (
      <div>
        <h2 className={classNames('mt-3 text-mBase font-bold', !subheader && 'mb-3')}>{title}</h2>
        {subheader && <p className="mt-1 text-mSm text-gray">{subheader}</p>}
      </div>
    );
  };

  const weightFromDevice = {
    weight: latestMetrics?.data.weight,
    bodyFat: latestMetrics?.data.bodyFat,
    boneMass: latestMetrics?.data.boneMass,
    muscleMass: latestMetrics?.data.muscleMass,
    totalWater: latestMetrics?.data.totalWater,
    visceralFat: latestMetrics?.data.visceralFat
  };

  const subheaderClassName = 'hidden text-xl font-bold md:block mb-0';

  const metricFromDevices = !!patient?.isWMDevices;
  const manualMetrics = !metricFromDevices;

  return isLoading ? (
    <Skeleton />
  ) : patientId ? (
    <>
      <h2 className={subheaderClassName}>Health metrics</h2>
      {metricFromDevices && (
        <>
          {sectionHeader('Digital scale', 'Data not editable in app.')}
          <WeightFromDevice weight={weightFromDevice} patient={patient} />
          {sectionHeader('Blood pressure monitor', 'Data not editable in app.')}
          <BloodPressureFromDevice bloodPressure={latestMetrics?.data.bloodPressure} />{' '}
        </>
      )}
      {sectionHeader('Profile metrics')}
      <Height height={latestMetrics?.data.height} />
      {manualMetrics && <Weight weight={latestMetrics?.data.weight} patient={patient} />}
      {latestMetrics?.data.bmi && <BodyMassIndex bmi={latestMetrics?.data.bmi} />}
      {manualMetrics && <BloodPressure bloodPressure={latestMetrics?.data.bloodPressure} />}
      <Cholesterol cholesterol={latestMetrics?.data.cholesterol} />
      <Waist waistCircumference={latestMetrics?.data.waistCircumference} />
    </>
  ) : null;
};

export default HealthMetrics;
