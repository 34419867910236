import { nanoid } from '@reduxjs/toolkit';
import { Common } from '@thecvlb/design-system';
import { AnimatePresence, LayoutGroup, motion } from 'framer-motion';
import { AT_LEAST_ONE_LETTER_REGEXP } from 'utils/regExp';

import { MedicationsProps } from './medications.types';

const Medications: React.FC<MedicationsProps> = ({ onUpdate, medications, loading = false }) => {
  const handleAdd = () => {
    onUpdate([...medications, { dosage: '', id: nanoid(), isPrescription: false, name: '' }]);
  };

  const handleRemove = (id: string) => {
    onUpdate(medications.filter((el) => el.id !== id));
  };

  const handleChange = (
    value: string | boolean,
    id: string,
    field: 'name' | 'dosage' | 'isPrescription'
  ) => {
    onUpdate(
      medications.map((medication) =>
        medication.id === id ? { ...medication, [field]: value } : medication
      )
    );
  };

  const isError = (name: string) => {
    const trimmedName = name.trim();
    const isDirty = trimmedName.length > 0;
    return isDirty && (trimmedName.length < 3 || !trimmedName.match(AT_LEAST_ONE_LETTER_REGEXP));
  };

  return (
    <LayoutGroup id="md-lix">
      <AnimatePresence>
        {medications.map((el, i) => (
          <div className="flex flex-col rounded-2xl border bg-white p-4 shadow-sm" key={el.id}>
            <div className="mb-2 flex gap-3" key={el.id}>
              <Common.Input
                dataTestId="medication_input"
                disabled={loading}
                errors={isError(el.name) ? { [`medication-${i}`]: isError(el.name) } : undefined}
                helper={isError(el.name) ? 'Min 3 characters with letters' : ''}
                label="Medication"
                maxLength={1000}
                placeholder="Enter here..."
                size="md"
                value={el.name}
                onChange={(e) => handleChange(e.target.value, el.id, 'name')}
              />
              <Common.Input
                className="max-w-[100px]"
                dataTestId="dosage_input"
                disabled={loading}
                label="Dosage"
                maxLength={20}
                placeholder="0 units"
                size="md"
                value={el.dosage ?? ''}
                onChange={(e) => handleChange(e.target.value, el.id, 'dosage')}
              />
              <button
                className="flex pt-9 text-gray transition-all hover:text-red"
                data-testid="remove_medication_btn"
                disabled={loading}
                onClick={() => handleRemove(el.id)}
              >
                <Common.Icon name="close" />
              </button>
            </div>
            <Common.Checkbox
              checked={el.isPrescription}
              color="blue"
              disabled={loading}
              onChange={() => handleChange(!el.isPrescription, el.id, 'isPrescription')}
            >
              <span className="text-base">This is a prescription</span>
            </Common.Checkbox>
          </div>
        ))}
      </AnimatePresence>
      <motion.div key="add-btn-m" layoutId="add-btn-m">
        <Common.Button
          color="white"
          dataTestId="add-medication"
          disabled={medications.length >= 100 || loading}
          preIcon="plus-small"
          size="sm"
          onClick={handleAdd}
        >
          Add medication
        </Common.Button>
      </motion.div>
    </LayoutGroup>
  );
};

export default Medications;
