import Alert from 'components/common/Alert';
import { useAppDispatch } from 'hooks/redux';
import AuthCode from 'react-auth-code-input';
import { Controller, useForm } from 'react-hook-form';
import { closeModal } from 'store/modal/modalSlice';

import { ConfirmationCodeFormProps } from '../updateEmail.types';

type FormValues = {
  code: string;
};

const ConfirmationCodeForm = ({ onSubmit, isLoading }: ConfirmationCodeFormProps) => {
  const dispatch = useAppDispatch();
  const { handleSubmit, control, formState } = useForm<FormValues>({
    mode: 'onChange'
  });
  const rules = {
    required: {
      value: true,
      message: 'Confirmation code is required'
    },
    validate: (value: string) => value.length === 6 || 'Confirmation code must contain six numbers'
  };
  const isDisabled = !formState.isValid || isLoading;

  const handleCancel = () => {
    dispatch(closeModal());
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Controller
        control={control}
        name="code"
        rules={rules}
        render={({ field }) => (
          <AuthCode
            {...field}
            allowedCharacters="numeric"
            length={6}
            inputClassName="w-[42px] h-[50px] text-center border-solid border border-gray-400 rounded-md shadow-sm text-gray-700 font-medium text-mLg"
            containerClassName="flex items-center gap-3 w-[312px] mb-6"
          />
        )}
      />

      <Alert type="info">
        <p className="text-base">
          When you click <span className="font-bold text-gray-700">Update email</span>, you will be
          signed out of LifeMD. You will need to use your new email address to sign in again.
        </p>
      </Alert>

      <div className="mt-6 flex items-center justify-between gap-2">
        <button
          className="w-full rounded-lg bg-gray-100 py-[7.5px] text-sm font-bold text-gray-700 disabled:bg-gray-200 disabled:text-gray"
          onClick={handleCancel}
        >
          Cancel
        </button>
        <button
          className="w-full rounded-lg bg-primary py-[7.5px] text-sm font-bold text-white disabled:bg-gray-200 disabled:text-gray"
          disabled={isDisabled}
        >
          Update email
        </button>
      </div>
    </form>
  );
};

export default ConfirmationCodeForm;
