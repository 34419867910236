import { Role } from 'enums/role';
import queryString from 'query-string';
import { RouteProps } from 'router/router.types';

export const getAllowedRoutes = (routes: RouteProps[], role: string, hiddenItems?: string[]) => {
  return routes.filter(
    ({ permissions, path }) =>
      !permissions?.length || (permissions.includes(role as Role) && !hiddenItems?.includes(path))
  );
};

export const checkIsProtectedRoute = (pathname: string, routes: RouteProps[]) =>
  routes.some((route) => {
    const regex = new RegExp('^' + route.path.replace(/:[^\s/]+/g, '([\\w-]+)') + '$');
    return regex.test(pathname);
  });

export const parseQueryParams = <T extends Record<string, unknown>>(
  searchParams: URLSearchParams
): T => {
  const params = queryString.parse(searchParams.toString());
  if (params.searchKey) {
    params.searchKey = decodeURIComponent(params.searchKey as string);
  }
  // TODO: this is a hotfix. Need to be investigated. Why? is this issue also on another encoded filters?
  if (params.email) {
    params.email = decodeURIComponent(params.email as string)?.trim();
  }
  return params as T;
};
