import { DOC_EXTENSIONS, VIDEO_EXTENSIONS } from 'constants/extensions';

import { Common } from '@thecvlb/design-system';
import classNames from 'classnames';
import { Role } from 'enums/role';
import parse, { Element, Text } from 'html-react-parser';
import { getErrorMessage } from 'utils/common/errors';
import { sanitizeHTML } from 'utils/common/parseHTML';

import { FilePreviewProps } from './message.types';
import Image from '../Image';

export const getProfileImageWrapperClassName = (isSelf: boolean, isGrouped?: boolean) =>
  classNames('flex items-end', {
    'flex-row-reverse': isSelf,
    'mr-12': isSelf && isGrouped,
    'ml-12': !isSelf && isGrouped
  });

export const filePreview = ({
  fileName,
  imageClasses,
  mediaWrapperClasses,
  onMouseEnterImg,
  onFocusImg,
  onImageClick,
  src,
  isBlurred
}: FilePreviewProps) => {
  const extensionIndex = fileName?.lastIndexOf('.') as number;
  const fileExtension = fileName?.substring(extensionIndex).toLowerCase() as string;

  if (DOC_EXTENSIONS.includes(fileExtension)) {
    return (
      <a className={mediaWrapperClasses} href={src} target="_blank" rel="noreferrer">
        <Common.Icon name="articles" className="mx-auto mb-1 size-9" />
        <p>{fileName}</p>
      </a>
    );
  } else if (VIDEO_EXTENSIONS.includes(fileExtension)) {
    return (
      <video controls className={imageClasses}>
        <source src={src} />
      </video>
    );
  } else {
    return (
      <Image
        isBlurred={isBlurred}
        onImageClick={onImageClick}
        onFocusImg={onFocusImg}
        onMouseEnterImg={onMouseEnterImg}
        src={src}
        fileName={fileName}
        imageClasses={imageClasses}
      />
    );
  }
};

export const parseEditedString = (string = '') => {
  const sanitizedString = sanitizeHTML(string);

  try {
    return parse(sanitizedString, {
      replace: (domNode) => {
        const classes = 'whitespace-pre-line break-normal text-base text-gray-300';
        const testId = 'msg_content';

        if (domNode instanceof Element) {
          if (domNode.tagName === 'p') {
            domNode.attribs.className = classes;
            domNode.attribs['data-testid'] = testId;

            return domNode;
          } else if (domNode.tagName === 'a') {
            domNode.attribs.className = 'text-base underline text-gray-300';

            return domNode;
          } else if (domNode.tagName === 'ol') {
            domNode.attribs.className = 'text-base ps-5 list-decimal';

            return domNode;
          } else if (domNode.tagName === 'ul') {
            domNode.attribs.className = 'text-base ps-5 list-disc';

            return domNode;
          }
        } else if (domNode instanceof Text && domNode.parent === null) {
          return (
            <p className={classes} test-id={testId}>
              {domNode.data}
            </p>
          );
        }
      }
    });
  } catch (error) {
    console.error(getErrorMessage(error));
    throw error;
  }
};

export const parseString = (string = '') => {
  const sanitizedString = sanitizeHTML(string);

  try {
    return parse(sanitizedString, {
      replace: (domNode) => {
        const classes = 'whitespace-pre-line break-normal text-base text-gray-700';
        const testId = 'msg_content';

        if (domNode instanceof Element) {
          if (domNode.tagName === 'p') {
            domNode.attribs.className = classes;
            domNode.attribs['data-testid'] = testId;

            return domNode;
          } else if (domNode.tagName === 'a') {
            domNode.attribs.className = 'text-base underline text-primary';

            return domNode;
          } else if (domNode.tagName === 'ol') {
            domNode.attribs.className = 'text-base ps-5 list-decimal';

            return domNode;
          } else if (domNode.tagName === 'ul') {
            domNode.attribs.className = 'text-base ps-5 list-disc';

            return domNode;
          }
        } else if (domNode instanceof Text && domNode.parent === null) {
          return (
            <p className={classes} test-id={testId}>
              {domNode.data}
            </p>
          );
        }
      }
    });
  } catch (error) {
    console.error(getErrorMessage(error));
    throw error;
  }
};

export const getUserRole = (userRole?: string) => {
  switch (userRole) {
    case Role.PH:
      return 'Provider';
    case 'Patient':
      return 'Patient';
    default:
      return null;
  }
};

export const messageSkeleton = (isMine?: boolean) => {
  return (
    <div className={classNames('flex gap-3', { 'flex-row-reverse self-end': isMine })}>
      <div className="size-9 animate-pulse rounded-full bg-slate-200" />
      <div
        className={classNames(
          'h-9 w-64 animate-pulse rounded-2xl bg-slate-200',
          isMine ? 'rounded-br-none' : 'rounded-bl-none'
        )}
      />
    </div>
  );
};

export const skeletonChatList = () => {
  return (
    <div className="flex w-full flex-col gap-3" data-testid="chat_loader">
      <div className="self-center">
        <div className="mb-4 h-6 w-32 animate-pulse rounded-full bg-slate-200" />
      </div>
      {messageSkeleton()}
      {messageSkeleton(true)}
    </div>
  );
};

export const DELETE_MESSAGE_CONFIRMATION_SUBHEADER_TEXT = `Are you sure you want to delete this message?<br/>You will not be able to undo this.`;
export const TOOLTIP_FOR_SEEN_MESSAGES =
  'Once a patient sees your message, you can no longer edit or delete it.';
export const DELETED_MESSAGE_TEXT = `This message has been deleted by {name}`;
