import parse, { DOMNode, Element, Text } from 'html-react-parser';
import sanitize from 'sanitize-html';

import { getErrorMessage } from './errors';

const sanitizeHTML = (
  html: string,
  allowedTags = ['a', 'p', 'em', 'strong', 'br', 'ol', 'li', 'ul']
) => {
  return sanitize(html, {
    allowedTags
  });
};

const parseHTMLToText = ({
  text,
  testId,
  className
}: {
  text: string;
  testId?: string;
  className: string;
}) => {
  const sanitizedText = sanitizeHTML(text);

  try {
    return parse(sanitizedText, {
      replace: (domNode: DOMNode) => {
        if (domNode instanceof Element) {
          domNode.attribs.className = className;

          if (testId) domNode.attribs['data-testid'] = testId;

          return domNode;
        }

        if (domNode instanceof Text) {
          return domNode;
        }
      }
    });
  } catch (error) {
    console.error(getErrorMessage(error));
    throw error;
  }
};

/**
 * @description Strip HTML tags from string
 * @param html
 * @returns string without HTML tags
 */
const stripHTML = (html: string) => {
  return sanitize(html, {
    allowedTags: [],
    allowedAttributes: {}
  });
};

export { parseHTMLToText, sanitizeHTML, stripHTML };
