import React from 'react';

import { Common } from '@thecvlb/design-system';

import Filters from './Filters';
import { HeaderTypes } from './header.types';
import WeekSwitch from './WeekSwitch';
import CalendarHint from '../../../Calendar/CalendarHint';

const Header: React.FC<HeaderTypes> = ({
  selectedWeek,
  onChangeWeek,
  selectedShiftTypes,
  setSelectedShiftType,
  shiftTypes
}) => {
  return (
    <div className="relative flex items-center px-6">
      <div className="mr-4 flex w-full flex-wrap items-center justify-between gap-5">
        <WeekSwitch week={selectedWeek} onChange={onChangeWeek} />
        <Filters
          selectedShiftTypes={selectedShiftTypes}
          setSelectedShiftType={setSelectedShiftType}
          shiftTypes={shiftTypes}
        />
      </div>
      <div className="group relative flex cursor-pointer items-center justify-start hover:text-primary-500">
        <Common.Icon
          name="info"
          className="mr-1 size-5 cursor-pointer text-gray-500 hover:text-primary-500"
        />
        <span className="text-base font-bold text-black">Key</span>
        <CalendarHint />
      </div>
    </div>
  );
};

export default Header;
