import { Common } from '@thecvlb/design-system';
import UpdateBloodPressure from 'components/modals/HealthMetrics/BloodPressure/BloodPressure';
import UpdateCholesterol from 'components/modals/HealthMetrics/Cholesterol/Cholesterol';
import HealthMetricsModal from 'components/modals/HealthMetrics/HealthMetrics';
import UpdateHeight from 'components/modals/HealthMetrics/Height/Height';
import UpdateWeight from 'components/modals/HealthMetrics/Weight/Weight';
import { HealthMetricTypes } from 'enums/chart';
import { useDispatch } from 'react-redux';
import { openModal } from 'store/modal/modalSlice';
import { HealthMetricType } from 'store/patients/patients.types';

import { HealthMetricTableColumnsProps } from './healthMetric.types';
import UpdateWaist from '../../modals/HealthMetrics/Waist';

const EditCell: React.FC<{ data: HealthMetricTableColumnsProps; metricType: HealthMetricType }> = ({
  data,
  metricType
}) => {
  const dispatch = useDispatch();

  const getMetricForm = () => {
    switch (metricType) {
      case HealthMetricTypes.Height:
        return <UpdateHeight metric={data.metrics} />;
      case HealthMetricTypes.Weight:
        return <UpdateWeight metric={data.metrics.weight} />;
      case HealthMetricTypes.WaistCircumference:
        return <UpdateWaist metric={data.metrics} />;
      case HealthMetricTypes.BloodPressure:
        return <UpdateBloodPressure metric={data.metrics} />;
      case HealthMetricTypes.Cholesterol:
        return <UpdateCholesterol metric={data.metrics} />;
      default:
        return null;
    }
  };

  return (
    <div className="flex gap-1">
      <button
        data-testid="pencil_icon"
        onClick={() =>
          dispatch(
            openModal({
              size: 'base',
              hideClose: true,
              modalContent: (
                <HealthMetricsModal
                  metricDate={data.collectedDate}
                  metricId={data._id}
                  type={metricType}
                  isEdit
                >
                  {getMetricForm()}
                </HealthMetricsModal>
              )
            })
          )
        }
      >
        <Common.Icon name="pencil" className="size-4 hover:text-primary-500" />
      </button>
    </div>
  );
};

export default EditCell;
