export enum AlertBaseOn {
  CreateDate = 'createdAt',
  DueDate = 'dueDate'
}

export enum OccurrenceOrder {
  Before = 'before',
  After = 'after'
}

export enum AlertUnit {
  Mins = 'Mins',
  Min = 'min',
  Hrs = 'hrs'
}
