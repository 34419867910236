import { ReportsFilterData } from 'constants/filters';

import React, { useEffect, useState } from 'react';

import { Common } from '@thecvlb/design-system';
import FilterButtons from 'components/filters/FilterTabs';
import { DashboardCard } from 'components/reports/DashboardCard/dashboardCard';
import { DashboardCardProps } from 'components/reports/DashboardCard/dashboardCard.types';
import dayjs from 'dayjs';
import { DateFormat } from 'enums/dateFormats';
import { PathName } from 'enums/pathName';
import fileDownload from 'js-file-download';
import { getDefaultReportsParams } from 'pages/AdminReports/adminReports.settings';
import queryString from 'query-string';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  AppointmentsDashboardType,
  MessagesDashboardType,
  PatientDashboardType
} from 'store/physicianReports/physicianReports.types';
import {
  useLazyGetDownloadReportQuery,
  useLazyGetPhysicianReportsAssigneeQuery,
  useLazyGetPhysicianReportsDashboardQuery,
  useLazyGetPhysicianReportsStaffQuery,
  useLazyGetPhysicianReportsTasksQuery
} from 'store/physicianReports/physicianReportsSlice';

import { tableNames } from './physicianReports.settings';
import PhysicianReportsTables from './PhysicianReportsTables/PhysicianReportsTables';

const PhysicianReports: React.FC = () => {
  const [getReportsDashboard, { data: dashboardData, isFetching: isFetchingDashboard }] =
    useLazyGetPhysicianReportsDashboardQuery();
  const [getReportsTasks, { data: tasksData, isFetching: isFetchingTasks }] =
    useLazyGetPhysicianReportsTasksQuery();
  const [getStaffRequests, { data: staffData, isFetching: isFetchingStaffData }] =
    useLazyGetPhysicianReportsStaffQuery();
  const [getAssigneeRequests, { data: assigneeData, isFetching: isFetchingAssigneeData }] =
    useLazyGetPhysicianReportsAssigneeQuery();
  const [getDownloadReports, { isLoading: isDownloading }] = useLazyGetDownloadReportQuery();
  const [loadData, setLoadData] = useState<Record<string, boolean | undefined>>({
    staff: false,
    assignee: false
  });
  const navigate = useNavigate();
  const location = useLocation();

  const isLoadingReports =
    isFetchingDashboard || isFetchingTasks || isFetchingStaffData || isFetchingAssigneeData;
  const isDisabledDownload = isLoadingReports || isDownloading;

  const dashboardDataFormat = (
    dashData: PatientDashboardType | AppointmentsDashboardType | MessagesDashboardType | undefined
  ): DashboardCardProps['text'] => {
    return dashData
      ? Object.keys(dashData).map((item) => ({
          key: item,
          value: dashData[item as keyof typeof dashData]
        }))
      : [];
  };

  const onClickDownload = () => {
    if (location.search) {
      const params = queryString.parse(location.search);
      getDownloadReports({
        params: queryString.stringify({ startDate: params.startDate, endDate: params.endDate })
      })
        .unwrap()
        .then((res) => {
          fileDownload(
            res,
            `lifemd-care-report-${dayjs().format(DateFormat.YYYY_MMM_DD_hh_mm_A)}.csv`
          );
        });
    }
  };

  useEffect(() => {
    if (location.search) {
      const params = queryString.parse(location.search);
      getReportsDashboard(
        {
          params: queryString.stringify({
            startDate: params.startDate,
            endDate: params.endDate
          })
        },
        true
      );
      getReportsTasks(
        {
          params: queryString.stringify({
            pageNo: params['rpt-tasks'],
            limit: 10,
            startDate: params.startDate,
            endDate: params.endDate,
            sortField: params['rpt-tasks' + '--sf'],
            sortOrder: params['rpt-tasks' + '--so']
          })
        },
        true
      );
      loadData.staff &&
        getStaffRequests(
          {
            params: queryString.stringify({
              pageNo: params['rqst-stf'],
              limit: 10,
              startDate: params.startDate,
              endDate: params.endDate,
              sortField: params['rqst-stf' + '--sf'],
              sortOrder: params['rqst-stf' + '--so']
            })
          },
          true
        );
      loadData.assignee &&
        getAssigneeRequests(
          {
            params: queryString.stringify({
              pageNo: params['rqst-asgn'],
              limit: 10,
              startDate: params.startDate,
              endDate: params.endDate,
              sortField: params['rqst-asgn' + '--sf'],
              sortOrder: params['rqst-asgn' + '--so']
            })
          },
          true
        );
    } else {
      let queryParams = getDefaultReportsParams();
      tableNames.map((item) => {
        queryParams = { ...queryParams, [item]: 0 };
      });
      navigate(
        {
          pathname: PathName.PhysicianReports,
          search: queryString.stringify(queryParams)
        },
        { replace: true }
      );
    }
  }, [
    location.search,
    loadData,
    navigate,
    getReportsDashboard,
    getReportsTasks,
    getStaffRequests,
    getAssigneeRequests
  ]);

  return (
    <React.Fragment>
      <div className="flex justify-between">
        <div className="flex items-center justify-between">
          <FilterButtons filterData={ReportsFilterData} queryParam="dateType" />
        </div>
        <Common.Button
          size="sm"
          style="pill"
          color="green-alt"
          preIcon="download"
          onClick={onClickDownload}
          disabled={isDisabledDownload}
        >
          Download
        </Common.Button>
      </div>
      <div className="mb-4">
        <div className="mb-4 mr-5 mt-5 grid grid-cols-2">
          <DashboardCard
            title="My patients"
            text={dashboardDataFormat(dashboardData?.Patients)}
            color="primary"
            isInfoRequired={true}
            isFetching={isFetchingDashboard}
          />
        </div>
        <div className="grid grid-cols-2 gap-5">
          <DashboardCard
            title="Appointments"
            text={dashboardDataFormat(dashboardData?.Appointments)}
            color="secondary-light"
            isInfoRequired={true}
            isFetching={isFetchingDashboard}
          />
          <DashboardCard
            title="Messages"
            text={dashboardDataFormat(dashboardData?.Messages)}
            color="secondary-light"
            isInfoRequired={true}
            isFetching={isFetchingDashboard}
          />
        </div>
      </div>
      <PhysicianReportsTables
        myTasksData={tasksData}
        myTasksDataCount={0}
        myTasksDataIsFetching={isFetchingTasks}
        requestsToStaff={staffData}
        requestsToStaffCount={0}
        requestsToStaffIsFetching={isFetchingStaffData}
        requestsByAssignee={assigneeData?.data}
        requestsByAssigneeCount={assigneeData?.totalCount}
        requestsByAssigneeIsFetching={isFetchingAssigneeData}
        setLoad={setLoadData}
      />
    </React.Fragment>
  );
};
export default PhysicianReports;
