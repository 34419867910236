export const AUDIENCE_OPTIONS = [
  { label: 'Admin', value: 'AD' },
  { label: 'Physician', value: 'PH' },
  { label: 'Nurse Practitioner', value: 'NP' },
  { label: 'Medical Assistant', value: 'MA' },
  { label: 'Customer Service Rep', value: 'CS' }
];

export const CATEGORY_OPTION = [
  { label: 'Training', value: 'Training' },
  { label: 'Protocol', value: 'Protocol' }
];
