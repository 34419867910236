import { createColumnHelper } from '@tanstack/react-table';
import dayjs from 'dayjs';
import { DateFormat } from 'enums/dateFormats';
import { Rating } from 'react-simple-star-rating';

import ActionsCell from './ActionsCell';
import { ReviewsTableProps } from './reviewsTable.types';
import BulkEditCheckbox from '../BasicTable/BulkEditCheckox';
import Header from '../Header';
const columnHelper = createColumnHelper<ReviewsTableProps>();

export const columns = [
  columnHelper.accessor('_id', {
    header: ({ table }) => (
      <BulkEditCheckbox
        onChange={table.getToggleAllRowsSelectedHandler()}
        checked={table.getIsAllRowsSelected()}
        reset={table.resetRowSelection}
        isHeading
        ids={table.getGroupedRowModel().rows.map((id) => id.original._id)}
      />
    ),
    cell: ({ row }) => (
      <BulkEditCheckbox
        onChange={row.getToggleSelectedHandler()}
        setSelection={row.toggleSelected}
        checked={row.getIsSelected()}
        id={row.original._id}
      />
    )
  }),
  columnHelper.accessor('patientInfo', {
    header: () => <Header text="Name" sortField="patientName" />,
    size: 300,
    id: 'patientName',
    cell: (info) => (
      <span className="max-w-[300px] font-medium">
        {info.row.original.patientInfo?.name}
        {info.row.original.patientInfo?.dob
          ? `, ${dayjs().diff(info.row.original.patientInfo.dob, 'year')}`
          : null}
      </span>
    )
  }),
  columnHelper.accessor('rating', {
    header: () => <Header text="Rating" sortField="rating" />,
    cell: (info) => (
      <div>
        <Rating
          initialValue={Number(info.getValue()) || 0}
          readonly={true}
          SVGclassName="inline-block"
          size={16}
          SVGstorkeWidth="1"
          fillColor="#FFA700"
          SVGstrokeColor="#FFA700"
          emptyColor="transparent"
        />
      </div>
    )
  }),
  columnHelper.accessor('createdAt', {
    header: () => <Header text="Date" sortField="createdAt" />,
    cell: (info) =>
      dayjs(info.getValue()).isToday()
        ? 'Today'
        : dayjs(info.getValue()).format(DateFormat.MMM_D_YYYY)
  }),
  columnHelper.accessor('providerInfo', {
    id: 'providerName',
    header: () => <Header text="Provider" sortField="providerName" />,
    cell: (info) => (
      <p className="truncate text-sm font-medium">{info.row.original.providerInfo?.name}</p>
    )
  }),
  columnHelper.accessor('reviewComment', {
    header: () => <Header text="Review content" sortField="reviewComment" noSort />,
    cell: (info) => <p className="truncate text-sm font-medium">{info.getValue()}</p>,
    size: 250
  }),
  columnHelper.accessor('status', {
    header: () => <div className="text-center text-sm font-bold">Actions</div>,
    cell: (info) => <ActionsCell status={info.getValue()} id={info.row.original._id} />,
    enableSorting: false
  })
];
