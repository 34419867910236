import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from 'store';

import { SystemProps } from './system.types';

const initialState: SystemProps = {
  wasLoggedOutManually: false,
  startLocation: null
};

const systemSlice = createSlice({
  name: 'system',
  initialState,
  reducers: {
    setWasLoggedOutManually: (state, action: PayloadAction<boolean>) => {
      state.wasLoggedOutManually = action.payload;
    },
    setStartLocation: (state, action: PayloadAction<SystemProps['startLocation']>) => {
      state.startLocation = action.payload;
    }
  }
});

export const selectSystem = (state: RootState) => state.system;

export const { setStartLocation, setWasLoggedOutManually } = systemSlice.actions;

export default systemSlice.reducer;
