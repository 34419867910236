import MIFOption from 'components/tasks/slidingPane/MIFDetails/MIFOption';
import isString from 'lodash/isString';
import { sanitizeHTML } from 'utils/common/parseHTML';

import { MIFQuestionProps } from './mifQuestion.types';

const SimpleMIFQuestion: React.FC<MIFQuestionProps> = ({ question, showAllOptions }) => {
  const answersArray = Array.isArray(question.answer)
    ? question.answer
    : question.answer
      ? [question.answer]
      : [];

  const isAnswerWithOtherOptions = answersArray.includes('Other');
  const sortedAnswersArray = isAnswerWithOtherOptions
    ? [...answersArray].sort((a, b) => (a === 'Other' ? 1 : b === 'Other' ? -1 : 0))
    : answersArray;

  const options = question.options || sortedAnswersArray;

  return (
    <div className="flex flex-col gap-1 text-base">
      <span className="font-semibold">{sanitizeHTML(question.question, [])}</span>
      <span className="flex flex-col gap-1 font-medium text-gray-500">
        {(showAllOptions ? options : sortedAnswersArray).map((option) => (
          <MIFOption
            key={option}
            warning={question.warning}
            option={option}
            showAllOptions={showAllOptions}
            answersArray={sortedAnswersArray}
          />
        ))}
      </span>
      {question.additionalDetails?.map((additionalOption) => {
        const additionalOptionValue = isString(additionalOption)
          ? additionalOption
          : additionalOption.value;
        return (
          <MIFOption
            key={additionalOptionValue}
            warning={question.warning}
            option={additionalOptionValue}
            showAllOptions={showAllOptions}
            answersArray={sortedAnswersArray}
            isAdditionalOption
          />
        );
      })}
    </div>
  );
};

export default SimpleMIFQuestion;
