import classNames from 'classnames';

export const getStatusClasses = (status: string) =>
  classNames('flex items-center rounded w-fit py-0.5 px-2', {
    'bg-green-100 text-green-600': status === 'successful',
    'bg-gray-100 text-gray-600': status === 'pending',
    'bg-red-100 text-red-600': status === 'not-processed'
  });

export const getBalanceClasses = (balance: number) => {
  return balance < 0 ? 'text-red-600' : 'text-gray-600';
};

export const getBalanceTitle = (balance: number) => {
  return balance < 0 ? `-$${balance.toFixed(2).substring(1)}` : `$${balance.toFixed(2)}`;
};
