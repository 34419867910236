import React from 'react';

const TableSkeleton: React.FC<{ columnCount?: number }> = ({ columnCount = 6 }) => {
  return (
    <div className="rounded-lg bg-white shadow">
      <table className="w-full rounded-lg">
        <thead>
          <tr>
            {Array.from(Array(columnCount).keys()).map((hIdx) => (
              <th key={hIdx} className="h-12 min-w-[60px] bg-gray-100 pl-2 text-left">
                <div className="h-4 min-w-[40px] max-w-[80px] animate-pulse rounded bg-slate-200" />
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="w-fit bg-white">
          {Array.from(Array(10).keys()).map((idx) => (
            <tr key={idx} className="h-14 border-t border-t-gray-100">
              {Array.from(Array(columnCount).keys()).map((cIdx) => {
                return (
                  <td
                    key={cIdx}
                    className="min-w-[60px] border-b border-gray-200 p-3 text-sm font-medium"
                  >
                    <div className="h-4 min-w-[60px] max-w-[90px] animate-pulse rounded bg-slate-200" />
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TableSkeleton;
