import * as RadioGroup from '@radix-ui/react-radio-group';
import { Common } from '@thecvlb/design-system';
import { EditEventOption, EditEventOptions } from 'models/event.types';
import { useForm } from 'react-hook-form';

import type { EditEventFormProps, EditFormValues } from './editEvent.types';

const indicatorClasses =
  'flex h-full w-full items-center justify-center after:h-1.5 after:w-1.5 after:rounded-full after:bg-white';
const labelClasses = 'flex flex-col text-base text-gray-700 font-medium';
const forGroupClasses = 'flex items-center gap-2 mb-2';

const EditEventForm = ({
  isLoading,
  isEditing,
  options,
  onSubmit,
  onClose
}: EditEventFormProps) => {
  const { handleSubmit, watch, setValue, getValues } = useForm<EditFormValues>({
    defaultValues: {
      editEventOption: Object.keys(options)[0] as EditEventOption
    }
  });

  const isDisabled = isLoading || isEditing;

  const getRadioItemClasses = (value: string) => {
    return `h-4 w-4 rounded-full border disabled:bg-gray-800/20 ${
      watch('editEventOption') === value && !isEditing
        ? 'border-blue bg-blue'
        : 'border-gray-400 bg-white'
    }`;
  };

  return (
    <form data-testid="update_confirmation_popup_shift_time_off" onSubmit={handleSubmit(onSubmit)}>
      <RadioGroup.Root
        defaultValue={getValues('editEventOption')}
        value={watch('editEventOption')}
        onValueChange={(value) =>
          setValue('editEventOption', value as EditEventOption, { shouldDirty: true })
        }
        disabled={isEditing}
      >
        {!isLoading ? (
          Object.entries(options).map((entry) => {
            const [key, value] = entry;

            return (
              <div className={forGroupClasses} key={key}>
                <RadioGroup.Item value={key} className={getRadioItemClasses(key)} id={value}>
                  <RadioGroup.Indicator className={indicatorClasses} />
                </RadioGroup.Item>
                <label className={labelClasses} htmlFor={value}>
                  {value}

                  {key === EditEventOptions.ALL && (
                    <span className="text-xs">
                      <strong>Caution:</strong> selecting this last option will update historical
                      data
                    </span>
                  )}
                </label>
              </div>
            );
          })
        ) : (
          <div
            className="flex animate-pulse flex-col gap-2"
            data-testid="delete-event-options-skeleton"
          >
            <div className="h-4 w-2/5 rounded-lg bg-slate-200" />
            <div className="h-4 w-3/5 rounded-lg bg-slate-200" />
            <div className="h-4 w-4/5 rounded-lg bg-slate-200" />
          </div>
        )}
      </RadioGroup.Root>
      <div className="mt-6 flex items-center gap-2">
        <Common.Button
          color="white-alt"
          size="sm"
          className="w-full justify-center"
          type="button"
          onClick={onClose}
        >
          Cancel
        </Common.Button>
        <Common.Button
          disabled={isDisabled}
          color="blue"
          size="sm"
          className="w-full justify-center"
          type="submit"
        >
          Confirm update
        </Common.Button>
      </div>
    </form>
  );
};

export default EditEventForm;
