import { CATEGORY_OPTIONS, LENGTH_OPTIONS } from 'constants/appointmentType';
import { STATUS_OPTIONS } from 'constants/status';

import ControlledSelect from 'components/forms/controlled/ControlledSelect';
import InputField from 'components/forms/controlled/InputField';
import Footer from 'components/modals/Footer';
import { useTableFiltersData } from 'hooks/filters/useTableFiltersData';
import { useAppDispatch } from 'hooks/redux';
import isEqual from 'lodash/isEqual';
import queryString from 'query-string';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { closeModal } from 'store/modal/modalSlice';
import { getAppliedFilterValues, handleReset } from 'utils/filters/filters';

import { defaultValues } from './appointmentTypesFiltersForm.settings';
import { AppointmentTypesFiltersFormProps } from './appointmentTypesFiltersForm.types';

const AppointmentTypesFiltersForm = () => {
  const { control, watch, handleSubmit, reset } = useForm<AppointmentTypesFiltersFormProps>({
    reValidateMode: 'onChange'
  });
  const [formData, setFormData] = useTableFiltersData('appointmentTypes', defaultValues, reset);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const onSubmit = (data: AppointmentTypesFiltersFormProps) => {
    setFormData({ ...defaultValues, ...data });
    const parsedUrl = queryString.parse(location.search);
    const filters = {
      ...parsedUrl,
      pageNo: '0',
      ...data,
      searchKey: data.searchKey && encodeURIComponent(data.searchKey),
      displayName: data.displayName && encodeURIComponent(data.displayName),
      uniqueId: data.uniqueId && encodeURIComponent(data.uniqueId),
      length: data.length?.value,
      category: data.category?.value,
      status: data.status?.value
    };

    const appliedFilterValues = getAppliedFilterValues(filters);

    if (!isEqual(appliedFilterValues, parsedUrl)) {
      navigate({ search: queryString.stringify(appliedFilterValues) });
    }
    dispatch(closeModal());
  };

  watch();

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="grid grid-cols-2">
        <div className="flex flex-col gap-4 border-r border-gray-200 pr-7">
          <InputField
            name="displayName"
            label="Appointment type"
            type="text"
            placeholder="Enter appointment type"
            control={control}
            value={formData?.displayName}
          />
          <ControlledSelect
            control={control}
            labelDirection="col"
            options={LENGTH_OPTIONS}
            placeholder="Select length"
            label="Length"
            name="length"
            defaultValue={formData?.length}
          />
          <InputField
            name="uniqueId"
            label="Unique ID"
            type="text"
            placeholder="Enter unique ID"
            control={control}
            value={formData?.uniqueId}
          />
        </div>
        <div className="flex flex-col gap-4 pl-7">
          <ControlledSelect
            control={control}
            labelDirection="col"
            options={CATEGORY_OPTIONS}
            placeholder="Select category"
            label="Category"
            name="category"
            defaultValue={formData?.category}
          />
          <ControlledSelect
            control={control}
            labelDirection="col"
            options={STATUS_OPTIONS}
            placeholder="Select status"
            label="Status"
            name="status"
            defaultValue={formData?.status}
          />
        </div>
      </div>
      <Footer onClick={() => handleReset(dispatch, reset, defaultValues, navigate)} />
    </form>
  );
};

export default AppointmentTypesFiltersForm;
