import { ChangeEvent, ChangeEventHandler, useEffect } from 'react';

import { Common } from '@thecvlb/design-system/lib/src';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import {
  removeBulkEdit,
  resetBulkEdits,
  selectBulkEdit,
  setAllBulkEdits,
  setBulkEdit
} from 'store/bulkEdit/bulkEditSlice';

const BulkEditCheckbox: React.FC<{
  id?: string;
  onChange: ChangeEventHandler<HTMLInputElement>;
  checked?: boolean;
  reset?: () => void;
  isHeading?: boolean;
  ids?: string[];
  setSelection?: (value: boolean) => void;
}> = ({ id, onChange, checked, reset, isHeading, ids, setSelection }) => {
  const dispatch = useAppDispatch();
  const { bulkEdits } = useAppSelector(selectBulkEdit);

  const isChecked = checked === undefined ? bulkEdits.includes(id || '') : checked;

  // Following useEffects keep in sync BulkEdits store and Checkboxes state on various cases
  useEffect(() => {
    // if bulkEdits store was reseted/cleared - reset checkboxes
    if (!bulkEdits.length && reset && isHeading) {
      reset();
    }
  }, [bulkEdits, reset, isHeading]);

  useEffect(() => {
    // if checkbox is selected but there is no such id in bulkEdits store ( some table tab was changed for example) - unselect it
    if (bulkEdits.length && id && isChecked && !bulkEdits.includes(id)) {
      setSelection && setSelection(false);
    }
  }, [bulkEdits, isChecked, id, setSelection]);

  useEffect(() => {
    // if some id is presented in bulkEdits store but no in a table - remove it from store
    if (ids?.length && bulkEdits.length)
      bulkEdits.forEach((item) => {
        if (!ids.includes(item)) dispatch(removeBulkEdit(item));
      });
  }, [bulkEdits, dispatch, ids]);
  // End of BulkEdits store and Checkboxes state syncing

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (isHeading) {
      event.target.checked && ids?.length
        ? dispatch(setAllBulkEdits(ids))
        : dispatch(resetBulkEdits());
    } else {
      event.target.checked ? dispatch(setBulkEdit(id)) : dispatch(removeBulkEdit(id));
    }
    onChange(event);
  };

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <Common.Checkbox color="blue" checked={isChecked} onChange={handleOnChange}></Common.Checkbox>
    </div>
  );
};

export default BulkEditCheckbox;
