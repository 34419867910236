import { Common } from '@thecvlb/design-system/lib/src';

import { RefreshTasksProps } from './refreshTasks.types';

const RefreshTasks: React.FC<RefreshTasksProps> = ({ amount = 50, handleOnClick }) => {
  const buttonClassnames =
    'absolute inset-x-0 top-[65px] z-50 mx-auto flex justify-center max-w-[190px] gap-1 rounded-full border border-gray-200 bg-white px-5 py-1 text-base font-bold shadow-xl';
  return (
    <button
      data-testid="show_new_task_btn"
      type="button"
      className={buttonClassnames}
      onClick={handleOnClick}
    >
      <Common.Icon name="repeat" />
      {amount ? `Show ${amount} new ${amount === 1 ? 'task' : 'tasks'}` : `Refresh tasks`}
    </button>
  );
};

export default RefreshTasks;
