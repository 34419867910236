import React from 'react';

const UserTimeEmptyMessage: React.FC<{
  emptyMessage?: string;
}> = ({ emptyMessage }) => {
  return (
    <div className="flex h-44 w-full">
      <div className="flex w-44 flex-none items-center py-1 pl-6 pr-3" />
      <div className="flex w-full items-center justify-center text-xl font-semibold text-gray-700">
        {emptyMessage}
      </div>
    </div>
  );
};

export default UserTimeEmptyMessage;
