import React from 'react';

import { Common } from '@thecvlb/design-system';
import classNames from 'classnames';

import { generateShiftTypesList } from './filter.setting';
import { FiltersTypes } from './filters.types';

const Filters: React.FC<FiltersTypes> = ({
  selectedShiftTypes,
  setSelectedShiftType,
  shiftTypes
}) => {
  const shiftTypesList = generateShiftTypesList(shiftTypes ?? []);

  return (
    <div data-testid="availability_filters" className="flex items-center gap-2">
      {shiftTypesList.map((item, idx) => {
        const isActive = item._id === selectedShiftTypes;
        const buttonClassName = classNames('!px-3 !font-semibold', {
          'bg-gray-100 text-gray-500': !isActive
        });

        return (
          <Common.Button
            key={idx}
            onClick={() => setSelectedShiftType(item._id)}
            size="sm"
            style="pill"
            className={buttonClassName}
            {...(isActive && { color: 'green' })}
          >
            {item.title}
          </Common.Button>
        );
      })}
    </div>
  );
};

export default Filters;
