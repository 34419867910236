import Loader from 'components/common/Loader';
import { useLazyDownloadIdentityJobsQuery } from 'store/patientIdentity/patientIdentitySlice';

import { IdentityDocumentFileData } from '../identitySection.types';

const FileLabel: React.FC<{ fileData: IdentityDocumentFileData }> = ({ fileData }) => {
  const [downloadIdentityJobs, { isLoading: isDownloading, isFetching }] =
    useLazyDownloadIdentityJobsQuery();
  const showLoader = isDownloading || isFetching;
  const lastIndexOfDot = fileData.fileName?.lastIndexOf('.');
  const pdfFileName =
    lastIndexOfDot !== -1 ? fileData.fileName?.slice(0, lastIndexOfDot) : fileData.fileName;

  const handleClickDocument = () => {
    const jobId = fileData.identity?.jobId;
    if (jobId) {
      downloadIdentityJobs({ jobId })
        .unwrap()
        .then((response) => {
          window.open(window.URL.createObjectURL(response));
        });
    } else {
      window.open(fileData.filePath, '_blank');
    }
  };

  return (
    <>
      {showLoader && <Loader isVisible />}
      <span
        key={fileData._id || fileData.label}
        className="max-w-[120px] cursor-pointer overflow-hidden text-ellipsis text-sm font-medium text-gray-500"
        onClick={handleClickDocument}
      >
        {fileData.label}{' '}
        {!!fileData.fileName && (
          <span className="underline">
            {fileData.identity?.jobId ? pdfFileName : fileData.fileName}
          </span>
        )}
      </span>
    </>
  );
};

export default FileLabel;
