import { NotificationsFiltersFormProps } from './NotificationsFiltersForm.types';

export const defaultValues: NotificationsFiltersFormProps = {
  patientNames: [],
  category: { label: '', value: '' },
  searchKey: '',
  urgencyTask: { label: '', value: '' },
  createdAt: '',
  startCreateDate: '',
  endCreateDate: ''
};

export const URGENCY_TASK_FILTER = [
  { label: 'Urgent', value: 'true' },
  { label: 'Not urgent', value: 'false' }
];
