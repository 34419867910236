import React from 'react';

import StaffCard from './StaffCard';
import { StaffPickerTypes } from './staffPicker.types';
import { Provider } from '../dateAndTime.types';

const StaffPicker: React.FC<StaffPickerTypes> = ({
  isLoading,
  provider,
  setProvider,
  primaryStaffInfo
}) => {
  const wrapperClassName =
    'mb-4 flex flex-row items-stretch justify-between gap-3 self-stretch md:mb-8 md:flex-row md:gap-4';

  return (
    <div className={wrapperClassName}>
      <StaffCard
        currentProvider={Provider.primary}
        activeProvider={provider}
        staffInfo={primaryStaffInfo}
        setProvider={(value) => setProvider(value)}
        isLoading={isLoading}
      />

      <StaffCard
        currentProvider={Provider.any}
        activeProvider={provider}
        setProvider={(value) => setProvider(value)}
        isLoading={isLoading}
      />
    </div>
  );
};

export default StaffPicker;
