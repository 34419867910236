import { useEffect, useState } from 'react';

import classNames from 'classnames';

import { ProgressBarProps } from './progressBar.types';

const ProgressBar: React.FC<ProgressBarProps> = ({
  step,
  totalSteps,
  color = 'secondary',
  height = '',
  rounded = false
}) => {
  const [progress, setProgress] = useState('0%');

  useEffect(() => {
    requestAnimationFrame(() => {
      setProgress(`${(step / totalSteps) * 100}%`);
    });
  }, [step, totalSteps]);

  const getStepperClassNames = classNames(`bg-${color} ${height ? `h-${height}` : 'h-1'}`, {
    'rounded-l-lg': rounded,
    'rounded-r-lg': step !== totalSteps,
    'transition-all duration-300': totalSteps > 1
  });

  const wrapperClassName = classNames('w-full bg-gray-200 transition-all duration-300', {
    'rounded-lg': rounded
  });

  return (
    <div className={wrapperClassName}>
      <div className={getStepperClassNames} style={{ width: progress }} />
    </div>
  );
};

export default ProgressBar;
