import { Common } from '@thecvlb/design-system';

import { RetryBlockProps } from './retryBlock.types';

const RetryBlock: React.FC<RetryBlockProps> = ({
  text = 'Unable to load messages.',
  btnText = 'Retry',
  handleClick
}) => {
  return (
    <div className="flex flex-col items-center justify-center gap-y-4">
      <span className="text-base font-semibold text-gray">{text}</span>
      <Common.Button size="sm" color="white-alt" preIcon="repeat" onClick={handleClick}>
        <span className="text-sm font-bold text-gray-700">{btnText}</span>
      </Common.Button>
    </div>
  );
};

export default RetryBlock;
