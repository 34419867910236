import dayjs from 'dayjs';
// eslint-disable-next-line import/order
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';

dayjs.extend(isSameOrBefore);

import { FULL_NAME_REGEXP, ZIP_REGEXP } from 'utils/regExp';

export const cardNumberValidationRules = {
  required: {
    value: true,
    message: 'Card number is required'
  },
  validate: {
    isValidCardNumber: (v: string) => {
      const dryValue = v?.replace(/ /g, '');
      return (dryValue && dryValue.length > 12 && dryValue.length < 17) || 'Invalid card number';
    }
  }
};

export const expDateValidationRules = {
  required: {
    value: true,
    message: 'Expiration date is required'
  },
  validate: {
    isValidExpDate: (value: string) => {
      const today = new Date();
      const currentDay = dayjs
        .utc(
          new Date(
            today.getFullYear() +
              '/' +
              (today.getMonth() + 1) +
              '/' +
              new Date(today.getFullYear(), today.getMonth() + 1, 0).getDate()
          )
        )
        .format();

      const visaValue = value.split('/');
      const visaDate = dayjs
        .utc(new Date(Number(`20${visaValue[1]}`), Number(visaValue[0]), 0))
        .format();

      return (
        (value &&
          value.length === 5 &&
          Number(value.substring(0, 2)) <= 13 &&
          dayjs(currentDay).isSameOrBefore(visaDate)) ||
        'Invalid expiration date'
      );
    }
  }
};

export const zipCodeValidationRules = {
  required: {
    value: true,
    message: 'ZIP code is required'
  },
  pattern: {
    value: ZIP_REGEXP,
    message: 'Invalid ZIP code'
  }
};

export const getCVVFormat = (cardNumber: string) => {
  const amexCardPan = ['34', '37'];

  const cvvFormat = amexCardPan.includes(cardNumber?.trim().substring(0, 2)) ? 4 : 3;
  return Array(cvvFormat).fill('#').join('');
};

export const fullNameValidationRules = {
  required: {
    value: true,
    message: 'Full name is required'
  },
  pattern: {
    value: FULL_NAME_REGEXP,
    message: 'Invalid full name'
  }
};
