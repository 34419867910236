import React, { useEffect } from 'react';

import { ColumnDef, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { Common } from '@thecvlb/design-system/lib/src';
import Card from 'components/common/Card';
import { AgingAlertsTableProps } from 'components/tables/AgingAlertsTable/agingAlertsTable.types';
import BasicTable from 'components/tables/BasicTable';
import { columns } from 'components/tables/TeamsTable/columns';
import { useGetDefaultBasicTableValues } from 'hooks/common/useGetDefaultBasicTableValues';
import { useHeaderParams } from 'hooks/common/useHeaderParams';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import { useLazyGetTeamsQuery } from 'store/teams/teamsSlice';

const Teams: React.FC = () => {
  const location = useLocation();

  const { onClickNew, buttonTitle } = useHeaderParams();
  const [getTeams, { data, isFetching, isUninitialized }] = useLazyGetTeamsQuery();

  const [tableData, tableColumns] = useGetDefaultBasicTableValues({
    isFetching: isFetching || isUninitialized,
    columns,
    data: data?.teams
  });
  const table = useReactTable({
    data: tableData,
    columns: tableColumns as ColumnDef<AgingAlertsTableProps, unknown>[],
    getCoreRowModel: getCoreRowModel()
  });

  useEffect(() => {
    getTeams({ params: queryString.parse(location.search) }, true);
  }, [location.search, getTeams]);

  return (
    <>
      <div className="my-5 flex justify-end gap-2">
        {onClickNew && (
          <Common.Button
            style="pill"
            preIcon="plus-small"
            color="green-alt"
            size="sm"
            onClick={onClickNew}
          >
            {buttonTitle}
          </Common.Button>
        )}
      </div>
      <Card>
        <BasicTable table={table} totalCount={data?.totalCount || 0} isFetching={isFetching} />
      </Card>
    </>
  );
};

export default Teams;
