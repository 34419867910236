import { ACCEPT_PROFILE_IMG_EXTENSIONS } from 'constants/extensions';

import { useRef, useState } from 'react';

import { Common } from '@thecvlb/design-system';
import { useChangeImage } from 'hooks/components/StaffMember/useChangeImage';

import { StandardHeaderProps } from './standardHeader.types';
import { handleAvatarChange, handleClick } from '../staffMember.settings';

const StandardHeader: React.FC<StandardHeaderProps> = ({ staffImage = '', handleLogoUpdate }) => {
  const [selectedFile, setSelectedFile] = useState<File>();
  const [imgSrc] = useChangeImage({ selectedFile });
  const containerRef = useRef<HTMLInputElement>(null);

  return (
    <div className="mt-7 flex items-center">
      <span className="w-[120px] text-sm font-semibold text-gray-700">Profile photo</span>
      {imgSrc ? (
        <Common.ProfileImage
          src={typeof imgSrc === 'string' && imgSrc.length ? imgSrc : staffImage}
          size="lg"
        />
      ) : (
        <div className="flex size-12 items-center justify-center rounded-full border border-gray-100">
          <Common.Icon name="camera" className="size-5" />
        </div>
      )}
      <input
        id="uploadAvatar"
        style={{ display: 'none' }}
        accept={ACCEPT_PROFILE_IMG_EXTENSIONS}
        ref={containerRef}
        type="file"
        multiple={false}
        onChange={(event) => handleAvatarChange({ event, setSelectedFile, handleLogoUpdate })}
      />
      <button
        data-testid="upload_picture_btn"
        onClick={() => handleClick({ containerRef })}
        className="ml-4 text-sm text-gray-500 underline"
      >
        Upload picture
      </button>
    </div>
  );
};

export default StandardHeader;
