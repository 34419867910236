import Appointments from 'components/patient/Appointments';
import Billing from 'components/patient/Billing';
import Chart from 'components/patient/Chart';
import Documents from 'components/patient/Documents';
import History from 'components/patient/History';
import Insurance from 'components/patient/Insurance';
import Messages from 'components/patient/Messages';
import Overview from 'components/patient/Overview';
import Prescriptions from 'components/patient/Prescriptions';
import ProspectsParameters from 'components/patient/ProspectsParameters';
import StaffNotes from 'components/patient/StaffNotes';
import PatientTasks from 'components/patient/Tasks';
import { FrontDeskProvider } from 'contexts/FrontDeskContext/FrontDeskContext';
import { MessagesProvider } from 'contexts/MessagesContext/MessagesContext';
import { PatientOverviewTab } from 'enums/patientOverviewTabs';
import { PatientProps } from 'store/patients/patients.types';

import { PatientSummaryResult } from '../../components/patient/Overview/PatientSummary/summary.types';
// @MikePrus: Find a way avoid prop drilling passing patient to all components
export const renderContentList = (
  tab: PatientOverviewTab,
  patient: PatientProps,
  id: string,
  patientSummary: PatientSummaryResult,
  isChartDisable?: boolean
) => {
  if (patient) {
    switch (tab) {
      case 'Overview':
        return <Overview patient={patient} patientSummary={patientSummary} />;
      case 'Messages':
        return (
          /* @MikePrus TODO: scope out if we need to split these contexts or split support/care messages states
           in order to avoid message shuffling */
          <MessagesProvider>
            <FrontDeskProvider>
              <Messages patient={patient} />
            </FrontDeskProvider>
          </MessagesProvider>
        );
      case 'Staff notes':
        return <StaffNotes />;
      case 'Appointments':
        return <Appointments patient={patient} />;
      case 'Documents':
        return <Documents patient={patient} />;
      case 'Insurance':
        return <Insurance patientId={id} planCode={patient?.planInfo?.planCode} />;
      case 'Prescriptions':
        return <Prescriptions patient={patient} />;
      case 'Chart':
        return isChartDisable ? (
          <h1 data-testid="no_charts_msg" className="mt-4 text-center font-bold text-primary">
            Patient chart coming soon...
          </h1>
        ) : (
          <Chart patient={patient} />
        );
      case 'Billing':
        return <Billing patient={patient} />;
      case 'Tasks':
        return <PatientTasks patientId={id} />;
      case 'History':
        return <History patientId={id} />;
      case 'Parameters':
        return (
          <ProspectsParameters
            flowQueryParams={patient?.flowQueryParams}
            flowUrl={patient?.flowUrl}
            referrerUrl={patient?.referrerUrl}
          />
        );
    }
  }
};
