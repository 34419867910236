import { useCallback, useMemo, useState } from 'react';

import { Option } from '@thecvlb/design-system/lib/src/common/AutocompleteInputSelect/autocompleteInputSelect.props';
import { notifyError, notifySuccess } from 'components/common/Toast/Toast';
import TimeConflict from 'components/modals/TimeConflict';
import { getAssignProviderData } from 'components/tasks/slidingPane/Dropdown/dropdown.settings';
import dayjs from 'dayjs';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { useLazyGetAppointmentsQuery } from 'store/appointments/appointmentsSlice';
import { closeModal } from 'store/modal/modalSlice';
import {
  selectTask,
  setTaskDetails,
  useAssignProviderMutation,
  useLazyGetTaskDetailsQuery
} from 'store/tasks/tasksSlice';
import { getISOStringIncludingTimezone } from 'utils/helpers';

import { ProviderSearchProps } from './assignProvider.types';
import Form from './Form/Form';

const AssignProvider = () => {
  const dispatch = useAppDispatch();
  const { taskDetails } = useAppSelector(selectTask);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [doctor, setDoctor] = useState<Option>();
  const [getTaskDetails] = useLazyGetTaskDetailsQuery();
  const [assignProvider, { isLoading: isLoadingAssignProvider }] = useAssignProviderMutation();
  const [
    getAppointments,
    { isLoading: isLoadingAvailableProviders, isFetching, data: appointments }
  ] = useLazyGetAppointmentsQuery();

  const isLoading = isLoadingAssignProvider || isLoadingAvailableProviders || isFetching;

  const { isReAssignProviderFlow } = useMemo(
    () => getAssignProviderData(taskDetails),
    [taskDetails]
  );

  const handleAssignProvider = useCallback(
    (data?: ProviderSearchProps) => {
      if (!data?.doctorName?.id && !doctor?.id) return;

      assignProvider({
        appointmentId: taskDetails.appointmentInfo.taskId,
        providerId: data?.doctorName?.id || doctor?.id || '',
        patientId: taskDetails.personalInfo._id
      })
        .unwrap()
        .then((response) => {
          dispatch(closeModal());
          notifySuccess(response.message);
          getTaskDetails({ taskId: taskDetails._id })
            .unwrap()
            .then((taskData) => {
              dispatch(setTaskDetails(taskData));
            });
        });
    },
    [assignProvider, dispatch, doctor?.id, getTaskDetails, taskDetails]
  );

  const handleCheckAvailabilityAndAssignProvider = useCallback(
    (data: ProviderSearchProps) => {
      getAppointments(
        {
          startTime: getISOStringIncludingTimezone(dayjs(), dayjs.tz.guess()),
          endTime: getISOStringIncludingTimezone(dayjs().add(600, 'minute'), dayjs.tz.guess()),
          appointmentStatus: 'pending',
          providerUserId: data.doctorName?.id,
          limit: 1
        },
        true
      )
        .unwrap()
        .then((availableData) => {
          if (data?.doctorName) setDoctor(data.doctorName);

          if (availableData && !availableData.length) {
            handleAssignProvider(data);
          } else {
            setShowCancelModal(true);
          }
        })
        .catch((error) => notifyError(error.data?.message || error.message));
    },
    [getAppointments, handleAssignProvider]
  );

  const actionName = isReAssignProviderFlow ? 'Reassign' : 'Assign';

  return (
    <div data-testid="assign_provider_popup" className="p-6">
      {showCancelModal && (
        <TimeConflict
          setShowModal={setShowCancelModal}
          appointmentStartTime={appointments?.[0]?.appointmentTime?.startTime || ''}
          providerName={doctor?.label}
          assignProviderType={actionName}
          onTakePatient={handleAssignProvider}
          isLoading={isLoading}
        />
      )}
      <h2 className="mb-2 text-xl font-bold text-gray-700">{`${actionName} provider`}</h2>
      <p className="mb-6 text-base font-medium text-gray-700">
        This appointment and future tasks will be assigned to a specific provider. If the patient
        has tasks already assigned to another provider, these will need to be reassigned separately.
      </p>
      <Form
        onSubmit={handleCheckAvailabilityAndAssignProvider}
        isLoading={isLoading}
        actionBtnLabel={actionName}
      />
    </div>
  );
};

export default AssignProvider;
