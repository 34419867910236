import Loader from 'components/common/Loader';
import { notifySuccess } from 'components/common/Toast/Toast';
import { useAppDispatch } from 'hooks/redux';
import { openModal } from 'store/modal/modalSlice';
import { useChangeEmailMutation } from 'store/myProfile/myProfileSlice';

import ConnectedConfirmationCodeForm from './ConfirmationCodeForm';
import UpdateEmailForm from './UpdateEmailForm/UpdateEmailForm';

const ConnectedUpdateEmailForm = () => {
  const dispatch = useAppDispatch();
  const [updateEmail, { isLoading }] = useChangeEmailMutation();

  const onSubmit = (data: { email: string }) => {
    updateEmail({ email: data.email })
      .unwrap()
      .then((res) => {
        notifySuccess(res?.message);
        dispatch(
          openModal({
            size: 'sm',
            modalContent: <ConnectedConfirmationCodeForm />,
            hideClose: true
          })
        );
      })
      .catch((error) => {
        console.error(JSON.stringify(error));
      });
  };

  return (
    <div data-testid="update_email_form" className="p-6">
      <h2 className="mb-6 text-xl font-bold text-gray-700">Update email address</h2>
      <Loader isVisible={isLoading} />
      <UpdateEmailForm onSubmit={onSubmit} isLoading={isLoading} />
    </div>
  );
};

export default ConnectedUpdateEmailForm;
