import HealthMetricsModal from 'components/modals/HealthMetrics';
import UpdateBloodPressure from 'components/modals/HealthMetrics/BloodPressure';
import { HealthMetricTypes } from 'enums/chart';
import { useAppDispatch } from 'hooks/redux';
import { openModal } from 'store/modal/modalSlice';

import { BloodPressureProps } from './bloodPressure.types';
import HealthMetric from '../../HealthMetric/HealthMetric';

const BloodPreasure: React.FC<BloodPressureProps> = ({ bloodPressure }) => {
  const dispatch = useAppDispatch();

  const openCreateMetricModal = () => {
    dispatch(
      openModal({
        size: 'base',
        hideClose: true,
        modalContent: (
          <HealthMetricsModal type={HealthMetricTypes.BloodPressure}>
            <UpdateBloodPressure />
          </HealthMetricsModal>
        )
      })
    );
  };

  const subtitle = bloodPressure ? `${bloodPressure.SYS}/${bloodPressure.DIA}` : '';

  return (
    <HealthMetric
      type={HealthMetricTypes.BloodPressure}
      value={bloodPressure?.DIA}
      metricSubtitle={subtitle}
      openMetricModal={openCreateMetricModal}
    />
  );
};

export default BloodPreasure;
