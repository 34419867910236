import { Common } from '@thecvlb/design-system';

const EmptyChatPlaceholder: React.FC<{ isChannelAvailable: boolean }> = ({
  isChannelAvailable
}) => {
  return (
    <div className="absolute top-1/4 flex w-full flex-col items-center justify-center">
      <div className="w-fit rounded-full bg-gray-100 p-4">
        <Common.Icon className="size-8 text-gray" name="chat-outline" />
      </div>

      <p className="font-bold text-gray">Nothing to see here!</p>
      {isChannelAvailable && (
        <p className="text-sm text-gray">Add a message using the field below.</p>
      )}
    </div>
  );
};

export default EmptyChatPlaceholder;
