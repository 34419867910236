import { AssignValueTypes } from 'store/tasks/task.types';

export const RadioType = {
  rich: 'rich',
  standard: 'standard'
} as const;

export type RadioTypes = keyof typeof RadioType;

export type RadioOption = {
  label: string;
  value: string;
  type: RadioTypes;
  isHighlighted?: boolean;
};

export type AssignFormProps = {
  type: AssignValueTypes;
  role?: string;
  team?: string;
  staff?: {
    id: string;
    label: string;
    value: string;
  };
};
