import { Dispatch, useEffect, useState } from 'react';

import { PathName } from 'enums/pathName';
import { useGetFiltersAppliedCount } from 'hooks/filters/useGetFiltersAppliedCount';
import omit from 'lodash/omit';
import pick from 'lodash/pick';
import queryString from 'query-string';
import type { ParsedQuery } from 'query-string';
import {
  DefaultValues,
  FieldValues,
  KeepStateOptions,
  UseFormGetValues,
  UseFormSetValue
} from 'react-hook-form';
import { NavigateFunction, useLocation, useSearchParams } from 'react-router-dom';
import { closeModal } from 'store/modal/modalSlice';

import { DefaultFilterValues, FiltersType } from './filters.types';

export const handleReset = (
  dispatch: Dispatch<unknown>,
  reset: (values?: DefaultValues<FieldValues>, keepStateOptions?: KeepStateOptions) => void,
  defaultValues: FieldValues,
  navigate: NavigateFunction
) => {
  const defaultUrlParams = { ...queryString.parse(location.search), pageNo: '0' };

  reset(defaultValues);

  navigate({
    search: queryString.stringify(omit(defaultUrlParams, Object.keys(defaultValues)))
  });

  dispatch(closeModal());
};

export function countAppliedFilters(values: FieldValues): number {
  return Object.entries(values).filter(([, value]) => !!value).length;
}

export function getAppliedFilterValues(filters: FiltersType) {
  return Object.fromEntries(Object.entries(filters).filter(([, value]) => !!value));
}

export function pickFilterValues(searchQuery: string, defaultValues: DefaultFilterValues) {
  return pick(queryString.parse(searchQuery), Object.keys(defaultValues));
}

export const useSetDefaultFilterValues = (
  setValue: UseFormSetValue<FieldValues>,
  getValues: UseFormGetValues<FieldValues>,
  defaultFilters: { [key: string]: unknown },
  getFilterValue?: (key: string, val: string, picked: Pick<ParsedQuery, string>) => unknown,
  fieldNameForAppliedCount?: string
) => {
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const [filtersAppliedCount, setFiltersAppliedCount] = useState(0);
  const [appliedFiltersCount] = useGetFiltersAppliedCount(
    getValues,
    fieldNameForAppliedCount ?? ''
  );

  useEffect(() => {
    const picked = pickFilterValues(searchParams.toString(), defaultFilters);
    if (location.pathname.includes(PathName.Tasks) && !picked.futureTasksRange)
      picked.futureTasksRange = '0';

    Object.entries(picked).map(([key, val]) => {
      /* NOTE: we're already having these values from local storage.
        So I've added this condition to avoid rewriting them.
      * */
      if (key !== 'assignedToNames' && key !== 'doctorName') {
        const currentValue = getFilterValue?.(key, val as string, picked) || val;
        setValue(key, currentValue);
      }
    });
    setFiltersAppliedCount(appliedFiltersCount);
  }, [getValues, searchParams, setValue]);

  return { appliedFiltersCount, filtersAppliedCount, setFiltersAppliedCount };
};
