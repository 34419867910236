import MIFOption from 'components/tasks/slidingPane/MIFDetails/MIFOption';

import { COULD_NOT_COMPLETE } from '../mifDetails.settings';
import { MIFQuestionProps } from './mifQuestion.types';

const GroupedMIFQuestion: React.FC<MIFQuestionProps> = ({ question, showAllOptions }) => {
  const answersArray =
    question?.answers?.filter((item) => item.answer !== COULD_NOT_COMPLETE) || [];
  const options = question?.answers || [];

  return (
    <div className="flex flex-col gap-1 text-base">
      <span className="font-semibold">{question.groupTitle}</span>
      <span className="flex flex-col gap-1 font-medium text-gray-500">
        {(showAllOptions ? options : answersArray).map((option) => (
          <MIFOption
            key={option.questionContext}
            warning={option.warning}
            option={`${option.answer !== COULD_NOT_COMPLETE ? option.answer : COULD_NOT_COMPLETE} ${option.questionContext} `}
            isNotComplete={option.answer === COULD_NOT_COMPLETE}
            isGrouped
          />
        ))}
      </span>
    </div>
  );
};

export default GroupedMIFQuestion;
