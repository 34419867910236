import { DEFAULT_SEARCH_PARAMS } from 'constants/tables';

import { PathName } from 'enums/pathName';
import { Role } from 'enums/role';
import ActivityFeed from 'pages/ActivityFeed';
import Admin from 'pages/Admin';
import AdminReports from 'pages/AdminReports';
import AgingConfig from 'pages/AgingConfig';
import Appointments from 'pages/Appointments';
import AppointmentTypes from 'pages/AppointmentTypes';
import Articles from 'pages/Articles';
import Availability from 'pages/Availability';
import BillingConfiguration from 'pages/BillingConfiguration';
import CreateArticle from 'pages/CreateArticle';
import CrossSell from 'pages/CrossSell';
import CustomerFeedback from 'pages/CustomerFeedback';
import FrontDeskRequestTypes from 'pages/FrontDeskRequestTypes';
import InternalAutomations from 'pages/InternalAutomations';
import InternalNoteTemplates from 'pages/InternalNoteTemplates';
import ManageRole from 'pages/ManageRole';
import ManageRoles from 'pages/ManageRoles';
import MedicalAssistantReports from 'pages/MedicalAssistantReports';
import OrderDetails from 'pages/OrderDetails';
import Orders from 'pages/Orders';
import Patient from 'pages/Patient';
import PatientAutomations from 'pages/PatientAutomations/PatientAutomations';
import PatientMessageTemplates from 'pages/PatientMessageTemplates';
import Patients from 'pages/Patients';
import PhysicianReports from 'pages/PhysicianReports';
import Prospects from 'pages/Prospects';
import RequestMessageTemplates from 'pages/RequestMessageTemplates';
import Reviews from 'pages/Reviews';
import Settings from 'pages/Settings';
import SingleArticle from 'pages/SingleArticle';
import StaffCosts from 'pages/StaffCosts';
import StaffFeedback from 'pages/StaffFeedback';
import Staffs from 'pages/Staffs';
import Tasks from 'pages/Tasks';
import Teams from 'pages/Teams';
import { RouteProps } from 'router/router.types';

export const routes: RouteProps[] = [
  {
    path: PathName.CrossSell,
    page: CrossSell,
    permissions: [Role.PH, Role.AD, Role.CS, Role.NP, Role.MA, Role.MD]
  },
  {
    path: PathName.Tasks,
    searchParams: '?limit=50&pageNo=0&sort[dueDate]=ASC',
    label: 'Tasks',
    page: Tasks,
    sidebar: true,
    permissions: [Role.PH, Role.AD, Role.CS, Role.NP, Role.MA]
  },
  {
    path: PathName.ActivityFeed,
    searchParams: '?active-tab=Inbox',
    label: 'Activity feed',
    page: ActivityFeed,
    sidebar: true,
    permissions: [Role.PH, Role.AD, Role.CS, Role.NP, Role.MA]
  },
  {
    path: PathName.Calendar,
    label: 'Calendar',
    page: Appointments,
    sidebar: true,
    permissions: [Role.PH, Role.AD, Role.CS, Role.NP, Role.MA]
  },
  {
    path: PathName.AdminReports,
    label: 'Reports',
    page: AdminReports,
    sidebar: true,
    permissions: [Role.AD]
  },
  {
    path: PathName.MedicalAssistantReports,
    label: 'Reports',
    page: MedicalAssistantReports,
    sidebar: true,
    permissions: [Role.MA]
  },
  {
    path: PathName.PhysicianReports,
    label: 'Reports',
    page: PhysicianReports,
    sidebar: true,
    permissions: [Role.PH]
  },
  {
    path: PathName.Prospect,
    label: 'Patients',
    searchParams: DEFAULT_SEARCH_PARAMS,
    page: Prospects,
    permissions: [Role.AD, Role.CS, Role.NP, Role.MA]
  },
  {
    path: `${PathName.Availability}/:id`,
    label: 'Availability',
    page: Availability,
    permissions: [Role.PH, Role.MA, Role.AD]
  },
  {
    path: PathName.Patients,
    label: 'Patients',
    searchParams: DEFAULT_SEARCH_PARAMS,
    page: Patients,
    sidebar: true,
    permissions: [Role.PH, Role.AD, Role.CS, Role.NP, Role.MA]
  },
  {
    path: `${PathName.Patient}/:patientId`,
    label: 'Patient',
    page: Patient,
    permissions: [Role.PH, Role.AD, Role.CS, Role.NP, Role.MA]
  },
  {
    path: PathName.CreateArticle,
    label: 'CreateArticle',
    page: CreateArticle,
    permissions: [Role.PH, Role.AD, Role.CS, Role.NP, Role.MA]
  },
  {
    path: `${PathName.UpdateArticle}/:articleId`,
    label: 'Article',
    page: CreateArticle,
    permissions: [Role.PH, Role.AD, Role.CS, Role.NP, Role.MA]
  },
  {
    path: `${PathName.Articles}/:articleId`,
    label: 'Article',
    page: SingleArticle,
    permissions: [Role.PH, Role.AD, Role.CS, Role.NP, Role.MA]
  },
  {
    path: PathName.Articles,
    label: 'Articles',
    searchParams: DEFAULT_SEARCH_PARAMS,
    page: Articles,
    sidebar: true,
    permissions: [Role.PH, Role.AD, Role.CS, Role.NP, Role.MA]
  },
  {
    path: PathName.Admin,
    label: 'Admin',
    page: Admin,
    sidebar: true,
    permissions: [Role.AD]
  },
  {
    path: PathName.SystemSettings,
    label: 'System Settings',
    page: Settings,
    permissions: [Role.AD]
  },
  {
    path: PathName.AgingConfiguration,
    label: 'Aging Configurations',
    searchParams: DEFAULT_SEARCH_PARAMS,
    page: AgingConfig,
    permissions: [Role.AD]
  },
  {
    path: PathName.AppointmentTypes,
    label: 'Appointment Types',
    searchParams: DEFAULT_SEARCH_PARAMS,
    page: AppointmentTypes,
    permissions: [Role.AD]
  },
  {
    path: PathName.FrontDeskRequestTypes,
    label: 'FrontDesk Request Types',
    searchParams: DEFAULT_SEARCH_PARAMS,
    page: FrontDeskRequestTypes,
    permissions: [Role.AD]
  },
  {
    path: PathName.PatientMessageTemplates,
    label: 'Patient Message Templates',
    searchParams: DEFAULT_SEARCH_PARAMS,
    page: PatientMessageTemplates,
    permissions: [Role.AD]
  },
  {
    path: PathName.RequestMessageTemplates,
    label: 'Request Message Templates',
    searchParams: DEFAULT_SEARCH_PARAMS,
    page: RequestMessageTemplates,
    permissions: [Role.AD]
  },
  {
    path: PathName.InternalNoteTemplates,
    label: 'Internal note templates',
    searchParams: DEFAULT_SEARCH_PARAMS,
    page: InternalNoteTemplates,
    permissions: [Role.AD]
  },
  {
    path: PathName.Staffs,
    label: 'Staffs',
    searchParams: DEFAULT_SEARCH_PARAMS,
    page: Staffs,
    permissions: [Role.AD]
  },
  {
    path: PathName.Teams,
    label: 'Teams',
    searchParams: DEFAULT_SEARCH_PARAMS,
    page: Teams,
    permissions: [Role.AD]
  },
  {
    path: PathName.Reviews,
    label: 'Reviews',
    searchParams: DEFAULT_SEARCH_PARAMS,
    page: Reviews,
    permissions: [Role.AD]
  },
  {
    path: PathName.StaffFeedback,
    label: 'Reviews',
    searchParams: DEFAULT_SEARCH_PARAMS,
    page: StaffFeedback,
    permissions: [Role.AD]
  },
  {
    path: PathName.CustomerFeedback,
    label: 'Reviews',
    searchParams: DEFAULT_SEARCH_PARAMS,
    page: CustomerFeedback,
    permissions: [Role.AD]
  },
  {
    path: PathName.ManageRoles,
    label: 'Manage Roles',
    searchParams: DEFAULT_SEARCH_PARAMS,
    page: ManageRoles,
    permissions: [Role.AD]
  },
  {
    path: `${PathName.ManageRole}/:id`,
    label: 'Manage Role',
    page: ManageRole,
    permissions: [Role.AD]
  },
  {
    path: PathName.InternalAutomations,
    searchParams: DEFAULT_SEARCH_PARAMS,
    label: 'Internal Automations',
    page: InternalAutomations,
    permissions: [Role.AD]
  },
  {
    path: PathName.PatientAutomations,
    label: 'Patient Automations',
    searchParams: DEFAULT_SEARCH_PARAMS,
    page: PatientAutomations,
    permissions: [Role.AD]
  },
  {
    path: PathName.StaffCosts,
    label: 'Staff costs configuration',
    searchParams: DEFAULT_SEARCH_PARAMS,
    page: StaffCosts,
    permissions: [Role.AD]
  },
  {
    path: PathName.BillingConfiguration,
    label: 'Billing configuration',
    searchParams: DEFAULT_SEARCH_PARAMS,
    page: BillingConfiguration,
    permissions: [Role.AD]
  },
  {
    path: PathName.Orders,
    label: 'Orders',
    page: Orders,
    sidebar: true,
    permissions: [Role.AD, Role.CS, Role.MA]
  },
  {
    path: `${PathName.Orders}/:orderId`,
    label: 'Order',
    page: OrderDetails,
    permissions: [Role.AD, Role.CS, Role.MA]
  }
];
