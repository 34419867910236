import React from 'react';

import { ErrorMessage } from '@hookform/error-message';
import { Common } from '@thecvlb/design-system';
import { Controller, useFormContext } from 'react-hook-form';

import { roleOptionsList } from './roleSelector.settings';

const RoleSelector: React.FC = () => {
  const {
    formState: { errors },
    control
  } = useFormContext();

  return (
    <div className="flex flex-col gap-y-3">
      {roleOptionsList.map((option, key) => (
        <Controller
          key={key}
          control={control}
          name="role"
          rules={{
            required: {
              value: true,
              message: 'Choose a role'
            }
          }}
          render={({ field }) => (
            <Common.RadioButton
              color="blue"
              size="md"
              value={option.value}
              onChange={field.onChange}
              checked={field.value === option.value}
            >
              {option.label}
            </Common.RadioButton>
          )}
        />
      ))}
      <ErrorMessage
        errors={errors}
        name="role"
        render={({ message }) => <p className="text-xs text-red">{message}</p>}
      />
    </div>
  );
};

export default RoleSelector;
