import { InternalAutomationsFiltersFormProps } from './internalAutomationsFiltersForm.types';

export const defaultValues: InternalAutomationsFiltersFormProps = {
  name: '',
  searchKey: '',
  triggerType: { label: '', value: '' },
  audience: { label: '', value: '' },
  'triggerTime.timeInHours': null,
  'triggerTime.occurenceOrder': null,
  timeInHours: '',
  triggerUnit: { label: '', value: '' },
  uniqueId: '',
  status: { label: '', value: '' }
};
