export const handleRequired = (
  value: { label: string; value: unknown } | unknown,
  errorMsg: string
) => {
  if (typeof value === 'object') {
    return (value && !!Object.values(value).filter((val) => !!val).length) || errorMsg;
  } else {
    return errorMsg;
  }
};
