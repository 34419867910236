import {
  BillingBody,
  BillingProps,
  BillingQueryParams,
  Billings
} from 'store/billing/billing.types';

import { apiSlice } from '../api/apiSlice';

export const billingApi = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getBillings: build.query<Billings, { params: BillingQueryParams }>({
      query: ({ params }) => ({ url: `/billing-config`, params }),
      transformResponse: (response: { data: BillingProps[]; info: { totalCount: number } }) => ({
        data: response.data,
        totalCount: response.info.totalCount
      }),
      providesTags: (result) =>
        result
          ? [
              'BillingConfiguration',
              ...result.data.map(({ _id }) => ({ type: 'BillingConfiguration' as const, id: _id }))
            ]
          : ['BillingConfiguration']
    }),
    createBillingConfig: build.mutation<{ data: Billings; message: string }, BillingBody>({
      query: (body) => ({
        url: `/billing-config`,
        method: 'POST',
        body
      }),
      invalidatesTags: ['BillingConfiguration']
    }),
    updateBillingConfig: build.mutation({
      query: ({ id, body }) => ({
        url: `/billing-config/${id}`,
        method: 'PATCH',
        body
      }),
      transformResponse: (response: { data: BillingProps; message: string }) => response,
      invalidatesTags: (_result, _error, arg) => [{ type: 'BillingConfiguration', id: arg.id }]
    }),
    getBilling: build.query<BillingProps, { id: string }>({
      query: ({ id }) => `/billing-config/${id}`,
      transformResponse: (response: { data: BillingProps }) => response.data
    }),
    deleteBillingConfig: build.mutation({
      query: ({ id }) => ({
        url: `/billing-config/${id}`,
        method: 'DELETE'
      }),
      transformResponse: (response: { message: string }) => response,
      invalidatesTags: ['BillingConfiguration']
    })
  })
});

export const {
  useGetBillingsQuery,
  useLazyGetBillingsQuery,
  useDeleteBillingConfigMutation,
  useCreateBillingConfigMutation,
  useUpdateBillingConfigMutation,
  useLazyGetBillingQuery
} = billingApi;
