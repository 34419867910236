import { SubmitPAFormBodyProps } from 'components/modals/SubmitPA/submitPA.types';
import {
  MarkCompleteReasons,
  NotPrescribingReason,
  SnoozeDuration
} from 'enums/markCompleteOption';
import { PARequestStatus } from 'enums/taskDetailsStatus';
import {
  IntakeItemsInfo,
  TaskDetailsProps,
  TaskPatientInfo,
  TaskProps,
  TasksSummaryProps
} from 'models/tasks.types';
import { ObjectValues } from 'utils/common/types';

export const initialStateTask: {
  taskDetails: TaskDetailsProps;
  intakeItems: IntakeItemsInfo[];
  selectRequireAppointment: boolean;
} = {
  taskDetails: {
    _id: '',
    status: 'Inactive',
    isPendingApproval: false,
    isAutomated: false,
    agingAlertAudience: [],
    dueDate: '',
    category: 'Review',
    doctorInfo: {
      _id: '',
      name: ''
    },
    createdAt: '',
    intakeItemsInfo: [
      {
        _id: '',
        title: '',
        taskCategory: '',
        sortNum: 0,
        active: false
      }
    ],
    appointmentInfo: {
      _id: '',
      appointmentStatus: '',
      images: [],
      postConsultTasks: [],
      hasPatientJoined: false,
      doctorId: '',
      appointmentTime: {
        startTime: '',
        endTime: ''
      },
      appointmentMethod: '',
      appointmentDescription: '',
      patientId: '',
      appointmentType: {
        _id: '',
        displayName: '',
        category: ''
      },
      preConsultTaskId: '',
      staffName: '',
      taskId: ''
    },
    personalInfo: {
      dob: '',
      phone: {
        phoneNumber: '',
        countryCode: ''
      },
      _id: '',
      status: '',
      firstName: '',
      lastName: '',
      email: '',
      zipCode: '',
      state: '',
      profileImage: '',
      planInfo: {
        _id: '',
        planName: '',
        planPrice: 0
      }
    },
    patientInfo: {
      _id: '',
      state: '',
      name: '',
      dob: '',
      stateShortCode: '',
      phone: {
        phoneNumber: '',
        countryCode: ''
      }
    },
    requestInfo: [],
    upcomingAppointmentInfo: [],
    patientId: '',
    preConsultTaskId: '',
    showBackToCall: false,
    categoryInfo: null
  },
  intakeItems: [{ active: false, taskCategory: '', sortNum: 0, title: '', _id: '' }],
  selectRequireAppointment: false
};

export const initialStateTasks: {
  tasksSummary: TasksSummaryProps;
  asyncProviderEnabled: boolean;
} = {
  tasksSummary: {
    messageCount: 0,
    renewalCount: 0,
    requestCount: 0,
    requestPACount: 0,
    reviewCount: 0,
    asyncCount: 0,
    escalationsCount: 0
  },
  asyncProviderEnabled: false
};

export interface CreateNewTaskParams {
  patientId: string;
  assignedTo?: string;
  audience?: string;
  note: string;
  dueDate?: string;
  isUrgent?: boolean;
  parentTaskId?: string;
  elationPrescriptionId?: string;
  tags?: string[];
  tpsBenefit?: {
    request_id: string;
    drug_name: string;
    insurance_name: string;
  };
  timezone?: string;
}

export interface DiagnosisCodesProps {
  _id?: string;
  code: string;
  description?: string;
  createdAt?: string;
  updatedAt?: string;
}

export interface UpdatedTaskResponseProps {
  taskAudience: string[];
  taskId: string;
  updatedData: TaskProps;
}

export interface CreatedTaskResponseProps {
  taskAudience: string[];
  taskId: string;
  task: TaskProps;
}

export interface AvailableStaffWarning {
  message: string;
  shouldConfirm?: boolean;
  canAssignStaff?: boolean;
}

export interface AvailableStaffResponse {
  _id: string;
  userType: {
    name: string;
    shortCode: 'MD' | 'NP' | 'MA' | 'CS' | 'AD' | 'PH';
  };
  name: string;
  profileImage: string;
  warning: AvailableStaffWarning;
  isClinicalLeadership?: boolean;
}

export interface AvailableStaffProps {
  limit?: number;
  pageNo?: number;
  searchKey?: string;
  timezone?: string;
}

export interface AvailableStaffForPatientProps {
  taskCategory: string;
  patientId?: string;
  searchKey?: string;
  isUrgentTask?: boolean;
  timezone?: string;
}

export interface RequestPAProps {
  patientUserId: string;
  audience?: string;
  assignedTo?: string;
  note: string;
  isUrgent: boolean;
  tpsBenefit?: { request_id: string; drug_name: string; insurance_name: string };
  medication?: string;
  timezone?: string;
}

export type ChangeDispositionRequestProps = {
  taskId: string;
  body: {
    note?: string;
    disposition?: MarkCompleteReasons;
    notPrescribingReason?: NotPrescribingReason;
    snoozeDuration?: SnoozeDuration;
  };
};

export type SubmitPAFormRequestProps = {
  taskId: string;
  body: SubmitPAFormBodyProps;
};

export type AssignTaskToTeamRequestProps = {
  taskId: string;
  teamIds?: string[];
};

export type AsyncScheduleDetails = {
  new: number;
  active: number;
  completedNoRx: number;
  prescribed: number;
  additionalInfoRequired: number;
  averageWaitingTime: number;
  longestWaitingTime: number;
};

export type ChangePAStatusProps = {
  status: PARequestStatus;
  patientId?: string;
  requestId?: string;
  drugName?: string;
  insuranceName?: string;
  taskId?: string;
};

export type EscalateTaskRequestProps = {
  taskId: string;
  userId?: string;
  teamIds?: string[];
  isConfirmed?: boolean;
  note?: string;
};

export type TaskTagsType = {
  name: string;
  _id: string;
  category: string;
};

export const AssignValueType = {
  releaseToList: 'release-to-list',
  takeOver: 'takeover',
  role: 'assign-role',
  team: 'assign-teams',
  staff: 'assign-staff-member',
  primary: 'assign-primary-provider'
} as const;

export type AssignValueTypes = ObjectValues<typeof AssignValueType>;

export type BulkEditTaskParams = {
  taskIds: string[];
  updateData: {
    operationType: AssignValueTypes;
    audience?: string[];
    timezone?: string;
    staffUserId?: string;
    teamIds?: string[];
  };
};

export type BulkEditUpdatedResponseItem = {
  taskId: string;
  category: string;
  patientInfo: TaskPatientInfo;
  dueDate?: string;
  createdAt?: string;
};

export type BulkEditFailedResponseItem = BulkEditUpdatedResponseItem & {
  reason: string;
};

export type BulkEditTaskResponse = {
  message: string;
  data: {
    updated: BulkEditUpdatedResponseItem[];
    failed: BulkEditFailedResponseItem[];
  };
};
