import React, { useEffect, useMemo, useRef, useState } from 'react';

import { Common } from '@thecvlb/design-system';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { DateFormat } from 'enums/dateFormats';
import { DateRange } from 'react-day-picker';
import { useClickAway, useKeyPress, useToggle } from 'react-use';

import { StyledDateRangePickerInput } from './dateRangePickerInput.styled';
import { DateRangePickerInputProps } from './dateRangePickerInput.types';

const DateRangePickerInput: React.FC<DateRangePickerInputProps> = ({
  placeholder,
  fromValue,
  setFromValue,
  toValue,
  setToValue,
  onSelect,
  labelDirection,
  startAllowedDate,
  lastAllowedDate,
  wrapperClasses,
  label,
  size,
  min,
  max,
  isBtT
}) => {
  const [showDayPicker, toggle] = useToggle(false);
  const [isPressed] = useKeyPress('Backspace');
  const nodeRef = useRef(null);
  const [selectedDays, setSelectedDays] = useState<DateRange>();

  const dayPickerContainerClasses = classNames(
    'absolute bg-white shadow-lg rounded-lg w-fit overflow-auto mt-1 z-20',
    {
      'left-[120px]': labelDirection === 'row',
      'inset-x-0': labelDirection === 'col',
      'bottom-7': isBtT
    }
  );

  const disabledDays = useMemo(
    () => [{ before: startAllowedDate || dayjs('1900-01-01').toDate(), after: lastAllowedDate }],
    [lastAllowedDate, startAllowedDate]
  );

  const handleSelectDays = (selected?: DateRange) => {
    setSelectedDays(selected);
  };

  const handleSelect = () => {
    toggle();
    onSelect?.();
  };

  useClickAway(nodeRef, () => {
    toggle(false);
  });

  useEffect(() => {
    if (selectedDays?.from) {
      setFromValue(dayjs(selectedDays.from).format(DateFormat.MM_DD_YYYY));
    }
    if (selectedDays?.from && selectedDays?.to) {
      if (dayjs(selectedDays.to).isSame(selectedDays.from, 'day')) {
        setToValue('');
      } else if (dayjs(selectedDays.to).isBefore(selectedDays.from, 'day')) {
        setFromValue(dayjs(selectedDays.to).format(DateFormat.MM_DD_YYYY));
        setToValue(' - ' + dayjs(selectedDays.from).format(DateFormat.MM_DD_YYYY));
      } else {
        setToValue(' - ' + dayjs(selectedDays.to).format(DateFormat.MM_DD_YYYY));
      }
    }
    // NOTE: run this effect only on `selectedDays` change
  }, [selectedDays]);

  useEffect(() => {
    if (!fromValue?.length && !toValue?.length) {
      setSelectedDays(undefined);
    }
    if (fromValue?.length) {
      setSelectedDays({
        from: dayjs(fromValue).toDate(),
        ...(!!toValue && { to: dayjs(toValue).toDate() })
      });
    }
  }, [fromValue, toValue]);

  useEffect(() => {
    if (isPressed && showDayPicker) {
      setFromValue('');
      setToValue('');
      toggle(false);
    }
  }, [isPressed, setFromValue, setToValue, showDayPicker, toggle]);

  return (
    <div ref={nodeRef} className={wrapperClasses}>
      <Common.Input
        label={label}
        labelDirection={labelDirection}
        placeholder={placeholder}
        value={fromValue + toValue}
        preIcon="calendar"
        size={size}
        onClick={() => toggle()}
        inputClassName="cursor-pointer"
        readOnly
      />
      {showDayPicker && (
        <>
          <div className={dayPickerContainerClasses}>
            <StyledDateRangePickerInput
              mode="range"
              selected={selectedDays}
              onSelect={handleSelectDays}
              disabled={disabledDays}
              min={min}
              max={max}
            />
            <div className="z-10 mb-2 mr-2 flex w-[290px] justify-end bg-white">
              <Common.Button size="md" onClick={() => toggle(false)}>
                Cancel
              </Common.Button>
              <Common.Button
                onClick={handleSelect}
                color="blue"
                size="md"
                disabled={!selectedDays}
                style="pill"
              >
                Select
              </Common.Button>
            </div>
          </div>
          <div className="invisible absolute bottom-[-420px] h-5 w-full" />
        </>
      )}
    </div>
  );
};

export default DateRangePickerInput;
