import { STATUS_OPTIONS } from 'constants/status';

import { ErrorMessage } from '@hookform/error-message';
import { skipToken } from '@reduxjs/toolkit/query';
import { OptionProps } from '@thecvlb/design-system/lib/src/common';
import { Editor } from '@tinymce/tinymce-react';
import ControlledSelect from 'components/forms/controlled/ControlledSelect';
import InputField from 'components/forms/controlled/InputField';
import PopupFooter from 'components/modals/components/PopupFooter';
import PopupHeader from 'components/modals/components/PopupHeader';
import { Control, Controller, FieldValues } from 'react-hook-form';
import { useGetPatientMessageTemplateByIdQuery } from 'store/patientMessageTemplates/patientMessageTemplatesSlice';
import { validation } from 'utils/helpers';
import { ANY_CHARACTER_REGEXP } from 'utils/regExp';

import { MessageTemplatesFormProps, TemplateTypes } from './messageTemplatesForm.types';

const MessageTemplatesForm: React.FC<MessageTemplatesFormProps> = ({
  type,
  popupHeaderTitle,
  onFormSubmit,
  onDeleteTemplate,
  handleSubmit,
  disabledButtons,
  getValues,
  setValue,
  control,
  formState,
  variableOptions,
  id
}) => {
  const { data } = useGetPatientMessageTemplateByIdQuery(
    id && type === TemplateTypes.patient ? { templateId: id } : skipToken
  );

  const onChangeVariable = (option: OptionProps) => {
    if (option) {
      const { message } = getValues();
      const newMessage: string = message ? `${message} [${option.value}]` : `[${option.value}]`;
      setValue('message', newMessage);
    }
  };

  return (
    <div data-testid="patient_msg_template_popup" className="p-8">
      <PopupHeader title={popupHeaderTitle} id={id} />

      <form onSubmit={handleSubmit(onFormSubmit)} className="flex flex-col gap-4">
        <div className="flex flex-row items-baseline justify-between gap-16">
          <span className="mr-2 text-sm font-semibold">Message</span>
          <Controller
            control={control as unknown as Control<FieldValues, object>}
            name="message"
            rules={validation('Message')}
            render={({ field: { onChange, value }, formState: { errors } }) => (
              <div className="flex w-full flex-col">
                <Editor
                  onEditorChange={onChange}
                  initialValue={data?.message}
                  value={value}
                  init={{
                    apiKey: import.meta.env.VITE_TINYMCE_API_KEY,
                    height: 150,
                    width: '100%',
                    toolbar: 'undo redo | bold italic | link | numlist bullist',
                    plugins: 'link lists',
                    menubar: false,
                    branding: false,
                    resize: 'both',
                    content_style:
                      'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                    browser_spellcheck: true,
                    placeholder: 'Enter message here'
                  }}
                />
                <ErrorMessage
                  errors={errors}
                  name="message"
                  render={({ message }) => <p className="mt-1.5 text-sm text-red">{message}</p>}
                />
              </div>
            )}
          />
        </div>
        <ControlledSelect
          dataTestId="variables_field"
          control={control as unknown as Control<FieldValues, object>}
          onChange={onChangeVariable}
          labelDirection="row"
          options={variableOptions}
          placeholder="Insert variable..."
          label="Variables"
          name="variables"
        />
        <InputField
          dataTestId="shortcode_field"
          name="shortCode"
          label="Shortcode"
          placeholder="/set-shortcode"
          control={control as unknown as Control<FieldValues, object>}
          labelDirection="row"
          type="text"
          errors={formState.errors.shortCode}
          helper={formState.errors.shortCode?.message}
          rules={validation('Shortcode', ANY_CHARACTER_REGEXP)}
        />
        <ControlledSelect
          dataTestId="status_field"
          control={control as unknown as Control<FieldValues, object>}
          labelDirection="row"
          options={STATUS_OPTIONS}
          placeholder="Select status..."
          label="Status"
          name="status"
          rules={validation('Status')}
        />

        <PopupFooter
          hiddenDeleteButton={!id}
          onRemove={onDeleteTemplate}
          disabled={disabledButtons}
        />
      </form>
    </div>
  );
};

export default MessageTemplatesForm;
