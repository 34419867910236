import { createSelector } from '@reduxjs/toolkit';
import { Common } from '@thecvlb/design-system/lib/src';
import PaypalLogo from 'assets/icons/paymentForm/paypal.svg?react';
import classNames from 'classnames';
import { notifySuccess } from 'components/common/Toast/Toast';
import PaymentIcon from 'components/crossSell/confirmAndPay/PaymentIcon/PaymentIcon';
import AddPaymentMethod from 'components/modals/ChangePlan/ConfirmPayment/AddPaymentMethod';
import dayjs from 'dayjs';
import { PaymentMethod } from 'enums/crossSell';
import { DateFormat } from 'enums/dateFormats';
import { AnimatePresence, motion } from 'framer-motion';
import { useAppSelector } from 'hooks/redux';
import { useToggle } from 'react-use';
import { PatientProps, PaymentFormFields } from 'store/patients/patients.types';
import {
  selectPatient,
  useCreatePaymentProfileMutation,
  useDeletePaymentProfileMutation,
  useUpdateDefaultPaymentProfileMutation
} from 'store/patients/patientsSlice';

import { getCardTypeName, otherPaymentVariants, wrapperVariants } from './paymentCard.settings';
import PaymentMethodCard from './PaymentMethodCard';

const selectPaymentCardState = createSelector([selectPatient], (patient) => ({
  paymentProfiles: patient.paymentProfiles
}));

const PaymentCard: React.FC<{
  patientInfo?: PatientProps;
  disabledPayment?: boolean;
  onCreateFirstCard?: () => void;
}> = ({ patientInfo, disabledPayment, onCreateFirstCard }) => {
  const { paymentProfiles } = useAppSelector(selectPaymentCardState);
  const [createPaymentProfile, { isLoading: isCreatingPaymentMethod }] =
    useCreatePaymentProfileMutation();
  const [deletePaymentProfile, { isLoading: isDeletingPaymentMethod }] =
    useDeletePaymentProfileMutation();
  const [updateDefaultPaymentMethod, { isLoading: isUpdatingDefaultPaymentMethod }] =
    useUpdateDefaultPaymentProfileMutation();
  const [isExtended, setIsExtended] = useToggle(false);
  const [showPopup, togglePopup] = useToggle(false);
  const currentPaymentMethod = paymentProfiles?.find((profile) => profile.isDefault);
  const patientId = patientInfo?._id || '';

  const isCardType = currentPaymentMethod?.paymentMethod === PaymentMethod.CreditCard;
  const isLoading = isUpdatingDefaultPaymentMethod || isDeletingPaymentMethod;

  const handleSuccess = (message?: string, hideExtended?: boolean, callback?: () => void) => {
    notifySuccess(message ?? 'Updated default payment method');
    if (hideExtended) setIsExtended(false);
    callback?.();
  };

  const handleDeletePaymentMethod = (id: string, callback?: () => void) => {
    if (id) {
      deletePaymentProfile({ patientId, paymentProfileId: id })
        .unwrap()
        .then((res) => handleSuccess(res.message || 'Deleted payment method', false, callback));
    }
  };

  const handleFavoritePaymentMethod = (id: string) => {
    if (id) {
      updateDefaultPaymentMethod({ patientId, paymentProfileId: id })
        .unwrap()
        .then((res) => handleSuccess(res.message, true));
    }
  };

  const handleCreatePaymentMethod = (data: PaymentFormFields | string) => {
    const expDate = typeof data === 'string' ? [] : data.expDate.split('/');
    const expirationMonth = expDate[0];
    const expirationYear = `${dayjs().format(DateFormat.YYYY).slice(0, 2)}${expDate[1]}`;
    const body =
      typeof data === 'string'
        ? { chargifyToken: data, paymentMethod: PaymentMethod.Paypal }
        : {
            fullNumber: data.fullNumber,
            expirationYear: expirationYear,
            expirationMonth: expirationMonth,
            cvv: data.cvc,
            paymentMethod: PaymentMethod.CreditCard
          };

    if (patientId && data) {
      createPaymentProfile({ patientId, body })
        .unwrap()
        .then(() => {
          if (!currentPaymentMethod) onCreateFirstCard?.();
          togglePopup(false);
        });
    }
  };

  return (
    <>
      <Common.Modal isOpen={showPopup} zIndex={120} padding={false} size="base">
        <AddPaymentMethod
          isLoading={isCreatingPaymentMethod}
          onConfirm={handleCreatePaymentMethod}
          onGoBack={togglePopup}
        />
      </Common.Modal>
      <AnimatePresence mode="wait">
        {!isExtended && !!currentPaymentMethod ? (
          <motion.div
            animate="visible"
            className="flex items-center gap-4 rounded-2xl border border-gray-200 p-4"
            initial="hidden"
            variants={wrapperVariants}
          >
            <div
              className={classNames(
                'flex h-11 w-[60px] items-center rounded-lg px-4 py-2',
                isCardType ? 'bg-primary-100' : 'bg-[#FEC53B]'
              )}
            >
              {isCardType ? (
                <PaymentIcon cardType={currentPaymentMethod.cardType} />
              ) : (
                <PaypalLogo className="size-full" />
              )}
            </div>
            <div className="flex grow gap-2 max-md:flex-col md:items-center md:justify-between">
              <div className="flex flex-col gap-1 text-sm">
                {isCardType ? (
                  <>
                    <span className="first-letter:uppercase">
                      {`${getCardTypeName(currentPaymentMethod)} ending in `}
                      <span className="font-bold">
                        {currentPaymentMethod.cardNumber.substring(
                          currentPaymentMethod.cardNumber.length - 4
                        )}
                      </span>
                    </span>
                    <span className="text-gray">
                      Expires{' '}
                      {`${currentPaymentMethod.expirationMonth}/${String(
                        currentPaymentMethod.expirationYear
                      ).substring(String(currentPaymentMethod.expirationYear).length - 2)}`}
                    </span>
                  </>
                ) : (
                  <>
                    <span>Paypal</span>
                    <span className="max-w-[200px] truncate text-gray">
                      {currentPaymentMethod.email}
                    </span>
                  </>
                )}
              </div>
              <div className="flex gap-2 max-md:flex-col md:gap-4">
                <Common.Button
                  color="white-alt"
                  size="sm"
                  onClick={setIsExtended}
                  type="button"
                  disabled={disabledPayment}
                >
                  Update payment
                </Common.Button>
              </div>
            </div>
          </motion.div>
        ) : (
          <motion.div
            animate="open"
            className="flex flex-col gap-4 rounded-2xl border border-gray-200 p-4"
            exit="collapsed"
            initial="collapsed"
            key="collapsed"
            transition={{ duration: 0.3, ease: 'linear' }}
            variants={{
              collapsed: { height: 0, opacity: 0 },
              open: { height: 'auto', opacity: 1 }
            }}
          >
            <h3 className="text-base font-bold text-primary-700">Current payment method</h3>
            {currentPaymentMethod && (
              <PaymentMethodCard
                data={currentPaymentMethod}
                isLoading={isLoading}
                onDelete={handleDeletePaymentMethod}
                onPickAsFavorite={handleFavoritePaymentMethod}
              />
            )}
            {!!paymentProfiles && paymentProfiles?.length > 1 && (
              <>
                <h3 className="text-base font-bold text-primary-700">Other payment methods</h3>
                <AnimatePresence>
                  {paymentProfiles.map(
                    (payment, index) =>
                      !payment.isDefault && (
                        <motion.div
                          animate="visible"
                          exit="exit"
                          initial="hidden"
                          key={payment.paymentProfileId}
                          variants={otherPaymentVariants(index)}
                        >
                          <PaymentMethodCard
                            data={payment}
                            isLoading={isLoading}
                            onDelete={handleDeletePaymentMethod}
                            onPickAsFavorite={handleFavoritePaymentMethod}
                          />
                        </motion.div>
                      )
                  )}
                </AnimatePresence>
              </>
            )}
            <button
              className="flex w-full justify-center gap-2 rounded-2xl border border-dashed border-gray-200 p-4 text-base transition-all hover:bg-primary-100"
              onClick={() => togglePopup(true)}
              disabled={disabledPayment}
              type="button"
            >
              <Common.Icon name="plus" />
              Add payment method
            </button>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

export default PaymentCard;
