import { InsuranceInfo } from './InsuranceInfo';
import OrderInfo from './OrderInfo';
import { OverviewProps } from './overview.types';
import PatientInfo from './PatientInfo';
import PharmacyInfo from './PharmacyInfo';
import PrescriptionInfo from './PrescriptionInfo';

const Overview: React.FC<OverviewProps> = ({ order }) => {
  return (
    <>
      <div className="mt-8 flex w-full flex-row rounded-lg bg-gray-100 px-6 py-4">
        <div className="w-full">
          <OrderInfo order={order} isEditable />
        </div>
      </div>
      <div className="mt-8 flex w-full flex-row rounded-lg bg-gray-100 px-6 py-4">
        <div className="w-full">
          {typeof order.patient === 'object' && order.patient.elationId && (
            <PatientInfo order={order} patient={order.patient} isEditable />
          )}
        </div>
      </div>
      <div className="mt-8 flex w-full flex-row rounded-lg bg-gray-100 px-6 py-4">
        <div className="w-full">
          <h4 className="mb-2 text-base font-bold text-gray-700">Prescription info</h4>
          {order.prescriptions?.length > 0 && (
            <PrescriptionInfo prescription={order.prescriptions[0]} />
          )}
        </div>
      </div>
      <div className="mt-8 flex w-full flex-row rounded-lg bg-gray-100 px-6 py-4">
        <div className="w-full">
          <InsuranceInfo order={order} insurance={order.insurance} />
        </div>
      </div>
      {order.pharmacy && (
        <div className="mt-8 flex w-full flex-row rounded-lg bg-gray-100 px-6 py-4">
          <div className="w-full">
            <PharmacyInfo pharmacy={order.pharmacy} />
          </div>
        </div>
      )}
    </>
  );
};

export default Overview;
