import { createContext, ReactNode, useContext, useEffect, useMemo, useState } from 'react';

import { MessageTemplates } from 'enums/messages';
import { useKeyPressEvent } from 'react-use';
import { useGetInternalNoteTemplatesQuery } from 'store/internalNoteTemplates/internalNoteTemplatesSlice';
import { useGetPatientMessageTemplatesQuery } from 'store/patientMessageTemplates/patientMessageTemplatesSlice';

import { TemplatesContextProps } from './templatesContext.types';

const getTemplatesQuery = (templatesType: MessageTemplates) => {
  if (templatesType === MessageTemplates.Internal) {
    return useGetInternalNoteTemplatesQuery;
  } else {
    return useGetPatientMessageTemplatesQuery;
  }
};

export const TemplatesContext = createContext<TemplatesContextProps | undefined>(undefined);

const TemplatesProvider: React.FC<{ children: ReactNode; templatesType: MessageTemplates }> = ({
  children,
  templatesType
}) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [showTemplates, setShowTemplates] = useState(false);
  const [selectedTemplateIndex, setSelectedTemplateIndex] = useState(0);

  const getTemplates = getTemplatesQuery(templatesType);
  const { data: templates } = getTemplates({
    params: { limit: 500, pageNo: 0, status: 'active' }
  });

  const filteredTemplates = useMemo(() => {
    const lowercaseQuery = searchQuery.toLowerCase();

    if (!templates?.data) return [];

    // Filter by shortCode first
    const shortCodeMatches = templates.data.filter((template) =>
      template.shortCode.toLowerCase().includes(lowercaseQuery)
    );

    // Filter by message if shortCode didn't yield enough results
    const messageMatches = templates.data.filter((template) =>
      template.message.toLowerCase().includes(lowercaseQuery)
    );

    // Combine results, giving precedence to shortCode matches
    const combinedMatches = [...new Set([...shortCodeMatches, ...messageMatches])];

    return combinedMatches;
  }, [searchQuery, templates?.data]);

  // Hide templates on escape key press
  useKeyPressEvent('Escape', () => {
    setShowTemplates(false);
  });

  // Show or Hide templates according to templates and searchQuery state
  useEffect(() => {
    if (filteredTemplates?.length === 0) {
      setShowTemplates(false);
    } else if (searchQuery.length && filteredTemplates?.length > 0) {
      setShowTemplates(true);
    }
  }, [filteredTemplates?.length, searchQuery]);

  const values = useMemo(
    () => ({
      searchQuery,
      setSearchQuery,
      templates: filteredTemplates as TemplatesContextProps['templates'],
      templatesCount: templates?.totalCount ?? 0,
      showTemplates,
      setShowTemplates,
      setSelectedTemplateIndex,
      selectedTemplateIndex
    }),
    [searchQuery, filteredTemplates, templates?.totalCount, showTemplates, selectedTemplateIndex]
  );

  return <TemplatesContext.Provider value={values}>{children}</TemplatesContext.Provider>;
};

const useTemplates = () => {
  const context = useContext(TemplatesContext);

  if (context === undefined) {
    throw new Error(`useChat must be used within a TemplatesProvider`);
  }
  return context;
};

TemplatesProvider.displayName = 'Chat Provider';

export { TemplatesProvider, useTemplates };
