import { STATUS_OPTIONS } from 'constants/status';

import { InternalNoteTemplatesFiltersFormProps } from './internalNoteTemplatesFiltersForm.types';

export const defaultValues: InternalNoteTemplatesFiltersFormProps = {
  message: '',
  shortCode: '',
  uniqueId: '',
  searchKey: '',
  status: { label: '', value: '' }
};

export const getInternalNoteTemplatesFiltersValue = (key: string, val: string) => {
  let currentValue;
  switch (key) {
    case 'status':
      currentValue = STATUS_OPTIONS.find((item) => item.value === val);
      break;
    default:
      currentValue = val;
  }
  return currentValue;
};
