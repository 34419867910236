import { AllFiltersParams } from 'constants/filters';

import { useMemo } from 'react';

import { AppliedFilterProps } from 'components/filters/AppliedFiltersTags/appliedFiltersTags.type';
import queryString from 'query-string';
import { useSearchParams } from 'react-router-dom';

export function useAppliedFilters() {
  const [searchParams, setSearchParams] = useSearchParams();
  const queryParams = useMemo(
    () => queryString.parse(decodeURIComponent(searchParams.toString())),
    [searchParams]
  );

  const appliedFilters: AppliedFilterProps[] = useMemo(() => {
    const result = Object.keys(queryParams)
      .filter((filter) => AllFiltersParams.includes(filter))
      .map((filter) => ({ label: filter, value: queryParams[filter] ?? '' }));

    // Make sure that startDate is always before endDate
    if (queryParams?.startDate && queryParams?.endDate) {
      result.sort((a, b) => {
        if (a.label === 'startDate' && b.label === 'endDate') {
          return -1;
        } else if (a.label === 'endDate' && b.label === 'startDate') {
          return 1;
        }
        return 0;
      });
    }
    return result;
  }, [queryParams]);

  const appliedFiltersCount = useMemo(() => {
    let filtersCount = appliedFilters.length;
    if (appliedFilters.find((item) => item.label === 'occurenceOrder')) {
      filtersCount = filtersCount - 1;
    }
    if (appliedFilters.find((item) => item.label === 'triggerTime.occurenceOrder')) {
      filtersCount = filtersCount - 1;
    }
    return filtersCount;
  }, [appliedFilters]);

  const removeFilter = (filter: string) => {
    if (searchParams.has(filter)) {
      if (filter === 'alertUnit') searchParams.delete('occurenceOrder');
      if (filter === 'triggerUnit') searchParams.delete('triggerTime.occurenceOrder');

      searchParams.delete(filter);
      setSearchParams(searchParams);
    }
  };

  return { appliedFilters, appliedFiltersCount, removeFilter };
}
