import { PAY_TYPE_TO_OPTIONS, ROLE_OPTIONS } from 'constants/staff';
import { STATUS_OPTIONS } from 'constants/status';

import { StaffCostsFiltersFormProps } from './staffCostsFiltersForm.types';

export const defaultValues: StaffCostsFiltersFormProps = {
  payAmount: '',
  searchKey: '',
  payType: { label: '', value: '' },
  status: { label: '', value: '' },
  staff: { label: '', value: '' }
};

export const getStaffCostsFiltersValue = (key: string, val: string) => {
  let currentValue;
  switch (key) {
    case 'status':
      currentValue = STATUS_OPTIONS.find((item) => item.value === val);
      break;
    case 'staff':
      currentValue = ROLE_OPTIONS.find((item) => item.value === val);
      break;
    case 'payType':
      currentValue = PAY_TYPE_TO_OPTIONS.find((item) => item.value === val);
      break;
    default:
      currentValue = val;
  }
  return currentValue;
};
