import * as HoverCard from '@radix-ui/react-hover-card';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { DateFormat } from 'enums/dateFormats';
import { useAppSelector } from 'hooks/redux';
import { EventTypes } from 'models/event.types';
import { useSearchParams } from 'react-router-dom';
import { selectUser } from 'store/user/userSlice';

import { CombineTimeSlotElementProps } from './combineTimeSlot.types';

const CombineTimeSlot: React.FC<CombineTimeSlotElementProps> = ({
  timeSlot,
  isLoading,
  isSelected
}) => {
  const { timezone } = useAppSelector(selectUser);
  const localTz = timezone || dayjs.tz.guess();
  const [searchParams] = useSearchParams();
  const activeShiftTypesParam = searchParams.get('active-shift-types');
  const isDayOffType = timeSlot.type === EventTypes.BREAK || timeSlot.type === EventTypes.TIME_OFF;

  const timeSlotClassnames = classNames(
    'absolute inset-y-0 my-auto h-fit max-h-6 truncate whitespace-nowrap rounded border-0 border-l-4 py-1 pl-2 text-left font-medium text-xs border-secondary bg-secondary-100',
    {
      // At this moment, we show the Break/TimeOff event type only in the "offline" tab.
      hidden:
        (activeShiftTypesParam !== 'offline' && isDayOffType) ||
        (activeShiftTypesParam === 'offline' && isSelected)
    }
  );

  const startTime = dayjs(timeSlot.startTime, DateFormat.HH_mm).format(DateFormat.h_mm_a);
  const endTime = dayjs(timeSlot.endTime, DateFormat.HH_mm).format(DateFormat.h_mm_a);

  if (isLoading) {
    return (
      <div
        data-testid="skeleton"
        className="absolute inset-0 my-auto h-6 animate-pulse rounded border-l-4 border-gray bg-slate-200"
      />
    );
  }

  const wrapperTooltipClassnames = classNames(
    'z-20 min-w-max h-6 px-2 py-1 shadow top-0 border-t border-gray-100 bg-gray-100 bg-opacity-90 text-xs leading-tight rounded '
  );

  return (
    <HoverCard.Root openDelay={300} closeDelay={300}>
      <div className="cursor-pointer">
        <p
          data-testid="combine_time_slot"
          className={timeSlotClassnames}
          style={{
            left: timeSlot.left,
            right: timeSlot.right
          }}
        >
          <HoverCard.Trigger>
            <div className="truncate whitespace-nowrap text-left text-xs font-medium">
              {isSelected && <span className="font-bold">Combined:&nbsp;</span>}{' '}
              {`${startTime} - ${endTime} ${dayjs().tz(localTz).format(DateFormat.z)}`}
            </div>
          </HoverCard.Trigger>
        </p>
      </div>

      <HoverCard.Portal>
        <HoverCard.Content
          side="top"
          align="start"
          alignOffset={-5}
          sideOffset={5}
          className="combineTimeHint z-20"
        >
          <span className={wrapperTooltipClassnames}>
            {isSelected && <span className="font-bold">Combined:&nbsp;</span>}
            {`${startTime} - ${endTime} ${dayjs().tz(localTz).format(DateFormat.z)}`}
          </span>
        </HoverCard.Content>
      </HoverCard.Portal>
    </HoverCard.Root>
  );
};

export default CombineTimeSlot;
