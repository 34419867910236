import { useRef } from 'react';

import { Common } from '@thecvlb/design-system/lib/src';
import { twMerge } from 'tailwind-merge';

import { handleProcessFile } from './fileUploader.settings';
import { FileUploaderProps } from './fileUploader.types';

const FileUploader: React.FC<FileUploaderProps> = ({
  accept = 'image/png, image/jpeg, image/jpg, .heic',
  label,
  sideName,
  fileUploaderWrapperClasses,
  fileUploaderIconClasses,
  onChange,
  shouldUploadDocuments = true
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const handleBrowseFiles = () => {
    shouldUploadDocuments && inputRef.current?.click();
  };

  const handleChangeFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const newFile = e?.target.files?.[0];
    if (!newFile) return;

    handleProcessFile(newFile, onChange);
    e.target.value = '';
  };
  const fileUploaderWrapper = twMerge(
    'flex h-min w-32 cursor-pointer flex-col items-center gap-0.5 rounded-xl border border-dashed border-gray-400 px-4 py-6 text-center text-gray',
    fileUploaderWrapperClasses
  );
  const iconClasses = twMerge('size-5 text-gray-500', fileUploaderIconClasses);

  return (
    <div className={fileUploaderWrapper} onClick={handleBrowseFiles}>
      <input
        type="file"
        className="hidden"
        accept={accept}
        ref={inputRef}
        onChange={handleChangeFile}
        data-testid="file-uploader"
      />
      <Common.Icon name="camera" className={iconClasses} />
      <span
        className={`text-sm ${sideName ? 'text-nowrap text-gray-300' : 'font-semibold text-gray-500'}`}
      >
        {sideName || label}
      </span>
    </div>
  );
};

export default FileUploader;
