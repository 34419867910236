import { FRONTEND_DATE_FORMAT } from 'constants/dateFormat';

import React, { useCallback, useState } from 'react';

import { ErrorMessage } from '@hookform/error-message';
import { Common } from '@thecvlb/design-system/lib/src';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { DateFormat } from 'enums/dateFormats';
import { useClickAway, useToggle } from 'react-use';

import { DateInputProps } from './dateInput.types';
import { StyledDayPicker } from '../../Calendar/calendar.styled';

const DateInput = React.forwardRef<HTMLInputElement, DateInputProps>(
  (
    {
      labelTitle,
      name = 'birth',
      labelClasses = 'mr-12 flex items-center mb-4 text-sm',
      inputClasses,
      format = FRONTEND_DATE_FORMAT,
      wrapperInputClasses = 'relative',
      containerClasses,
      setValue,
      onChange,
      onFocus,
      onBlur,
      value,
      errors,
      dataTestId,
      startYear,
      lastYear
    },
    ref
  ) => {
    const [show, toggle] = useToggle(false);
    // Hold the month in state to control the calendar when the input changes
    const [month, setMonth] = useState(new Date());

    // Hold the selected date in state
    const [selectedDate, setSelectedDate] = useState<Date | undefined>(
      dayjs(value).toDate() || new Date()
    );

    // Hold the input value in state
    const [inputValue, setInputValue] = useState('');

    const handleDayPickerSelect = (date: Date | undefined) => {
      if (!date) {
        setInputValue('');
        setSelectedDate(undefined);
      } else {
        setSelectedDate(date);
        setMonth(date);
        setInputValue(dayjs(date).format(DateFormat.MM_DD_YYYY));
      }
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setInputValue(e.target.value); // keep the input value in sync

      const parsedDate = dayjs(e.target.value, DateFormat.MM_DD_YYYY).toDate();

      if (dayjs(parsedDate).isValid()) {
        setSelectedDate(parsedDate);
        setMonth(parsedDate);
      } else {
        setSelectedDate(undefined);
      }
    };

    const containerRef = React.useRef(null);

    useClickAway(containerRef, () => {
      toggle(false);
    });

    const errorMessageTextClasses = 'text-red text-sm mt-1.5';

    const handleChange = useCallback(
      (day: Date) => {
        if (day) {
          setValue(name, dayjs(day).format(format));
          onChange && onChange(dayjs(day).format(format));
          toggle(false);
        }
      },
      [format, name, onChange, setValue, toggle]
    );

    const handleClick = () => {
      toggle();
    };

    const handleDayClick = React.useCallback(
      (day: Date) => {
        handleChange(day);
      },
      [handleChange]
    );

    const disabled = React.useMemo(
      () => [
        {
          before: startYear || dayjs('1900-01-01').toDate(),
          after: lastYear || dayjs().toDate()
        }
      ],
      [lastYear, startYear]
    );

    React.useEffect(() => {
      setSelectedDate(dayjs(value).toDate());
    }, [value]);

    React.useEffect(() => {
      show ? onFocus?.() : onBlur?.();
    }, [show, onFocus, onBlur]);

    return (
      <>
        <div className={classNames('flex justify-between', containerClasses)}>
          {labelTitle ? <label className={labelClasses}>{labelTitle}</label> : null}
          <div data-testid={dataTestId} className={wrapperInputClasses} ref={containerRef}>
            <div className="relative pt-2 text-sm">
              <div onClick={handleClick} className="relative cursor-pointer text-sm">
                <Common.Icon name="calendar" className="absolute top-0 z-[1] ml-3 size-5" />
                <input
                  type="text"
                  value={inputValue}
                  placeholder={DateFormat.MM_DD_YYYY}
                  onChange={handleInputChange}
                  className={classNames('cursor-pointer', inputClasses)}
                  readOnly={true}
                  ref={ref}
                />
                <Common.Icon
                  name={show ? 'arrow-up' : 'arrow-down'}
                  className="absolute right-3 top-0 ml-1.5 size-5 text-gray-700"
                />
              </div>

              {show && (
                <div className="absolute left-4 top-16 z-10 rounded-md bg-white shadow">
                  <StyledDayPicker
                    onDayClick={handleDayClick}
                    selected={selectedDate}
                    disabled={disabled}
                    startMonth={startYear || dayjs('1900-01-01').toDate()}
                    endMonth={lastYear || dayjs().toDate()}
                    month={month}
                    onMonthChange={setMonth}
                    mode="single"
                    onSelect={handleDayPickerSelect}
                  />
                </div>
              )}
            </div>
            {errors && (
              <ErrorMessage
                errors={errors}
                name={name}
                render={({ message }) => <p className={errorMessageTextClasses}>{message}</p>}
              />
            )}
          </div>
        </div>
      </>
    );
  }
);
export default React.memo(DateInput);
