import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { Common } from '@thecvlb/design-system';
import Header from 'components/tables/Header';
import StuckOrderStatus from 'components/tasks/slidingPane/StuckOrderStatus';
import dayjs from 'dayjs';
import { NotPrescribingReason } from 'enums/markCompleteOption';
import { Role } from 'enums/role';
import { Status } from 'enums/status';
import { TaskTags } from 'enums/taskCategories';
import capitalize from 'lodash/capitalize';
import truncate from 'lodash/truncate';
import { getQueueCategoryText } from 'pages/Tasks/Tasks.settings';
import Skeleton from 'react-loading-skeleton';
import { getDisplayName } from 'store/patients/patients.settings';
import type { QueueTasksProps } from 'store/queue/queueSlice.types';
import type { UserRoleInfo } from 'store/staffs/staffs.types';
import { dateTimeFormatter } from 'utils/datesAndTime';
import { getTaskStatusColor } from 'utils/helpers';
import { hasPermission, TASK_ACCORDING_TO_CATEGORY } from 'utils/popupsContentPermission';

import AssignedToCell from './Cells/AssignedToCell';
import DateCell from './Cells/DateCell';
import DetailsLabel from './DetailsLabel';
import OnboardingDetails from './OnboardingDetails';
import TaskCategoryLabel from './TaskCategoryLabel';
import type { TaskCategoryLabelProps } from './TaskCategoryLabel/taskCategoryLabel.types';
import type { TasksTableColumnsProps } from './tasksTable.types';
import BulkEditCheckbox from '../BasicTable/BulkEditCheckox.tsx';

const columnHelper = createColumnHelper<TasksTableColumnsProps>();
export const columns = [
  columnHelper.accessor('bulkEditIds', {
    header: () => <Header text="" sortField="bulkEditIds" />,
    cell: ({ row }) => {
      return <BulkEditCheckbox onChange={row.getToggleSelectedHandler()} id={row.original._id} />;
    }
  }),
  columnHelper.accessor('patientInfo', {
    header: () => <Header text="Patient" sortField="patientInfo.name" />,
    size: 300,
    cell: (info) => (
      <span className="flex max-w-[300px]">
        <b>{getDisplayName(info.getValue(), true)}</b>{' '}
        <span className="self-end pl-1">
          {info.getValue().dob ? ` ${dayjs().diff(info.getValue()?.dob, 'year')}` : null}
        </span>
        <span className="self-end pl-1">
          <span>•</span>
          <span className="pl-1">{info.getValue()?.stateShortCode}</span>
        </span>
        {!!Object.values(info?.row?.original?.orderWarning || {})?.length && (
          <StuckOrderStatus
            stuckOrder={info.row.original.orderWarning}
            tooltipWrapperClasses="bottom-[200%] -ml-[121px]"
            iconWrapperClasses="pl-1"
          />
        )}
      </span>
    )
  }),
  columnHelper.accessor('dueDate', {
    header: () => <Header text="Date" sortField="dueDate" />,
    cell: (info) => (
      <DateCell
        createdAt={info.row?.original.createdAt}
        dueDate={info.row?.original?.dueDate}
        isQueue={info.row?.original.subCategory?.toLowerCase() === 'queue'}
        assignedAt={
          info.row?.original?.status.toLowerCase() === Status.Completed
            ? info.row?.original?.assignedAt
            : ''
        }
      />
    )
  }),
  columnHelper.accessor('category', {
    header: () => <Header text="Category" sortField="category" />,
    cell: (info) => (
      <TaskCategoryLabel
        type={
          info.row.original.subCategory?.toLowerCase() === 'queue'
            ? 'queue'
            : (info.getValue().toLowerCase() as TaskCategoryLabelProps['type'])
        }
        appointmentStatus={
          info.row.original.subCategory?.toLowerCase() === 'queue'
            ? getQueueCategoryText(info.getValue())
            : (info.row.original.categoryInfo ?? '')
        }
        isSentRequests={
          info.row.original.isSent && !info.row.original.tags?.includes(TaskTags.PriorAuthorization)
        }
        tags={info.row.original.tags}
      />
    )
  }),
  columnHelper.accessor('details', {
    header: () => <Header text="Details" sortField="details" />,
    cell: (info) => {
      const isSnoozedTask =
        ((info.row.original.notPrescribingReason === NotPrescribingReason.TooEarlyToPrescribe ||
          info.row.original.notPrescribingReason === NotPrescribingReason.WaitingForLabs) &&
          info.row.original.prescribeReminderDate) ||
        !!info.row.original.pausedDetails;

      return hasPermission(
        TASK_ACCORDING_TO_CATEGORY.onboardingDetailsStatuses,
        info.row.original.category
      ) && !isSnoozedTask ? (
        <OnboardingDetails details={info.row.original} />
      ) : (
        <DetailsLabel
          detailsNote={info.getValue() ? truncate(info.getValue()?.note, { length: 64 }) : ''}
          benefitsStatus={info.row.original.benefitsStatus}
          mifDetails={info.row.original.mifDetails}
          prescribeReminderDate={info.row.original.prescribeReminderDate}
          notPrescribingReason={info.row.original.notPrescribingReason}
          notSeenMessagesCounter={info.row.original.notSeenMessagesCounter}
          category={info.row.original.category}
          pausedDetails={info.row.original.pausedDetails}
        />
      );
    }
  }),
  columnHelper.accessor('doctorInfo.name', {
    header: () => <Header text="Doctor" sortField="doctorInfo.name" />,
    size: 140,
    cell: (info) => (
      <span className="max-w-[140px] font-normal">
        {info.row.original.doctorInfo?.name || 'Unassigned'}
      </span>
    )
  }),
  columnHelper.accessor('assignedToInfo', {
    header: () => <Header text="Assigned to" sortField="assignedToInfo.name" />,
    size: 140,
    cell: (info) => (
      <AssignedToCell
        _id={info.getValue()?._id}
        name={info.getValue()?.name}
        profileImage={info.getValue()?.profileImage}
        specificAudience={info.row.original?.specificAudience}
        defaultAudience={info.row.original?.defaultAudience}
        userRoleInfo={info.getValue()?.userRoleInfo as UserRoleInfo}
        category={info.row.original.category}
        taskId={info.row.original._id}
        tags={info.row.original.tags}
        escalationDetails={info.row.original.escalationDetails}
        teams={info.row.original?.teams}
        patientId={info.row.original.patientInfo?._id}
        assignedAt={info.row.original.assignedAt}
        assignedTeamAt={info.row.original.assignedTeamAt}
      />
    )
  }),
  columnHelper.accessor('assignedAt', {
    header: () => <Header text="Assigned at" sortField="assignedAt" />,
    size: 140,
    cell: (info) => (
      <span className="max-w-[140px] text-sm font-semibold text-gray-700">
        {info.row.original.assignedAt ? dateTimeFormatter(info.row.original.assignedAt) : ''}
      </span>
    )
  }),
  columnHelper.accessor('status', {
    header: () => <Header text="Status" sortField="status" />,
    cell: (info) => {
      const value = info.getValue() ?? '';
      return (
        <span className={`font-normal ${getTaskStatusColor(value)}`}>{capitalize(value)}</span>
      );
    }
  })
];

export const queueColumns = (role: string) => {
  const isAdmin = role === Role.AD;
  const isPhysician = role === Role.PH;
  const isNP = role === Role.NP;
  const isMA = role === Role.MA;

  return [
    {
      header: () => <Header text="Patient" sortField="patientInfo.name" />,
      accessorKey: 'patientName',
      size: 300,
      id: 'expander',
      cell: ({ row, getValue }) => {
        const value = getValue();
        const labelForPH = value === '0' ? '0 patients' : `Patient (+${value ?? ''} more)`;
        return value ? (
          <div>
            <>
              {row.getCanExpand() ? (
                <button
                  {...{
                    onClick: row.getToggleExpandedHandler(),
                    className: 'flex items-center align-middle text-sm font-bold text-gray-800'
                  }}
                >
                  <>
                    {isAdmin || isMA
                      ? `${value ?? '-'} patient${value === '1' ? '' : 's'}`
                      : labelForPH}
                    <Common.Icon
                      name={row.getIsExpanded() ? 'arrow-filled-down' : 'arrow-filled-right'}
                      className="mt-0.5 size-3 opacity-30"
                    />
                  </>
                </button>
              ) : (
                <span className="max-w-[300px] text-sm font-bold">
                  {isPhysician || isNP ? labelForPH : `${value}`}
                </span>
              )}
            </>
          </div>
        ) : (
          <Skeleton />
        );
      }
    },
    {
      header: () => <Header text="Date" sortField="dueDate" />,
      accessorKey: 'createdAt',
      cell: (info) => {
        const value = info.row.original.createdAt;
        const isNoPatients = info.row.original.patientName === '0';
        return isNoPatients ? (
          <span className="font-bold">N/A</span>
        ) : value ? (
          <DateCell
            createdAt={info.row.original.createdAt}
            addedToQueueAt={info.row.original.addedToQueueAt}
            assignedAt={info.row.original?.assignedAt}
            dueDate={''}
            isQueue
          />
        ) : (
          <Skeleton />
        );
      }
    },
    {
      header: () => <Header text="Category" sortField="category" />,
      accessorKey: 'category',
      cell: (info) => {
        const value = info.row.original.category;
        return value ? (
          <TaskCategoryLabel
            type={'queue'}
            appointmentStatus={getQueueCategoryText(value)}
            tags={info.row.original.tags}
          />
        ) : (
          <Skeleton />
        );
      }
    },
    {
      header: () => <Header text="Details" sortField="details" />,
      accessorKey: 'subCategory',
      cell: (info) => {
        const patientStatus = info.row.original.patientStatus;
        const isNoPatients = info.row.original.patientName === '0';

        return patientStatus ? (
          isNoPatients ? (
            <span>No patients in the queue</span>
          ) : (
            <OnboardingDetails details={info.row.original} />
          )
        ) : (
          <Skeleton />
        );
      }
    },
    {
      header: () => <Header text="Doctor" sortField="doctorInfo.name" />,
      accessorKey: 'doctor',
      cell: (info) => {
        const value = info.row.original.doctor;
        return value ? <span className="max-w-[140px] font-normal">{value}</span> : <Skeleton />;
      }
    },
    {
      header: () => <Header text="Assigned to" sortField="assignedToInfo.name" />,
      accessorKey: 'assignedTo',
      cell: (info) => {
        const value = info.row.original.assignedTo;
        return value ? (
          <span className="max-w-[140px] font-medium text-gray-700">{value}</span>
        ) : (
          <Skeleton />
        );
      }
    },
    {
      header: () => <Header text="Status" sortField="status" />,
      accessorKey: 'status',
      cell: (info) => {
        const value = info.row.original.status ?? '';
        const isNoPatients = info.row.original.patientName === '0';
        return value ? (
          isNoPatients ? (
            <span className="text-gray-500">Status</span>
          ) : (
            <span className={`font-normal ${getTaskStatusColor(value)}`}>{capitalize(value)}</span>
          )
        ) : (
          <Skeleton />
        );
      }
    }
  ] as ColumnDef<QueueTasksProps>[];
};
