export enum CustomerFeedbackProblemType {
  UsingApp = 'using-app',
  MyProvider = 'my-provider'
}

export enum FeedbackStatuses {
  New = 'new',
  Published = 'published',
  Hidden = 'hidden'
}
