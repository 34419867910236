import { useMemo } from 'react';

import Skeleton from 'react-loading-skeleton';

import 'react-loading-skeleton/dist/skeleton.css';

interface TablePops<TData, TValue> {
  isFetching: boolean;
  data?: TData;
  columns: TValue[];
}
export const useGetDefaultBasicTableValues = <TData extends object, TValue>({
  isFetching,
  columns,
  data
}: TablePops<TData, TValue>) => {
  const tableData = useMemo(
    () => (isFetching ? Array(10).fill({}) : data || []),
    [isFetching, data]
  );
  const tableColumns = useMemo(
    () =>
      isFetching
        ? columns.map((column) => {
            return {
              ...column,
              cell: () => <Skeleton />
            };
          })
        : columns,
    [isFetching, columns]
  );

  return [tableData, tableColumns];
};
