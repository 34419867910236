import { createSelector } from '@reduxjs/toolkit';
import classNames from 'classnames';
import { Status } from 'enums/messages';
import { useAppSelector } from 'hooks/redux';
import { selectChat } from 'store/chat/chatSlice';
import { stripHTML } from 'utils/common/parseHTML';

import { getLatestMessage, getMessageTime, parseMessageToChannel } from './channel.settings';
import { ChannelProps } from './channel.types';

const selectChannelState = createSelector(selectChat, (chat) => ({
  isLoadingMessages: chat.loadingMessagesStatus === Status.Pending
}));

const Channel: React.FC<ChannelProps> = ({ channel, handleChange, isActive }) => {
  const { isLoadingMessages } = useAppSelector(selectChannelState);
  const channelClasses = classNames('truncate hover:bg-secondary-50 w-[320px]', {
    'bg-secondary-100': isActive
  });

  const messageTime = getMessageTime(channel);
  const latestMessage = getLatestMessage(channel);
  const handleClick = () => handleChange && handleChange(channel);

  return (
    <button
      className={channelClasses}
      onClick={handleClick}
      disabled={isLoadingMessages || isActive}
      data-testid={`channel-${channel.channelTitle}`}
    >
      <div className="relative flex flex-row pr-4">
        {channel?.unreadMessageCount > 0 && (
          <div
            data-testid="unread_msgs_red_dot"
            className="absolute inset-y-0 left-4 m-auto flex size-2 items-center rounded-full bg-red"
          />
        )}
        <div className="ml-10 mr-4 w-full gap-1 truncate border-b border-gray-200 py-4">
          <div className="flex w-full justify-between">
            <p data-testid="channel" className="text-left text-base font-bold">
              {channel.channelTitle}
            </p>
            <p className="truncate text-sm text-gray-500">{messageTime}</p>
          </div>
          <p
            data-testid="last_channel_msg"
            className="mt-1 w-full truncate text-left text-base text-gray-500"
          >
            {stripHTML(parseMessageToChannel(latestMessage))}
          </p>
        </div>
      </div>
    </button>
  );
};

export default Channel;
