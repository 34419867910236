import { STATUS_OPTIONS } from 'constants/status';

import { RolesFiltersFormProps } from './rolesFiltersForm.types';

export const defaultValues: RolesFiltersFormProps = {
  name: '',
  createdAt: '',
  color: '',
  _id: '',
  searchKey: '',
  'userType.name': '',
  userType: { label: '', value: '' },
  status: { label: '', value: '' }
};

export const getRolesFiltersValue = (key: string, val: string) => {
  const currentKey = key === 'userType.name' ? 'userType' : key;
  let currentValue;
  switch (currentKey) {
    case 'status':
      currentValue = STATUS_OPTIONS.find((item) => item.value === val);
      break;
    case 'userType':
      currentValue = { label: val, value: val };
      break;
    default:
      currentValue = val;
  }
  return currentValue;
};
