import { CATEGORY_LABELS } from 'constants/appointmentType';

import { useEffect } from 'react';

import { Common } from '@thecvlb/design-system/lib/src';
import Alert from 'components/common/Alert';
import { PathName } from 'enums/pathName';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';
import { useKeyPress } from 'react-use';

type TaskCreatedProps = {
  patientId: string;
  taskCategory: string;
  closeModal: () => void;
  type: 'created' | 'assigned' | 'updated';
  assignedTo?: string;
  heading?: string;
};

const TaskUpdated = ({
  patientId,
  taskCategory,
  closeModal,
  type = 'created',
  assignedTo,
  heading
}: TaskCreatedProps) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [isEnterPressed, keyboardEvent] = useKeyPress('Enter');

  useEffect(() => {
    if (isEnterPressed && closeModal && keyboardEvent) {
      keyboardEvent.preventDefault();
      keyboardEvent.stopPropagation();
      closeModal();
    }
  }, [isEnterPressed, closeModal, keyboardEvent]);

  const isTasksPage = !!matchPath(PathName.Tasks, location.pathname);

  const navigateToPatientsTasks = (e: React.MouseEvent) => {
    e.preventDefault();
    navigate(`${PathName.Patient}/${patientId}?active-tab=Tasks`);
    closeModal();
  };

  const action =
    type === 'created'
      ? 'created'
      : type === 'assigned' && assignedTo
        ? `assigned to ${assignedTo}`
        : type === 'assigned'
          ? 'assigned'
          : 'updated';

  const taskWarning = (
    <div>
      <p className="mb-2 text-base text-gray-700">
        Viewing tasks differs on our platform depending on the page you are on.
      </p>
      <p className="mb-2 text-base text-gray-700">
        A patient’s page allows you to view all tasks assigned for that patient regardless of whom
        is assigned or what your user role may be.
      </p>
      <p className="text-base text-gray-700">
        The ‘Tasks’ page is different - it will only show tasks you have permission to view or have
        been assigned to.
      </p>
    </div>
  );

  const taskCategoryLabel =
    CATEGORY_LABELS.find((category) => category.plural === taskCategory)?.singular || taskCategory;

  return (
    <div>
      {heading && <h2 className="mb-2 text-xl font-bold text-gray-700">{heading}</h2>}
      <div className="mx-auto my-5 flex size-16 items-center justify-center rounded-full bg-secondary-100">
        <Common.Icon name="check" className="size-8 text-secondary" />
      </div>
      <p className="mb-4 text-center text-xl font-bold">
        {type === 'created' && 'Your'} {taskCategoryLabel} task was successfully {action}
      </p>
      <p className="mb-2 text-center text-base">
        If you would like to view the task, you can navigate to the patient’s page{' '}
        <span
          onClick={navigateToPatientsTasks}
          className="cursor-pointer text-primary-500 underline"
        >
          here
        </span>
        .
      </p>

      {isTasksPage && <Alert containerClasses="mt-2" type="info" children={taskWarning} />}

      <button
        type="button"
        className="mt-4 w-full rounded-lg bg-primary py-[7.5px] text-sm font-bold text-white"
        onClick={closeModal}
      >
        Close
      </button>
    </div>
  );
};

export default TaskUpdated;
