import { DEFAULT_SEARCH_PARAMS } from 'constants/tables';

import Card from 'components/common/Card';
import { PathName } from 'enums/pathName';
import { NavLink } from 'react-router-dom';

import { NavCardProps } from './admin.types';

export const adminLinks: NavCardProps[] = [
  {
    tag: 'aging',
    title: 'Tasks by date',
    description: 'View and manage old tasks.',
    link: PathName.Tasks,
    searchParams: '?limit=50&pageNo=0'
  },
  {
    tag: 'aging',
    title: 'Aging configuration',
    description: 'Configure alerts for staff.',
    link: PathName.AgingConfiguration
  },
  {
    tag: 'appointment',
    title: 'Appointment types',
    description: 'View and manage appoint types and routing.',
    link: PathName.AppointmentTypes
  },
  {
    tag: 'content',
    title: 'Articles',
    description: 'View and manage clinical protocols and training.',
    link: PathName.Articles
  },
  {
    tag: 'requests',
    title: 'Front desk request types',
    description: 'View and manage Front desk request types and routing to staff.',
    link: PathName.FrontDeskRequestTypes
  },
  {
    tag: 'settings',
    title: 'System settings',
    description: 'Configure system/global settings.',
    link: PathName.SystemSettings
  },
  {
    tag: 'templates',
    title: 'Patient message templates',
    description: 'Create templated messages to send to the patient.',
    link: PathName.PatientMessageTemplates
  },
  {
    tag: 'templates',
    title: 'Request message templates',
    description: 'Create templated messages to send along with staff requests.',
    link: PathName.RequestMessageTemplates
  },
  {
    tag: 'templates',
    title: 'Internal note templates',
    description: 'Create templated messages for internal staff communication.',
    link: PathName.InternalNoteTemplates
  },
  {
    tag: 'users & roles',
    title: 'Patients',
    description: 'Manage and view patients.',
    link: PathName.Patients
  },
  {
    tag: 'users & roles',
    title: 'Staff',
    description: 'View and manage staff.',
    link: PathName.Staffs
  },
  {
    tag: 'users & roles',
    title: 'Roles',
    description: 'View and manage user types and roles.',
    link: PathName.ManageRoles
  },
  //TODO: (Vlad) Change teams place after Dominic answer
  {
    tag: 'users & roles',
    title: 'Teams',
    description: 'View and manage teams.',
    link: PathName.Teams
  },
  {
    tag: 'automation',
    title: 'Internal Automations',
    description: 'Create automated triggers based on requests or appointments.',
    link: PathName.InternalAutomations
  },
  {
    tag: 'automation',
    title: 'Patient Automations',
    description: 'Create automated triggers based on requests or appointments.',
    link: PathName.PatientAutomations
  },
  {
    tag: 'billing',
    title: 'Billing configuration',
    description: 'Set default prices for billing.',
    link: PathName.BillingConfiguration
  },
  {
    tag: 'billing',
    title: 'Staff costs configuration',
    description: 'Update staff pricing defaults.',
    link: PathName.StaffCosts
  },
  {
    tag: 'Reviews',
    title: 'Reviews',
    description: 'Moderate customer reviews.',
    link: PathName.Reviews
  },
  {
    tag: 'Reviews',
    title: 'Staff Feedback',
    description: 'View feedback from staff.',
    link: PathName.StaffFeedback
  },
  {
    tag: 'Reviews',
    title: 'Patient Feedback',
    description: 'View feedback from customers.',
    link: PathName.CustomerFeedback
  }
];

export const renderCard = (card: NavCardProps) => (
  <NavLink
    to={{ pathname: card.link, search: card.searchParams ?? DEFAULT_SEARCH_PARAMS }}
    state={{
      prevPath: [PathName.Tasks, PathName.Patients, PathName.Articles].some((path) =>
        card.link.includes(path)
      )
        ? undefined
        : location.pathname
    }}
    key={card.title}
    className="min-h-[6.8rem] w-[28.5%] min-w-[19.7rem]"
  >
    <Card className="group size-full border-2 border-secondary p-7 py-6 shadow-none transition-all duration-300 hover:bg-secondary">
      <h3 className="mb-1 font-bold text-gray-700 transition-all duration-300 group-hover:text-white">
        {card.title}
      </h3>
      <p className="text-base text-gray-500 transition-all duration-300 group-hover:text-white">
        {card.description}
      </p>
    </Card>
  </NavLink>
);
