import dayjs from 'dayjs';
import { DateFormat } from 'enums/dateFormats';
import { OrderLabels } from 'enums/orderLabels';
import { OrderProps, OrdersTypes, Statuses } from 'store/orders/orders.types';

import { OrderFormValues, OrderInfoItem } from './orderInfo.types';

const getItems = (order: OrderProps): OrderInfoItem[] => {
  return [
    {
      label: OrderLabels.OrderId,
      property: '_id',
      value: order._id && <span>{order._id}</span>
    },
    {
      label: OrderLabels.Type,
      property: 'type',
      value: order.type && <span>{order.type}</span>
    },
    {
      label: OrderLabels.CreatedDate,
      property: 'createdAt',
      value: order.createdAt && (
        <span>{dayjs(order?.createdAt).format(DateFormat.MM_DD_YYYY_HH_mmA)}</span>
      )
    },
    {
      label: OrderLabels.OrderStatus,
      property: 'orderStatus',
      value: order.orderStatus && <span>{order.orderStatus}</span>
    },
    {
      label: OrderLabels.ShippingStatus,
      property: 'shippingStatus',
      value: <span>{order.shippingStatus || '-'}</span>
    },
    {
      label: OrderLabels.PharmacyStatus,
      property: 'pharmacyStatus',
      value: <span>{order.pharmacyStatus || '-'}</span>
    },
    {
      label: OrderLabels.TrackingNumber,
      property: 'trackingNumber',
      value: <span>{order.trackingNumber || '-'}</span>
    }
  ];
};

const getSubmitOrderData = (formData: OrderFormValues) => {
  return {
    trackingNumber: formData.trackingNumber,
    shippingStatus: formData.shippingStatus?.value || null,
    orderStatus: formData.orderStatus?.value || null,
    pharmacyStatus: formData.pharmacyStatus?.value || null
  };
};

const getShowReleaseFromHold = (order: OrderProps) => {
  const isWells = order.type === OrdersTypes.wells;
  const isAdditionalSuppliesReceived = order.additionalSupplies?.find(
    (item) => item.Status === Statuses.received
  );
  const isPharmacyReceived = order.pharmacy?.Status === Statuses.received;

  return isWells && !!isAdditionalSuppliesReceived && !!isPharmacyReceived;
};

const sortOptions = (
  a: {
    label: string;
    value: string;
  },
  b: {
    label: string;
    value: string;
  }
) => {
  if (a.label < b.label) return -1;
  if (a.label > b.label) return 1;
  return 0;
};

const sharedStatusOptions = [
  { label: 'Cancelled', value: 'Cancelled' },
  { label: 'Error', value: 'Error' },
  { label: 'Closed', value: 'Closed' }
];

const orderOptions = [
  ...sharedStatusOptions,
  ...[
    { label: 'Created', value: 'Created' },
    { label: 'Completed', value: 'Completed' },
    { label: 'In progress', value: 'In progress' }
  ]
];

const pharmacyOptions = [
  ...sharedStatusOptions,
  ...[
    { label: 'Missing RX', value: 'Missing RX' },
    { label: 'Expired', value: 'Expired' },
    { label: 'Transferred', value: 'Transferred' },
    { label: 'Exception', value: 'Exception' },
    { label: 'In progress', value: 'In progress' },
    { label: 'Replacement', value: 'Replacement' },
    { label: 'Blocked', value: 'Blocked' },
    { label: 'PA required', value: 'PA required' },
    { label: 'Patient no response', value: 'Patient no response' },
    { label: 'Provider no response', value: 'Provider no response' }
  ]
];

const shippingOptions = [
  ...sharedStatusOptions,
  ...[
    { label: 'Local pharmacy', value: 'Local pharmacy' },
    { label: 'Shipping', value: 'Shipping' },
    { label: 'Delivered', value: 'Delivered' },
    { label: 'Exception', value: 'Exception' },
    { label: 'Blocked', value: 'Blocked' }
  ]
];

export {
  getItems,
  getSubmitOrderData,
  sortOptions,
  getShowReleaseFromHold,
  orderOptions,
  pharmacyOptions,
  shippingOptions
};
