import { Common } from '@thecvlb/design-system';
import classNames from 'classnames';
import {
  getIssueDescriptionsText,
  getIssueTypeText
} from 'components/tables/StaffFeedbackTable/staffFeedbackTable.settings';
import dayjs from 'dayjs';
import { DateFormat } from 'enums/dateFormats';
import { StaffFeedbackProps } from 'store/staffFeedback/staffFeedback.types';

const StaffFeedbackDetails: React.FC<{ feedback?: StaffFeedbackProps }> = ({ feedback }) => {
  const issueText = getIssueTypeText(feedback?.issueType);
  const issueDescriptions = getIssueDescriptionsText(feedback?.issueDescriptions);

  const wrapperClassNames = classNames('flex flex-col gap-3 font-semibold');

  return (
    <div className={wrapperClassNames}>
      <div data-testid="issue_type" className="flex text-sm">
        <p className="w-[120px] text-sm">Issue type</p> {issueText}
      </div>
      <div className="flex text-sm">
        <p className="w-[120px] text-sm">Issue descriptions</p>{' '}
        <span data-testid="issue_description" className="flex flex-col gap-1">
          {issueDescriptions}
        </span>
      </div>
      <div className="flex text-sm">
        <p className="w-[120px] text-sm">Feedback</p>{' '}
        <div
          data-testid="review_content"
          className="max-w-[420px] truncate whitespace-normal text-sm"
        >
          {feedback?.comment || '--'}
        </div>
      </div>
      <div data-testid="patient_name" className="flex text-sm">
        <p className="w-[120px] text-sm">Patient name</p> {feedback?.patientInfo?.name}
      </div>
      <div data-testid="status" className="flex text-sm">
        <p className="w-[120px] text-sm">Status</p> {feedback?.status === 'good' ? '👍' : '👎'}
      </div>
      <div className="flex items-center text-sm">
        <p className="w-[120px] text-sm">Twilio name / SID</p>{' '}
        <span data-testid="twilio_name_sid" className="mr-5">
          {feedback?.roomName}
        </span>
        <Common.Button
          onClick={() => {
            const link = `https://console.twilio.com/`;
            window.open(link, '_blank');
          }}
          color="white"
          size="sm"
        >
          Go to Twilio
        </Common.Button>
      </div>
      <div data-testid="date_added_container" className="flex text-sm">
        <p className="w-[120px] text-sm">Date added</p>{' '}
        {dayjs(feedback?.createdAt).format(DateFormat.MMM_D_h_mm_a_z)}
      </div>
    </div>
  );
};

export default StaffFeedbackDetails;
