import React from 'react';

import { Common } from '@thecvlb/design-system/lib/src';
import classNames from 'classnames';

import { ActionButtonProps } from './actionButton.types';

const ActionButton: React.FC<ActionButtonProps> = ({
  type,
  onClick,
  disabled,
  dataTestId,
  className
}) => {
  const buttonClasses = classNames(
    'rounded-lg bg-white px-4 py-[7.5px] text-gray-700 ring-1 ring-gray-200 hover:ring-gray-400 disabled:bg-gray-200 disabled:text-gray',
    className
  );
  return (
    <button
      data-testid={dataTestId}
      onClick={onClick}
      disabled={disabled}
      className={buttonClasses}
    >
      <Common.Icon className="size-5" name={type === 'phone' ? 'phone-filled' : type} />
    </button>
  );
};

export default ActionButton;
