import config from 'config/socket/chat';
import { io } from 'socket.io-client';

const { webSocket } = config;

const socketMessages = io(webSocket.baseUrlWebSocketMessages, {
  secure: true,
  reconnectionAttempts: 5,
  transports: ['websocket', 'webtransport']
});

export default socketMessages;
