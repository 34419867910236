import type {
  GetElationMedicatonsResponse,
  SubmitPrescriptionResponse,
  SubmitPrescriptionsBody
} from './weightManagement.types';
import { apiSlice } from '../api/apiSlice';

export const patientsApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getMedications: builder.query<GetElationMedicatonsResponse, { state?: string; userId: string }>(
      {
        query: ({ state, userId }) => ({
          url: '/elation-medications',
          params: { state, userId }
        })
      }
    ),

    submitPrescriptions: builder.mutation<
      { data: SubmitPrescriptionResponse },
      { patientId: string; body: SubmitPrescriptionsBody }
    >({
      query: ({ body, patientId }) => ({
        url: `/patients/${patientId}/prescriptions`,
        method: 'POST',
        body
      }),
      invalidatesTags: ['Prescriptions', 'Patient']
    }),
    triggerRenewal: builder.mutation<{ message: string }, { orderId: string; patientId: string }>({
      query: ({ orderId, patientId }) => ({
        url: `/patients/${patientId}/orders/${orderId}/surveys/renewal`,
        method: 'POST'
      }),
      invalidatesTags: ['Prescriptions']
    })
  })
});

export const {
  useGetMedicationsQuery,
  useLazyGetMedicationsQuery,
  useSubmitPrescriptionsMutation,
  useTriggerRenewalMutation
} = patientsApi;
