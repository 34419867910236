import { PathName } from 'enums/pathName';
import { useLocation } from 'react-router-dom';
import { useUpdateFrontDeskRequestTypesMutation } from 'store/frontDeskRequestTypes/frontDeskRequestTypesSlice';
import { useModerateReviewsMutation } from 'store/reviews/reviewsSlice';
import { useUpdateStaffMembersMutation } from 'store/staffs/staffsSlice';

export const useRetryBulkEdit = () => {
  const location = useLocation();

  const [updateStaffMembers, { isLoading: updateStaffLoading, data }] =
    useUpdateStaffMembersMutation({
      fixedCacheKey: 'staffBulkEdit'
    });

  const [
    updateFrontDeskRequestTypes,
    { isLoading: frontDeskRequestsTypesLoading, data: frontDeskRequestsTypesData }
  ] = useUpdateFrontDeskRequestTypesMutation({ fixedCacheKey: 'frontDeskRequestsTypes' });

  const [moderateReviews, { isLoading: moderateLoading, data: reviewsData }] =
    useModerateReviewsMutation({
      fixedCacheKey: 'reviewsBulkEdit'
    });

  if (location.pathname.includes(PathName.Staffs)) {
    return {
      retry: updateStaffMembers,
      isLoading: updateStaffLoading,
      editResult: data
    };
  }

  if (location.pathname.includes(PathName.FrontDeskRequestTypes)) {
    return {
      retry: updateFrontDeskRequestTypes,
      isLoading: frontDeskRequestsTypesLoading,
      editResult: frontDeskRequestsTypesData
    };
  }

  if (location.pathname.includes(PathName.Reviews)) {
    return {
      retry: moderateReviews,
      isLoading: moderateLoading,
      editResult: reviewsData
    };
  }

  return {
    retry: updateStaffMembers,
    isLoading: false,
    editResult: data
  };
};
