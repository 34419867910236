import { PatientFiltersFormProps } from './patientFiltersForm.types';

export const defaultPatientFilters: PatientFiltersFormProps = {
  searchKey: '',
  patient: '',
  doctor: '',
  email: '',
  membership: { label: '', value: '' },
  createdAt: '',
  uniqueId: '',
  status: { label: '', value: '' },
  tags: [''],
  states: []
};
