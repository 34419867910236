import { useEffect, useState } from 'react';

import { useLocalStorage } from 'react-use';

const useEditorToolbar = () => {
  const [showToolbar, toggle] = useLocalStorage('show-message-editor', true);
  const [editorToolbar, setEditorToolbar] = useState<Element | null>(
    document.querySelector('.tox-editor-header')
  );

  const toggleFormatText = () => {
    toggle(!showToolbar);
  };

  const setToolbar = () => {
    setEditorToolbar(document.querySelector('.tox-editor-header'));
  };

  useEffect(() => {
    if (editorToolbar) {
      // @ts-expect-error - editorToolbar has the style property
      editorToolbar.style.display = showToolbar ? 'block' : 'none';
    }
  }, [showToolbar, editorToolbar]);

  return { showToolbar, toggleFormatText, setToolbar };
};

export default useEditorToolbar;
