import { CustomerFeedbackProblemType } from 'enums/customerFeedback';

import { CustomerFeedbackFiltersProps } from './customerFeedbackFiltersForm.types';

export const defaultValues: CustomerFeedbackFiltersProps = {
  searchKey: '',
  providerName: { value: '', label: '' },
  patientName: { value: '', label: '' },
  startCreateDate: '',
  endCreateDate: '',
  createdAt: '',
  problemType: { value: '', label: '' },
  rating: { value: '', label: '' }
};

export const RATING_FILTER = [
  { label: '1', value: '1' },
  { label: '2', value: '2' },
  { label: '3', value: '3' },
  { label: '4', value: '4' },
  { label: '5', value: '5' }
];

export const PROBLEM_TYPE_FILTER = [
  { label: 'All', value: '' },
  { label: 'My provider', value: CustomerFeedbackProblemType.MyProvider },
  { label: 'Using the app', value: CustomerFeedbackProblemType.UsingApp }
];
