import HealthMetricsModal from 'components/modals/HealthMetrics';
import UpdateHeight from 'components/modals/HealthMetrics/Height/Height';
import { HealthMetricTypes } from 'enums/chart';
import { useAppDispatch } from 'hooks/redux';
import { openModal } from 'store/modal/modalSlice';

import { HeightProps } from './height.types';
import HealthMetric from '../../HealthMetric/HealthMetric';

const Height: React.FC<HeightProps> = ({ height }) => {
  const dispatch = useAppDispatch();

  const openCreateMetricModal = () => {
    dispatch(
      openModal({
        size: 'base',
        hideClose: true,
        modalContent: (
          <HealthMetricsModal type={HealthMetricTypes.Height}>
            <UpdateHeight />
          </HealthMetricsModal>
        )
      })
    );
  };

  const heightSubtitle = height ? `${Math.floor(+height / 12)} ft ${+height % 12} in` : '';

  return (
    <HealthMetric
      type={HealthMetricTypes.Height}
      value={height}
      metricSubtitle={heightSubtitle}
      openMetricModal={openCreateMetricModal}
    />
  );
};

export default Height;
