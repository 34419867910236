import { Common } from '@thecvlb/design-system/lib/src';

const PriceInfo = ({ pricePointId }: { pricePointId: string }) => {
  let renderedContent;

  const itemContainerClassNames = 'mb-2';
  const itemClassNames = 'flex items-center gap-2';
  const itemContentClassNames = 'text-left text-sm text-gray-700';
  const footerItemClassNames = 'text-sm';

  switch (pricePointId) {
    case 'wm-one-month-129':
      renderedContent = (
        <>
          <div className={itemContainerClassNames}>
            <div className={itemClassNames}>
              <Common.Icon className="flex-none text-secondary" name="check" />
              <span className={itemContentClassNames}>
                Insurance coverage assistance for GLP-1 medications, including Ozempic® and
                Wegovy®
              </span>
            </div>
            <div className={itemClassNames}>
              <Common.Icon className="flex-none text-secondary" name="check" />
              <span className={itemContentClassNames}>
                Access to compounded medication (Semaglutide & Tizepatide) at cost
              </span>
            </div>
            <div className={itemClassNames}>
              <Common.Icon className="flex-none text-secondary" name="check" />
              <span className={itemContentClassNames}>
                Access to a GLP-1 prescription from a U.S.-licensed provider
              </span>
            </div>
            <div className={itemClassNames}>
              <Common.Icon className="flex-none text-secondary" name="check" />
              <span className={itemContentClassNames}>Complimentary weight-related labs</span>
            </div>
            <div className={itemClassNames}>
              <Common.Icon className="flex-none text-secondary" name="check" />
              <span className={itemContentClassNames}>
                24/7 messaging with your LifeMD care team
              </span>
            </div>
          </div>
          <div className={footerItemClassNames}>$129 per month. Cancel anytime</div>
        </>
      );
      break;
    case 'wm-three-months-129':
      renderedContent = (
        <>
          <div className={itemContainerClassNames}>
            <div className={itemClassNames}>
              <Common.Icon className="flex-none text-secondary" name="check" />
              <span className={itemContentClassNames}>
                Insurance coverage assistance for GLP-1 medications, including Ozempic® and
                Wegovy®
              </span>
            </div>
            <div className={itemClassNames}>
              <Common.Icon className="flex-none text-secondary" name="check" />
              <span className={itemContentClassNames}>
                Access to compounded medication (Semaglutide & Tizepatide) at cost
              </span>
            </div>
            <div className={itemClassNames}>
              <Common.Icon className="flex-none text-secondary" name="check" />
              <span className={itemContentClassNames}>
                Access to a GLP-1 prescription from a U.S.-licensed provider
              </span>
            </div>
            <div className={itemClassNames}>
              <Common.Icon className="flex-none text-secondary" name="check" />
              <span className={itemContentClassNames}>Complimentary weight-related labs</span>
            </div>
            <div className={itemClassNames}>
              <Common.Icon className="flex-none text-secondary" name="check" />
              <span className={itemContentClassNames}>
                24/7 messaging with your LifeMD care team
              </span>
            </div>
          </div>
          <div className={footerItemClassNames}>
            $290.25 for the first 3 months. Renews at $387. Cancel anytime
          </div>
        </>
      );
      break;
    case 'wm-six-months-129':
      renderedContent = (
        <>
          <div className={itemContainerClassNames}>
            <div className={itemClassNames}>
              <Common.Icon className="flex-none text-secondary" name="check" />
              <span className={itemContentClassNames}>
                Insurance coverage assistance for GLP-1 medications, including Ozempic® and
                Wegovy®
              </span>
            </div>
            <div className={itemClassNames}>
              <Common.Icon className="flex-none text-secondary" name="check" />
              <span className={itemContentClassNames}>
                Access to compounded medication (Semaglutide & Tizepatide) at cost
              </span>
            </div>
            <div className={itemClassNames}>
              <Common.Icon className="flex-none text-secondary" name="check" />
              <span className={itemContentClassNames}>
                Access to a GLP-1 prescription from a U.S.-licensed provider
              </span>
            </div>
            <div className={itemClassNames}>
              <Common.Icon className="flex-none text-secondary" name="check" />
              <span className={itemContentClassNames}>Complimentary weight-related labs</span>
            </div>
            <div className={itemClassNames}>
              <Common.Icon className="flex-none text-secondary" name="check" />
              <span className={itemContentClassNames}>
                24/7 messaging with your LifeMD care team
              </span>
            </div>
          </div>
          <div className={footerItemClassNames}>
            $387 for the first 6 months. Renews at $774. Cancel anytime{' '}
          </div>
        </>
      );
      break;
    case 'wm-twelve-months-129':
      renderedContent = (
        <>
          <div className={itemContainerClassNames}>
            <div className={itemClassNames}>
              <Common.Icon className="flex-none text-secondary" name="check" />
              <span className={itemContentClassNames}>
                Insurance coverage assistance for GLP-1 medications, including Ozempic® and
                Wegovy®
              </span>
            </div>
            <div className={itemClassNames}>
              <Common.Icon className="flex-none text-secondary" name="check" />
              <span className={itemContentClassNames}>
                Access to compounded medication (Semaglutide & Tizepatide) at cost
              </span>
            </div>
            <div className={itemClassNames}>
              <Common.Icon className="flex-none text-secondary" name="check" />
              <span className={itemContentClassNames}>
                Access to a GLP-1 prescription from a U.S.-licensed provider
              </span>
            </div>
            <div className={itemClassNames}>
              <Common.Icon className="flex-none text-secondary" name="check" />
              <span className={itemContentClassNames}>Complimentary weight-related labs</span>
            </div>
            <div className={itemClassNames}>
              <Common.Icon className="flex-none text-secondary" name="check" />
              <span className={itemContentClassNames}>
                24/7 messaging with your LifeMD care team
              </span>
            </div>
          </div>
          <div className={footerItemClassNames}>
            $774 for the first 12 months. Renews at $1548. Cancel anytime
          </div>
        </>
      );
      break;
    case 'bundle-semaglutide-one-month':
      renderedContent = (
        <>
          <div className={itemContainerClassNames}>
            <div className={itemClassNames}>
              <Common.Icon className="flex-none text-secondary" name="check" />
              <span className={itemContentClassNames}>Compounded Semaglutide included*</span>
            </div>
            <div className={itemClassNames}>
              <Common.Icon className="flex-none text-secondary" name="check" />
              <span className={itemContentClassNames}>
                Access to a GLP-1 prescription from a U.S.-licensed provider
              </span>
            </div>
            <div className={itemClassNames}>
              <Common.Icon className="flex-none text-secondary" name="check" />
              <span className={itemContentClassNames}>Complimentary weight-related labs</span>
            </div>
            <div className={itemClassNames}>
              <Common.Icon className="flex-none text-secondary" name="check" />
              <span className={itemContentClassNames}>
                24/7 messaging with your LifeMD care team
              </span>
            </div>
            <div className={itemClassNames}>
              <Common.Icon className="flex-none text-secondary" name="check" />
              <span className={itemContentClassNames}>Comprehensive prescription renewals</span>
            </div>
          </div>
          <div className={footerItemClassNames}>
            $199 for the first month. Renews at $299. Cancel anytime{' '}
          </div>
          <div className={footerItemClassNames}>
            *Includes all dosages including maximum titrated level
          </div>
        </>
      );
      break;
    case 'bundle-tirzepatide-one-month':
      renderedContent = (
        <>
          <div className={itemContainerClassNames}>
            <div className={itemClassNames}>
              <Common.Icon className="flex-none text-secondary" name="check" />
              <span className={itemContentClassNames}>Compounded Tirzepatide included*</span>
            </div>
            <div className={itemClassNames}>
              <Common.Icon className="flex-none text-secondary" name="check" />
              <span className={itemContentClassNames}>
                Access to a GLP-1 prescription from a U.S.-licensed provider
              </span>
            </div>
            <div className={itemClassNames}>
              <Common.Icon className="flex-none text-secondary" name="check" />
              <span className={itemContentClassNames}>Complimentary weight-related labs</span>
            </div>
            <div className={itemClassNames}>
              <Common.Icon className="flex-none text-secondary" name="check" />
              <span className={itemContentClassNames}>
                24/7 messaging with your LifeMD care team
              </span>
            </div>
            <div className={itemClassNames}>
              <Common.Icon className="flex-none text-secondary" name="check" />
              <span className={itemContentClassNames}>Comprehensive prescription renewals</span>
            </div>
          </div>
          <div className={footerItemClassNames}>
            $399 for the first month. Renews at $599. Cancel anytime
          </div>
          <div className={footerItemClassNames}>
            *Includes all dosages including maximum titrated level
          </div>
        </>
      );
      break;
    default:
      break;
  }

  return renderedContent;
};

export default PriceInfo;
