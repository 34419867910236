import classNames from 'classnames';
import { FieldError, FieldErrorsImpl, Merge } from 'react-hook-form';

export const getTimeInputClassName = (
  errors?: FieldError | Merge<FieldError, FieldErrorsImpl> | boolean
) =>
  classNames(
    'py-2.5 px-3 w-28 h-10 rounded border text-sm text-gray shadow',
    errors ? 'ring-1 ring-red' : 'border-gray'
  );
