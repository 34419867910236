import { useMemo, useState } from 'react';

import { Tab, TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/react';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { CalendarTab } from 'enums/calendarTabs';
import { Role } from 'enums/role';
import { useAppSelector } from 'hooks/redux';
import { useSearchParams } from 'react-router-dom';
import { selectUser } from 'store/user/userSlice';

import GlobalCalendar from '../GlobalCalendar';
import MonthlyAvailabilityTab from '../ManageAvailability/MonthlyAvailability/MonthlyAvailabilityTab';
import StaffAvailability from '../ManageAvailability/StaffAvailabilityTab';
import MyCalendar from '../MyCalendar';
import PhysicianMultiSelect from '../PhysicianMultiSelect';
import UpdateTimezone from '../UpdateTimezone';

const tabsData = [
  { tabTitle: CalendarTab.Tasks, tabAvailableForRoles: [Role.PH, Role.NP] },
  {
    tabTitle: CalendarTab.StaffCalendar,
    tabAvailableForRoles: [Role.AD, Role.MA, Role.PH, Role.NP, Role.CS]
  },
  { tabTitle: CalendarTab.Availability, tabAvailableForRoles: [Role.PH, Role.AD, Role.MA] }
];

const CalendarTabs = () => {
  const { userType, timezone } = useAppSelector(selectUser);

  /**
   * @description filter tabs based on user role
   * show tabs that are only available for the current user
   */
  const tabsList = useMemo(
    () =>
      tabsData
        .filter((tab) => tab.tabAvailableForRoles.includes(userType.name as Role))
        .map((tab) => tab.tabTitle),
    [userType]
  );

  const [searchParams, setSearchParams] = useSearchParams({ 'active-tab': tabsList[0] });

  const [selectedPhysiciansIds, setSelectedPhysiciansIds] = useState<string[]>([]);

  const activeTab = useMemo(
    () => (searchParams.get('active-tab') ?? CalendarTab.Tasks) as CalendarTab,
    [searchParams]
  );
  const defaultIndex = tabsList.indexOf(activeTab) !== -1 ? tabsList.indexOf(activeTab) : 0;
  const isPhysician = userType.name === Role.PH;
  const timeZone = isPhysician ? timezone : dayjs.tz.guess();

  const handleChangeTab = (index: number) => {
    searchParams.set('active-tab', tabsList[index]);
    setSearchParams(searchParams);
  };

  return (
    <TabGroup onChange={handleChangeTab} defaultIndex={defaultIndex}>
      <TabList
        data-testid="calendar_tabs"
        className="mb-6 flex items-center justify-between border-b border-gray-200 px-6 pt-6"
      >
        <div>
          {tabsList.map((tab) => (
            <Tab
              key={tab}
              className={({ selected }) =>
                classNames(
                  'px-3 py-4 text-base font-semibold text-gray',
                  selected ? 'border-b-2 border-secondary text-secondary' : ''
                )
              }
            >
              {tab}
            </Tab>
          ))}
        </div>
        <UpdateTimezone />
      </TabList>

      <TabPanels className="mt-6">
        {tabsList.map((tab) => {
          if (tab === CalendarTab.Tasks) {
            return (
              <TabPanel key={tab}>
                <MyCalendar activeTab={activeTab as CalendarTab} timezone={timeZone} />
              </TabPanel>
            );
          }
          if (tab === CalendarTab.StaffCalendar) {
            return (
              <TabPanel key={tab}>
                <PhysicianMultiSelect setSelectedPhysiciansIds={setSelectedPhysiciansIds} />
                <GlobalCalendar
                  activeTab={activeTab}
                  timezone={timeZone}
                  physiciansIds={selectedPhysiciansIds}
                />
              </TabPanel>
            );
          }
          if (tab === CalendarTab.Availability) {
            // Providers can edit only their availability
            // Other roles such as Admin and MA can view and edit other providers availability
            return (
              <TabPanel key={tab}>
                {userType.shortCode === 'MA' || userType.shortCode === 'AD' ? (
                  <StaffAvailability />
                ) : (
                  <MonthlyAvailabilityTab />
                )}
              </TabPanel>
            );
          }
        })}
      </TabPanels>
    </TabGroup>
  );
};

export default CalendarTabs;
