import { useCallback, useEffect, useState } from 'react';

import Calendar from 'components/appointments/Calendar';
import Loader from 'components/common/Loader';
import dayjs from 'dayjs';
import { CalendarTab } from 'enums/calendarTabs';
import { DateFormat } from 'enums/dateFormats';
import { useGetGlobalAppointmentsMutation } from 'store/appointments/appointmentsSlice';

import { CalendarPeriod, GlobalCalendarEvent } from '../Calendar/calendar.types';

const GlobalCalendar: React.FC<{
  timezone: string;
  activeTab: string;
  physiciansIds: string[];
}> = ({ timezone, activeTab, physiciansIds }) => {
  const [getGlobalAppointments, { data: globalAppointments, isLoading }] =
    useGetGlobalAppointmentsMutation();

  const [dates, setDates] = useState<CalendarPeriod>({
    startDate: dayjs().startOf('week').format(DateFormat.YYYY_MM_DD),
    endDate: dayjs().endOf('week').add(1, 'days').format(DateFormat.YYYY_MM_DD),
    timezone
  });
  const [globalEventsList, setGlobalEventsList] = useState<GlobalCalendarEvent[]>([]);

  const handleDateChange = useCallback(
    (startDate: string, endDate: string) => {
      setDates({
        startDate: dayjs(startDate).format(DateFormat.YYYY_MM_DD),
        endDate: dayjs(endDate).format(DateFormat.YYYY_MM_DD),
        timezone
      });
    },
    [timezone]
  );

  useEffect(() => {
    if (activeTab === CalendarTab.StaffCalendar) {
      getGlobalAppointments({
        startDate: dates.startDate,
        endDate: dates.endDate,
        timezone: dates.timezone,
        physicianIds: physiciansIds
      });
    }
  }, [
    getGlobalAppointments,
    dates.startDate,
    dates.endDate,
    dates.timezone,
    physiciansIds,
    activeTab
  ]);

  useEffect(() => {
    if (globalAppointments) {
      const list: GlobalCalendarEvent[] = globalAppointments.map((appointment) => ({
        appointments: appointment.appointments,
        time: appointment.time,
        start: dayjs(appointment.time).toDate(),
        end: dayjs(appointment.time).add(1, 'hour').toDate(),
        color:
          appointment.appointments.length > 3
            ? '#64CCC9'
            : appointment.appointments.length > 1
              ? '#64CCC95E'
              : '#64CCC926'
      }));
      setGlobalEventsList(list);
    }
  }, [globalAppointments]);

  return (
    <>
      <Loader isVisible={isLoading} />
      <Calendar global events={globalEventsList} onUpdate={handleDateChange} timezone={timezone} />
    </>
  );
};

export default GlobalCalendar;
