import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import { Common } from '@thecvlb/design-system';

import { AlertProps } from './alert.types';

function renderIcon(alertType: AlertProps['type']) {
  switch (alertType) {
    case 'info':
      return <Common.Icon name="info" className="mr-2 shrink-0 text-blue-500" />;
    case 'warning':
    case 'notice':
      return <ExclamationTriangleIcon className="mr-2 size-5 shrink-0 text-yellow-500" />;
    case 'error':
      return <Common.Icon name="error" className="mr-2 shrink-0 text-red" />;
  }
}

export { renderIcon };
