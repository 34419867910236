import React from 'react';

import { Common } from '@thecvlb/design-system';
import { FieldErrors, useFieldArray, useFormContext } from 'react-hook-form';

import AvailabilityTimeInput from './AvailabilityTimeInput';
import { defaultTimeInputValue, startInputValue, validateTime } from './timePeriod.settings';
import { FormValues } from '../manageAvailability.types';

const TimePeriod: React.FC<{
  errors?: FieldErrors<FormValues>;
  isLoading?: boolean;
}> = ({ isLoading, errors }) => {
  const { control, watch, trigger } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'shiftTime'
  });

  return (
    <ul>
      {fields.map((item, index) => {
        const error = errors?.shiftTime?.[index];
        const errorMessage = error?.start?.message || error?.end?.message || '';
        return (
          <div className="mb-2" key={item.id}>
            <li data-testid="time_container" className="mb-1 flex items-center justify-between">
              <div className="flex items-center">
                {isLoading ? (
                  <div className="h-9 w-[80px] animate-pulse rounded-md bg-slate-200" />
                ) : (
                  <AvailabilityTimeInput
                    name={`shiftTime.${index}.start`}
                    validate={{
                      required: (value) => value !== '' || 'Start time is required',
                      invalidTime: (value) => validateTime(value, watch(`shiftTime.${index}.end`))
                    }}
                    testId={`shiftTime.${index}.start`}
                    onBlur={() => trigger([`shiftTime.${index}.end`, `shiftTime.${index}.start`])}
                  />
                )}
                <span className="mx-3 text-sm">to</span>
                {isLoading ? (
                  <div className="h-9 w-[80px] animate-pulse rounded-md bg-slate-200" />
                ) : (
                  <AvailabilityTimeInput
                    name={`shiftTime.${index}.end`}
                    validate={{
                      isNotEmpty: (value) => value !== '' || 'End time is required',
                      invalidTime: (value) => validateTime(watch(`shiftTime.${index}.start`), value)
                    }}
                    testId={`shiftTime.${index}.end`}
                    onBlur={() => trigger([`shiftTime.${index}.start`, `shiftTime.${index}.end`])}
                  />
                )}
                <button
                  data-testid="trash_btn"
                  disabled={index === 0} // first item should not be removed
                  onClick={() => remove(index)}
                  className="disabled:cursor-not-allowed"
                >
                  <Common.Icon name="trash" className="ml-3" />
                </button>
              </div>
              <button
                data-testid="plus_btn"
                type="button"
                onClick={() => append({ start: startInputValue, end: defaultTimeInputValue })}
              >
                <Common.Icon name="plus" className="text-primary" />
              </button>
            </li>
            {errorMessage && (
              <p className="text-sm text-red-500" data-testid="time-error">
                {errorMessage}
              </p>
            )}
          </div>
        );
      })}
    </ul>
  );
};

export default TimePeriod;
