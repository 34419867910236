import { useState } from 'react';

import { Common } from '@thecvlb/design-system';
import classNames from 'classnames';
import { useFormContext } from 'react-hook-form';

import { AttachFilesProps } from './attachFiles.types';
import DropContainer from '../../components/DropContainer';

const AttachFiles: React.FC<AttachFilesProps> = ({ handleBack, handleNext }) => {
  const [dragOverlay, setDragOverlay] = useState(false);
  const { setValue, watch } = useFormContext();

  const files: File[] = watch('files') ?? [];
  const isFileAlreadyAdded = (f: File) =>
    files.some((fi) => fi.lastModified === f.lastModified && fi.name === f.name);

  const handleClick = (items: FileList | null) => {
    if (!items) {
      return;
    }
    const newItems = [...items].filter((f) => !isFileAlreadyAdded(f));
    items && setValue('files', [...files, ...newItems]);
  };

  const removeFile = (file: File) => {
    const newFiles = files.filter(
      (f) => f.name !== file.name && f.lastModified !== file.lastModified
    );
    setValue('files', newFiles);
  };

  const uploadWrapperClassName = classNames(
    'target-animate-div mb-4 w-full cursor-pointer rounded-2xl border p-8 transition-all duration-300 md:mb-8',
    dragOverlay ? 'border-2 border-solid border-primary bg-primary-400' : 'border-dashed'
  );
  const dragTextClassName = classNames(
    'block text-center text-base font-medium',
    dragOverlay ? 'text-white' : 'text-gray'
  );
  const cameraClassName = classNames(
    'mx-auto mt-2 mb-6 h-12 w-12',
    dragOverlay ? 'text-white' : 'text-gray-400'
  );

  const dragOrClassName = classNames(
    'my-4 block w-full text-center text-base font-bold',
    dragOverlay ? 'text-white/30' : 'text-gray'
  );
  const dragButtonClassName = classNames('mx-auto ', { 'opacity-30': dragOverlay });
  const imageIconClassName = 'h-[37px] w-[37px] text-primary';
  const wrapperFilesClassName = 'w-full max-w-[385px] mx-auto';
  const fileClassName =
    'mb-2 flex items-center gap-2 rounded-2xl bg-gray-100 p-4 text-mSm md:py-2 md:text-base';
  const fileNameClassName = 'flex-1 truncate';

  return (
    <div className="mt-8">
      <DropContainer
        className={uploadWrapperClassName}
        setDragOverlay={setDragOverlay}
        onChange={handleClick}
      >
        <Common.Icon className={cameraClassName} name="camera" />
        <span className={dragTextClassName}>Drag photos or videos here to start uploading.</span>
        <span className={dragOrClassName}>Or</span>
        <Common.Button
          className={dragButtonClassName}
          color="blue-alt"
          fullWidthOnMobile
          type="button"
        >
          Browse files
        </Common.Button>
      </DropContainer>

      <div className={wrapperFilesClassName}>
        {files &&
          [...files].map((file: File) => (
            <div className={fileClassName} key={file.lastModified + file.name}>
              <Common.Icon className={imageIconClassName} name="image" />
              <span className={fileNameClassName}>{file.name}</span>
              <button onClick={() => removeFile(file)} type="button" data-testid="remove-button">
                <Common.Icon className="text-gray" name="close" />
              </button>
            </div>
          ))}
      </div>

      <div className="flex items-center justify-center gap-3">
        <Common.Button color="white-alt" preIcon="arrow-left" onClick={handleBack}>
          Back
        </Common.Button>
        <Common.Button color="blue" postIcon="arrow-right" onClick={handleNext} type="button">
          Next
        </Common.Button>
      </div>
    </div>
  );
};

export default AttachFiles;
