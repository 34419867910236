export enum NotificationTab {
  Inbox = 'Inbox',
  Archived = 'Archived'
}

export enum AppointmentNotificationType {
  Before = 'before',
  After = 'after',
  CreatedAt = 'createdAt'
}

export enum NotificationType {
  Appointment = 'Appointment',
  Task = 'Task',
  Message = 'Message'
}
