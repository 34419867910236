import { useEffect } from 'react';

import Loader from 'components/common/Loader';
import { notifySuccess } from 'components/common/Toast/Toast';
import InputField from 'components/forms/controlled/InputField';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import {
  useEditAppointmentCategoriesMutation,
  useGetAppointmentCategoriesQuery
} from 'store/appointmentCategories/appointmentCategoriesSlice';
import { closeModal } from 'store/modal/modalSlice';
import { validation } from 'utils/helpers';
import { URL_REGEXP } from 'utils/regExp';

import { FormCategoriesData } from './appointmentCategoryVideo.types';
import {
  CHRONIC_CARE,
  PRIMARY_CARE,
  TELEHEALTH_VISIT,
  URGENT_CARE
} from './appointmentCategoryVideos.settings';
import PopupFooter from '../components/PopupFooter';
import PopupHeader from '../components/PopupHeader';

const AppointmentCategoryVideos = () => {
  const dispatch = useDispatch();
  const { data, isLoading, refetch } = useGetAppointmentCategoriesQuery();
  const [editAppointmentCategories, { isLoading: loadEdit }] =
    useEditAppointmentCategoriesMutation();
  const { control, formState, handleSubmit, reset } = useForm<FormCategoriesData>({
    reValidateMode: 'onChange'
  });

  const onSubmitArticle = (formData: FormCategoriesData) => {
    if (formData && data) {
      const newData = data.map((category) => ({
        ...category,
        referenceUrl: formData[category.name] || category.referenceUrl
      }));

      editAppointmentCategories(newData)
        .unwrap()
        .then((editData) => {
          notifySuccess(editData.message);
          refetch();
          dispatch(closeModal());
        });
    }
  };

  useEffect(() => {
    if (data) {
      reset({
        [PRIMARY_CARE]: data
          .find((category) => category.name === PRIMARY_CARE)
          ?.referenceUrl.trim(),
        [URGENT_CARE]: data.find((category) => category.name === URGENT_CARE)?.referenceUrl.trim(),
        [CHRONIC_CARE]: data
          .find((category) => category.name === CHRONIC_CARE)
          ?.referenceUrl.trim(),
        [TELEHEALTH_VISIT]: data
          .find((category) => category.name === TELEHEALTH_VISIT)
          ?.referenceUrl.trim()
      });
    }
  }, [data, reset]);

  return (
    <div data-testid="edit_video_urls_popup" className="p-8">
      <Loader isVisible={isLoading || loadEdit} />
      <PopupHeader title="Appointment category videos" />

      <form onSubmit={handleSubmit(onSubmitArticle)} className="flex flex-col gap-4">
        <InputField
          dataTestId="primary_care_field"
          name={PRIMARY_CARE}
          label="Primary care"
          control={control}
          labelDirection="row"
          type="text"
          errors={formState.errors[PRIMARY_CARE]}
          helper={formState.errors[PRIMARY_CARE]?.message?.toString()}
          rules={validation('Primary care', URL_REGEXP)}
        />
        <InputField
          dataTestId="urgent_care_field"
          name={URGENT_CARE}
          label="Urgent care"
          control={control}
          labelDirection="row"
          type="text"
          errors={formState.errors[URGENT_CARE]}
          helper={formState.errors[URGENT_CARE]?.message?.toString()}
          rules={validation('Urgent care', URL_REGEXP)}
        />
        <InputField
          dataTestId="chronic_care_field"
          name={CHRONIC_CARE}
          label="Chronic care"
          control={control}
          labelDirection="row"
          type="text"
          errors={formState.errors[CHRONIC_CARE]}
          helper={formState.errors[CHRONIC_CARE]?.message?.toString()}
          rules={validation('Chronic care', URL_REGEXP)}
        />
        <InputField
          dataTestId="telehealth_visit_field"
          name={TELEHEALTH_VISIT}
          label="Telehealth visit"
          control={control}
          labelDirection="row"
          type="text"
          errors={formState.errors[TELEHEALTH_VISIT]}
          helper={formState.errors[TELEHEALTH_VISIT]?.message?.toString()}
          rules={validation('Chronic care', URL_REGEXP)}
        />

        <PopupFooter hiddenDeleteButton disabled={isLoading || loadEdit} />
      </form>
    </div>
  );
};

export default AppointmentCategoryVideos;
