import * as Popover from '@radix-ui/react-popover';
import classnames from 'classnames';

import { GlobalCalendarEvent } from '../calendar.types';

const GlobalEventButton: React.FC<{
  globalEvent: GlobalCalendarEvent;
}> = ({ globalEvent }) => {
  const appointmentCount = globalEvent?.appointments ? globalEvent.appointments.length : 0;

  const parseTextColor = (eventsAmount: string | number): string => {
    if (typeof eventsAmount === 'number' && eventsAmount < 4) {
      return 'text-primary';
    }
    return 'text-white';
  };

  const buttonClasses = classnames(
    'flex w-full justify-between text-sm font-bold',
    parseTextColor(appointmentCount)
  );

  return (
    <Popover.Trigger asChild>
      <button className="flex size-full outline-none" data-testid="global_event">
        <div className="m-auto overflow-hidden">
          <span className={buttonClasses} data-testid="appointments_count">{`${appointmentCount} ${
            appointmentCount > 1 ? 'appts' : 'appt'
          }`}</span>
        </div>
      </button>
    </Popover.Trigger>
  );
};

export default GlobalEventButton;
