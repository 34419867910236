import { useEffect } from 'react';

import { Portal } from '@headlessui/react';
import { Amplify } from 'aws-amplify';
import Banner from 'components/common/Banner';
import { getAuthConfig } from 'config/auth';
import useStartLocation from 'hooks/common/location/useStartLocation';
import { useLogout } from 'hooks/common/useLogout';
import { useMarkerio } from 'hooks/common/useMarkerio';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { routerConfig } from 'router';

const toastStyle = {
  width: '25%'
};

export const IS_LIVE = import.meta.env.VITE_APP_TITLE === 'production';

// use 'http://localhost:9001/login' for local development
const redirectUrl = IS_LIVE
  ? `${location.protocol}//${location.host}`
  : `${location.protocol}//${location.host}/login`;

const authConfig = getAuthConfig({
  redirectSignIn: [redirectUrl],
  redirectSignOut: [redirectUrl]
});
const router = createBrowserRouter(routerConfig);

function App() {
  const { logoutAllTabs } = useLogout();

  useEffect(() => {
    // Logout all tabs via the BroadcastChannel when user logs out
    logoutAllTabs();
  }, [logoutAllTabs]);

  useMarkerio();
  Amplify.configure(authConfig);
  useStartLocation();

  return (
    <>
      <Banner />
      <RouterProvider router={router} />
      <Portal>
        <ToastContainer position="bottom-right" theme="light" style={toastStyle} />
      </Portal>
    </>
  );
}

export default App;
