import React, { useEffect } from 'react';

import { ColumnDef, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import ReportTable from 'components/reports/ReportTable/ReportTable';
import { useGetDefaultBasicTableValues } from 'hooks/common/useGetDefaultBasicTableValues';
import { useInView } from 'react-intersection-observer';
import { getDynamicColumns } from 'utils/getDynamicColumns';

import { AdminReportsTablesProps } from './adminReportsTables.types';

const AdminReportsTables: React.FC<AdminReportsTablesProps> = ({
  platformUtilizationDetails,
  platformUtilizationDetailsFetching,
  physicianUtilizationDetails,
  physicianUtilizationDetailsCount,
  physicianUtilizationDetailsFetching,
  nurseUtilizationDetails,
  nurseUtilizationDetailsCount,
  nurseUtilizationDetailsFetching,
  medicalAssistantOverview,
  medicalAssistantOverviewCount,
  medicalAssistantOverviewFetching,
  medicalAssistantOverviewByAgent,
  medicalAssistantOverviewByAgentCount,
  medicalAssistantOverviewByAgentFetching,
  frontDeskOverview,
  frontDeskOverviewCount,
  frontDeskOverviewFetching,
  frontDeskOverviewByUser,
  frontDeskOverviewByUserCount,
  frontDeskOverviewByUserFetching,

  setLoad
}) => {
  const { ref: physicianOverviewRef, entry: physicianOverviewEntry } = useInView({
    threshold: 0.4
  });
  const { ref: npOverviewRef, entry: npOverviewEntry } = useInView({ threshold: 0.4 });
  const { ref: maOverviewRef, entry: maOverviewEntry } = useInView({ threshold: 0.4 });
  const { ref: maOverviewAgentRef, entry: maOverviewAgentEntry } = useInView({ threshold: 0.4 });
  const { ref: frontdeskRef, entry: frontdeskEntry } = useInView({ threshold: 0.4 });
  const { ref: frontdeskUserRef, entry: frontdeskUserEntry } = useInView({ threshold: 0.4 });

  // Physician requests
  const [platformUtilizationDetailsTableData, platformUtilizationDetailsColumns] =
    useGetDefaultBasicTableValues({
      isFetching: platformUtilizationDetailsFetching,
      data: platformUtilizationDetails?.['Physician requests'].physicianRequests || [],
      columns: getDynamicColumns(
        'plf-utlzn',
        true,
        platformUtilizationDetails?.['Physician requests']?.physicianRequests
      )
    });

  const platformUtilizationDetailsTable = useReactTable({
    data: platformUtilizationDetailsTableData,
    columns: platformUtilizationDetailsColumns as ColumnDef<Record<string, string | number>[]>[],
    getCoreRowModel: getCoreRowModel()
  });

  // Physician overview
  const [physicianUtilizationDetailsTableData, physicianUtilizationDetailsColumns] =
    useGetDefaultBasicTableValues({
      isFetching: physicianUtilizationDetailsFetching,
      data: physicianUtilizationDetails || [],
      columns: getDynamicColumns('phy-utlzn', false, physicianUtilizationDetails)
    });

  const physicianUtilizationDetailsTable = useReactTable({
    data: physicianUtilizationDetailsTableData,
    columns: physicianUtilizationDetailsColumns as ColumnDef<Record<string, string | number>[]>[],
    getCoreRowModel: getCoreRowModel()
  });

  // Nurse-practitioner overview
  const [nurseUtilizationDetailsTableData, nurseUtilizationDetailsColumns] =
    useGetDefaultBasicTableValues({
      isFetching: nurseUtilizationDetailsFetching,
      data: nurseUtilizationDetails || [],
      columns: getDynamicColumns('nrs-utlzn', false, nurseUtilizationDetails)
    });

  const nurseUtilizationDetailsTable = useReactTable({
    data: nurseUtilizationDetailsTableData,
    columns: nurseUtilizationDetailsColumns as ColumnDef<Record<string, string | number>[]>[],
    getCoreRowModel: getCoreRowModel()
  });

  // Medical assistant overview
  const [medicalAssistantOverviewTableData, medicalAssistantOverviewColumns] =
    useGetDefaultBasicTableValues({
      isFetching: medicalAssistantOverviewFetching,
      data: medicalAssistantOverview || [],
      columns: getDynamicColumns('ma-ovrw', false, medicalAssistantOverview)
    });

  const medicalAssistantOverviewTable = useReactTable({
    data: medicalAssistantOverviewTableData,
    columns: medicalAssistantOverviewColumns as ColumnDef<Record<string, string | number>[]>[],
    getCoreRowModel: getCoreRowModel()
  });

  // Medical assistant overview by agent
  const [medicalAssistantOverviewByAgentTableData, medicalAssistantOverviewByAgentColumns] =
    useGetDefaultBasicTableValues({
      isFetching: medicalAssistantOverviewByAgentFetching,
      data: medicalAssistantOverviewByAgent || [],
      columns: getDynamicColumns('ma-ovrw-agnt', true, medicalAssistantOverviewByAgent)
    });

  const medicalAssistantOverviewByAgentTable = useReactTable({
    data: medicalAssistantOverviewByAgentTableData,
    columns: medicalAssistantOverviewByAgentColumns as ColumnDef<
      Record<string, string | number>[]
    >[],
    getCoreRowModel: getCoreRowModel()
  });

  // Front desk overview
  const [frontDeskOverviewTableData, frontDeskOverviewColumns] = useGetDefaultBasicTableValues({
    isFetching: frontDeskOverviewFetching,
    data: frontDeskOverview || [],
    columns: getDynamicColumns('frnt-ovrw', false, frontDeskOverview)
  });

  const frontDeskOverviewTable = useReactTable({
    data: frontDeskOverviewTableData,
    columns: frontDeskOverviewColumns as ColumnDef<Record<string, string | number>[]>[],
    getCoreRowModel: getCoreRowModel()
  });

  // Front desk by user
  const [frontDeskOverviewByUserTableData, frontDeskOverviewByUserColumns] =
    useGetDefaultBasicTableValues({
      isFetching: frontDeskOverviewByUserFetching,
      data: frontDeskOverviewByUser || [],
      columns: getDynamicColumns('frnt-by-usr', false, frontDeskOverviewByUser)
    });

  const frontDeskOverviewByUserTable = useReactTable({
    data: frontDeskOverviewByUserTableData,
    columns: frontDeskOverviewByUserColumns as ColumnDef<Record<string, string | number>[]>[],
    getCoreRowModel: getCoreRowModel()
  });

  useEffect(() => {
    setLoad({
      physicianOverview: physicianOverviewEntry?.isIntersecting,
      npOverview: npOverviewEntry?.isIntersecting,
      maOverview: maOverviewEntry?.isIntersecting,
      maOverviewAgent: maOverviewAgentEntry?.isIntersecting,
      frontdesk: frontdeskEntry?.isIntersecting,
      frontdeskUser: frontdeskUserEntry?.isIntersecting
    });
  }, [
    physicianOverviewEntry?.isIntersecting,
    npOverviewEntry?.isIntersecting,
    maOverviewEntry?.isIntersecting,
    maOverviewAgentEntry?.isIntersecting,
    frontdeskEntry?.isIntersecting,
    frontdeskUserEntry?.isIntersecting
  ]);

  return (
    <React.Fragment>
      <div className="mb-10">
        <ReportTable
          title="Physician requests"
          table={platformUtilizationDetailsTable}
          totalCount={0}
          isFetching={platformUtilizationDetailsFetching}
        />
      </div>
      <div className="mb-10" ref={physicianOverviewRef}>
        <ReportTable
          title="Physician overview"
          table={physicianUtilizationDetailsTable}
          totalCount={physicianUtilizationDetailsCount || 0}
          isFetching={physicianUtilizationDetailsFetching}
          tableName="phy-utlzn"
        />
      </div>
      <div className="mb-10" ref={npOverviewRef}>
        <ReportTable
          title="Nurse-practitioner overview"
          table={nurseUtilizationDetailsTable}
          totalCount={nurseUtilizationDetailsCount || 0}
          isFetching={nurseUtilizationDetailsFetching}
          tableName="nrs-utlzn"
        />
      </div>
      <div className="mb-10" ref={maOverviewRef}>
        <ReportTable
          title="Medical assistant overview"
          table={medicalAssistantOverviewTable}
          totalCount={medicalAssistantOverviewCount || 0}
          isFetching={medicalAssistantOverviewFetching}
          tableName="ma-ovrw"
        />
      </div>
      <div className="mb-10" ref={maOverviewAgentRef}>
        <ReportTable
          title="Medical assistant overview by agent"
          table={medicalAssistantOverviewByAgentTable}
          totalCount={medicalAssistantOverviewByAgentCount || 0}
          isFetching={medicalAssistantOverviewByAgentFetching}
          tableName="ma-ovrw-agnt"
        />
      </div>
      <div className="mb-10" ref={frontdeskRef}>
        <ReportTable
          title="Front desk overview"
          table={frontDeskOverviewTable}
          totalCount={frontDeskOverviewCount || 0}
          isFetching={frontDeskOverviewFetching}
          tableName={'frnt-ovrw'}
        />
      </div>
      <div className="mb-10" ref={frontdeskUserRef}>
        <ReportTable
          title="Front desk by user"
          table={frontDeskOverviewByUserTable}
          totalCount={frontDeskOverviewByUserCount || 0}
          isFetching={frontDeskOverviewByUserFetching}
          tableName="frnt-by-usr"
        />
      </div>
    </React.Fragment>
  );
};

export default AdminReportsTables;
