import { Common } from '@thecvlb/design-system/lib/src';
import { notifySuccess } from 'components/common/Toast/Toast';
import ControlledTextArea from 'components/forms/controlled/ControlledTextArea';
import { useForm } from 'react-hook-form';
import { useRefundInvoiceMutation } from 'store/patients/patientsSlice';
import { validation } from 'utils/helpers';

import { RefundFormProps } from './refundForm.types';

const RefundForm: React.FC<RefundFormProps> = ({
  invoiceId,
  setIsRefunding,
  setRefundResponseData
}) => {
  const [refundInvoice, { isLoading }] = useRefundInvoiceMutation();

  const { control, formState, handleSubmit } = useForm<{ note: string }>();

  const handleRefundInvoice = (data: { note: string }) => {
    const body: { note: string } = {
      note: data.note
    };
    refundInvoice({ id: invoiceId, body })
      .unwrap()
      .then((response) => {
        notifySuccess(response?.message);
        setIsRefunding(false);
        setRefundResponseData(response?.data);
      });
  };

  return (
    <form onSubmit={handleSubmit(handleRefundInvoice)} className="my-4 rounded-md bg-gray-50 p-4">
      <h2 className="mb-4 font-bold">Refund invoice</h2>
      <div data-testid="refund-info-alert" className="my-4 flex items-center rounded-lg border p-4">
        <Common.Icon name="info" className="mr-2 shrink-0 text-gray-400" />
        <p className="text-sm font-medium text-gray-600">
          Refunds will only be applied for the amount paid by the patient’s original payment method.
          Any credits used on this invoice will not be refunded.
        </p>
      </div>
      <ControlledTextArea
        control={control}
        name="note"
        label="Notes:"
        placeholder="Please enter refund notes..."
        labelDirection="col"
        rows={4}
        errors={formState.errors.note}
        rules={validation('Notes')}
      />
      <div className="mt-4 flex gap-2">
        <Common.Button
          disabled={isLoading}
          isLoading={isLoading}
          color="blue"
          size="sm"
          className="justify-center"
        >
          Submit refund
        </Common.Button>
        <Common.Button
          disabled={isLoading}
          type="button"
          onClick={() => setIsRefunding(false)}
          color="white-alt"
          size="sm"
          className="justify-center"
        >
          Cancel
        </Common.Button>
      </div>
    </form>
  );
};

export default RefundForm;
