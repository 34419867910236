import { INSURANCE_RELATIONSHIP_OPTIONS } from 'constants/order';

import { useEffect } from 'react';

import { Common } from '@thecvlb/design-system';
import classNames from 'classnames';
import Loader from 'components/common/Loader';
import { notifyError, notifySuccess } from 'components/common/Toast/Toast';
import ControlledSelect from 'components/forms/controlled/ControlledSelect';
import InputField from 'components/forms/controlled/InputField';
import { InsuranceLabels } from 'enums/orderLabels';
import { useForm } from 'react-hook-form';
import { useToggle } from 'react-use';
import { useUpdateOrderMutation } from 'store/orders/ordersSlice';

import { getItems, getSubmitOrderData } from './insuranceInfo.settings';
import { InsuranceInfoProps, OrderFormStateProps } from './insuranceInfo.types';

const InsuranceInfoAlt: React.FC<InsuranceInfoProps> = ({ order, insurance }) => {
  const [isEditing, setIsEditing] = useToggle(false);
  const [updateOrder, { isLoading: isLoadingEdit }] = useUpdateOrderMutation();

  const { handleSubmit, control, reset } = useForm<OrderFormStateProps>({
    reValidateMode: 'onChange',
    criteriaMode: 'all',
    mode: 'onChange'
  });

  const labelClasses = classNames('text-sm mr-5 flex-none w-24 mb-1', {
    'text-gray-500': !isEditing
  });
  const itemClasses = classNames({
    flex: !isEditing,
    'mb-4': isEditing,
    'mb-2': !isEditing
  });

  useEffect(() => {
    reset({
      ...order.insurance,
      relationship: INSURANCE_RELATIONSHIP_OPTIONS.find(
        (c) => c.value === order.insurance?.relationship
      )
    });
  }, [reset, order]);

  const onSubmit = async (formData: OrderFormStateProps) => {
    const hasRequiredData = (o: OrderFormStateProps) => {
      if (!o.memberId) return false;
      if (!o.rxBin) return false;
      if (!o.relationship) return false;
      return true;
    };

    if (hasRequiredData(formData)) {
      const submitOrder = getSubmitOrderData(formData);

      if (submitOrder) {
        updateOrder({ orderId: order.id, order: submitOrder })
          .unwrap()
          .then((data) => {
            notifySuccess(data.message || 'Successfully updated order');
            setIsEditing(false);
          })
          .catch((error) => {
            notifyError(error.data?.message);
          });
      }
    } else {
      notifyError('Missing required data');
    }
    return;
  };

  const EditButtons = () => (
    <div className="mb-4">
      {isEditing && (
        <div className="flex">
          <Common.Button
            color="white-alt"
            size="sm"
            className="mr-2"
            onClick={() => setIsEditing(false)}
            preIconClassName="shrink-0"
          >
            <span className="whitespace-normal text-left font-semibold">Cancel</span>
          </Common.Button>
          <Common.Button
            disabled={isLoadingEdit}
            color="blue"
            size="sm"
            className=""
            preIconClassName="shrink-0"
          >
            <span className="whitespace-normal text-left font-semibold">Save</span>
          </Common.Button>
        </div>
      )}
      {!isEditing && (
        <div>
          <Common.Button
            color="white-alt"
            size="sm"
            className=""
            onClick={() => setIsEditing(true)}
            preIconClassName="shrink-0"
          >
            <span className="whitespace-normal text-left font-semibold">Update</span>
          </Common.Button>
        </div>
      )}
    </div>
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Loader isVisible={isLoadingEdit} />
      <div className="mb-4">
        {getItems(insurance).map((el) => {
          if (!isEditing) {
            return (
              <div className={itemClasses} key={el.label}>
                <p className={labelClasses}>{el.label}</p>
                <p className={classNames('text-base text-gray-700', { underline: el.isUnderline })}>
                  {el.value}
                </p>
              </div>
            );
          }

          if (el.label === InsuranceLabels.MemberId) {
            return (
              <div className={itemClasses} key={el.label}>
                <p className={labelClasses}>{el.label}</p>
                <InputField control={control} name={el.property} className="w-full" />
              </div>
            );
          } else if (el.label === InsuranceLabels.RxGroup) {
            return (
              <div className={itemClasses} key={el.label}>
                <p className={labelClasses}>{el.label}</p>
                <InputField control={control} name={el.property} className="w-full" />
              </div>
            );
          } else if (el.label === InsuranceLabels.RxBIN) {
            return (
              <div className={itemClasses} key={el.label}>
                <p className={labelClasses}>{el.label}</p>
                <InputField control={control} name={el.property} className="w-full" />
              </div>
            );
          } else if (el.label === InsuranceLabels.PCN) {
            return (
              <div className={itemClasses} key={el.label}>
                <p className={labelClasses}>{el.label}</p>
                <InputField control={control} name={el.property} className="w-full" />
              </div>
            );
          } else if (el.label === InsuranceLabels.Relationship) {
            return (
              <div className={itemClasses} key={el.label}>
                <p className={labelClasses}>{el.label}</p>
                <ControlledSelect
                  control={control}
                  options={INSURANCE_RELATIONSHIP_OPTIONS}
                  name={el.property}
                  placeholder="Select..."
                  className="w-full"
                />
              </div>
            );
          } else {
            return (
              <div className={itemClasses} key={el.label}>
                <p className={labelClasses}>{el.label}</p>
                <p className={classNames('text-base text-gray-700', { underline: el.isUnderline })}>
                  {el.value}
                </p>
              </div>
            );
          }
        })}
      </div>
      <div className="">
        <EditButtons />
      </div>
    </form>
  );
};

export default InsuranceInfoAlt;
