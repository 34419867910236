export enum CancelAppointmentReason {
  NoLongerNeeded = 'NoLongerNeeded',
  NoLongerAvailable = 'NoLongerAvailable',
  NeedsInPersonCare = 'NeedsInPersonCare',
  ProviderUnavailable = 'ProviderUnavailable',
  OnboardingIncomplete = 'OnboardingIncomplete'
}

export enum AppointmentListType {
  MissedAppointment = 1,
  UpcomingAppointment = 2,
  CompletedAppointments = 3,
  CanceledAppointments = 4,
  PastAppointments = 5, // missed by patient, missed by staff, completed, canceled
  Default = 0 // Past and Upcoming appointments
}
