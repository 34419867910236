const PROFILE_IMAGE_EXTENSIONS = ['.jpeg', '.png', '.gif', '.svg', '.heic', '.heif'];
export const ACCEPT_PROFILE_IMG_EXTENSIONS = PROFILE_IMAGE_EXTENSIONS.join(',');

export const DOC_EXTENSIONS = ['.pdf', '.doc', '.docx', '.xls', '.xlsx', '.ppt', '.pptx'];
export const VIDEO_EXTENSIONS = ['.mov', '.qt', '.mp4', '.m4v', '.webm'];
export const IMAGE_EXTENSIONS = ['.jpg', '.jpeg', '.png', '.gif', '.webp', '.heic', '.heif'];
export const ACCEPT_CHAT_EXTENSIONS = [
  ...DOC_EXTENSIONS,
  ...IMAGE_EXTENSIONS,
  ...VIDEO_EXTENSIONS
].join(',');
