import { isValidPhoneNumber } from 'react-phone-number-input';
import { validateBirth } from 'utils/datesAndTime';
import { ADDRESS_REGEXP, EMAIL_REGEXP, NAME_REGEXP } from 'utils/regExp';

const isValidPhone = (val: string) => {
  return (val && isValidPhoneNumber(val)) || 'Invalid phone number';
};

export const phoneNumberValidationRules = {
  required: {
    value: true,
    message: 'Phone number is required'
  },

  validate: {
    isValidPhone
  }
};

export const textFieldValidationRules = (fieldName: string) => {
  return {
    required: {
      value: true,
      message: `${fieldName} is required`
    },
    pattern: {
      value: NAME_REGEXP,
      message: `Invalid ${fieldName.toLowerCase()}`
    }
  };
};

export const addressValidationRules = {
  required: {
    value: true,
    message: 'Address is required'
  },
  pattern: {
    value: ADDRESS_REGEXP,
    message: 'Invalid address'
  }
};

export const emailValidationRules = {
  required: {
    value: true,
    message: 'Email is required'
  },
  pattern: {
    value: EMAIL_REGEXP,
    message: 'Invalid email'
  }
};

export const dateOfBirthValidationRules = {
  required: {
    value: true,
    message: 'Date of birth is required'
  },
  validate: {
    isValidBirth: (value: string) => validateBirth(value, 'Invalid date')
  }
};
