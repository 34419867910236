import { Common } from '@thecvlb/design-system';
import classNames from 'classnames';

import { Props } from './advantageItem.types';

const AdvantageItem: React.FC<Props> = ({ advantage, showCustomIcon = false, className = '' }) => {
  const text = typeof advantage === 'string' ? advantage : advantage.question;
  const icon = typeof advantage === 'string' || advantage.value ? 'check' : 'close';
  const hasCustomIcons = typeof advantage !== 'string' && 'icon' in advantage && showCustomIcon;

  return (
    <div className={classNames('flex items-center gap-2', className)}>
      {hasCustomIcons ? (
        <span className="w-5 flex-none text-center">{advantage.icon}</span>
      ) : (
        <Common.Icon className="flex-none text-secondary" name={icon} />
      )}
      <span
        className={classNames('text-left text-gray-700', {
          'font-bold': typeof advantage !== 'string' && advantage.bold
        })}
        data-testid="membership_advantage"
      >
        {text}
      </span>
    </div>
  );
};

export default AdvantageItem;
