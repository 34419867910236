import { StaffCost, StaffCostsQueryParams } from './staffCosts.types';
import { apiSlice } from '../api/apiSlice';

export const staffCostsApiSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getStaffCosts: build.query<
      { staffCosts: StaffCost[]; totalCount: number },
      { params: StaffCostsQueryParams }
    >({
      query: ({ params }) => ({ url: `/staff-costs-configurations`, params }),
      transformResponse: (response: { data: StaffCost[]; info: { totalCount: number } }) => ({
        staffCosts: response.data,
        totalCount: response.info.totalCount
      }),
      providesTags: (result) =>
        result
          ? [
              'StaffCosts',
              ...result.staffCosts.map(({ _id }) => ({ type: 'StaffCosts' as const, id: _id }))
            ]
          : ['StaffCosts']
    }),
    getStaffCostsById: build.query<StaffCost, { id: string }>({
      query: ({ id }) => `/staff-costs-configurations/${id}`,
      transformResponse: (response: { data: StaffCost }) => response.data
    }),
    createStaffCost: build.mutation({
      query: ({ body }) => ({
        url: `/staff-costs-configurations`,
        method: 'POST',
        body
      }),
      transformResponse: (response: { data: StaffCost; message: string }) => response,
      invalidatesTags: ['StaffCosts']
    }),
    updateStaffCost: build.mutation({
      query: ({ id, body }) => ({
        url: `/staff-costs-configurations/${id}`,
        method: 'PUT',
        body
      }),
      transformResponse: (response: { data: StaffCost; message: string }) => response,
      invalidatesTags: (_result, _error, arg) => [{ type: 'StaffCosts', id: arg._id }]
    }),
    deleteStaffCost: build.mutation({
      query: ({ id }) => ({
        url: `/staff-costs-configurations/${id}`,
        method: 'DELETE'
      }),
      transformResponse: (response: { message: string }) => response,
      invalidatesTags: ['StaffCosts']
    })
  })
});

export const {
  useLazyGetStaffCostsQuery,
  useLazyGetStaffCostsByIdQuery,
  useCreateStaffCostMutation,
  useUpdateStaffCostMutation,
  useDeleteStaffCostMutation
} = staffCostsApiSlice;
