import { STATUS_OPTIONS } from 'constants/status';

import { useCallback, useEffect } from 'react';

import { Common } from '@thecvlb/design-system';
import Loader from 'components/common/Loader';
import { notifySuccess } from 'components/common/Toast/Toast';
import AutocompleteMultiSelect from 'components/forms/controlled/AutocompleteMultiSelect';
import ControlledSelect from 'components/forms/controlled/ControlledSelect';
import InputField from 'components/forms/controlled/InputField';
import capitalize from 'lodash/capitalize';
import isEmpty from 'lodash/isEmpty';
import { Controller, FormProvider, useController, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useGetAppointmentCategoriesQuery } from 'store/appointmentCategories/appointmentCategoriesSlice';
import {
  useCreateAppointmentTypeMutation,
  useDeleteAppointmentTypeMutation,
  useEditAppointmentTypeMutation,
  useGetAppointmentTypeByIdQuery
} from 'store/appointmentTypes/appointmentTypesSlice';
import { closeModal } from 'store/modal/modalSlice';
import { useLazyGetTriggersQuery } from 'store/triggers/triggersSlice';
import { validation } from 'utils/helpers';

import { handleRequired } from './appointmentType.settings';
import AppointmentTypeLengths from './AppointmentTypeLengths';
import { AppointmentFormDataProps, AppointmentTypeProps } from './AppointmentTypes.types';
import PopupFooter from '../components/PopupFooter';
import PopupHeader from '../components/PopupHeader';

const AppointmentType: React.FC<AppointmentTypeProps> = ({ id = '' }) => {
  const dispatch = useDispatch();
  const [getTriggers, { data: triggersData, isFetching: isFetchingTriggers }] =
    useLazyGetTriggersQuery();
  const {
    data: singleAppointmentType,
    isLoading,
    isFetching,
    isSuccess
  } = useGetAppointmentTypeByIdQuery({ id });
  const [createAppointmentType, { isLoading: loadingCreate }] = useCreateAppointmentTypeMutation();
  const [editAppointmentType, { isLoading: loadingEdit }] = useEditAppointmentTypeMutation();
  const [deleteAppointmentType, { isLoading: loadingDelete }] = useDeleteAppointmentTypeMutation();
  const { data: listCategory } = useGetAppointmentCategoriesQuery();
  const isDisabled = isLoading || loadingCreate || loadingEdit || loadingDelete;

  const methods = useForm<AppointmentFormDataProps>({
    reValidateMode: 'onChange',
    criteriaMode: 'all',
    mode: 'onChange'
  });
  const detCategory = () => {
    return listCategory?.length
      ? listCategory?.map((item) => ({ id: item._id, label: item?.name, value: item?.name }))
      : [];
  };

  const { reset, handleSubmit, control, formState } = methods;

  useController({
    control,
    defaultValue: [],
    name: 'lengthByPlan',
    rules: { required: true }
  });

  const normalizeTriggersData = useCallback(
    () => triggersData?.triggers?.map((trigger) => ({ value: trigger._id, label: trigger.name })),
    [triggersData]
  );

  const onSuccess = (message: string) => {
    notifySuccess(message);
    dispatch(closeModal());
  };

  const onSubmit = (formData: AppointmentFormDataProps) => {
    const body = {
      category: formData.category?.label,
      displayName: formData.displayName,
      lengthByPlan: formData.lengthByPlan,
      status: formData.status?.value,
      triggers: formData.triggers.map((trigger) => trigger.value),
      uploadRequired: formData.uploadRequired
    };

    if (id) {
      editAppointmentType({ appointmentTypeId: id, body })
        .unwrap()
        .then((data) => {
          onSuccess(data.message);
          dispatch(closeModal());
        });
    } else {
      createAppointmentType(body)
        .unwrap()
        .then((data) => {
          onSuccess(data.message);
          dispatch(closeModal());
        });
    }
  };
  const onDeleteAppointmentType: React.MouseEventHandler = (event) => {
    event.stopPropagation();
    if (id) {
      deleteAppointmentType(id)
        .unwrap()
        .then((data) => onSuccess(data.message))
        .finally(() => dispatch(closeModal()));
    }
  };

  useEffect(() => {
    if (isSuccess)
      reset({
        category: {
          label: singleAppointmentType?.category,
          value: singleAppointmentType?.category
        },
        displayName: singleAppointmentType?.displayName,
        lengthByPlan: singleAppointmentType?.lengthByPlan || [],
        status: {
          label: capitalize(singleAppointmentType?.status),
          value: singleAppointmentType?.status
        },
        triggers: (singleAppointmentType?.triggers || []).map((trigger) => ({
          label: trigger.name,
          value: trigger._id
        })),
        uploadRequired: singleAppointmentType?.uploadRequired
      });
  }, [isSuccess, reset, singleAppointmentType]);

  return (
    <div data-testid="appo_types_popup" className="p-8">
      <Loader isVisible={isLoading || isFetching || isFetchingTriggers} />
      <PopupHeader title="Appointment type" />
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)} className="mt-5 flex w-full flex-col gap-4">
          <InputField
            dataTestId="display_name_field"
            name="displayName"
            label="Display name"
            control={control}
            labelDirection="row"
            placeholder="Enter display name..."
            type="text"
            errors={formState.errors.displayName}
            helper={formState.errors.displayName?.message?.toString()}
            rules={validation('Display name')}
          />
          <ControlledSelect
            dataTestId="category"
            control={control}
            name="category"
            options={detCategory()}
            defaultValue=""
            labelDirection="row"
            placeholder="Select category..."
            label="Category"
            rules={{
              validate: {
                required: (value) => handleRequired(value, 'Category is required')
              }
            }}
          />
          <AutocompleteMultiSelect
            defaultValue=""
            control={control}
            size="sm"
            label="Automations"
            name="triggers"
            placeholder="Select display triggers..."
            labelDirection="row"
            options={normalizeTriggersData()}
            getOptionsFunc={(value) => {
              return value && getTriggers({ params: { searchKey: value ?? '', status: 'active' } });
            }}
          />

          <div className="border-t border-gray-200" />
          <AppointmentTypeLengths />
          <div className="border-t border-gray-200" />

          <div className="flex gap-1">
            <label className={'min-w-[120px] text-sm font-semibold text-gray-700'}>
              Upload required
            </label>
            <Controller
              control={control}
              name="uploadRequired"
              render={({ field }) => (
                <Common.Checkbox
                  dataTestId="upload_required_checkbox"
                  {...field}
                  value={field.value as unknown as string}
                  checked={field.value || false}
                  onChange={field.onChange}
                  size="sm"
                />
              )}
            />
          </div>
          <ControlledSelect
            dataTestId="status_field"
            control={control}
            labelDirection="row"
            options={STATUS_OPTIONS}
            placeholder="Select status..."
            label="Status"
            name="status"
            rules={{
              validate: {
                required: (value) => handleRequired(value, 'Status is required')
              }
            }}
          />
          <div className="mt-4">
            <PopupFooter
              onRemove={onDeleteAppointmentType}
              hiddenDeleteButton={!id}
              disabled={isDisabled || isEmpty(formState.dirtyFields)}
              disabledDeleteButton={isDisabled}
            />
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default AppointmentType;
