import { StaffFeedbackProps } from 'store/staffFeedback/staffFeedback.types';

export const getPrevNextStaffFeedbackId = (
  currentRoomId: string,
  feedbackList: StaffFeedbackProps[]
) => {
  const currentFeedbackIndex = feedbackList.findIndex((item) => item.roomName === currentRoomId);
  const prevFeedbackId =
    currentFeedbackIndex >= 1 ? feedbackList[currentFeedbackIndex - 1].roomName : null;
  const nextFeedbackId =
    currentFeedbackIndex >= 0 && feedbackList[currentFeedbackIndex + 1]
      ? feedbackList[currentFeedbackIndex + 1].roomName
      : null;
  return { prevFeedbackId, nextFeedbackId };
};
