import { nanoid } from '@reduxjs/toolkit';
import Loader from 'components/common/Loader';
import dayjs from 'dayjs';
import { DateFormat } from 'enums/dateFormats';
import { ExceptionProps } from 'store/availability/availability.types';
import { useUpdateOverrideMutation } from 'store/availability/availabilitySlice';

import ExceptionItem from './ExceptionItem';
import { ExceptionsListProps } from './exceptionsList.types';

const ExceptionsList: React.FC<ExceptionsListProps> = ({
  exceptions,
  loading,
  onRemove,
  timezone,
  isPhysicianTab,
  userId
}) => {
  const [updateOverride, { isLoading }] = useUpdateOverrideMutation();
  const arrayOfExceptions = Object.entries(exceptions);
  const defaultExceptions: ExceptionProps[] = [
    { startTime: '', endTime: '', appointments: true, queue: false }
  ];

  const exceptionsDates = (exceptionsList: { startTime: string; endTime: string }[]) => {
    const datesArr: string[] = [];
    (exceptionsList || []).forEach(({ startTime, endTime }) => {
      datesArr.push(dayjs(startTime).tz(timezone).format(DateFormat.MMMM_D));
      datesArr.push(dayjs(endTime).tz(timezone).format(DateFormat.MMMM_D));
    });

    return [...new Set(datesArr)];
  };

  const onChangeExceptionTypeCheckboxes = (
    date: string,
    type: string,
    value: boolean,
    currentException: ExceptionProps
  ) => {
    const updateExceptionsDate = exceptions[date].map((exception) =>
      exception.startTime === currentException.startTime &&
      exception.endTime === currentException.endTime
        ? { ...exception, [type]: value }
        : exception
    );

    updateOverride({
      userId,
      body: { dates: updateExceptionsDate }
    });
  };

  return (
    <>
      <Loader isVisible={loading || isLoading} />
      {!!arrayOfExceptions.length &&
        arrayOfExceptions.map(([date, list], index) => (
          <div data-testid="exception_item" key={date + index} className="border-b py-5">
            <p data-testid="date" className="text-base">
              {list?.[0]?.startTime
                ? exceptionsDates(list).map((listDate, listIndex) =>
                    listIndex === exceptionsDates(list).length - 1 ? listDate : listDate + '-'
                  )
                : dayjs(date).format(DateFormat.MMMM_D)}
            </p>
            {(list.length ? list : defaultExceptions).map((exception) => {
              const isUnavailable = !exception.startTime && !exception.endTime;
              const onRemoveData = isUnavailable ? date : exception;
              return (
                <ExceptionItem
                  handleClick={() => onRemove(onRemoveData)}
                  onChangeExceptionTypeCheckboxes={onChangeExceptionTypeCheckboxes}
                  date={date}
                  exception={exception}
                  loading={loading}
                  timezone={timezone}
                  isPhysicianTab={isPhysicianTab}
                  key={nanoid()}
                />
              );
            })}
          </div>
        ))}
    </>
  );
};

export default ExceptionsList;
