import { VideoCallIssueType, VideoCallProblemType } from 'enums/videoCall';

export const getIssueTypeText = (value?: VideoCallIssueType) => {
  return value === VideoCallIssueType.PatientIssue
    ? 'Patient issue'
    : value === VideoCallIssueType.ProviderIssue
      ? 'Provider issue'
      : value === VideoCallIssueType.Both
        ? 'Both'
        : '--';
};

export const getIssueDescriptionsText = (value?: VideoCallProblemType[]) => {
  return value?.length
    ? value.map((issue) =>
        issue === VideoCallProblemType.AudioProblem ? (
          <span key={VideoCallProblemType.AudioProblem}>Problem with audio</span>
        ) : issue === VideoCallProblemType.VideoProblem ? (
          <span key={VideoCallProblemType.VideoProblem}>Problem with video</span>
        ) : issue === VideoCallProblemType.FrozeComputer ? (
          <span key={VideoCallProblemType.FrozeComputer}>My computer froze</span>
        ) : (
          issue
        )
      )
    : '--';
};
