import { AgingAlertsProps, AgingAlertsQueryParams } from 'store/agingAlerts/agingAlerts.types';

import { apiSlice } from '../api/apiSlice';

export const agingAlertsApi = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getAgingAlerts: build.query<
      { agingAlerts: AgingAlertsProps[]; totalCount: number },
      { params: AgingAlertsQueryParams }
    >({
      query: ({ params }) => ({ url: '/aging-alerts', params: params }),
      transformResponse: (response: {
        data: AgingAlertsProps[];
        info: { totalCount: number };
      }) => ({
        agingAlerts: response.data,
        totalCount: response.info.totalCount
      }),
      providesTags: (result) =>
        result
          ? [
              'AgingAlert',
              ...result.agingAlerts.map(({ _id }) => ({ type: 'AgingAlert' as const, id: _id }))
            ]
          : ['AgingAlert']
    }),

    getAgingAlertById: build.query<AgingAlertsProps, { id: string }>({
      query: ({ id }) => ({
        url: `/aging-alerts/${id}`
      }),
      transformResponse: (response: { data: AgingAlertsProps }) => response.data
    }),

    createAgingAlert: build.mutation({
      query: ({ body }) => ({
        url: `aging-alerts`,
        method: 'POST',
        body
      }),
      transformResponse: (response: { data: AgingAlertsProps; message: string }) => response,
      invalidatesTags: ['AgingAlert']
    }),

    editAgingAlert: build.mutation({
      query: ({ id, body }) => ({
        url: `aging-alerts/${id}`,
        method: 'PUT',
        body
      }),
      transformResponse: (response: { data: AgingAlertsProps; message: string }) => response,
      invalidatesTags: (_result, _error, arg) => [{ type: 'AgingAlert', id: arg.id }]
    }),

    deleteAgingAlert: build.mutation<{ message: string }, { id: string }>({
      query: ({ id }) => ({ url: `/aging-alerts/${id}`, method: 'DELETE' }),
      transformResponse: (response: { message: string }) => response,
      invalidatesTags: ['AgingAlert']
    })
  })
});

export const {
  useGetAgingAlertsQuery,
  useCreateAgingAlertMutation,
  useGetAgingAlertByIdQuery,
  useEditAgingAlertMutation,
  useDeleteAgingAlertMutation
} = agingAlertsApi;
