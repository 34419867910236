export const getBodyImageText = (
  isNotSubmitted: boolean,
  isVerified: boolean,
  isInvalid?: boolean
) => {
  if (isInvalid) return 'Invalid';
  if (isNotSubmitted) return 'Not submitted';
  if (isVerified) return 'Accepted';
  return 'Unverified';
};
