import { AssignValueType } from 'store/tasks/task.types';

import { RadioOption, RadioType } from './assignTasks.types';

export const radioOptions: RadioOption[] = [
  {
    label: 'Release tasks to list',
    value: AssignValueType.releaseToList,
    type: RadioType.standard,
    isHighlighted: true
  },
  {
    label: 'Myself',
    value: AssignValueType.takeOver,
    type: RadioType.standard
  },
  {
    label: 'Role',
    value: AssignValueType.role,
    type: RadioType.rich
  },
  {
    label: 'Team',
    value: AssignValueType.team,
    type: RadioType.rich
  },
  {
    label: 'Staff member',
    value: AssignValueType.staff,
    type: RadioType.rich
  }
];
