import { useEffect } from 'react';

import Loader from 'components/common/Loader';
import { notifySuccess } from 'components/common/Toast/Toast';
import { useAppDispatch } from 'hooks/redux';
import { closeModal } from 'store/modal/modalSlice';
import { useGetPatientQuery, useReactivatePatientMutation } from 'store/patients/patientsSlice';

const ReactivatePatient: React.FC<{ patientId: string }> = ({ patientId }) => {
  const dispatch = useAppDispatch();
  const { patient } = useGetPatientQuery(
    { id: patientId },
    {
      selectFromResult: ({ data }) => ({
        patient: data
      })
    }
  );
  const [reactivatePatient, { isLoading, isSuccess, data }] = useReactivatePatientMutation();

  const handleReactivate = () => {
    reactivatePatient({ patientId });
  };

  const handleClose = () => {
    dispatch(closeModal());
  };

  useEffect(() => {
    if (isSuccess && data?.message) {
      dispatch(closeModal());
      notifySuccess(data.message);
    }
  }, [dispatch, isSuccess, data]);

  return (
    <div className="p-6">
      <Loader isVisible={isLoading} />
      <h2 className="mb-2 text-xl font-bold">
        Reactivate {patient?.firstName + ' ' + patient?.lastName}?
      </h2>
      <p className="mb-6 text-base text-gray-700">
        When you reactivate a patient, they will gain access to the platform with the same
        permissions they had before they were deactivated.
      </p>
      <div className="mt-6 flex gap-2">
        <button
          className="w-full rounded-lg bg-gray-100 py-[7.5px] text-sm font-bold text-gray-700 disabled:bg-gray-200 disabled:text-gray"
          onClick={handleClose}
        >
          Close window
        </button>
        <button
          className="w-full rounded-lg bg-primary py-[7.5px] text-sm font-bold text-white disabled:bg-gray-200 disabled:text-gray"
          onClick={handleReactivate}
          disabled={isLoading}
        >
          Reactivate account
        </button>
      </div>
    </div>
  );
};

export default ReactivatePatient;
