import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from 'store';

import { CustomSorting, initialStateBasicTables } from './basicTableSlice.types';

export const basicTableSlice = createSlice({
  name: 'basicTables',
  initialState: initialStateBasicTables,
  reducers: {
    setNewBasicTablesSorting: (
      state,
      action: PayloadAction<{ key: string } & Partial<CustomSorting>>
    ) => {
      return {
        ...state,
        [action.payload.key]: { ...(state[action.payload.key] || {}), ...action.payload }
      };
    }
  }
});

export const selectBasicTables = (state: RootState) => state.basicTables;

export const { setNewBasicTablesSorting } = basicTableSlice.actions;
