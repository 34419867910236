import { OptionProps } from '@thecvlb/design-system/lib/src/common';
import ControlledSelect from 'components/forms/controlled/ControlledSelect';
import { useFormContext } from 'react-hook-form';
import { validation } from 'utils/helpers';

const CurrentRoleSelect: React.FC<{
  roleOptions: OptionProps[];
  isOptional?: boolean;
  shouldShowDivider?: boolean;
}> = ({ roleOptions, isOptional = false, shouldShowDivider = true }) => {
  const { control } = useFormContext();

  return (
    <>
      <div className="col-span-2 w-1/2 pr-5">
        <ControlledSelect
          control={control}
          labelDirection="row"
          options={roleOptions}
          placeholder="Select role..."
          label="Role"
          name="role"
          rules={validation('Role', null, null, isOptional)}
        />
      </div>

      {shouldShowDivider && <div className="col-span-2 h-px w-full bg-gray-200" />}
    </>
  );
};

export default CurrentRoleSelect;
