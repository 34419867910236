import { useEffect, useRef, useState } from 'react';

import { nanoid } from '@reduxjs/toolkit';
import { Common } from '@thecvlb/design-system';
import classNames from 'classnames';
import Card from 'components/common/Card';
import AvailabilityDropdown from 'components/crossSell/AvailabilityDropdown';
import { useFormContext } from 'react-hook-form';

import { SelectDoctorCardProps } from './selectDoctorCard.types';

const SelectDoctorCard: React.FC<SelectDoctorCardProps> = ({
  doctor: {
    experience,
    specialtyAreas,
    displayName,
    aboutDoctor,
    shortBio,
    slots,
    userId,
    profileImage
  }
}) => {
  const { setValue, watch } = useFormContext();
  const [showMore, setShowMore] = useState(false);
  const [slot, setSlot] = useState(slots?.length && slots[0]);
  const aboutDoctorRef = useRef<HTMLParagraphElement>(null);
  const isSelected = userId === watch('doctorId');

  const isEllipsisActive = (offsetHeight: number, scrollHeight: number) => {
    return offsetHeight < scrollHeight;
  };

  const isOverflowText = isEllipsisActive(
    aboutDoctorRef?.current?.offsetHeight || 0,
    aboutDoctorRef?.current?.scrollHeight || 0
  );

  const cardClasses = classNames('h-full flex flex-col px-5 py-6 w-1/2', {
    'bg-secondary-50 border-2 border-secondary': isSelected
  });

  const handleClick = (id: string) => {
    setValue('doctorId', id);
    setValue('appointmentTime', slot);
  };

  useEffect(() => {
    // Set appointment time on slot change with the dropdown
    if (isSelected) {
      setValue('appointmentTime', slot);
    }
  }, [slot]);

  useEffect(() => {
    setSlot(slots?.[0]);
  }, [slots]);

  return (
    <Card dataTestId="doctor_card" className={cardClasses} onClick={() => handleClick(userId)}>
      <Common.ProfileImage src={profileImage} size="xl" />
      <p data-testid="doc_name" className="py-4 text-2xl font-bold text-primary">
        {displayName}
      </p>
      <div className="mb-4 flex flex-wrap gap-4">
        {specialtyAreas?.length &&
          specialtyAreas.map((item) => (
            <div className="flex items-center rounded-lg bg-secondary/10 p-2" key={nanoid()}>
              <img src={item.icon} alt={item.name} className="mr-3 mt-1 size-5" />
              <span className="text-sm text-gray-700">{item.name}</span>
            </div>
          ))}
      </div>
      <div className="flex flex-col">
        {experience?.map((exp) => (
          <div key={nanoid()} className="mb-4 flex flex-1 items-center">
            <Common.Icon name="graduation" className="mr-2 size-5 text-primary" />
            <p className="flex-1 text-sm">{exp}</p>
          </div>
        ))}
      </div>
      <div ref={aboutDoctorRef} className={classNames({ 'line-clamp-7': !showMore })}>
        {(shortBio || aboutDoctor)?.split('\n')?.map((paragraph) => (
          <span className="text-sm leading-relaxed" key={nanoid()}>
            {(
              <>
                {paragraph}
                <br />
              </>
            ) || (
              <>
                <br />
                <br />
              </>
            )}
          </span>
        ))}
      </div>
      {isOverflowText && !showMore && (
        <Common.Button
          type="button"
          onClick={() => setShowMore(!showMore)}
          style="text-only"
          size="md"
          color="white"
          className="underline"
        >
          {'Read more…'}
        </Common.Button>
      )}
      <div className="mt-8 flex flex-col items-center justify-center">
        <AvailabilityDropdown
          slots={slots}
          slot={slot as { startTime: string; endTime: string }}
          setSlot={
            setSlot as React.Dispatch<React.SetStateAction<{ startTime: string; endTime: string }>>
          }
        />

        <Common.Button
          type="button"
          style="pill"
          color="blue"
          size="sm"
          postIcon={isSelected ? 'check' : 'arrow-right'}
        >
          Select
        </Common.Button>
      </div>
    </Card>
  );
};

export default SelectDoctorCard;
