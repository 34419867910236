const sequenceNumberNames = [
  'zeroth',
  'first',
  'second',
  'third',
  'fourth',
  'fifth',
  'sixth',
  'seventh',
  'eighth',
  'ninth',
  'tenth',
  'eleventh',
  'twelfth',
  'thirteenth',
  'fourteenth',
  'fifteenth',
  'sixteenth',
  'seventeenth',
  'eighteenth',
  'nineteenth'
];
const decaSequenceNumberNames = [
  'twent',
  'thirt',
  'fort',
  'fift',
  'sixt',
  'sevent',
  'eight',
  'ninet'
];

export const stringifyNumber = (number: number) => {
  if (number < 20) return sequenceNumberNames[number];
  if (number % 10 === 0) return decaSequenceNumberNames[Math.floor(number / 10) - 2] + 'ieth';
  return (
    decaSequenceNumberNames[Math.floor(number / 10) - 2] + 'y-' + sequenceNumberNames[number % 10]
  );
};
