import classNames from 'classnames';
import Card from 'components/common/Card';

import { AmountCardProps } from './amountCard.types';

const AmountCard: React.FC<AmountCardProps> = ({
  isSelected,
  onClick,
  title,
  subtitle,
  disabled
}) => {
  const cardClasses = classNames(
    'cursor-pointer transition-all bg-white w-full !shadow-none rounded-xl border px-4 py-3 border-2',
    {
      'font-bold bg-blue-50 border-blue': isSelected,
      'opacity-50': disabled
    }
  );

  const titleClasses = classNames(
    'text-base text-gray-700',
    isSelected ? 'font-bold' : 'font-semibold'
  );

  return (
    <Card className={cardClasses} onClick={() => onClick(title)}>
      <h3 className={titleClasses}>{title}</h3>
      {subtitle && <p className="mt-0.5 text-sm font-medium text-gray-500">{subtitle}</p>}
    </Card>
  );
};

export default AmountCard;
