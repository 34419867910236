import { Relationship } from 'enums/relationship';

export const INSURANCE_RELATIONSHIP_OPTIONS: { label: string; value: Relationship }[] = [
  { label: 'Self', value: Relationship.Self },
  { label: 'Spouse', value: Relationship.Spouse },
  { label: 'Child', value: Relationship.Child },
  { label: 'Other', value: Relationship.Other },
  { label: 'Coupon', value: Relationship.Coupon }
];

export const PHARMACY_TYPE_OPTIONS = [
  { label: 'TPS', value: 'TPS' },
  { label: 'Wells', value: 'Wells' }
];

export const ORDER_STATUS_OPTIONS = [
  { label: 'Cancelled', value: 'Cancelled' },
  { label: 'Completed', value: 'Completed' },
  { label: 'Error', value: 'Error' },
  { label: 'In progress', value: 'In progress' },
  { label: 'Pending', value: 'Pending' }
];
