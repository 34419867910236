import { InternalTriggersFilterData } from 'constants/filters';

import { useMemo } from 'react';

import { ColumnDef, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import Card from 'components/common/Card';
import AppliedFiltersTags from 'components/filters/AppliedFiltersTags/AppliedFiltersTags';
import FilterButtons from 'components/filters/FilterTabs';
import SearchForm from 'components/filters/SearchForm';
import { AgingAlertsTableProps } from 'components/tables/AgingAlertsTable/agingAlertsTable.types';
import BasicTable from 'components/tables/BasicTable';
import { columns } from 'components/tables/InternalTriggers/column';
import { useGetDefaultBasicTableValues } from 'hooks/common/useGetDefaultBasicTableValues';
import { useSearchParams } from 'react-router-dom';
import { TriggersQueryParams } from 'store/triggers/triggers.types';
import { useGetTriggersQuery } from 'store/triggers/triggersSlice';
import { parseQueryParams } from 'utils/router';

const InternalAutomations: React.FC = () => {
  const [searchParams] = useSearchParams();
  const queryParams: TriggersQueryParams = useMemo(
    () => parseQueryParams(searchParams),
    [searchParams]
  );
  const { data, isFetching, isUninitialized } = useGetTriggersQuery({
    params: queryParams,
    triggerModule: 'internal'
  });
  const [tableData, tableColumns] = useGetDefaultBasicTableValues({
    isFetching: isFetching || isUninitialized,
    columns,
    data: data?.triggers
  });
  const table = useReactTable({
    data: tableData,
    columns: tableColumns as ColumnDef<AgingAlertsTableProps, unknown>[],
    getCoreRowModel: getCoreRowModel()
  });
  return (
    <>
      <div className="relative my-5 flex items-center justify-between">
        <FilterButtons filterData={InternalTriggersFilterData} queryParam="triggerType" />
        <SearchForm showSearchBar />
      </div>
      <AppliedFiltersTags />
      <Card>
        <BasicTable table={table} totalCount={data?.totalCount || 0} isFetching={isFetching} />
      </Card>
    </>
  );
};

export default InternalAutomations;
