import { Common } from '@thecvlb/design-system/lib/src';
import ControlledInput from 'components/common/form/ControlledInput';
import ControlledSelect from 'components/common/form/ControlledSelect';
import ControlledMaskInput from 'components/common/form/maskInput/ControlledMaskInput';
import { defaultCoupon, getCouponCodes } from 'pages/CrossSell/crossSell.settings';
import { useFormContext } from 'react-hook-form';

import {
  cardNumberValidationRules,
  expDateValidationRules,
  fullNameValidationRules,
  getCVVFormat,
  zipCodeValidationRules
} from './paymentForm.settings';
import { FormContextDataProps } from './paymentForm.types';
import PaymentIcon from '../PaymentIcon/PaymentIcon';

const PaymentForm = () => {
  const { control, watch, formState } = useFormContext<FormContextDataProps>();
  const cvvFormat = getCVVFormat(watch('creditCardAttributes.fullNumber'));

  const planType = watch('planType');
  const couponCodes = getCouponCodes(
    planType?.plan?.planCode,
    planType?.pricePoint?.planPricePointId
  );
  const couponOptions = [defaultCoupon];
  if (!!couponCodes) {
    couponOptions.push(...couponCodes);
  }

  return (
    <div className="grow flex-wrap">
      <div className="mb-4 flex justify-center gap-4">
        <ControlledInput
          name="creditCardAttributes.fullName"
          label="Full name"
          className="w-full"
          control={control}
          rules={fullNameValidationRules}
        />
        <div>
          <ControlledMaskInput
            name="creditCardAttributes.billingZip"
            control={control}
            rules={zipCodeValidationRules}
            placeholder="ZIP"
            format="#####"
            isError={!!formState.errors.creditCardAttributes?.billingZip}
          />
        </div>
      </div>

      <div className="relative mb-7 w-full md:mb-4">
        <ControlledMaskInput
          name="creditCardAttributes.fullNumber"
          control={control}
          rules={cardNumberValidationRules}
          placeholder="Card number"
          format="#### #### #### ####"
          isError={!!formState.errors.creditCardAttributes?.fullNumber}
        />
        <div className="absolute right-2.5 top-2 flex h-[27px] gap-3">
          {watch('creditCardAttributes.fullNumber') ? (
            <PaymentIcon cardNumber={watch('creditCardAttributes.fullNumber')} />
          ) : (
            <>
              <Common.Icon className="size-full" name="visa" />
              <Common.Icon className="size-full" name="mastercard" />
              <Common.Icon className="size-full" name="amex" />
              <Common.Icon className="size-full" name="discover" />
            </>
          )}
        </div>
      </div>

      <div className="flex gap-4">
        <div className="mb-7 w-1/2 md:mb-4">
          <ControlledMaskInput
            name="creditCardAttributes.expDate"
            control={control}
            placeholder="MM/YY"
            format="##/##"
            rules={expDateValidationRules}
            isError={!!formState.errors.creditCardAttributes?.expDate}
          />
        </div>
        <div className="mb-7 w-1/2 md:mb-4">
          <ControlledMaskInput
            name="creditCardAttributes.cvv"
            control={control}
            placeholder="CVV"
            format={cvvFormat}
            isError={!!formState.errors.creditCardAttributes?.cvv}
            rules={{
              required: {
                value: true,
                message: 'CVV is required'
              },
              validate: {
                isValidCVV: (v?: string) => {
                  return (
                    (v &&
                      v.trim().length ===
                        getCVVFormat(watch('creditCardAttributes.fullNumber')).length) ||
                    'Invalid CVV'
                  );
                }
              }
            }}
          />
        </div>
      </div>

      <ControlledSelect
        control={control}
        options={couponOptions}
        name="creditCardAttributes.couponCode"
        label="Coupon code"
        className="w-full"
        rules={{
          required: {
            value: false,
            message: 'Coupon code is optional'
          }
        }}
      />
    </div>
  );
};

export default PaymentForm;
