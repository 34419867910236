import { MIFqaData } from 'models/tasks.types';

export const COULD_NOT_COMPLETE = 'Could not complete';

export const getGroupedQAData = (qaData: MIFqaData[] | undefined) => {
  return qaData?.reduce<MIFqaData[]>((acc, question) => {
    if (question?.group) {
      const preparedAnswer = {
        answer:
          typeof question.answer === 'string' || typeof question.answer === 'number'
            ? question.answer
            : '',
        questionContext: question.questionContext,
        warning: question.warning,
        displayNumber: Number(question.displayNumber)
      };
      let group = acc.find((item) => item.group === question.group) as MIFqaData | undefined;
      if (group) {
        group.answers = [...(group.answers || []), preparedAnswer];
      } else {
        const displayNumber = question.displayNumber
          ? Number(question.displayNumber.toString().split('.')[0])
          : null;

        group = {
          displayNumber,
          question: question.question,
          warning: question.warning,
          group: question.group,
          groupTitle: question.groupTitle || '',
          questionContext: question.questionContext || '',
          answers: [preparedAnswer]
        };
        acc.push(group);
      }
    } else {
      acc.push({
        ...question,
        answers: []
      } as MIFqaData);
    }

    // NOTE: in general, questions should be sorted by displayNumber
    return acc.sort((a, b) => (a.displayNumber || 0) - (b.displayNumber || 0));
  }, []);
};
