import { createSlice } from '@reduxjs/toolkit';

import { OrderPaneProps } from './orderPane.types';

const initialState: OrderPaneProps = {
  orderId: '',
  isOpen: false,
  isLoading: false
};

const orderPaneSlice = createSlice({
  name: 'orderPane',
  initialState,
  reducers: {
    open: (state, action) => {
      state.isOpen = true;
      if (action.payload.orderId) state.orderId = action.payload.orderId;
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    reset: () => initialState
  }
});

export const { open, reset, setIsLoading } = orderPaneSlice.actions;

export default orderPaneSlice.reducer;
