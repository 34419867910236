import { MessageType } from 'enums/messages';

export const DEFAULT_LIMIT_LOADED_ONCE_MESSAGES = 10;

export const GENERAL_HEALTH = 'General health';

export const PATIENT_MESSAGE_TAB = [
  { label: 'Medical', value: MessageType.Medical },
  { label: 'Support', value: MessageType.Support },
  { label: 'SMS', value: MessageType.SMS }
];
