import { Common } from '@thecvlb/design-system';
import PrescribeCompound from 'components/modals/PrescribeCompound';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { openModal } from 'store/modal/modalSlice';
import { selectPatient } from 'store/patients/patientsSlice';

const getCoverageColor = (coverage: string) => {
  switch (coverage && coverage.toLowerCase()) {
    case 'covered':
      return <Common.ColorTag text={coverage} color="green" />;
    case 'not covered':
      return <Common.ColorTag text={coverage} color="red" />;
    case 'pa required':
      return <Common.ColorTag text={coverage} color="yellow" />;
    case 'not contracted':
      return <Common.ColorTag text={coverage} color="red" />;
    default:
      return <Common.ColorTag text={coverage} color="gray" />;
  }
};

const ActionsCell: React.FC<{ status: string }> = ({ status }) => {
  const dispatch = useAppDispatch();
  const { benefits, patientInfo } = useAppSelector(selectPatient);

  const isRequestAuth = status && status.toLowerCase() === 'pa required';

  const handlePrescribe = () => {
    if (benefits?.userId)
      dispatch(
        openModal({
          hideClose: true,
          modalContent: (
            <PrescribeCompound patientId={benefits.userId} {...(patientInfo && { patientInfo })} />
          ),
          size: 'sm'
        })
      );
  };

  return (
    <div className="flex gap-2">
      <Common.Button
        onClick={handlePrescribe}
        color="white"
        className="whitespace-nowrap"
        size="sm"
        disabled={!!isRequestAuth}
      >
        Prescribe
      </Common.Button>
    </div>
  );
};

export { getCoverageColor, ActionsCell };
