import { useCallback, useEffect, useMemo } from 'react';

import { Common } from '@thecvlb/design-system';
import isEmpty from 'lodash/isEmpty';
import { EventMultiSelectValue } from 'models/event.types';
import { Controller, ControllerRenderProps, useFormContext } from 'react-hook-form';
import { MultiValue, SingleValue } from 'react-select';
import { useToggle } from 'react-use';
import { useGetShiftTypesQuery } from 'store/calendar/calendarSlice';

import { transformShiftTypesToCategoryFilters } from './eventCategorySelect.settings';
import {
  type CategoryFiltersProps,
  EventCategoryFieldNames,
  type EventCategoryNameType
} from './eventCategorySelect.types';
import { FormValues } from '../../manageAvailability.types';

const EventCategorySelect = ({ name }: CategoryFiltersProps) => {
  const { watch, control, clearErrors } = useFormContext<FormValues>();
  const [showCategoryMultiSelect, toggle] = useToggle(!isEmpty(watch(name)));

  const { data: shiftTypes } = useGetShiftTypesQuery();

  const selectedEventTypes = watch('eventTypes');
  const selectedCategoryFilters = watch(EventCategoryFieldNames.FILTERS);
  const isExclusions = useMemo(() => name === EventCategoryFieldNames.EXCLUSIONS, [name]);
  const oppositeValues = watch(
    isExclusions ? EventCategoryFieldNames.FILTERS : EventCategoryFieldNames.EXCLUSIONS
  );

  const shiftCategoryOptions = useMemo(() => {
    if (shiftTypes) {
      return transformShiftTypesToCategoryFilters(shiftTypes, selectedEventTypes, oppositeValues);
    }
    return [];
  }, [shiftTypes, selectedEventTypes, oppositeValues]);

  const handleChange = useCallback(
    (
      selectedOptions: MultiValue<EventMultiSelectValue> | SingleValue<EventMultiSelectValue>,
      field: ControllerRenderProps<FormValues, EventCategoryNameType>
    ) => {
      if (Array.isArray(selectedOptions) && selectedOptions.length === 0) {
        toggle(false);
      }
      field.onChange(selectedOptions);
      clearErrors('eventTypes');
    },
    [clearErrors, toggle]
  );

  useEffect(() => {
    if (!isEmpty(selectedCategoryFilters)) toggle(true);
  }, [selectedCategoryFilters, toggle]);

  return (
    <div
      data-testid="category-filters"
      className={showCategoryMultiSelect ? 'w-full flex-none' : ''}
    >
      {showCategoryMultiSelect ? (
        <div>
          <span className="mb-2 inline-block text-base font-semibold">
            {isExclusions ? 'Add exclusions' : 'Add filters to chosen categories'}
          </span>
          <Controller
            control={control}
            name={name}
            render={({ field }) => (
              <Common.MultiSelect
                size="sm"
                value={field.value}
                options={shiftCategoryOptions}
                placeholder={isExclusions ? 'Select exclusions' : 'Select category filter'}
                name={field.name}
                onChange={(selectedOptions) =>
                  handleChange(
                    selectedOptions as
                      | MultiValue<EventMultiSelectValue>
                      | SingleValue<EventMultiSelectValue>,
                    field
                  )
                }
              />
            )}
          />
        </div>
      ) : (
        <Common.Button type="button" color="white" size="sm" onClick={toggle}>
          {isExclusions ? 'Add exclusions' : 'Add filter'}
        </Common.Button>
      )}
    </div>
  );
};

export default EventCategorySelect;
