import { useMemo, useState } from 'react';

import { Common } from '@thecvlb/design-system/lib/src';
import { Alert } from '@thecvlb/design-system/lib/src/common';
import classNames from 'classnames';
import { TaskTags } from 'enums/taskCategories';
import { useAppSelector } from 'hooks/redux';
import { useToggle } from 'react-use';
import { selectTask } from 'store/tasks/tasksSlice';

import { getGroupedQAData } from './mifDetails.settings';
import { MifDetailsProps, MIFTabs } from './mifDetails.types';
import MIFQuestion from './MIFQuestion';

const MIFDetails: React.FC<MifDetailsProps> = ({ mifDetails }) => {
  const { taskDetails } = useAppSelector(selectTask);
  const isNutrition = taskDetails?.tags?.includes(TaskTags.Nutrition);

  const [activeTab, setActiveTab] = useState<string>(
    isNutrition ? MIFTabs.AllAnswers : MIFTabs.WarningsOnly
  );
  const [showAllOptions, toggleShowAllOptions] = useToggle(false);

  const isOnlyWarnings = useMemo(() => activeTab === MIFTabs.WarningsOnly, [activeTab]);
  const mifData = mifDetails ?? taskDetails.mifDetails;

  const groupedQAData = useMemo(() => {
    return getGroupedQAData(mifData?.qaData);
  }, [mifData?.qaData]);

  const filteredQAData = useMemo(() => {
    return isOnlyWarnings ? groupedQAData?.filter((question) => question.warning) : groupedQAData;
  }, [isOnlyWarnings, groupedQAData]);

  const tabsData: Common.DataItemProps[] = [
    ...(!isNutrition
      ? [
          {
            label: MIFTabs.WarningsOnly,
            badge: true,
            count: mifData?.warningCount || 0,
            color: 'text-black'
          }
        ]
      : []),
    { label: MIFTabs.AllAnswers }
  ];

  const showAllOptionsClasses = classNames(
    'flex items-center gap-2 text-sm font-medium cursor-pointer',
    showAllOptions ? 'text-gray-700' : 'text-gray-500'
  );

  const handleActiveTab = (tab: Common.DataItemProps) => {
    setActiveTab(tab.label);
  };

  const handleShowAllOptions: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    event.stopPropagation();
    toggleShowAllOptions();
  };

  return (
    <div className="flex flex-col gap-4">
      <div className="flex justify-between">
        <Common.Tabs onChange={handleActiveTab} data={tabsData} type="bar" />
        <label className={showAllOptionsClasses}>
          Show all options
          <Common.Toggle
            color="blue"
            size="sm"
            checked={showAllOptions}
            onClick={handleShowAllOptions}
          />
        </label>
      </div>

      {isOnlyWarnings && !mifData?.warningCount ? (
        <Alert type="success" children={'No concerning answers found in medical intake form.'} />
      ) : (
        <div>
          {filteredQAData?.map((question, idx) => (
            <MIFQuestion
              key={idx}
              question={question}
              showAllOptions={showAllOptions}
              displayNumber={idx + 1}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default MIFDetails;
