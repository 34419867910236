import { useCallback, useEffect, useState } from 'react';

import Loader from 'components/common/Loader';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import Drawer from 'react-modern-drawer';
import { useLocation, useSearchParams } from 'react-router-dom';
import { reset } from 'store/orderPane/orderPaneSlice';
import { resetOrderDetails, setOrderDetails, useLazyGetOrderQuery } from 'store/orders/ordersSlice';

import Body from './Body';
import Header from './Header';

import 'react-modern-drawer/dist/index.css';

export const OrderPane = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const {
    isOpen,
    orderId,
    isLoading: isLoadingInSlidingPane
  } = useAppSelector((state) => state.orderPane);
  const [
    getOrderById,
    { data: orderDetails, isFetching: orderIsFetching, isLoading: orderIsLoading }
  ] = useLazyGetOrderQuery();
  const [isLoadingClose, setIsLoadingClose] = useState(false);

  const showOrderContent = orderDetails && !orderIsLoading && !orderIsFetching;

  const handleClose = useCallback(() => {
    if (searchParams.has('orderModalOpenID')) {
      searchParams.delete('orderModalOpenID');
      setSearchParams(searchParams);
    }
    dispatch(resetOrderDetails());
    dispatch(reset());
  }, [dispatch, searchParams, setSearchParams]);

  const onRequestClose = useCallback(() => {
    if (!isLoadingInSlidingPane) {
      handleClose();
    } else {
      setIsLoadingClose(true);
    }
  }, [handleClose, isLoadingInSlidingPane]);

  useEffect(() => {
    if (!searchParams.has('orderModalOpenID')) {
      dispatch(reset());
      dispatch(resetOrderDetails());
    }
  }, [location, searchParams, dispatch]);

  useEffect(() => {
    if (isOpen && orderId) {
      getOrderById({ id: orderId })
        .unwrap()
        .then((data) => {
          dispatch(setOrderDetails(data));
        })
        .catch(() => dispatch(reset()));
    }
    // should not respond to changing search parameters
  }, [orderId, dispatch, isOpen, orderDetails]);

  useEffect(() => {
    if (isLoadingClose && !isLoadingInSlidingPane) {
      setIsLoadingClose(false);
    }

    return () => {
      dispatch(resetOrderDetails());
      dispatch(reset());
    };
  }, [dispatch, isLoadingClose, isLoadingInSlidingPane]);

  return (
    <Drawer
      open={isOpen}
      // @MikePrus TODO: remove onClose if we don't close drawer when click outside
      onClose={onRequestClose}
      direction="right"
      enableOverlay={false}
      className="max-w-[75%] rounded-l-2xl md:max-w-[760px]"
      size="100%"
      style={{ top: 170, height: 'calc(100vh - 170px)' }}
    >
      <div className="absolute flex h-full flex-col">
        {showOrderContent ? (
          <>
            <Header handleClose={handleClose} />
            <div className="overflow-y-auto">
              <Body />
              <Loader isVisible={orderIsFetching || isLoadingClose} />
            </div>
          </>
        ) : (
          <Loader isVisible />
        )}
      </div>
    </Drawer>
  );
};

export default OrderPane;
