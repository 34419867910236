import React from 'react';

import { Common } from '@thecvlb/design-system/lib/src';
import queryString from 'query-string';
import { useLocation, useNavigate } from 'react-router-dom';

import { SortingBannerProps } from './sortingBanner.types';
import SortingFieldLabel from './SortingFieldLabel/SortingFieldLabel';

const SortingBanner: React.FC<SortingBannerProps> = ({ sortFields }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleResetSort = () => {
    const queryParams = queryString.parse(location.search);
    let newQueryParams: Record<string, unknown> = queryParams;
    sortFields?.forEach(({ sortName }) => {
      delete newQueryParams[`sort[${sortName}]`];
    });
    navigate({ search: queryString.stringify(newQueryParams) });
  };

  return (
    <div className="flex min-w-full items-center bg-blue-50 px-3 py-px">
      <span className="text-sm font-medium text-black/[.5]">
        Sorting by{' '}
        {(sortFields || [])?.map(({ sortName, sortOrder }, index) => (
          <SortingFieldLabel
            key={sortName}
            sortName={sortName}
            sortOrder={sortOrder}
            index={index}
          />
        ))}
      </span>
      <Common.Button
        style="text-only"
        size="sm"
        className="!text-blue-600"
        onClick={handleResetSort}
      >
        Reset sort
      </Common.Button>
    </div>
  );
};

export default SortingBanner;
