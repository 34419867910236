import { useEffect } from 'react';

import TemplateItem from 'components/patient/Channel/Form/Templates/TemplateItem/TemplateItem';
import { useTemplates } from 'contexts/TemplatesContext/TemplatesContext';
import { InView } from 'react-intersection-observer';

import { TemplateListProps } from './templateList.types';

const TemplateList: React.FC<TemplateListProps> = ({ onTemplateClick }) => {
  const { templates, selectedTemplateIndex, setSelectedTemplateIndex } = useTemplates();

  const showTemplates = !!templates?.length;

  // Reset selected template index when templates are hidden
  useEffect(() => {
    if (!showTemplates) {
      setSelectedTemplateIndex(0);
    }

    return () => {
      setSelectedTemplateIndex(0);
    };
  }, [showTemplates, setSelectedTemplateIndex]);

  return (
    <div data-testid="templates_scroll_area" className="h-48 overflow-y-auto" role="list">
      <InView>
        {({
          // inView,
          ref
          // entry
        }) => {
          // TODO remove it if everything is working fine
          // Scroll to selected template when templates are not in view
          // if (!inView) entry?.target.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });

          return (
            !!templates?.length &&
            templates.map((template, index) => {
              const isSelected = templates.indexOf(template) === selectedTemplateIndex;

              return isSelected ? (
                <TemplateItem
                  template={template}
                  onTemplateListClick={onTemplateClick}
                  key={template._id}
                  index={index}
                  isSelected={isSelected}
                  ref={ref}
                />
              ) : (
                <TemplateItem
                  template={template}
                  onTemplateListClick={onTemplateClick}
                  key={template._id}
                  index={index}
                  isSelected={false}
                />
              );
            })
          );
        }}
      </InView>
    </div>
  );
};

export default TemplateList;
