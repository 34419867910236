import React, { MouseEvent, useEffect, useState } from 'react';

import classNames from 'classnames';
import { useController } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';

import { DataItemProps, FilterButtonsProps } from './filterButtons.types';

const FilterButtons: React.FC<FilterButtonsProps> = ({
  data,
  name,
  control,
  defaultSelected,
  isMulti,
  dataTestId
}) => {
  const [selected, setSelected] = useState<string[] | string>(defaultSelected || [data[0]?.label]);
  const isStatus = name === 'status';
  const isCategory = name === 'category';
  const [searchParams] = useSearchParams();
  const { field } = useController({ name, control });

  useEffect(() => {
    if (!field.value || !field.value.length) {
      setSelected([data[0]?.label]);
    } else {
      setSelected(field.value);
    }
  }, [field.value, data]);

  const getButtonClassName = (el: DataItemProps) => {
    return classNames(
      `text-sm px-2 py-1 my-1 mr-2 rounded ${el?.textColor}`,
      {
        'flex items-center font-semibold gap-1 text-sm text-gray90 rounded py-1 px-2': isCategory,
        'font-bold': isStatus
      },
      isStatus && selected?.toString().includes(el?.value as string) && `${el?.bgColor}`,
      isCategory && selected?.toString().includes(el?.value as string) && `${el?.bgColor}`
    );
  };

  const getFieldValue = (): string[] => {
    if (field?.value?.length) {
      return Array.isArray(field?.value) ? field?.value : (field?.value as string).split(',');
    } else {
      return searchParams.getAll(name) ?? [];
    }
  };

  const isSelectItem = (value: string) => {
    return getFieldValue()?.includes(value);
  };

  const selectMulti = (value: string) => {
    let listValue: string[] = getFieldValue();
    listValue.push(value);
    if (listValue.length > 1 && listValue.includes('All')) {
      listValue = listValue.filter((item) => item !== 'All');
    }
    if (value === 'All') {
      listValue = ['All'];
    }
    field.onChange(listValue);
    setSelected(listValue);
  };
  const unSelectMulti = (value: string) => {
    let listValue: string[] = [];

    listValue = getFieldValue()?.filter((itemValue: string) => itemValue !== value);
    listValue = listValue.length ? listValue : ['All'];
    field.onChange(listValue);
    setSelected(listValue);
  };

  const onClick = (event: MouseEvent<HTMLButtonElement>) => {
    const value = event.currentTarget.value;
    if (isMulti) {
      if (isSelectItem(value)) {
        unSelectMulti(value);
      } else {
        selectMulti(value);
      }
    } else {
      field?.onChange(value);
      setSelected(value);
    }
  };

  return (
    <>
      {data?.map((el) => (
        <button
          data-testid={dataTestId}
          onClick={(event) => {
            onClick(event);
          }}
          type="button"
          key={el.label}
          value={el.value as string}
          className={getButtonClassName(el)}
        >
          {el.color && (
            <div className={`size-2 flex-none rounded-full border border-white ${el.color}`} />
          )}
          {el.label}
        </button>
      ))}
    </>
  );
};

export default FilterButtons;
