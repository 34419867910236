import type { TimeUnit } from 'chart.js';
import { _adapters } from 'chart.js';
import dayjs, { QUnitType } from 'dayjs';
import AdvancedFormat from 'dayjs/plugin/advancedFormat.js';
import CustomParseFormat from 'dayjs/plugin/customParseFormat.js';
import isoWeek from 'dayjs/plugin/isoWeek.js';
import LocalizedFormat from 'dayjs/plugin/localizedFormat.js';
import QuarterOfYear from 'dayjs/plugin/quarterOfYear.js';

dayjs.extend(AdvancedFormat);
dayjs.extend(QuarterOfYear);
dayjs.extend(LocalizedFormat);
dayjs.extend(CustomParseFormat);
dayjs.extend(isoWeek);

const FORMATS = {
  datetime: 'MMM D, YYYY, h:mm:ss a',
  millisecond: 'h:mm:ss.SSS a',
  second: 'h:mm:ss a',
  minute: 'h:mm a',
  hour: 'hA',
  day: 'MMM D',
  week: 'll',
  month: 'MMM YYYY',
  quarter: '[Q]Q - YYYY',
  year: 'YYYY'
};

_adapters._date.override({
  formats: () => FORMATS,
  parse: (value?: never, format?: TimeUnit) => {
    if (value === null || typeof value === 'undefined') return null;
    if (typeof value === 'string' && typeof format === 'string') {
      return dayjs(value, format).isValid() ? dayjs(value, format).valueOf() : null;
    }
    return dayjs(value).isValid() ? dayjs(value).valueOf() : null;
  },
  format: (time: never, format: TimeUnit) => dayjs(time).format(format),
  add: (time: never, amount: number, unit: QUnitType & TimeUnit) =>
    dayjs(time).add(amount, unit).valueOf(),
  diff: (max: never, min: never, unit: TimeUnit) => dayjs(max).diff(dayjs(min), unit),
  startOf: (time: never, unit: (TimeUnit & QUnitType) | 'isoWeek', weekday?: number) => {
    if (unit === 'isoWeek') {
      const validatedWeekday =
        typeof weekday === 'number' && weekday > 0 && weekday < 7 ? weekday : 1;
      return dayjs(time).isoWeekday(validatedWeekday).startOf('day').valueOf();
    }
    return dayjs(time).startOf(unit).valueOf();
  },
  endOf: (time: never, unit: TimeUnit & QUnitType) => dayjs(time).endOf(unit).valueOf()
});
