import { Common } from '@thecvlb/design-system/lib/src';
import { notifySuccess } from 'components/common/Toast/Toast';
import ControlledSelect from 'components/forms/controlled/ControlledSelect';
import ControlledTextArea from 'components/forms/controlled/ControlledTextArea';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useCreateFrontDeskChannelMutation } from 'store/channels/channelsSlice';
import { useGetFrontDeskRequestTypesQuery } from 'store/frontDeskRequestTypes/frontDeskRequestTypesSlice';
import { closeModal } from 'store/modal/modalSlice';
import { validation } from 'utils/helpers';
import { TRIMMED_START_REGEXP } from 'utils/regExp';

import { FormDataFrontDeskProps } from './addFrontDeskTicket.types';

const AddFrontDeskTicket: React.FC<{
  patientId: string;
}> = ({ patientId }) => {
  const dispatch = useDispatch();
  const { control, formState, handleSubmit } = useForm<FormDataFrontDeskProps>();
  const { data, isLoading: isLoadingRequestTypes } = useGetFrontDeskRequestTypesQuery({
    params: { limit: 200, pageNo: 0, status: 'active' }
  });
  const [createFrontDeskChannel, { isLoading }] = useCreateFrontDeskChannelMutation();
  const listFrontDesk = data?.data.map((item) => ({ value: item._id, label: item.requestType }));

  const handleCancel = () => {
    dispatch(closeModal());
  };

  const handleSuccess = (message: string) => {
    notifySuccess(message);
    dispatch(closeModal());
  };

  const onSubmit = (formData: FormDataFrontDeskProps) => {
    if (formData) {
      const body = {
        message: formData?.message.trim(),
        frontDeskRequestTypeId: formData?.frontDeskRequestTypeId?.value,
        receiverId: patientId
      };
      createFrontDeskChannel(body)
        .unwrap()
        .then((res) => handleSuccess(res?.message));
    }
  };

  return (
    <div data-testid="add_front_desk_ticket_popup" className="p-6">
      <h2 data-testid="header" className="mb-2 text-xl font-bold text-gray-700">
        Add new channel
      </h2>
      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-4">
        <ControlledSelect
          dataTestId="category_dropdown"
          control={control}
          label="Select category:"
          name="frontDeskRequestTypeId"
          placeholder="Select category..."
          options={listFrontDesk}
          errors={formState.errors.audience}
          rules={validation('Category')}
          disabled={isLoadingRequestTypes}
        />
        <ControlledTextArea
          dataTestId="description_field"
          control={control}
          placeholder="Description"
          label="Create message"
          rows={3}
          name="message"
          errors={formState.errors.description}
          rules={validation('Message', TRIMMED_START_REGEXP, 'Incorrect message format')}
        />

        <div className="mt-2 flex gap-2">
          <Common.Button
            dataTestId="cancel_btn"
            onClick={handleCancel}
            size="sm"
            color="white-alt"
            className="!w-full justify-center"
          >
            Cancel
          </Common.Button>
          <Common.Button
            dataTestId="add_ticket_btn"
            size="sm"
            color="blue"
            className="!w-full justify-center"
            disabled={isLoading}
          >
            Add ticket
          </Common.Button>
        </div>
      </form>
    </div>
  );
};

export default AddFrontDeskTicket;
