import { PatientPrescriptionsType, PaymentStatus } from 'enums/prescriptions';

export interface PatientPrescriptionsProps {
  limit?: number;
  pageNo?: number;
  sortField?: string;
  sortOrder?: string;
  type?: PatientPrescriptionsType;
  isDraft?: boolean;
  isStableDose?: boolean;
}

export interface PatientPrescription {
  id: string;
  _id?: string;
  elationPrescriptionId?: string;
  sig: string;
  name: string;
  subname?: string;
  type: PatientPrescriptionsType;
  qty: number;
  qtyUnits: 'ml' | 'tablets';
  createdAt: string;
  signedAt: string;
  orderStatus: string;
  paymentStatus: PaymentStatus;
  titration?: Titration;
  titrations: Titration[];
  orderId?: string;
  firstDoseTakenAt?: string;
  renews: string;
  renewal: number;
  renewalTaskId: string;
  fulfillmentState: string;
  deletedAt: string;
  renewalSurveys?: number;
  isCompound?: boolean;
  deliveredDate?: string;
  signedDate?: string;
  shippingStatus?: string;
  isRenewalRequestAllowed?: boolean;
  renewalRequestAllowedAt?: string;
  renewalFrequency?: {
    supply?: number;
  };
  isRenewalCreated?: boolean;
  renewalTask?: {
    id: string;
    status: RenewalStatus;
  };
  renewalSurvey?: {
    canceledAt: string | null;
  };
  bundleMedication?: {
    name: string;
    sigs: { name: string }[];
  };
}

export enum RenewalStatus {
  URGENT = 'URGENT',
  Active = 'Active',
  Open = 'Open',
  Completed = 'Completed',
  Deleted = 'Deleted',
  Cancelled = 'Cancelled'
}

export interface Titration {
  qty: number;
  units: 'ml' | 'tablets';
  stableDose?: boolean;
  updatedAt?: string;
  createdAt?: string;
  adjustedAt: string;
  _id?: string;
}
