import React from 'react';

import { notifySuccess } from 'components/common/Toast/Toast';
import Container from 'components/modals/bulkEditModals/UpdateFailed/Container';
import TaskCategoryLabel from 'components/tables/TaskTable/TaskCategoryLabel';
import dayjs from 'dayjs';
import { useAppDispatch } from 'hooks/redux';
import { TaskType } from 'models/tasks.types';
import { resetBulkEdits } from 'store/bulkEdit/bulkEditSlice';
import { closeModal } from 'store/modal/modalSlice';
import {
  BulkEditFailedResponseItem,
  BulkEditTaskParams,
  BulkEditUpdatedResponseItem
} from 'store/tasks/task.types';
import { useTasksBulkUpdateMutation } from 'store/tasks/tasksSlice';

const UpdateFailedModal: React.FC<{ requestBody: BulkEditTaskParams }> = ({ requestBody }) => {
  const dispatch = useAppDispatch();
  const [tasksBulkUpdate, { isLoading, data }] = useTasksBulkUpdateMutation({
    fixedCacheKey: 'taskBulkEdit'
  });

  const failedItems = data?.data?.failed || [];
  const failedCount = failedItems?.length ?? 0;

  const updatedItems = data?.data?.updated || [];
  const updatedCount = updatedItems?.length ?? 0;
  const allCount = failedCount + updatedCount;

  const handleOnRetry = () => {
    tasksBulkUpdate(requestBody)
      .unwrap()
      .then((res) => {
        if (!res.data?.failed.length) {
          notifySuccess(res.message);
          dispatch(resetBulkEdits());
          dispatch(closeModal());
        }
      });
  };

  const getItem = (item: BulkEditUpdatedResponseItem | BulkEditFailedResponseItem) => (
    <div className="my-2 ml-2 grid grid-cols-12 items-center gap-2" key={item.taskId}>
      <span className="col-span-5 flex text-sm">
        <b className="line-clamp-2 break-words">{item.patientInfo.name}</b>
        <span className="self-end pl-1">
          {item.patientInfo.dob ? ` ${dayjs().diff(item.patientInfo.dob, 'year')}` : null}
        </span>
        {item.patientInfo.stateShortCode && (
          <span className="self-end pl-1">
            <span>•</span>
            <span className="pl-1">{item.patientInfo.stateShortCode}</span>
          </span>
        )}
      </span>
      <b className="col-span-3 text-center text-sm">33 minutes ago</b>
      <div className="col-span-2">
        <TaskCategoryLabel type={item?.category.toLowerCase() as TaskType} />
      </div>
    </div>
  );

  const failedComponent = failedItems.map((item) => getItem(item));
  const updatedComponent = updatedItems.map((item) => getItem(item));

  return (
    <Container
      handleOnRetry={handleOnRetry}
      failedCount={failedCount}
      allCount={allCount}
      failedComponent={failedComponent}
      updatedComponent={updatedComponent}
      isLoading={isLoading}
      closeButtonTitle="Cancel"
      isTask
    />
  );
};

export default UpdateFailedModal;
