import { createColumnHelper } from '@tanstack/react-table';

import EditCell from './EditCell';
import { FrontDeskTableProps } from './frontDeskTable.types';
import BulkEditCheckbox from '../BasicTable/BulkEditCheckox';
import Header from '../Header';

const columnHelper = createColumnHelper<FrontDeskTableProps>();

export const columns = [
  columnHelper.accessor('bulkEditIds', {
    header: ({ table }) => (
      <BulkEditCheckbox
        onChange={table.getToggleAllRowsSelectedHandler()}
        checked={table.getIsAllRowsSelected()}
        reset={table.resetRowSelection}
        isHeading
        ids={table.getGroupedRowModel().rows.map((id) => id.original._id)}
      />
    ),
    cell: ({ row }) => (
      <BulkEditCheckbox
        onChange={row.getToggleSelectedHandler()}
        checked={row.getIsSelected()}
        id={row.original._id}
      />
    )
  }),
  columnHelper.accessor('requestType', {
    header: () => <Header text="Type" sortField="requestType" />
  }),
  columnHelper.accessor('routeTo', {
    header: () => <Header text="Route to" sortField="routeTo" />
  }),
  columnHelper.accessor('triggers', {
    header: () => <Header text="Triggers" sortField="triggers" />
  }),
  columnHelper.accessor('_id', {
    header: () => <Header text="Unique ID" sortField="uniqueId" />
  }),
  columnHelper.accessor('status', {
    header: () => <Header text="Status" sortField="status" />,
    cell: (info) => (
      <span className={`capitalize ${info.getValue() === 'active' ? 'text-blue' : 'text-red'}`}>
        {info.getValue()}
      </span>
    )
  }),
  columnHelper.accessor('actions', {
    header: () => <span className="text-sm font-bold">Actions</span>,
    cell: (info) => <EditCell id={info.row.original._id} />,
    enableSorting: false
  })
];
