export enum CrossSellLinks {
  CreateProfile = 'Create profile',
  PlanType = 'Plan type',
  AppointmentType = 'Appointment type',
  SelectDoctor = 'Select  a doctor',
  ConfirmAndPay = 'Confirm and pay',
  MedicalInformation = 'Medical information'
}

export enum PaymentMethod {
  CreditCard = 'credit_card',
  Paypal = 'paypal_account',
  BankAccount = 'bank_account'
}
