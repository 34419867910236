import { useCallback } from 'react';

import { MessageType } from 'enums/messages';
import {
  useLazyGetFrontDeskChannelsQuery,
  useLazyGetPatientChannelsQuery
} from 'store/channels/channelsSlice';
import { useLazyGetCareMessagesQuery } from 'store/chat/chatSlice';

export const useRetryLoadMessages = ({
  type,
  patientId,
  channelId
}: {
  type: MessageType;
  patientId: string;
  channelId?: string;
}) => {
  const [getMessages] = useLazyGetCareMessagesQuery();
  const [getPatientChannels] = useLazyGetPatientChannelsQuery();
  const [getFrontDeskChannels] = useLazyGetFrontDeskChannelsQuery();

  const handleRetry = useCallback(() => {
    if (channelId) {
      getMessages({ channelId: channelId, type });
    } else {
      if (type === MessageType.Medical && patientId) {
        getPatientChannels({ patientId });
      } else if (type === MessageType.Support && patientId) {
        getFrontDeskChannels({ patientId });
      }
    }
  }, [channelId, getFrontDeskChannels, getMessages, getPatientChannels, patientId, type]);

  return [handleRetry] as const;
};
