import { useCallback, useEffect, useState } from 'react';

import { createSelector } from '@reduxjs/toolkit';
import { Common } from '@thecvlb/design-system';
import { DataItemProps } from '@thecvlb/design-system/lib/src/common/Tabs/tabs.types';
import ExceptionsList from 'components/availability/exceptionsList';
import Form from 'components/availability/Form';
import Alert from 'components/common/Alert';
import Loader from 'components/common/Loader';
import { notifyError, notifySuccess } from 'components/common/Toast/Toast';
import CalendarPopup from 'components/modals/CalendarPopup';
import dayjs from 'dayjs';
import { useAppSelector } from 'hooks/redux';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { AvailabilityProps } from 'store/availability/availability.types';
import {
  selectAvailability,
  useDeleteOverrideMutation,
  useLazyGetAvailabilityQuery
} from 'store/availability/availabilitySlice';
import { openModal } from 'store/modal/modalSlice';
import { useLazyGetStaffQuery } from 'store/staffs/staffsSlice';
import { selectUser } from 'store/user/userSlice';

const selectAvailabilityState = createSelector(
  [selectAvailability, selectUser],
  (availability, user) => ({
    id: user._id,
    timezone: user.timezone,
    availabilityData: availability.data,
    hasUnsavedChanges: availability.isChanged
  })
);

const Availability: React.FC<{ physicianId?: string }> = ({ physicianId = '' }) => {
  const { id: idFromUrl = '' } = useParams<{ id: string }>();

  const userId = idFromUrl || physicianId;

  const { id, timezone, availabilityData, hasUnsavedChanges } =
    useAppSelector(selectAvailabilityState);
  const [getAvailability, { isSuccess, isFetching }] = useLazyGetAvailabilityQuery();
  const [deleteOverride, { isLoading: isLoadingDelete }] = useDeleteOverrideMutation();
  const [getStaff, { data: staffDetails }] = useLazyGetStaffQuery();
  const [tabsData, setTabsData] = useState<DataItemProps[]>();
  const [activeTab, setActiveTab] = useState<DataItemProps>();

  const dispatch = useDispatch();
  const days = dayjs.weekdays();
  const [exceptions, setExceptions] = useState<AvailabilityProps['overRide']>({});

  const isPhysicianTab = activeTab?.value === 'physician' || id === userId;

  const getLocalTz = useCallback(
    () => (isPhysicianTab ? staffDetails?.timezone : dayjs.tz.guess()),
    [activeTab?.value, id, userId]
  );

  const handleClick = () => {
    dispatch(
      openModal({
        size: 'sm',
        hideClose: true,
        modalContent: (
          <CalendarPopup
            getLocalTz={getLocalTz}
            userId={userId}
            physicianTZ={availabilityData?.timezone || dayjs.tz.guess()}
            isPhysicianTab={isPhysicianTab}
          />
        )
      })
    );
  };
  const onRemoveOverride = (date: string | { startTime: string; endTime: string }) => {
    deleteOverride({ userId, localTz: getLocalTz(), date })
      .unwrap()
      .then(() => notifySuccess('Successfully deleted'))
      .catch((e) => {
        notifyError(e.data?.message || e.message);
      });
  };

  useEffect(() => {
    if (isSuccess && availabilityData) {
      // TODO: move exceptions to availability reducer
      availabilityData.overRide && setExceptions(availabilityData.overRide);
    }
  }, [isSuccess, availabilityData, days]);

  useEffect(() => {
    if (id !== userId) {
      getStaff({ staffId: userId });
    }
  }, [getStaff, id, userId]);

  useEffect(() => {
    getAvailability({ userId, localTz: getLocalTz() });
  }, [activeTab, getAvailability, getLocalTz, userId]);

  useEffect(() => {
    if (staffDetails) {
      setTabsData([
        { label: `Local time (${dayjs().tz().offsetName()})`, value: 'local' },
        {
          label: `Physician’s time (${dayjs().tz(staffDetails.timezone).offsetName()})`,
          value: 'physician'
        }
      ]);
    }
  }, [staffDetails]);

  return (
    <div data-testid="availability_section" className="flex min-h-full flex-col">
      <Loader isVisible={isFetching} />
      <div className="flex self-end px-8">
        <button
          className="flex w-fit items-center gap-1 rounded-full bg-secondary px-4 py-[7.5px] text-sm font-bold text-white transition-all hover:bg-secondary-600 disabled:bg-gray-200 disabled:text-gray disabled:ring-0"
          data-testid="save_btn"
          type="submit"
          color="green"
          disabled={!hasUnsavedChanges}
          form="availability-form"
        >
          Save
        </button>
      </div>
      {staffDetails && (
        <div className="mx-8 mt-6 flex items-center gap-3 rounded-lg bg-secondary-100 px-5 py-4 font-bold">
          <Common.ProfileImage
            src={staffDetails.profileImage}
            size="sm"
            role={staffDetails.userType.shortCode}
          />
          <span>
            Viewing availability for {staffDetails.firstName} {staffDetails.lastName}.
          </span>
        </div>
      )}
      <div className="my-6 flex flex-row divide-x divide-gray-100">
        <Form
          userId={userId}
          tabsData={tabsData}
          setActiveTab={setActiveTab}
          getLocalTz={getLocalTz}
        />
        <div className="w-2/5 px-8">
          <h3 className="font-bold">Vacations / exceptions</h3>
          <p className="mb-4 mt-2 text-base">
            Add dates when your availability changes from your weekly hours.
          </p>
          {isPhysicianTab && (
            <Common.Button
              dataTestId="add_exception_btn"
              onClick={handleClick}
              color="blue"
              style="pill"
              size="sm"
            >
              Add an exception
            </Common.Button>
          )}
          {!isPhysicianTab && (
            <Alert
              type="info"
              children={
                <span data-testid="please_view_msg" className="text-base text-gray-700">
                  Please view in provider’s time zone to edit exceptions.
                </span>
              }
            />
          )}
          <ExceptionsList
            onRemove={onRemoveOverride}
            loading={isFetching || isLoadingDelete}
            exceptions={exceptions}
            isPhysicianTab={isPhysicianTab}
            userId={userId}
            timezone={timezone ?? availabilityData?.timezone}
          />
        </div>
      </div>
    </div>
  );
};

export default Availability;
