import { Common } from '@thecvlb/design-system';
import ThumbsSvg from 'assets/icons/symbols/thumbs-full.svg?react';
import dayjs from 'dayjs';
import { DateFormat } from 'enums/dateFormats';
import { useAppDispatch } from 'hooks/redux';
import { useFormContext } from 'react-hook-form';
import { closeModal } from 'store/modal/modalSlice';

import WelcomeToUnlimited from './WelcomeToUnlimited';

const descriptionClassName =
  'mb-4 line-clamp-4 break-words text-center max-sm:min-h-[50px] md:line-clamp-2 md:max-w-md md:text-base';
const startSoonWrapperClassName =
  'mt-4 flex h-full w-full flex-col items-center justify-between gap-4 md:h-auto md:w-auto md:justify-start';

const Confirmation: React.FC<{ closePopup?: () => void; isPopupOpen?: boolean }> = ({
  closePopup,
  isPopupOpen
}) => {
  const dispatch = useAppDispatch();
  const { watch } = useFormContext();

  const description = watch('appointmentDescription');
  const startTime = watch('startTime');
  const providerName = watch('randomProviderName');

  const handleCloseModal = () => {
    dispatch(closeModal());
  };

  return (
    <div className="flex h-full grow flex-col items-center">
      {closePopup && <WelcomeToUnlimited isOpen={!!isPopupOpen} onClose={closePopup} />}

      <div className="my-2 flex items-center justify-center">
        <ThumbsSvg width={178} height={112} />
      </div>

      <h2 className="mb-4 text-mLg font-bold md:text-xl" data-testid="time-block">
        {dayjs(startTime).format(DateFormat.dddd_MMMM_D_h_mm_A)}
      </h2>
      <h3 className="hidden text-mBase font-bold text-primary md:text-sm">{providerName}</h3>
      <p className={descriptionClassName} data-testid="description-block">
        {description}
      </p>
      <div className={startSoonWrapperClassName}>
        <div className="flex items-center justify-center gap-3">
          <Common.Button color="blue" onClick={handleCloseModal}>
            Close
          </Common.Button>
        </div>
      </div>
    </div>
  );
};

export default Confirmation;
