import {
  REMOVE_LEADING_LINE_BREAKS_AND_SPACES,
  REMOVE_TRAILING_LINE_BREAKS_AND_SPACES
} from 'utils/regExp';

/**
 * @description
 * First remove trailing and leading line breaks and spaces
 * than remove leading line breaks and spaces
 */
function parseMessage(message: string) {
  const parsed = message
    .replace(REMOVE_TRAILING_LINE_BREAKS_AND_SPACES, '$2')
    .replace(REMOVE_LEADING_LINE_BREAKS_AND_SPACES, '$1$3');

  return parsed;
}

export { parseMessage };
