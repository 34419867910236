export enum PatientPrescriptionsType {
  Order = 'order',
  Prescription = 'prescription',
  LMDPrescription = 'lmd_prescription'
}

export enum PaymentStatus {
  Paid = 'paid',
  Failed = 'failed'
}

export enum NotPrescribingReason {
  TooEarlyToPrescribe = 'too-early-to-prescribe',
  WaitingForLabs = 'waiting-for-labs',
  NotClinicallyAppropriate = 'not-clinically-appropriate',
  AlreadyFilled = 'already-filled',
  RecommendingMaintenancePlan = 'recommending-maintenance-plan'
}

export enum DevelopHealthPrescriptionDoseForm {
  TABLET = 'tablet',
  CAPSULE = 'capsule',
  SUSPENSION = 'suspension',
  CREAM = 'cream',
  SOLUTION = 'solution',
  OINTMENT = 'ointment',
  SUPPOSITORY = 'suppository',
  INHALER = 'inhaler',
  LIQUID = 'liquid',
  POWDER = 'powder',
  INJECTABLE = 'injectable',
  PATCH = 'patch',
  GEL = 'gel',
  LOZENGE = 'lozenge',
  CONCENTRATE = 'concentrate'
}

export enum DevelopHealthPrescriptionDispenseUnit {
  MILLILITER = 'milliliter',
  LITER = 'liter',
  GRAM = 'gram',
  MILLIGRAM = 'milligram',
  MICROGRAM = 'microgram',
  UNIT = 'unit',
  TABLET = 'tablet',
  CAPSULE = 'capsule',
  PATCH = 'patch',
  INHALER = 'inhaler',
  APPLICATOR = 'applicator',
  PACK = 'pack',
  VIAL = 'vial'
}

export enum DevelopHealthPrescriptionRouteOfAdministration {
  ORAL = 'oral',
  SUBCUTANEOUS = 'subcutaneous',
  INTRAVENOUS = 'intravenous',
  INTRAMUSCULAR = 'intramuscular',
  TOPICAL = 'topical',
  INHALATION = 'inhalation',
  NASAL = 'nasal',
  OPHTHALMIC = 'ophthalmic',
  OTIC = 'otic',
  RECTAL = 'rectal',
  VAGINAL = 'vaginal'
}

export const GLP_1_AND_KIT_GROUPED_ORDER = 'GLP-1 & kit';
