export enum HealthProfileNavItem {
  MIF = 'Medical Intake Form',
  HealthMetrics = 'Health metrics',
  HealthConditions = 'Health conditions',
  ChronicDiseases = 'Chronic diseases',
  WomenHealth = `Women's health`,
  SocialHistory = 'Social history',
  Medications = 'Medications',
  Allergies = 'Allergies'
}

export enum HealthMetricTypes {
  Height = 'height',
  WaistCircumference = 'waistCircumference',
  Weight = 'weight',
  BMI = 'bmi',
  BloodPressure = 'bloodPressure',
  Cholesterol = 'cholesterol'
}
