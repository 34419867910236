import { useEffect, useRef, useState } from 'react';

import { useFlag } from '@unleash/proxy-client-react';
import Card from 'components/common/Card';
import Loader from 'components/common/Loader';
import Header from 'components/patient/Header';
import { PatientOverviewTab } from 'enums/patientOverviewTabs';
import { useGetPatientActivePricePoint } from 'hooks/patient/useGetPatientActivePricePoint';
import { useAppDispatch } from 'hooks/redux';
import { useParams, useSearchParams } from 'react-router-dom';
import { RequestStatus, SuggestedMessageRequest } from 'store/aiAssistant/aiAssistant.types';
import { useGetSuggestedResponseMutation } from 'store/aiAssistant/aiAssistantSlice';
import {
  resetBenefits,
  resetDocuments,
  resetPatientInfo,
  resetPaymentProfiles,
  setIsTripleTherapyPatient,
  useLazyGetPatientQuery
} from 'store/patients/patientsSlice';

import Banners from './Banners';
import { renderContentList } from './patient.settings';

const Patient = () => {
  const { patientId = '' } = useParams<{ patientId: string }>();
  const [searchParams] = useSearchParams();
  const activeTabParam = searchParams.get('active-tab');
  const dispatch = useAppDispatch();
  const isChartDisable = useFlag('hide-patient-chart-tab');

  const retryRef = useRef<NodeJS.Timeout>();

  const [getPatient, { data: patient, isLoading }] = useLazyGetPatientQuery();

  const [getSuggestedResponse, patientSummaryResult] = useGetSuggestedResponseMutation();
  const { currentPricePoint } = useGetPatientActivePricePoint(patient);

  const patientSummaryObj = {
    data: patientSummaryResult.data?.data,
    isLoading: patientSummaryResult.isLoading,
    isError: patientSummaryResult.isError
  };

  const [activeTab, setActiveTab] = useState<PatientOverviewTab>(PatientOverviewTab.Overview);

  useEffect(() => {
    if (currentPricePoint) {
      dispatch(
        setIsTripleTherapyPatient(currentPricePoint?.planPricePointId === 'wm-tt-one-month')
      );
    }
  }, [currentPricePoint, dispatch]);

  useEffect(() => {
    if (patientId) {
      getPatient({ id: patientId });
    }
    if (activeTabParam) setActiveTab(activeTabParam as PatientOverviewTab);

    return () => {
      dispatch(resetDocuments());
      dispatch(resetBenefits());
    };
  }, [dispatch, activeTabParam, getPatient, patientId]);

  useEffect(() => {
    if (patientId) {
      const params: SuggestedMessageRequest = {
        body: {
          flow: 'patientSummary',
          userId: patientId,
          text: ''
        }
      };
      getSuggestedResponse(params)
        .unwrap()
        .then((res) => {
          if (res.info.status === RequestStatus.IN_PROGRESS) {
            retryRef.current = setTimeout(() => getSuggestedResponse(params), 30000);
          } else {
            clearTimeout(retryRef.current);
          }
        });
    }
  }, [getSuggestedResponse, patientId]);

  useEffect(() => {
    return () => {
      dispatch(resetPatientInfo());
      dispatch(resetPaymentProfiles());
      retryRef.current && clearTimeout(retryRef.current);
    };
  }, [dispatch]);

  return (
    <>
      <Loader isVisible={isLoading} />
      {patient && (
        <>
          <Card dataTestId="patient_page">
            <Header activeTab={activeTab} setActiveTab={setActiveTab} patient={patient} />
            <div className="border-t border-gray-200">
              {!!patient && <Banners patient={patient} />}
              {renderContentList(activeTab, patient, patientId, patientSummaryObj, isChartDisable)}
            </div>
          </Card>
        </>
      )}
    </>
  );
};

export default Patient;
