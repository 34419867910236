import { Common } from '@thecvlb/design-system';
import classNames from 'classnames';

import { renderIcon } from './alert.settings';
import { AlertProps } from './alert.types';

const Alert: React.FC<AlertProps> = ({
  children,
  type,
  handleClose,
  containerClasses,
  handleAction,
  actionButtonLabel
}) => {
  const wrapperClasses = classNames(
    `py-4 px-4 rounded-lg flex items-center justify-between ${containerClasses}`,
    {
      'bg-blue-100': type === 'info',
      'bg-red-100': type === 'error',
      'bg-white shadow mb-4': type === 'notice',
      'bg-yellow-100 shadow mb-4': type === 'warning'
    }
  );

  const actionButtonColor = type === 'info' ? 'blue' : type === 'error' ? 'red' : 'white-alt';

  return (
    <div data-testid="alert_info_banner" className={wrapperClasses}>
      <div className="flex w-full items-center">
        {renderIcon(type)}
        {children}
      </div>
      {!!handleAction && (
        <Common.Button color={actionButtonColor} size="sm" onClick={handleAction}>
          {actionButtonLabel}
        </Common.Button>
      )}
      {!!handleClose && (
        <span data-testid="close_alert_btn" onClick={handleClose} title="close button">
          <Common.Icon name="close" className="size-4 cursor-pointer text-black/30" />
        </span>
      )}
    </div>
  );
};

export default Alert;
