import { createColumnHelper } from '@tanstack/react-table';

import { AppointmentTypesTableProps } from './appointmentTypesTable.types';
import EditCell from './EditCell';
import Header from '../Header';

const columnHelper = createColumnHelper<AppointmentTypesTableProps>();
export const columns = [
  columnHelper.accessor('displayName', {
    header: () => <Header text="Appointment type" sortField="displayName" />
  }),
  columnHelper.accessor('category', {
    header: () => <Header text="Category" sortField="category" />
  }),
  columnHelper.accessor('triggers', {
    header: () => <Header text="Triggers" sortField="triggers" />,
    cell: (info) => <span>{info.getValue().join(', ')}</span>
  }),
  columnHelper.accessor('lengthByPlan', {
    header: () => <Header text="Length" sortField="lengthByPlan" />,
    cell: (info) => {
      const initialLength = info.getValue()?.[0]?.appointmentLengths?.initial?.length;
      const length = initialLength ? `${initialLength} min` : 'Default length';

      return <span>{length}</span>;
    }
  }),
  columnHelper.accessor('uploadRequired', {
    header: () => <Header text="Upload required" sortField="uploadRequired" />,
    cell: (info) => (
      <span className="block first-letter:uppercase">{info.getValue().toString()}</span>
    )
  }),
  columnHelper.accessor('_id', {
    header: () => <Header text="Unique ID" sortField="uniqueId" />
  }),
  columnHelper.accessor('status', {
    header: () => <Header text="Status" sortField="status" />,
    cell: (info) => (
      <span className={`capitalize ${info.getValue() === 'active' ? 'text-blue' : 'text-red'}`}>
        {info.getValue()}
      </span>
    )
  }),
  columnHelper.accessor('actions', {
    header: () => <span className="text-sm font-bold">Actions</span>,
    cell: (info) => <EditCell id={info.row.original._id} />,
    enableSorting: false
  })
];
