import { useCallback } from 'react';

import { NavbarProps } from './navbar.types';
import NavBarItems from './NavbarItem';

const NavBar = ({ mappings, areaName, sectionName, setAreaName, setSectionName }: NavbarProps) => {
  const handleNavItemClick = useCallback(
    (areaKey: string, sectionKey: string) => {
      setAreaName(areaKey);
      setSectionName(sectionKey);
    },
    [setAreaName, setSectionName]
  );

  return (
    <div>
      {mappings &&
        Object.keys(mappings).map((key) => {
          return (
            <div key={key}>
              <div className="mb-4">
                <div data-testid="section_title" className="p-3 font-semibold uppercase">
                  {key}
                </div>
                {NavBarItems({
                  areaKey: key,
                  sectionKeys: Object.keys(mappings[key]),
                  handleItemClick: handleNavItemClick,
                  areaName: areaName,
                  sectionName: sectionName
                })}
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default NavBar;
