import React from 'react';

import { Common } from '@thecvlb/design-system/lib/src';
import { getPricePoint } from 'components/modals/ChangePlan/ChoosePlan/choosePlan.settings';
import ConfirmPayment from 'components/modals/ChangePlan/ConfirmPayment';
import { useFormContext } from 'react-hook-form';

import PatientInsuranceBanner from './PatientInsuranceBanner';
import { PaymentCheckoutProps } from './paymentCheckout.types';

const PaymentCheckout: React.FC<PaymentCheckoutProps> = ({
  membershipData,
  onProceed,
  existingPlan,
  selectedPlan,
  patientInfo,
  isLoading,
  isInsuranceUser
}) => {
  const methods = useFormContext();
  const activePP = existingPlan?.pricePoints?.find(
    (pp) => pp.planPricePointId === patientInfo?.activePricePoint
  );
  const disableConfirmPayment = !methods.watch('confirmation');

  const newPricePoint = !!selectedPlan && getPricePoint(selectedPlan?.pricePoints);

  return (
    <div className="mt-8">
      {isInsuranceUser && (
        <PatientInsuranceBanner insuranceEligibility={membershipData?.insuranceEligibility} />
      )}

      {patientInfo && (
        <ConfirmPayment
          patientInfo={patientInfo}
          newPlan={selectedPlan}
          currentPlan={existingPlan}
          activePP={activePP}
          disabledPayment={isLoading}
          membershipData={membershipData}
          hideTitle
          isCreateAppointment
          newPricePoint={newPricePoint}
        />
      )}
      <div className="mt-8 flex flex-col items-center justify-center gap-4">
        <Common.Button
          color="blue"
          isLoading={isLoading}
          disabled={disableConfirmPayment || isLoading}
          onClick={methods.handleSubmit(onProceed)}
        >
          Confirm payment
        </Common.Button>
        <p className="flex items-center gap-1 text-sm">
          <Common.Icon name="lock" className="size-5 text-secondary-500" />
          SSL, Encrypted, Secure Payment
        </p>
      </div>
    </div>
  );
};

export default PaymentCheckout;
