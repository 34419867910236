import { ASAP_OPTION_DATE } from 'constants/appointmentType';

import { useContext } from 'react';

import classNames from 'classnames';
import { VisibilityContext } from 'react-horizontal-scrolling-menu';
import { getDaysList } from 'utils/appointment';

import { getContent } from './dayCard.settings';
import { DayCardProps } from '../schedulingDaySlider.types';

const DayCard: React.FC<DayCardProps> = ({
  onClick,
  selected,
  date,
  selectedDate,
  loading,
  disabled = false,
  queueWaitingTime
}) => {
  const datesList = getDaysList();
  const isOutRangeDateSelected = !datesList.includes(selectedDate);
  const visibility = useContext(VisibilityContext);

  const dayClassName = classNames(
    'h-20 w-24 rounded-lg disabled:opacity-30 md:w-[88px]',
    {
      'bg-white': !selected || date === 'calendar',
      'border-orange': date === ASAP_OPTION_DATE && !disabled
    },
    { 'bg-orange-100': date == ASAP_OPTION_DATE && selected },
    (date !== ASAP_OPTION_DATE && selected) ||
      (date === 'calendar' && !!selectedDate && isOutRangeDateSelected)
      ? 'border-2 border-primary-400 !bg-primary-100'
      : 'border border-gray-200'
  );

  const dateClassName = 'mb-1 block select-none text-base font-bold';
  const dayNameClassName = 'block text-mSm md:text-sm font-medium';

  return (
    <button
      data-testid="day-card"
      type="button"
      className={dayClassName}
      disabled={loading || disabled}
      tabIndex={0}
      onClick={() => onClick(visibility)}
    >
      <div>
        <span className={dateClassName} data-test="date">
          {getContent(date, 'day', selectedDate, disabled, queueWaitingTime)}
        </span>
        <span className={dayNameClassName}>
          {getContent(date, 'dayName', selectedDate, disabled)}
        </span>
      </div>
    </button>
  );
};

export default DayCard;
