import { MouseEventHandler } from 'react';

import { Common } from '@thecvlb/design-system';
import { PathName } from 'enums/pathName';
import { Link } from 'react-router-dom';

const EditCell: React.FC<{ id: string; hiddenPencil?: boolean }> = ({ id, hiddenPencil }) => {
  const handleEditClick: MouseEventHandler<HTMLAnchorElement> = (event) => {
    event.stopPropagation();
  };

  return (
    <div className="flex gap-1">
      <Link data-testid="eye_icon" to={`${PathName.Articles}/${id}`}>
        <Common.Icon name="view-case" className="size-4 text-gray-700 hover:text-primary-500" />
      </Link>
      {!hiddenPencil && (
        <Link
          data-testid="pencil_icon"
          to={`${PathName.UpdateArticle}/${id}`}
          onClick={handleEditClick}
        >
          <Common.Icon name="pencil" className="size-4 hover:text-primary-500" />
        </Link>
      )}
    </div>
  );
};

export default EditCell;
