import React, { useCallback, useEffect, useMemo, useRef } from 'react';

import { Common } from '@thecvlb/design-system';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { DateFormat } from 'enums/dateFormats';
import { useClickAway, useKeyPress, useToggle } from 'react-use';

import { StyledDatePickerInput } from './datePickerInput.styled';
import { DatePickerInputProps } from './datePickerInput.types';

const DatePickerInput: React.FC<DatePickerInputProps> = ({
  inputValue = '',
  setInputValue,
  selectedDate,
  setSelectedDate,
  placeholder,
  label,
  labelDirection,
  wrapperClasses,
  startAllowedDate,
  lastAllowedDate,
  captionLayout,
  dataTestId,
  errors,
  helper,
  size,
  isRtL,
  inputClassName,
  labelClassNames,
  showDateLabel,
  showPostIcon = false,
  disabled
}) => {
  const [showDayPicker, toggle] = useToggle(false);
  const nodeRef = useRef(null);
  const [isPressed] = useKeyPress('Backspace');

  const dayPickerContainerClasses = classNames(
    'absolute z-[20] mb-4 top-2 p-2 rounded-lg bg-white shadow min-w-fit',
    {
      'left-[120px]': labelDirection === 'row' && !isRtL,
      'inset-x-0': labelDirection === 'col' && !isRtL,
      'right-0': isRtL
    }
  );

  const dateLabelClassNames = classNames(
    labelClassNames,
    'flex items-center gap-1 font-medium cursor-pointer',
    {
      'text-mBase md:text-base': !size,
      'text-sm': size === 'sm',
      'text-base': size === 'md',
      'text-mBase': size === 'lg'
    }
  );

  const disabledDays = useMemo(
    () => [
      { before: startAllowedDate || dayjs('1900-01-01').toDate() },
      { after: lastAllowedDate || dayjs('2200-01-01').toDate() }
    ],
    [lastAllowedDate, startAllowedDate]
  );

  const handleDayClick = useCallback(
    (date?: Date) => {
      setSelectedDate(date);
      if (date) {
        setInputValue(dayjs(date).format(DateFormat.MM_DD_YYYY));
      } else {
        setInputValue('');
      }
      toggle(false);
    },
    [setInputValue, setSelectedDate, toggle]
  );

  useClickAway(nodeRef, () => toggle(false));

  useEffect(() => {
    if (isPressed && showDayPicker) {
      setInputValue('');
      toggle(false);
    }
  }, [isPressed, setInputValue, showDayPicker, toggle]);

  useEffect(() => {
    if (inputValue?.length) {
      const date = dayjs(inputValue);
      if (date.isValid()) setSelectedDate(date.toDate());
    }
  }, [inputValue, setSelectedDate]);

  return (
    <div className={wrapperClasses} data-testid={dataTestId} ref={nodeRef}>
      {showDateLabel ? (
        <label className={dateLabelClassNames} onClick={() => toggle()}>
          {inputValue && <Common.Icon name="calendar" className="size-4" />}
          {inputValue || '--'}
          {showPostIcon && <Common.Icon name="pencil" className="size-4" />}
        </label>
      ) : (
        <Common.Input
          label={label}
          labelDirection={labelDirection}
          value={inputValue}
          placeholder={placeholder}
          onClick={() => toggle()}
          preIcon="calendar"
          {...(showPostIcon && { postIcon: 'pencil' })}
          size={size}
          errors={errors}
          helper={helper}
          readOnly
          inputClassName={classNames('cursor-pointer', inputClassName)}
          disabled={disabled}
        />
      )}

      {showDayPicker && (
        <div className="relative">
          <div className={dayPickerContainerClasses}>
            <StyledDatePickerInput
              mode="single"
              className="!mt-2"
              startMonth={startAllowedDate}
              endMonth={lastAllowedDate || dayjs().add(100, 'year').toDate()}
              onDayClick={handleDayClick}
              defaultMonth={selectedDate}
              selected={selectedDate}
              disabled={disabledDays}
              captionLayout={captionLayout}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default DatePickerInput;
