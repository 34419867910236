import { MouseEventHandler } from 'react';

import { Common } from '@thecvlb/design-system/lib/src';
import Loader from 'components/common/Loader';
import FileLabel from 'components/tasks/slidingPane/IdentitySection/FileLabel';
import FileUploader from 'components/tasks/slidingPane/IdentitySection/FileUploader';
import { DocumentSubCategory } from 'enums/patientIdentity';
import {
  useDeleteDocumentMutation,
  useUploadDocumentsMutation
} from 'store/patients/patientsSlice';
import { twMerge } from 'tailwind-merge';

import { IdentityFileCategories, IdentityFileProps } from './identityFile.types';

const IdentityFile: React.FC<IdentityFileProps> = ({
  file,
  type = 'selfPortrait',
  label,
  patientId,
  onOpenGallery,
  onChangeIdentityImg,
  onChangeBodyImg,
  onChangeInsuranceImg,
  category,
  imageClasses,
  sideName,
  insuranceCardData
}) => {
  const [uploadDocuments, { isLoading }] = useUploadDocumentsMutation();
  const [deleteDocument, { isLoading: isDeleting }] = useDeleteDocumentMutation();
  const showLoader = isLoading || isDeleting;
  const fileLabel = type == 'selfPortrait' ? 'Photo:' : 'ID:';
  const isBodyImage = category === IdentityFileCategories.BODY_IMAGE;
  const isIdentityImage = category === IdentityFileCategories.USER_IDENTITY;
  const isInsuranceCard = category === IdentityFileCategories.INSURANCE;
  const shouldUploadDocuments =
    !!(isInsuranceCard && insuranceCardData?.insuranceId) || !isInsuranceCard;

  const onChangeImg = (newFile: File | null) => {
    if (newFile && !showLoader) {
      if (shouldUploadDocuments) {
        const body: FormData = new FormData();
        body.append('patientDocumentFile', newFile);
        body.append('category', category);

        if (category === IdentityFileCategories.USER_IDENTITY) {
          body.append(
            'subCategory',
            type === 'selfPortrait'
              ? DocumentSubCategory.SelfPortrait
              : DocumentSubCategory.GovernmentIssuedId
          );
        }

        if (isInsuranceCard) {
          body.append('insuranceCard', JSON.stringify(insuranceCardData));
        }

        uploadDocuments({ body, patientId: patientId, isBodyImage })
          .unwrap()
          .then((data) => {
            if (data.data) {
              const uploadedFile = {
                _id: data.data._id,
                fileName: data.data.fileName,
                filePath: data.data.filePath,
                side: 0
              };
              if (isBodyImage) {
                onChangeBodyImg?.(uploadedFile, data.data._id);
              } else if (isIdentityImage) {
                onChangeIdentityImg?.({ [type]: uploadedFile });
              } else if (isInsuranceCard) {
                uploadedFile.side = sideName === 'Add front' ? 1 : 2;
                onChangeInsuranceImg?.(uploadedFile, '');
              }
            }
          });
      }
    }
  };

  const handleRemoveFile: MouseEventHandler<HTMLDivElement> = (event) => {
    event.stopPropagation();

    if (file?._id && !showLoader)
      deleteDocument({ documentId: file._id, patientId, isBodyImage })
        .unwrap()
        .then(() => {
          if (isBodyImage) {
            onChangeBodyImg?.(null, file._id);
          } else if (isIdentityImage) {
            onChangeIdentityImg?.({ [type]: null });
          } else if (isInsuranceCard) {
            const deletedFile = {
              _id: '',
              fileName: '',
              filePath: '',
              side: 0
            };

            if (sideName === 'Add front') {
              deletedFile.side = 1;
            } else {
              deletedFile.side = 2;
            }
            onChangeInsuranceImg?.(deletedFile, file._id);
          }
        });
  };

  const imageClassNames = twMerge('w-32 cursor-pointer rounded-xl', imageClasses);
  const fileUploaderWrapperClasses = 'border-2 border-gray-300 py-[22px] w-full px-[45px]';
  const fileUploaderIconClasses = 'text-gray-300';

  return (
    <>
      {showLoader && <Loader isVisible />}
      {file?.filePath ? (
        <div className="flex w-32 flex-col gap-2">
          <div className="relative">
            <img
              className={imageClassNames}
              src={file.filePath}
              onClick={onOpenGallery}
              alt={file.fileName}
            />
            {file?._id && (
              <div onClick={handleRemoveFile}>
                <Common.Icon
                  name="close"
                  className="absolute right-2 top-2 size-6 cursor-pointer rounded-full bg-black/60 p-1 text-white"
                />
              </div>
            )}
          </div>

          {!isBodyImage && !isInsuranceCard && (
            <FileLabel fileData={{ ...file, label: fileLabel }} />
          )}
        </div>
      ) : (
        <FileUploader
          label={label}
          onChange={onChangeImg}
          fileUploaderWrapperClasses={fileUploaderWrapperClasses}
          fileUploaderIconClasses={fileUploaderIconClasses}
          sideName={sideName}
          shouldUploadDocuments={shouldUploadDocuments}
        />
      )}
    </>
  );
};

export default IdentityFile;
