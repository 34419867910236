import { ChangeEvent, PropsWithChildren, useRef } from 'react';

import { notifyError } from 'components/common/Toast/Toast';

import { fileValidator } from './dropContainer.settings';
import { DropContainerProps } from './dropContainer.types';

const DropContainer: React.FC<PropsWithChildren<DropContainerProps>> = ({
  children,
  onChange,
  setDragOverlay,
  className,
  multiple = true
}) => {
  const dragCounter = useRef(0);
  const inputRef = useRef<HTMLInputElement>(null);

  const preventBrowserDefaults = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragIn = (e: React.DragEvent<HTMLDivElement>) => {
    preventBrowserDefaults(e);
    dragCounter.current++;
    if (e.dataTransfer.items.length > 0) {
      setDragOverlay?.(true);
    }
  };
  const handleDragOut = (e: React.DragEvent<HTMLDivElement>) => {
    preventBrowserDefaults(e);
    dragCounter.current--;
    if (dragCounter.current === 0) {
      setDragOverlay?.(false);
    }
  };

  const onChangeFiles = (files: FileList) => {
    setDragOverlay?.(false);
    dragCounter.current = 0;
    const { isValidFile, errVal } = fileValidator(files);
    if (!isValidFile) {
      if (errVal) {
        notifyError(errVal);
      }
      return false;
    }
    onChange(files);
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    if (!e.dataTransfer) {
      return;
    }
    const files = e.dataTransfer.files;
    onChangeFiles(files);
    preventBrowserDefaults(e);
  };

  const onChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return;
    }
    onChangeFiles(e.target.files);
  };
  const uploadInputClassName = 'hidden';

  return (
    <div
      data-testid="drop-container"
      className={className}
      onClick={() => inputRef.current?.click()}
      onDragEnter={handleDragIn}
      onDragLeave={handleDragOut}
      onDragOver={preventBrowserDefaults}
      onDrop={handleDrop}
    >
      <input
        data-testid="drop-input"
        className={uploadInputClassName}
        multiple={multiple}
        ref={inputRef}
        role="input"
        type="file"
        onChange={onChangeInput}
      />
      {children}
    </div>
  );
};

export default DropContainer;
