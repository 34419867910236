import { isRejectedWithValue } from '@reduxjs/toolkit';
import type { Middleware } from '@reduxjs/toolkit';
import { notifyError } from 'components/common/Toast/Toast';
import { calendarApiSlice } from 'store/calendar/calendarSlice';

type Action = {
  payload: { data: { message: string } } | { message: string };
  type: string;
};

const expectedActions = [
  calendarApiSlice.endpoints.editEvent,
  calendarApiSlice.endpoints.createEvent
];

export const rtkQueryErrorLogger: Middleware = () => (next) => (action) => {
  const isExpectedAction = expectedActions.some((expectedAction) =>
    expectedAction.matchRejected(action)
  );

  if (!isExpectedAction && isRejectedWithValue(action)) {
    const actionPayload = action.payload as Action['payload'];
    const errorMessage =
      'data' in actionPayload ? actionPayload.data?.message : actionPayload?.message;
    if (errorMessage) {
      notifyError(errorMessage);
    }
  }

  return next(action);
};
