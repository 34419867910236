import { memo } from 'react';

import Card from 'components/common/Card';
import BasicTable from 'components/tables/BasicTable';

import { ReportTableProps } from './reportTable.types';
import TableSkeleton from './TableSkeleton';

const ReportTable: React.FC<ReportTableProps> = memo(
  ({ title, table, totalCount, isFetching, tableName }) => {
    return (
      <>
        <h2 className="mb-5 font-bold text-black">{title}</h2>
        <Card>
          {isFetching && table.getAllColumns().length === 0 ? (
            <TableSkeleton />
          ) : (
            <BasicTable
              table={table}
              totalCount={totalCount}
              isFetching={isFetching}
              tableName={tableName}
            />
          )}
        </Card>
      </>
    );
  }
);

export default ReportTable;
