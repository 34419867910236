import Loader from 'components/common/Loader';
import dayjs from 'dayjs';
import { DateFormat } from 'enums/dateFormats';
import useOpenTask from 'hooks/tasks/useOpenTask/useOpenTask';
import { useLazyGetTaskDetailsQuery } from 'store/tasks/tasksSlice';

import { getTooltipClasses } from './tooltip.settings';
import { TooltipProps } from './tooltip.types';

const Tooltip: React.FC<TooltipProps> = ({ eventInfo, events, timezone }) => {
  const [getTaskDetails, { isFetching }] = useLazyGetTaskDetailsQuery();
  const { handleOpenTask } = useOpenTask();

  const currentEvent = events.find((e) => e.id === eventInfo?.event?.id);
  const eventStartTime = dayjs(currentEvent?.start).tz(timezone).format(DateFormat.h_mm_a);

  const handleClick = () => {
    if (currentEvent?.status === 'completed') {
      currentEvent?.taskId &&
        getTaskDetails({ taskId: currentEvent?.taskId })
          .unwrap()
          .then((taskDetails) => handleOpenTask({ taskId: taskDetails?.postConsultTaskId }));
    } else {
      handleOpenTask({ taskId: currentEvent?.taskId });
    }
  };

  return (
    <div className={getTooltipClasses(currentEvent)}>
      <Loader isVisible={isFetching} />
      <button onClick={handleClick} className="m-auto flex w-full items-center gap-1">
        <span className="w-fit text-2xs font-medium">{eventStartTime}</span>
        <span className="truncate text-xs font-semibold">{currentEvent?.title}</span>
        {currentEvent?.isActionRequired && (
          <span className="mt-px size-3 rounded-full border-2 border-white bg-red" />
        )}
      </button>
    </div>
  );
};

export default Tooltip;
