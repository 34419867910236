import {
  AppointmentCategoriesValue,
  CategoryFilterAppointmentValues,
  CategoryFilterValues,
  TASK_CATEGORIES_FILTER_POPUP
} from 'constants/taskFilter';

import { TasksFiltersFormProps } from 'components/modals/TasksFilters/tasksFiltersForm.types';
import { Options } from 'components/patient/History/history.types';
import { UseFormSetValue } from 'react-hook-form';

import { Category } from './taskCategories.types';

export const handleChangeTaskTags = (
  setValue: UseFormSetValue<TasksFiltersFormProps>,
  tags: Options[],
  categories: Category[]
) => {
  const name = 'taskTags';

  // Get a list of values that are selected in the category
  const categoryValues = categories.map((category) => category.value);
  const hasAppointment = categoryValues.includes('Appointment');

  /**
   * If we added the “Appointment” category
   * We need to remove all the Appointment tags
   */
  const filteredTags = hasAppointment
    ? tags.filter(
        (tag) => !AppointmentCategoriesValue.includes(tag.value as CategoryFilterAppointmentValues)
      )
    : tags;

  /**
   * Filter tags list and leave only values that do not apply to Appointment tags
   * Or which are related to Appointment tags and are in the list of categories
   * This is necessary to remove from the list of tags those values that we have removed from the list of categories
   */
  const updatedTags = filteredTags.filter((tag) => {
    if (AppointmentCategoriesValue.includes(tag.value as CategoryFilterAppointmentValues)) {
      return categoryValues.includes(tag.value);
    }
    return true;
  });

  /**
   * Next, combine the updated list of tags
   * With a filtered list of categories that includes Appointment tags
   * Therefore, adding new tags that we have selected in the list of categories
   */
  const finalTags = [
    ...updatedTags,
    ...categories
      .filter(
        (category) =>
          AppointmentCategoriesValue.includes(category.value as CategoryFilterAppointmentValues) &&
          !updatedTags.some((tag) => tag.value === category.value)
      )
      .map((category) => ({ label: category.label, value: category.value }))
  ];

  setValue(name, finalTags);
};

export const handleUpdateCategory = (
  setValue: UseFormSetValue<TasksFiltersFormProps>,
  handleCategoryChange: (category: Category) => void,
  currentCategory: Category[],
  selectedCategory: string | string[]
) => {
  const name = 'category';
  const updatedSelectedCategories =
    typeof selectedCategory === 'string' ? [selectedCategory] : selectedCategory;

  const appointmentTagsInSelectedCategory = updatedSelectedCategories.filter((item) =>
    AppointmentCategoriesValue.includes(item as CategoryFilterAppointmentValues)
  );
  const appointmentTagsInCurrentCategory = currentCategory
    .map((item) => item.value)
    .filter((item) => AppointmentCategoriesValue.includes(item as CategoryFilterAppointmentValues));

  const isSelectedAppointment = selectedCategory.includes(CategoryFilterValues.Appointment);

  /**
   * If the list is empty, we previously pass the 'all' category
   * If 'all' was selected, need to select this category using handleCategoryChange function
   */
  if (updatedSelectedCategories.includes('all')) {
    return handleCategoryChange(TASK_CATEGORIES_FILTER_POPUP[0]);
  }

  /**
   * This part is only required for the first load, for correct recording of all categories
   */
  if (
    (!selectedCategory || selectedCategory.length === 0 || isSelectedAppointment) &&
    appointmentTagsInCurrentCategory.length > 0
  ) {
    const newCategoryList = updatedSelectedCategories.filter((item) => item !== 'Appointment');
    return setValue(name, [...newCategoryList, ...appointmentTagsInCurrentCategory]);
  }

  /**
   * This part is responsibility for selecting the category after adding them in the 'Task Tags' select input.
   * Finding the difference between lists
   */
  const difference = [
    ...appointmentTagsInSelectedCategory.filter(
      (item) => !appointmentTagsInCurrentCategory.includes(item)
    ),
    ...appointmentTagsInCurrentCategory.filter(
      (item) => !appointmentTagsInSelectedCategory.includes(item)
    )
  ];

  /**
   * Find the appropriate value in the TASK_CATEGORIES_FILTER_POPUP list
   * And call the function to change the category
   */
  difference.map((item) => {
    const category = TASK_CATEGORIES_FILTER_POPUP.find((category) => category.value === item);
    if (category) {
      handleCategoryChange(category);
    }
  });
};
