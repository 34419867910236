import { createSlice } from '@reduxjs/toolkit';
import queryString from 'query-string';
import type { RootState } from 'store';
import { apiSlice } from 'store/api/apiSlice';

import { initialState } from './calendar.settings';
import {
  DayStaffSchedule,
  EventDetailsProps,
  GetCombineStaffScheduleItem,
  GetStaffScheduleProps,
  ShiftTypesResponseProps,
  StaffScheduleReqProps
} from './calendar.types';

export const calendarApiSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getShiftTypes: build.query<ShiftTypesResponseProps[], void>({
      query: () => ({
        url: '/shift-types'
      }),
      transformResponse: (response: { data: ShiftTypesResponseProps[] }) => response.data
    }),
    getStaffSchedule: build.query<
      GetStaffScheduleProps,
      { userId: string; fromDate: string; toDate: string; timezone: string }
    >({
      query: ({ userId, fromDate, toDate, timezone }) => ({
        url: `/staffs/${userId}/schedule`,
        params: {
          fromDate,
          toDate,
          timezone
        }
      }),
      providesTags: (result) =>
        result
          ? ['Event', ...result.data.map(({ _id }) => ({ type: 'Event' as const, id: _id }))]
          : ['Event']
    }),
    createEvent: build.mutation<
      { data: EventDetailsProps; message: string },
      Partial<EventDetailsProps>
    >({
      query: (body) => ({
        url: '/events',
        method: 'POST',
        body: body
      }),
      transformResponse: (response: { data: EventDetailsProps; message: string }) => response,
      invalidatesTags: ['Event']
    }),
    getCombineStaffSchedule: build.query<GetCombineStaffScheduleItem[], StaffScheduleReqProps>({
      query: ({ params }) => {
        const paramsString = queryString.stringify(params, { arrayFormat: 'bracket' });
        return { url: `/combined-staff-schedule?${paramsString}` };
      },
      transformResponse: (response: { data: GetCombineStaffScheduleItem[] }) => response.data
    }),
    getDayStaffSchedule: build.query<DayStaffSchedule[], StaffScheduleReqProps>({
      query: ({ params }) => {
        const paramsString = queryString.stringify(params, { arrayFormat: 'bracket' });
        return { url: `/staff-schedules?${paramsString}` };
      },
      transformResponse: (response: { data: DayStaffSchedule[] }) => response.data
    }),
    getEvent: build.query<EventDetailsProps, { eventId: string }>({
      query: ({ eventId }) => ({
        url: `/events/${eventId}`
      }),
      transformResponse: (response: { data: EventDetailsProps }) => response.data,
      keepUnusedDataFor: 0
    }),
    deleteEvent: build.mutation<string, { eventId: string }>({
      query: ({ eventId }) => ({
        url: `/events/${eventId}`,
        method: 'DELETE'
      }),
      transformResponse: (response: { message: string }) => response.message,
      invalidatesTags: ['Event']
    }),
    editEvent: build.mutation<
      { data: Partial<EventDetailsProps>; message: string },
      { eventId: string; body: Partial<EventDetailsProps> }
    >({
      query: ({ eventId, body }) => ({
        url: `/events/${eventId}`,
        method: 'PATCH',
        body: body
      }),
      transformResponse: (response: { data: Partial<EventDetailsProps>; message: string }) => ({
        data: response.data,
        message: response.message
      }),
      invalidatesTags: ['Event']
    }),
    validateEvent: build.mutation<
      { message: string },
      { eventId: string; body: Partial<EventDetailsProps> }
    >({
      query: ({ eventId, body }) => ({
        url: `/events/${eventId}/validate`,
        method: 'POST',
        body: body
      }),
      transformResponse: (response: { message: string }) => ({ message: response.message })
    })
  })
});

export const {
  useGetShiftTypesQuery,
  useLazyGetStaffScheduleQuery,
  useCreateEventMutation,
  useLazyGetCombineStaffScheduleQuery,
  useLazyGetDayStaffScheduleQuery,
  useLazyGetEventQuery,
  useGetEventQuery,
  useDeleteEventMutation,
  useEditEventMutation,
  useValidateEventMutation
} = calendarApiSlice;

const calendarSlice = createSlice({
  name: 'calendar',
  initialState,
  reducers: {
    setEventId: (state, action) => {
      state.eventId = action.payload;
    },
    setEventInstanceId: (state, action) => {
      state.eventInstanceId = action.payload;
    },
    setCalendarTimezone: (state, action) => {
      state.selectedTimezone = action.payload;
    },
    resetCalendarState: () => initialState
  }
});

export const { setEventId, setEventInstanceId, setCalendarTimezone, resetCalendarState } =
  calendarSlice.actions;

export const selectEventId = (state: RootState) => state.calendar.eventId;

export const selectEventInstanceId = (state: RootState) => state.calendar.eventInstanceId;

export const selectCalendarTimezone = (state: RootState) => state.calendar.selectedTimezone;

export default calendarSlice.reducer;
