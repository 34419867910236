import { Tab, TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/react';
import { createSelector } from '@reduxjs/toolkit';
import classNames from 'classnames';
import { useAppSelector } from 'hooks/redux';
import { EventType, EventTypes } from 'models/event.types';
import { useParams } from 'react-router-dom';
import { selectEventId } from 'store/calendar/calendarSlice';
import { selectUser } from 'store/user/userSlice';

import AddShift from './AddShift';
import AddTimeOff from './AddTimeOff';

const selectManageAvailabilityState = createSelector(
  [selectUser, selectEventId],
  (user, eventId) => ({
    id: user._id,
    userType: user.userType,
    eventId
  })
);

const ManageAvailability: React.FC<{ defaultTab: EventType }> = ({ defaultTab }) => {
  const { id: physicianId = '' } = useParams<{ id: string }>();
  const { id, userType, eventId } = useAppSelector(selectManageAvailabilityState);

  const defaultIndex = defaultTab === EventTypes.SHIFT ? 1 : 0;
  const isTimeOff = defaultTab === EventTypes.TIME_OFF;

  /**
   * @var userId
   * @description
   * If event is created for staff, I get the staff id from the URL
   * else I get the id from the current user
   */
  const userId = physicianId ? physicianId : id;

  const getTabClassNames = (active: boolean) =>
    classNames('text-gray text-sm font-semibold py-2 px-4', {
      'text-secondary bg-secondary-100 rounded-full': active
    });

  return (
    <div data-testid="manage_availability_popup" className="p-6">
      <TabGroup defaultIndex={defaultIndex}>
        <TabList className="flex w-full grid-flow-col items-center px-6">
          <h2 className="w-1/2 self-end text-xl font-bold">
            {eventId ? (isTimeOff ? 'Edit time off' : 'Edit shift') : 'Add'}
          </h2>

          {!eventId && (
            <div className="w-1/2 justify-start">
              <Tab className={({ selected }) => getTabClassNames(selected)}>Time off</Tab>
              {/* Only Admin can create shifts */}
              {userType.shortCode === 'AD' && (
                <Tab className={({ selected }) => getTabClassNames(selected)}>Shift</Tab>
              )}
            </div>
          )}
        </TabList>

        <TabPanels>
          <TabPanel>
            <AddTimeOff userId={userId} />
          </TabPanel>
          {userType.shortCode === 'AD' && (
            <TabPanel>
              <AddShift userId={userId} />
            </TabPanel>
          )}
        </TabPanels>
      </TabGroup>
    </div>
  );
};

export default ManageAvailability;
