import { TASK_CATEGORIES } from 'constants/tasks';

import DateRangePickerInput from 'components/common/DateRangePickerInput/DateRangePickerInput';
import AutocompleteMultiSelect from 'components/forms/controlled/AutocompleteMultiSelect';
import ControlledSelect from 'components/forms/controlled/ControlledSelect';
import Footer from 'components/modals/Footer';
import { Options } from 'components/patient/History/history.types';
import dayjs from 'dayjs';
import { useTableFiltersData } from 'hooks/filters/useTableFiltersData';
import { useAppDispatch } from 'hooks/redux';
import isEqual from 'lodash/isEqual';
import queryString from 'query-string';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { useLazyGetUsersQuery } from 'store/lookup/lookupSlice';
import { closeModal } from 'store/modal/modalSlice';
import { getAppliedFilterValues, handleReset } from 'utils/filters/filters';

import { defaultValues, URGENCY_TASK_FILTER } from './NotificationsFiltersForm.settings';
import { NotificationsFiltersFormProps } from './NotificationsFiltersForm.types';

const NotificationsFiltersForm = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { handleSubmit, control, reset, watch, setValue, getValues } =
    useForm<NotificationsFiltersFormProps>();
  const [formData, setFormData] = useTableFiltersData('activityFeed', defaultValues, reset);
  const location = useLocation();

  const [getPatientList, { data: patientList }] = useLazyGetUsersQuery();
  const getValue = (data: Options | Options[]) => {
    if (Array.isArray(data)) {
      return data?.length && data?.map((item) => item?.value);
    }
    return data?.value;
  };
  const getListPatientsName = () => {
    const listPatientsName = patientList?.length
      ? patientList.map((item) => {
          return { label: item?.name as string, value: item?.name as string };
        })
      : [];
    return Array.from(new Set(listPatientsName.map((item) => JSON.stringify(item)))).map((item) =>
      JSON.parse(item)
    );
  };

  const onSubmit = (data: NotificationsFiltersFormProps) => {
    const parsedUrl = queryString.parse(location.search);
    setFormData({ ...defaultValues, ...data });

    const createdDateParams = {
      createdAt:
        data?.startCreateDate && !data?.endCreateDate
          ? data?.startCreateDate
          : data?.createdAt && !data?.endCreateDate
            ? data?.createdAt
            : '',
      startCreateDate: data?.startCreateDate && data?.endCreateDate ? data?.startCreateDate : '',
      endCreateDate: data?.endCreateDate
    };

    const filters = {
      ...parsedUrl,
      pageNo: '0',
      ...createdDateParams,
      category: data?.category.value,
      patientNames: getValue(data?.patientNames as []),
      urgencyTask: data?.urgencyTask?.value
    };

    const appliedFilterValues = getAppliedFilterValues(filters);

    if (!isEqual(appliedFilterValues, parsedUrl)) {
      navigate({ search: queryString.stringify(appliedFilterValues) });
    }
    dispatch(closeModal());
  };

  watch();

  const createdAtFromValue = getValues('startCreateDate')
    ? getValues('startCreateDate')
    : getValues('createdAt')
      ? getValues('createdAt')
      : '';

  return (
    <form className="w-[410px]" onSubmit={handleSubmit(onSubmit)}>
      <div className="flex flex-col gap-4">
        <ControlledSelect
          dataTestId="category_input"
          control={control}
          labelDirection="col"
          options={TASK_CATEGORIES}
          placeholder="Select category"
          label="Category"
          name="category"
          defaultValue={formData?.category}
        />
        <AutocompleteMultiSelect
          control={control}
          label="Name"
          name="patientNames"
          placeholder="Enter patient"
          labelDirection="col"
          value={formData?.patientNames}
          options={getListPatientsName()}
          getOptionsFunc={(value) => {
            return (
              value &&
              getPatientList({ userType: 'Patient', limit: 20, pageNo: 0, searchKey: value ?? '' })
            );
          }}
        />
        <DateRangePickerInput
          labelDirection="col"
          label="Date"
          placeholder={'Select a date range'}
          fromValue={createdAtFromValue}
          setFromValue={(value) => setValue('startCreateDate', value.toString())}
          toValue={getValues('endCreateDate') ? getValues('endCreateDate') : ''}
          setToValue={(value) => setValue('endCreateDate', value.toString())}
          startAllowedDate={dayjs().subtract(100, 'year').toDate()}
          lastAllowedDate={new Date()}
          size="sm"
          wrapperClasses="w-full relative"
        />
        <ControlledSelect
          dataTestId="urgent_or_not_input"
          control={control}
          labelDirection="col"
          options={URGENCY_TASK_FILTER}
          placeholder="Urgent or not"
          label="Urgency"
          name="urgencyTask"
          defaultValue={formData?.urgencyTask}
        />
      </div>
      <Footer onClick={() => handleReset(dispatch, reset, defaultValues, navigate)} />
    </form>
  );
};

export default NotificationsFiltersForm;
