import { Common } from '@thecvlb/design-system';
import { toast } from 'react-toastify';

export const notifyError = (message: string) =>
  toast.error(message, {
    closeOnClick: true,
    icon: <Common.Icon name="error" className="size-9 text-red" />,
    progressStyle: {
      backgroundColor: '#f2355b'
    },
    toastId: message
  });

export const notifySuccess = (message: string) =>
  toast.success(message, {
    closeOnClick: true,
    icon: <Common.Icon name="check" color="text-secondary" />,
    progressStyle: {
      backgroundColor: '#64ccc9'
    },
    toastId: message
  });
