import { QueueTaskDetails } from './queueSlice.types';

export function normelizeQueueTasks(tasks: QueueTaskDetails[]) {
  return tasks.map((task) => ({
    category: task.category,
    patientName: task.patientName,
    createdAt: task.createdAt,
    assignedTo: 'Unassigned',
    doctor: 'Unassigned',
    details: task.subCategory,
    patientStatus: task.patientStatus,
    dynamicStatus: task.dynamicStatus,
    status: 'New',
    _id: task._id
  }));
}
