import { ShiftType, ShiftTypes } from 'enums/event';
import unionBy from 'lodash/unionBy';
import { EventMultiSelectValue, EventType, EventTypes } from 'models/event.types';
import { ShiftTypesResponseProps } from 'store/calendar/calendar.types';

const processCategories = (eventTypeLabel: string, categories: EventMultiSelectValue[]) => {
  return categories?.length
    ? categories.reduce((acc: string[], filter: EventMultiSelectValue) => {
        //TODO: I think this will only work as long as we only have two types. For the future, we need to change this logic.
        if (eventTypeLabel !== 'Specialized' && filter.category === 'specialized') {
          return acc;
        }
        return [...acc, filter.value];
      }, [])
    : [];
};

const getEventTitle = (eventType: EventType, eventTypes: ShiftTypesResponseProps[]) => {
  if (eventType === EventTypes.BREAK) {
    return 'Break';
  }
  if (eventType === EventTypes.TIME_OFF) {
    return 'Day off';
  }
  if (eventTypes?.length === 1) {
    return eventTypes[0].title;
  } else if (eventTypes?.length === 2) {
    const desiredOrder: ShiftTypes[] = [ShiftType.PRIMARY_CARE, ShiftType.SPECIALIZED];

    // Set containing desired titles (for efficient lookup)
    const desiredTitles = new Set(desiredOrder);

    // Extract titles in desired order
    const titles = eventTypes
      .filter((event) => desiredTitles.has(event.title as ShiftTypes)) // Keep only desired titles
      .map((event) => event.title) // Extract titles
      .sort(
        (a, b) => desiredOrder.indexOf(a as ShiftTypes) - desiredOrder.indexOf(b as ShiftTypes)
      ); // Sort based on desired order

    // Join the titles with separator
    return titles.join(' & ');
  } else if (eventTypes?.length > 2) {
    return 'All types';
  } else {
    return '';
  }
};

const getEventsFiltersTitle = (eventTypes: ShiftTypesResponseProps[]) => {
  const combinedFilters = unionBy(
    eventTypes.flatMap((obj) => obj.filters),
    '_id'
  );

  return combinedFilters.map((filter) => filter?.title).join(' • ');
};

const getExclusionsTitleList = (eventTypes: ShiftTypesResponseProps[]) => {
  const combinedExclusions = unionBy(
    eventTypes.flatMap((obj) => obj.exclusions),
    '_id'
  );

  return combinedExclusions.map((filter) =>
    filter?._id === 'hrt' ? 'HRT' : filter?.title.toLowerCase()
  );
};

// TODO: Add tests for this function
const getEventColor = (
  eventType: EventType,
  title?: string
): {
  border: string;
  background: string;
} => {
  if (eventType === EventTypes.BREAK || eventType === EventTypes.TIME_OFF) {
    return {
      border: 'border-gray-600',
      background: 'bg-gray-100'
    };
  }

  if (title === ShiftType.PRIMARY_CARE) {
    return {
      border: 'border-yellow-500',
      background: 'bg-yellow-100'
    };
  } else if (title === ShiftType.SPECIALIZED) {
    return {
      border: 'border-violet-500',
      background: 'bg-violet-100'
    };
  } else if (
    title === ShiftType.ALL_TYPES ||
    title === ShiftType.ALL_TYPES_AVAILABLE_TO_BOOK ||
    title === ShiftType.ALL_TYPES_UNAVAILABLE_TO_BOOK ||
    title === ShiftType.PRIMARY_CARE_AND_SPECIALIZED
  ) {
    return {
      border: 'border-warmGray-500',
      background: 'bg-warmGray-200'
    };
  } else if (title === ShiftType.FLOATER) {
    return {
      border: 'border-secondary-700',
      background: 'bg-secondary-50'
    };
  }
  return {
    border: 'border-secondary',
    background: 'bg-secondary-100'
  };
};

const getEventTypeBadge = (eventTypes: ShiftTypesResponseProps[]) => {
  const result = eventTypes.map((type) => {
    switch (type.name) {
      case 'appointments':
        return { title: 'Primary care', bgColor: 'bg-yellow-100', textColor: 'text-yellow-700' };
      case 'specialized':
        return { title: 'Specialized', bgColor: 'bg-violet-100', textColor: 'text-violet-700' };
      case 'break':
        return { title: 'Break', bgColor: 'bg-gray-100', textColor: 'text-gray-700' };
      case 'time-off':
        return { title: 'Day off', bgColor: 'bg-gray-100', textColor: 'text-gray-700' };
      case 'floater':
        return { title: 'Floater', bgColor: 'bg-secondary-100', textColor: 'text-secondary-700' };
    }
  });

  return result;
};

export {
  processCategories,
  getEventColor,
  getEventTitle,
  getEventsFiltersTitle,
  getExclusionsTitleList,
  getEventTypeBadge
};
