import { Common } from '@thecvlb/design-system/lib/src';
import { notifyError } from 'components/common/Toast/Toast';
import { useAppDispatch } from 'hooks/redux';
import { Controller, useForm } from 'react-hook-form';
import { closeModal } from 'store/modal/modalSlice';
import { useReleaseOrderFromHoldMutation } from 'store/orders/ordersSlice';

import {
  ReleaseOrderFromHoldFormData,
  ReleaseOrderFromHoldProps
} from './releaseOrderFromHold.types';

const ReleaseOrderFromHold: React.FC<ReleaseOrderFromHoldProps> = ({ orderId }) => {
  const dispatch = useAppDispatch();
  const [releaseOrderFromHold, { isLoading }] = useReleaseOrderFromHoldMutation();
  const { handleSubmit, control } = useForm<ReleaseOrderFromHoldFormData>({
    reValidateMode: 'onChange',
    criteriaMode: 'all',
    mode: 'onChange'
  });

  const handleReleaseFromHold = (data: ReleaseOrderFromHoldFormData) => {
    releaseOrderFromHold({ orderId, shouldExpedite: data.expediteShipment })
      .unwrap()
      .then(() => dispatch(closeModal()))
      .catch((errorData: { data: { errorMessage: string } }) => {
        notifyError(errorData?.data?.errorMessage);
      });
  };

  return (
    <form className="p-6" onSubmit={handleSubmit(handleReleaseFromHold)}>
      <h2 className="mb-2 text-xl font-bold text-gray-700">Release from hold</h2>
      <p className="mb-4 text-base font-medium text-gray-700">
        If needed, you can also select to expedite the order by checking the box below. Please only
        do so when necessary.
      </p>

      <Controller
        control={control}
        name="expediteShipment"
        render={({ field }) => (
          <Common.Checkbox
            {...field}
            value={field.value?.toString()}
            checked={field.value || false}
            onChange={field.onChange}
            color="blue"
            size="md"
          >
            Expedite shipment
          </Common.Checkbox>
        )}
      />

      <div className="mt-6 flex justify-end gap-2">
        <Common.Button
          type="button"
          color="white-alt"
          onClick={() => dispatch(closeModal())}
          size="sm"
        >
          Cancel
        </Common.Button>
        <Common.Button type="submit" color="blue" size="sm" isLoading={isLoading}>
          Confirm
        </Common.Button>
      </div>
    </form>
  );
};

export default ReleaseOrderFromHold;
