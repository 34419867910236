import { PatientProps } from 'store/patients/patients.types';

type ParametersData = Pick<PatientProps, 'flowQueryParams' | 'flowUrl' | 'referrerUrl'>;

export const getParametersData = ({ flowQueryParams, flowUrl, referrerUrl }: ParametersData) => {
  const queryParams = Object.entries(flowQueryParams || {});

  const parametersData =
    flowUrl || referrerUrl
      ? [
          { key: 'Flow', value: flowUrl || '-' },
          { key: 'Source', value: referrerUrl || '-' },
          ...queryParams.map(([key, value]) => ({ key, value }))
        ]
      : [];

  return parametersData;
};
