import { CATEGORY_OPTIONS, LENGTH_OPTIONS } from 'constants/appointmentType';
import { STATUS_OPTIONS } from 'constants/status';

import { AppointmentTypesFiltersFormProps } from './appointmentTypesFiltersForm.types';

export const defaultValues: AppointmentTypesFiltersFormProps = {
  displayName: '',
  searchKey: '',
  length: { value: '', label: '' },
  uniqueId: '',
  category: { value: '', label: '' },
  status: { value: '', label: '' }
};

export const getAppointmentTypesFiltersValue = (key: string, val: string) => {
  let currentValue;
  switch (key) {
    case 'length':
      currentValue = LENGTH_OPTIONS.find((item) => item.value === val);
      break;
    case 'category':
      currentValue = CATEGORY_OPTIONS.find((item) => item.value === val);
      break;
    case 'status':
      currentValue = STATUS_OPTIONS.find((item) => item.value === val);
      break;
    default:
      currentValue = val;
  }
  return currentValue;
};
