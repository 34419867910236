import { PathName } from 'enums/pathName';
import { NavigateFunction } from 'react-router-dom';

export const onClickPatientRow = (
  originalRow: { [key: string]: unknown },
  navigate: NavigateFunction
) => {
  if (originalRow?._id) {
    navigate(`${PathName.Patient}/${originalRow?._id}`);
  }
};
