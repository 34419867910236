import { useMemo } from 'react';

import ControlledSelect from 'components/forms/controlled/ControlledSelect';
import { useAppSelector } from 'hooks/redux';
import { useForm } from 'react-hook-form';
import { useGetTimeZoneQuery } from 'store/lookup/lookupSlice';
import { selectUser } from 'store/user/userSlice';
import { validation } from 'utils/helpers';

import { FormValues } from '../updateTimezone.types';

const UpdateTimezoneForm: React.FC<{
  isLoading: boolean;
  saveData: (data: FormValues) => void;
  close: () => void;
  staffTimezone?: string;
}> = ({ isLoading, staffTimezone, saveData, close }) => {
  const { timezone } = useAppSelector(selectUser);
  const { data: timeZoneData, isLoading: isLoadingTimezoneData } = useGetTimeZoneQuery();

  const defaultTimezone = useMemo(
    () =>
      timeZoneData?.find((item) => {
        if (staffTimezone) {
          return item.value === staffTimezone;
        }
        return item.value === timezone;
      }),
    [timezone, timeZoneData, staffTimezone]
  );

  const isDisabled = isLoading || isLoadingTimezoneData;

  const { control, handleSubmit } = useForm<FormValues>({
    defaultValues: {
      timezone: { label: '', value: '' }
    },
    values: { timezone: defaultTimezone ?? { label: '', value: '' } }
  });

  const onSubmit = (formData: FormValues) => {
    saveData(formData);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <h3 className="mb-6 text-xl font-bold text-gray-700">Update timezone</h3>
      <ControlledSelect
        control={control}
        options={timeZoneData}
        defaultValue={defaultTimezone}
        placeholder="Select timezone..."
        name="timezone"
        rules={validation('TimeZone')}
        className="col-span-2"
        disabled={isDisabled}
      />
      <div className="mt-6 flex items-center gap-2">
        <button
          className="w-full rounded-lg bg-gray-800/5 px-4 py-2 text-sm font-bold text-gray-700 hover:bg-gray-800/10 disabled:cursor-not-allowed disabled:bg-gray-800/20 disabled:text-gray-200"
          type="button"
          onClick={() => close()}
          disabled={isLoading}
        >
          Cancel
        </button>

        <button
          className="w-full rounded-lg bg-primary px-4 py-2 text-sm font-bold text-white hover:bg-primary/75 disabled:cursor-not-allowed disabled:bg-primary/50"
          type="submit"
          disabled={isLoading}
        >
          Save
        </button>
      </div>
    </form>
  );
};

export default UpdateTimezoneForm;
