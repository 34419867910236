import React, { ChangeEvent, FC, forwardRef } from 'react';

import classNames from 'classnames';
import { useToggle } from 'react-use';

import { TextAreaProps } from './textArea.types';

export const TextArea: FC<TextAreaProps> = forwardRef<HTMLTextAreaElement, TextAreaProps>(
  (
    {
      name,
      label,
      disabled,
      id,
      helper,
      errors,
      onChange,
      value,
      cols,
      rows,
      className,
      dataTestId
    },
    ref
  ) => {
    const [focus, toggleFocus] = useToggle(false);

    const onFocus = (event: ChangeEvent<HTMLTextAreaElement>) => {
      toggleFocus();
      if (event.target.value.length) {
        setTimeout(() => event.target.select(), 50);
      }
    };

    const wrapperClassName = classNames('relative flex flex-col gap-1.5 sm: gap-1', className);
    const labelClassName = classNames(
      'absolute h-fit transition-all ease-in-out duration-300 left-4 sm:left-3 pointer-events-none',
      focus || value
        ? 'text-xs top-[6px] my-0 sm:top-[2px]'
        : 'sm:text-base top-[15px] sm:top-[12px]',
      !!errors && !disabled
        ? 'text-red'
        : focus
          ? 'text-primary'
          : value && !disabled
            ? 'text-secondary'
            : 'text-gray'
    );
    const textareaWrapperClassName = classNames(
      'relative w-full after:absolute after:inset-x-0 after:bottom-0 after:h-[2px] after:rounded-b-sm after:transition-all after:ease-in-out after:duration-300',
      !!errors && !disabled
        ? 'after:bg-red'
        : focus
          ? 'after:bg-primary'
          : value && !disabled
            ? 'after:bg-secondary'
            : 'after:bg-gray'
    );
    const textareaClassName =
      'block outline-none resize-none border w-full rounded-t-md rounded-b-sm border-gray-200 outline-0 shadow-sm pb-3.5 pt-4 px-4 text-gray-700 focus:border-gray-200 disabled:bg-gray-100 disabled:shadow-none sm:text-base sm:pb-2.5 sm:pt-3 sm:px-3';
    const helperClassName = classNames(
      'text-xs',
      !!errors && !disabled
        ? 'text-red'
        : focus
          ? 'text-primary'
          : value && !disabled
            ? 'text-secondary'
            : 'text-gray'
    );

    return (
      <div className={wrapperClassName}>
        <div className={textareaWrapperClassName}>
          <label className={labelClassName} htmlFor={id}>
            {label}
          </label>
          <textarea
            autoComplete="off"
            className={textareaClassName}
            cols={cols}
            data-testid={dataTestId}
            disabled={disabled}
            id={id}
            name={name}
            ref={ref}
            rows={rows}
            value={value}
            onBlur={toggleFocus}
            onChange={onChange}
            onFocus={onFocus}
          />
        </div>
        {helper && <span className={helperClassName}>{helper}</span>}
      </div>
    );
  }
);

export default React.memo(TextArea);
