import { TaskCategories, TaskTags } from 'enums/taskCategories';
import { TaskType } from 'models/tasks.types';

const getWMAdditionalTag = (tags?: TaskTags[], showShowOnlyTTTag?: boolean) => {
  const isTripleTherapy = tags && tags.includes(TaskTags.TripleTherapy);

  if (showShowOnlyTTTag) {
    return `• ${isTripleTherapy ? 'TT ' : ''}`;
  }

  return `• ${isTripleTherapy ? 'TT' : 'GLP-1'}`;
};

export const getTaskCategoryText = (
  type: TaskType | string,
  appointmentStatus?: string,
  isSentRequests?: boolean,
  tags?: TaskTags[]
): string => {
  if (isSentRequests) return 'Sent request';

  if (tags) {
    if (tags?.includes(TaskTags.MedicallyInsured)) {
      const tagsWithoutMedicallyInsured = tags.filter((tag) => tag !== TaskTags.MedicallyInsured);
      const categoryText = `${getTaskCategoryText(
        type,
        appointmentStatus,
        isSentRequests,
        tagsWithoutMedicallyInsured
      )} • MI`;

      return categoryText;
    }

    if (tags?.includes(TaskTags.CheckInFollowUp)) return `${type} • Renewal follow-up`;
    if (tags?.includes(TaskTags.PriorAuthorization)) return `${type} • PA`;

    if (tags?.includes(TaskTags.PAReview)) return `${type} • PA`;
    if (tags?.includes(TaskTags.Documents)) return `${type} • Documents`;
    if (tags?.includes(TaskTags.LabResults)) return `${type} • Results`;
    if (tags?.includes(TaskTags.Misc)) return `${type} • Misc`;
    if (tags?.includes(TaskTags.Symptoms)) return `${type} • Symptoms`;

    if (tags?.includes(TaskTags.Nutrition)) return `${type} • Nutrition`;
    if (tags?.includes(TaskTags.Sweat)) return `${type} • Fitness`;
    if (tags?.includes(TaskTags.Sleep)) return `${type} • Sleep`;
    if (tags?.includes(TaskTags.Stress)) return `${type} • Stress`;
    if (tags?.includes(TaskTags.Stillness)) return `${type} • Stillness`;
    if (tags?.includes(TaskTags.Social)) return `${type} • Social`;

    if (
      tags?.includes(TaskTags.WM) &&
      type !== TaskCategories.Renewal.toLowerCase() &&
      type !== TaskCategories.Async.toLowerCase()
    ) {
      if (tags?.includes(TaskTags.FirstAppointment)) return `${type} ${getWMAdditionalTag(tags)}`;
      if (tags?.includes(TaskTags.Renewal))
        return `${type} ${getWMAdditionalTag(tags, true)}Renewal`;
      if (tags?.includes(TaskTags.TripleTherapy)) return `${type} ${getWMAdditionalTag(tags)}`;
    }
    if (tags?.includes(TaskTags.AsyncCancelAccount)) return `${type} • Downgrade`;
    if (tags?.includes(TaskTags.WM) && type === TaskCategories.PreConsult.toLowerCase())
      return `Pre-consult ${getWMAdditionalTag(tags)}`;
  }
  if (appointmentStatus) return appointmentStatus;
  if (type === TaskCategories.Renewal.toLowerCase()) return `Renewal ${getWMAdditionalTag(tags)}`;

  return type;
};
