import { useEffect, useState } from 'react';

import { OptionProps } from '@thecvlb/design-system/lib/src/common';
import Loader from 'components/common/Loader';
import { notifySuccess } from 'components/common/Toast/Toast';
import MessageTemplatesForm from 'components/modals/components/MessageTemplatesForm';
import {
  FormDataTemplates,
  MessageTemplatesProps,
  TemplateTypes
} from 'components/modals/components/MessageTemplatesForm/messageTemplatesForm.types';
import startCase from 'lodash/startCase';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import {
  useCreateInternalNoteTemplatesMutation,
  useDeleteInternalNoteTemplatesMutation,
  useLazyGetInternalNoteTemplateByIdQuery,
  useUpdateInternalNoteTemplatesMutation
} from 'store/internalNoteTemplates/internalNoteTemplatesSlice';
import { useGetVariablesAndButtonsQuery } from 'store/lookup/lookupSlice';
import { closeModal } from 'store/modal/modalSlice';

const InternalNoteTemplatesModal: React.FC<MessageTemplatesProps> = ({ id }) => {
  const dispatch = useDispatch();
  const [getTemplateData, { data: singleTemplateData, isLoading }] =
    useLazyGetInternalNoteTemplateByIdQuery();
  const { data: variablesAndButtons } = useGetVariablesAndButtonsQuery({ type: 'Staff note' });
  const [updateTemplate, { isLoading: loadingEdit }] = useUpdateInternalNoteTemplatesMutation();
  const [deleteTemplate, { isLoading: loadingDelete }] = useDeleteInternalNoteTemplatesMutation();
  const [postMessageTemplate, { isLoading: loadingCreate }] =
    useCreateInternalNoteTemplatesMutation();
  const [variableOptions, setVariableOptions] = useState<OptionProps[]>([]);
  const { control, formState, handleSubmit, reset, getValues, setValue } =
    useForm<FormDataTemplates>({
      reValidateMode: 'onChange',
      shouldFocusError: false
    });

  const isDisabled = isLoading || loadingCreate || loadingEdit || loadingDelete;

  const onSuccess = (message: string) => {
    notifySuccess(message);
    dispatch(closeModal());
  };

  const onFormSubmit = (formData: FormDataTemplates) => {
    if (formData) {
      const body = {
        message: formData.message,
        shortCode: formData.shortCode,
        status: formData?.status?.value ?? 'inactive'
      };
      const request = id ? updateTemplate({ id, body }) : postMessageTemplate({ body });
      request.unwrap().then((res) => onSuccess(res.message));
    }
  };

  const onDeleteTemplate = () => {
    if (id) {
      deleteTemplate({ id })
        .unwrap()
        .then((res) => onSuccess(res.message));
    }
  };

  useEffect(() => {
    if (variablesAndButtons) {
      const newVariables = variablesAndButtons.variables.map((variable) => ({
        label: variable.label,
        value: variable.variable
      }));

      setVariableOptions(newVariables);
    }
  }, [variablesAndButtons]);

  useEffect(() => {
    if (singleTemplateData) {
      reset({
        message: singleTemplateData.message,
        shortCode: singleTemplateData.shortCode,
        status: { label: startCase(singleTemplateData.status), value: singleTemplateData.status }
      });
    }
  }, [reset, singleTemplateData]);

  useEffect(() => {
    if (id) {
      getTemplateData({ id });
    }
  }, [getTemplateData, id]);

  return (
    <>
      <Loader isVisible={isLoading} />

      <MessageTemplatesForm
        type={TemplateTypes.internal}
        popupHeaderTitle="Internal note template"
        onDeleteTemplate={onDeleteTemplate}
        onFormSubmit={onFormSubmit}
        handleSubmit={handleSubmit}
        formState={formState}
        getValues={getValues}
        setValue={setValue}
        control={control}
        variableOptions={variableOptions}
        disabledButtons={isDisabled}
        id={id}
      />
    </>
  );
};

export default InternalNoteTemplatesModal;
