import { MouseEvent } from 'react';

import { Common } from '@thecvlb/design-system';
import DeleteDocument from 'components/modals/DeleteDocument';
import dayjs from 'dayjs';
import { DateFormat } from 'enums/dateFormats';
import { useDispatch } from 'react-redux';
import { openModal } from 'store/modal/modalSlice';

import { DocumentProps } from './document.types';

const Document: React.FC<DocumentProps> = ({
  title,
  date,
  handleClick,
  handleDownload,
  byCurrentUser,
  uploadedByName,
  patientFullName,
  id,
  patientId,
  dataTestId
}) => {
  const dispatch = useDispatch();
  const handleClickDownload = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    handleDownload();
  };
  const handleClickDelete = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    dispatch(
      openModal({
        size: 'sm',
        hideClose: true,
        modalContent: <DeleteDocument title={title} documentId={id} patientId={patientId} />
      })
    );
  };
  return (
    <div
      data-testid={dataTestId}
      className="flex cursor-pointer items-center gap-3 p-4"
      onClick={handleClick}
    >
      <Common.Icon
        name="articles"
        className="size-[42px] rounded-lg bg-primary-50 p-[9px] text-primary"
      />
      <div className="flex flex-1 flex-col">
        <p className="text-base font-semibold text-gray-700">{title}</p>
        <p data-testid="uploaded_when_and_by" className="text-sm font-medium text-gray">
          {date && dayjs(date).format(`${DateFormat.MMM_DD_YYYY} • `)}
          {uploadedByName && !byCurrentUser ? uploadedByName : 'Me'}
        </p>
      </div>
      {uploadedByName.trim() !== patientFullName.trim() && (
        <Common.Button
          dataTestId="delete_btn"
          onClick={handleClickDelete}
          color="red-alt"
          size="sm"
          preIcon="trash"
          className="flex"
        >
          Delete
        </Common.Button>
      )}
      <Common.Button
        dataTestId="download_btn"
        onClick={handleClickDownload}
        color="green-alt"
        size="sm"
        preIcon="download"
        className="flex"
      >
        Download
      </Common.Button>
    </div>
  );
};

export default Document;
